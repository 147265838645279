import VerificationIcon from '@components/common/icons/verification.icon'

import styles from './index.module.scss'

const PostConfirmation = () => (
  <div className={styles.container}>
    <h2>Félicitation!</h2>
    <VerificationIcon />
    <p>
      Vous venez de valider votre livraison. ALB vous remercie de votre confiance. Nous mettons
      mettre tout en oeuvre afin de vous offrir le meilleur service.{' '}
    </p>
    <p>
      Vous recevrez par email et SMS une explication des démarches de sécurité à effectuer le jour
      de prise en main de votre véhicule.{' '}
    </p>
    <p>Merci et à bientôt. </p>
  </div>
)

export default PostConfirmation
