import { type FunctionComponent } from 'react'
import { Organization } from '@types_def/models/Organization.types'
import frenchDate from '@utils/functions/customDates'

export const TotalMembersCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const { members } = children as Organization
  return <>{members.length}</>
}

export const TotalDeliveriesCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const { deliveries } = children as Organization
  return <>{deliveries.length}</>
}

export const AssigneeCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const { owner } = children as Organization
  return (
    <>
      {owner.assigneeFirstName} {owner.assigneeLastName}
    </>
  )
}

export const AssigneeCellPhone: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const { owner } = children as Organization
  return <>{owner.assigneePhone}</>
}

export const DateCell: FunctionComponent = ({ children }) => {
  const { owner } = children as Organization
  const date = frenchDate(owner.createdAt)
  return <>{date}</>
}
