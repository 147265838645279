import type React from 'react'
import { type PropsWithChildren } from 'react'

import MainButton from '../Button'
import LoadingIcon from '../icons/loading.icon'
import styles from './index.module.scss'
import ClientSelector from './FormSection/clientSelector'
import TrackingSection from '@components/Delivery/DeliveryFormV2/TrackingSection'

type Props = {
  title?: string
  onSubmit?: () => void
  selectClient?: (client) => void
  loading?: boolean
  disabled?: boolean
  modalForm?: boolean
  showButton?: boolean
  isValid?: boolean
  editButton?: boolean
  editDisabled?: boolean
  onEditClick?: () => void
  admin?: boolean
  showClientSelector?: boolean
  showTrackingButton?: boolean
  deliveryId?: string
}

const MainForm = ({
  title,
  children,
  onSubmit,
  selectClient,
  disabled = false,
  loading = false,
  modalForm = false,
  showButton = true,
  isValid = true,
  editButton,
  editDisabled,
  onEditClick,
  admin = false,
  showClientSelector = false,
  showTrackingButton = false,
  deliveryId,
}: PropsWithChildren<Props>) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }
  const handleSelect = (client) => {
    Boolean(selectClient) && selectClient(client)
  }
  return (
    <div className={`${styles[modalForm ? 'modal-form' : 'main-form']} `}>
      <form noValidate={true} onSubmit={handleSubmit}>
        {title && (
          <header
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'capitalize',
            }}
          >
            <div
              className={`title ${modalForm ? 'title--bg' : ''}`}
              style={{ textAlign: 'center' }}
            >
              {title}
            </div>
            {showClientSelector && <ClientSelector onSelect={handleSelect} />}
            {showTrackingButton && <TrackingSection id={deliveryId} />}
          </header>
        )}
        {children}
        {showButton && Boolean(onSubmit) && (
          <div className={styles.buttonsContainer}>
            {Boolean(editButton) && (
              <MainButton
                buttonTheme='primary'
                disabled={editDisabled || loading}
                noFill={false}
                onClick={() => {
                  window.scrollTo(0, 0)
                  if (onEditClick) {
                    onEditClick()
                  }
                }}
              >
                Modifier
              </MainButton>
            )}
            <MainButton type='submit' disabled={disabled || loading || !isValid} noFill={false}>
              Confirmer
              {loading && <LoadingIcon />}
            </MainButton>
          </div>
        )}
      </form>
    </div>
  )
}

export default MainForm
