import get from '../generic/get'
import getDeepBySlug from '../generic/getBySlug'
import { DeepInclude } from '@types_def/common/filter.types'
import { CreateOrganizationInput, Organization } from '@types_def/models/Organization.types'
import { QueryType } from '@types_def/common/query.types'

const queryFields: QueryType<Organization> = {
  name: true,
  slug: true,
  settings: true,
  owner: {
    id: true,
    address: {
      postalCode: true,
      city: true,
      address: true,
      latitude: true,
      longitude: true,
      place_id: true,
    },
    assigneeFirstName: true,
    assigneeLastName: true,
    assigneeFunction: true,
    assigneeService: true,
    assigneeEmail: true,
    assigneePhone: true,
  },
}
export const getOrganizationDetails = async (data: {
  slug: string
  include?: DeepInclude<Organization>
}) => {
  const res = await getDeepBySlug<CreateOrganizationInput>(
    data.slug,
    'Organization',
    queryFields,
    data.include,
  )
  return res
}
