import { gql, graphqlQuery } from '@api/clients/apollo'
import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type Delivery } from '@types_def/models/delivery.types'

type Response = Pick<
  Delivery,
  | 'id'
  | 'rated'
  | 'outfitRating'
  | 'justiceRating'
  | 'qualityRating'
  | 'takeOffRating'
  | 'communicationRating'
  | 'correspondenceRating'
>

export const getDeliveryToBeRated = async (id: number): Promise<Response> => {
  try {
    const res = await graphqlQuery<ApiGetResponse<Response>, { id: number }>(
      gql`
        query {
         data:  getDeliveryToBeRated(
                id: ${id}
            ) {
                id
                outfitRating
                correspondenceRating
                justiceRating
                takeOffRating
                qualityRating
                communicationRating
                rated
              }
            }
        `,
      {
        id,
      },
    )
    if (res.errors !== undefined) {
      throw new Error(res.errors[0].message)
    }

    return res.data.data ?? ({} as Response)
  } catch (error) {
    throw new Error((error as { message: string }).message)
  }
}

// .then((res) => {
//   if (res.errors !== undefined) {
//     returnedValue = res.errors[0].message;
//   } else {
//     returnedValue = res.data.data!;
//   }
// })
// .catch((err) => {
//   return err;
// });
