import { toast } from 'react-toastify'
import { Socket, io } from 'socket.io-client'
import { UserRole } from '@types_def/models/user.type'

export type Location = {
  latitude: string
  longitude: string
  heading: string
  timestamp: string
  deliveryId: string
  driverId: string
}

function replacer(key, value) {
  if (value instanceof Map) {
    return { __type: 'Map', value: Object.fromEntries(value) }
  }
  if (value instanceof Set) {
    return { __type: 'Set', value: Array.from(value) }
  }
  return value
}

function reviver(key, value) {
  if (value?.__type === 'Set') {
    return new Set(value.value)
  }
  if (value?.__type === 'Map') {
    return new Map(Object.entries(value.value))
  }
  return value
}
const baseURL = import.meta.env.VITE_APP_API_BASE_URL
export class WebSocketSingleton {
  private static instance: WebSocketSingleton
  private socket: Socket
  private usersInDeliveris = new Map<[], string>([])
  connected = false
  usersInDelivery = new Map<string, []>([])
  constructor() {
    this.socket = io(baseURL + 'location', {
      autoConnect: false,
      transports: ['websocket', 'polling'],
      reconnectionDelay: 10000, // defaults to 1000
      reconnectionDelayMax: 10000, // defaults to 5000
    })

    this.socket.on('connect', () => {
      this.connected = true
      toast('connected to live tracking', {
        position: 'bottom-right',
        type: 'info',
      })
    })
    this.socket.on('disconnect', () => {
      this.connected = false
      toast('disconnected from sockets', {
        position: 'bottom-right',
        type: 'warning',
      })
    })
    this.socket.on('connect_error', (error) => {
      toast('disconnected from sockets', {
        position: 'bottom-right',
        type: 'error',
      })
      this.connected = false
    })

    this.socket.on('reconnect', () => {
      this.connected = true
    })
  }

  static getInstance(): WebSocketSingleton {
    if (!WebSocketSingleton.instance) {
      WebSocketSingleton.instance = new WebSocketSingleton()
    }
    return WebSocketSingleton.instance
  }

  async connect(_token?: string) {
    if (this.connected) {
      return
    }
    const token = _token ? _token : localStorage.getItem('AccessToken')
    this.socket.auth = { token }
    this.socket.connect()
  }

  async close() {
    this.socket.disconnect()
  }

  joinRoom(deliveryId: number, handler?: (response: any) => void) {
    this.socket.emit('join-delivery', { deliveryId }, (res) => {
      handler && handler(res)
    })
  }
  leaveRoom(deliveryId: number, handler?: (response: any) => void) {
    this.socket.emit('leave-delivery', { deliveryId }, handler)
  }
  sendLocation(data: Location, handler: (response: any) => void) {
    this.socket.emit('sendLocation', data, handler)
  }

  // * listeners
  onLocation(handler: (data: Location) => void) {
    this.socket.on('location', handler)
  }
  onAdminUpdate(handler: (data: any) => void) {
    this.socket.on('update-admin', (data) => {
      handler(data)
    })
  }
  onDriverUpdate(handler: (data: any) => void) {
    this.socket.on('update-driver', (data) => {
      handler(data)
    })
  }
  onPartnerUpdate(handler: (data: any) => void) {
    this.socket.on('update-partner', (data) => {
      handler(data)
    })
  }
  onOnliveDriversUpdate(handler: (data: any) => void) {
    this.socket.on('online-drivers', (data) => {
      handler(data)
    })
  }

  //get users

  // getDrivers() {
  //     let drivers = []
  //     this.usersInDeliveris.forEach((key, value) => {
  //         if (value.role === UserRole.DRIVER) {
  //             drivers.push(value)
  //         }
  //     })
  //     return drivers
  // }
  // getConnectedDrivers() {
  //     let drivers = []
  //     this.usersInDeliveris.forEach((key, value) => {
  //         if (value.role === UserRole.DRIVER && value.connected) {
  //             drivers.push(value)
  //         }
  //     })
  //     return drivers
  // }
}
