import { useQuery } from '@tanstack/react-query'
import get from '@api/graphql/generic/get'
import { type Delivery } from '@types_def/models/delivery.types'

const useDeliveryCodes = (publicId: string) => {
  return useQuery({
    queryKey: ['getCodes', publicId],
    queryFn: () => get<Delivery>(publicId, 'Delivery', { startCode: true, destinationCode: true }),
  })
}

export default useDeliveryCodes
