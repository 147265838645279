import { z } from "zod"

const LoginSchema = z.object({
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
  code: z.string().optional(),
  session: z.string().optional(),
})

type TLoginSchema = z.infer<typeof LoginSchema>

export { LoginSchema }
export type { TLoginSchema }
