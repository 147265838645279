import React, { ReactElement } from 'react';
import { Controller, useFormContext, ControllerProps } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

interface ControlledInputProps extends Omit<ControllerProps, 'render'> {
  label: string;
  children: ReactElement;
}

const ControlledInput: React.FC<ControlledInputProps> = ({
  name,
  label,
  children,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) =>
        React.cloneElement(children, {
          ...field,
          ...props,
          label,
          error: !!error,
          helpertext: error ? error.message : null,
        } as Partial<TextFieldProps>)
      }
    />
  );
};

export default ControlledInput;
