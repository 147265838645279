import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import globals from '@styles/globals.module.scss'
export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAFAFA',
  },
  // Hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
type Props = {
  id: number | string
  isItemSelected: boolean
  labelId: string
  isReadOnly?: boolean
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void
  className?: string
}
export const EnhancedTableRow = (props: React.PropsWithChildren<Props>) => {
  const {
    id,
    isItemSelected,
    labelId,
    handleClick,
    children,
    className,
    isReadOnly = false,
  } = props
  return (
    <StyledTableRow
      hover
      className={`data-table-row ${className ?? ''}`}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={`table-row-${id}`}
      selected={isItemSelected}
    >
      {!isReadOnly && (
        <TableCell padding='checkbox'>
          <Checkbox
            onClick={(event) => {
              handleClick(event, `${id}`)
            }}
            color='default'
            classes={{ root: globals['no-padding'] }}
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
      )}
      {children}
    </StyledTableRow>
  )
}
