import React from 'react'
import SideBar from './SideBar'
import { Outlet } from 'react-router-dom'
import { Grid } from '@mui/material'

type Props = {}

const ProfileOutlet = (props: Props) => {
    return (
        <Grid container position={'relative'} height={'100%'} spacing={2}>
            <Grid item xs={2} height={'100%'}>
                <SideBar />
            </Grid>
            <Grid item xs={10}>
                <Outlet />
            </Grid>
        </Grid>
    )
}

export default ProfileOutlet