import { Grid, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AlbInput from '@components/common/AlbInput'

type TClient = {}

const Client = ({}: TClient) => {
  const { t } = useTranslation()
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('delivery.form.client')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='clientFirstName'
          label={t('common.form.input.first-name')}
          type='TextField'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput name='clientLastName' label={t('common.form.input.last-name')} type='TextField' />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput name='clientPhone' label={t('common.form.input.phone')} type='PhoneField' />
      </Grid>

      <Grid item md={6} xs={12}>
        <AlbInput
          name='clientEmail'
          label={t('common.form.input.email')}
          type='TextField'
          inputType='email'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput name='clientCompany' label={t('client.form.company')} type='TextField' />
      </Grid>

      <Grid item md={6} xs={12}>
        <AlbInput name='clientSiret' label={t('client.form.siret')} type='TextField' />
      </Grid>
      <Grid item xs={12}>
        <AlbInput
          name='clientDrivingLicenceNumber'
          label={t('client.form.driving-licence-number')}
          type='TextField'
        />
      </Grid>
    </Grid>
  )
}

export default Client
