import { Box, Button, Divider, Modal } from '@mui/material'
import React, { Fragment } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { t } from 'i18next'
import Delivery from './delivery/Delivery'

const AnySearch = ({ isAdmin }: { isAdmin: boolean }) => {
  const searchSections = [
    {
      label: t('sections.delivery'),
      component: <Delivery />,
      requireAdmin: false,
      disabled: false,
    },
    {
      label: t('sections.driver'),
      component: <>Driver</>,
      requireAdmin: true,
      disabled: true,
    },
    {
      label: t('sections.manager'),
      component: <>Manager</>,
      requireAdmin: true,
      disabled: true,
    },
    {
      label: t('sections.organization'),
      component: <>Organization</>,
      requireAdmin: true,
      disabled: true,
    },
  ]

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [section, _setSection] = React.useState(0)
  return (
    <>
      <span
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '35px',
          width: '250px',
          backgroundColor: 'white',
          borderRadius: '6px',
          paddingLeft: 15,
          cursor: 'text',
        }}
        onClick={handleOpen}
      >
        <span style={{ color: '#333', textTransform: 'capitalize' }}>
          {t('layouts.header.search-anything')}
        </span>
        <SearchIcon color='primary' />
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '700px',
            minHeight: 'max-content',
            borderRadius: 2,
            backgroundColor: '#fff',
            border: 'none',
            outline: 'none',
          }}
        >
          <Box display={'flex'}>
            {searchSections.map((searchSection, index) => {
              if (searchSection.requireAdmin && !isAdmin) return null
              return (
                <Fragment key={searchSection.label}>
                  <Button
                    disabled={searchSection.disabled}
                    sx={{
                      flexGrow: 1,
                      paddingTop: 3,
                    }}
                    key={searchSection.label}
                    variant='text'
                    color='primary'
                  >
                    {searchSection.label}
                    {index == section ? (
                      <span
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          height: '2px',
                          width: '100%',
                          backgroundColor: '#fe5c8c',
                          display: 'block',
                        }}
                      ></span>
                    ) : null}
                  </Button>
                  <Divider orientation='vertical' flexItem />
                </Fragment>
              )
            })}
          </Box>
          <Box p={2} m={1}>
            {searchSections[section].component}
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default AnySearch
