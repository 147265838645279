import { Box } from '@mui/material'
import { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { useUserContext } from '@contexts/UserContext/index.context'

export const Unauthorized: FunctionComponent = () => {
  const { userState } = useUserContext()
  const redirectLink =
    userState && userState['custom:role'] ? userState['custom:role'].toLowerCase() : 'login'
  return (
    <Box p={5}>
      <h1>ALB</h1>
      <h2 style={{ padding: '1em 0' }}>
        {' '}
        Désolé, vous n'êtes pas autorisé à accéder à cette page !
      </h2>
      <h4>
        <Link to={`/${redirectLink}`}>Aller au Panneau</Link>
      </h4>
    </Box>
  )
}
