import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Delivery, DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { enumToStr } from '@utils/strEnum'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import { t } from 'i18next'
import { TablePaginationActions } from '../../MUI_Table'

const ActionCell = ({ data }: { data: Delivery }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1')

  const { type, status, id } = data
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <Button
      color='primary'
      variant='contained'
      size='small'
      onClick={() => navigate(`/${urlRole}/pilot/${strType}/${strStatus}/${id}`)}
    >
      {t('delivery.action.details')}
    </Button>
  )
}

type Props = {
  data:
    | {
        records: Delivery[]
        total: number
      }
    | undefined
  page: number
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
}

const DeliveryTable = ({ data, page, handleChangePage }: Props) => {
  if (!data || data.records.length === 0)
    return (
      <Typography textAlign={'center'} variant='body2' textTransform={'uppercase'}>
        {t('common.shared.no-data')}
      </Typography>
    )
  const { records, total } = data

  return (
    <Table
      sx={{
        height: '300px',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              textAlign: 'center',
            }}
          >
            {t('common.table.headers.Contrat')}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
            }}
          >
            {t('common.table.headers.status')}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
            }}
          >
            {t('common.table.headers.Action')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records?.map((delivery) => (
          <TableRow
            key={`delivery-${delivery.id}`}
            sx={{
              maxHeight: '50px',
            }}
          >
            <TableCell
              sx={{
                textAlign: 'center',
                borderLeft: `10px solid ${getDeliveryTypeColor(delivery.type)}`,
              }}
            >
              {delivery.id}
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
              }}
            >
              {t(`delivery.status.${delivery.status}`)}
              {/* {deliveryStatusAttributes.find((c) => c.key === delivery.status)?.title} */}
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
                maxHeight: '20px',
              }}
            >
              <ActionCell data={delivery} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={3}
            rowsPerPageOptions={[]}
            count={total || 0}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
            //onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={''}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default DeliveryTable
