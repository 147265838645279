const XIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5877 11.6114C14.1345 12.1583 14.1345 13.0441 13.5877 13.591C13.3165 13.8644 12.9577 14 12.599 14C12.2403 14 11.8824 13.8633 11.6095 13.5899L6.99945 8.98227L2.38988 13.5888C2.11646 13.8644 1.75818 14 1.39989 14C1.04161 14 0.683759 13.8644 0.410124 13.5888C-0.136708 13.042 -0.136708 12.1561 0.410124 11.6092L5.02101 6.99836L0.410124 2.38966C-0.136708 1.84283 -0.136708 0.956957 0.410124 0.410124C0.956957 -0.136708 1.84282 -0.136708 2.38966 0.410124L6.99945 5.0232L11.6103 0.412312C12.1572 -0.134521 13.043 -0.134521 13.5899 0.412312C14.1367 0.959144 14.1367 1.84501 13.5899 2.39184L8.97899 7.00273L13.5877 11.6114Z'
      fill='#000'
    />
  </svg>
)

export default XIcon
