import { gql, graphqlMutation } from '@api/clients/apollo'
import { Delivery, DeliveryStatus } from '@types_def/models/delivery.types'

type ApiUpdateResponse<Delivery> = {
  updateStatus: Delivery
}

export async function updateDeliveryStatus(input: { id: number; status: DeliveryStatus }) {
  const { id, status } = input
  try {
    const res = await graphqlMutation<ApiUpdateResponse<Delivery>, any>(
      gql`
        mutation updateDeliveryStatus($input: updateDeliveryStatusInput!) {
          updateDeliveryStatus(UpdateDeliveryStatusInput: $input) {
            id
            status
          }
        }
      `,
      { input },
    )
    return res.data?.updateStatus
  } catch (error) {
    throw new Error(error as string)
  }
}

export default updateDeliveryStatus
