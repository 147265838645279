import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Authenticated from '@components/auth'
import ClientSubHeader from '@components/common/header/SubHeader/ClientsSubHeader'
import DashboardSubHeader from '@components/common/header/SubHeader/DashboardSubHeader'
import DriverSubHeader from '@components/common/header/SubHeader/DriversSubHeader'
import FormSubHeader from '@components/common/header/SubHeader/FormSubHeader'
import InvoiceSubheader from '@components/common/header/SubHeader/InvoicesSubHeader'
import PilotSubHeader from '@components/common/header/SubHeader/PilotSubheader'
import { Dashboard } from '@components/Dashboard'
import { AcceptDeliveryByClient } from '@components/Delivery/AcceptDeliveryByClient'
import DeliveryCreationConfirmed from '@components/Delivery/ConfirmedCreatedDelivery'
import CreateDeliveryDeal from '@components/Delivery/CreateDeliveryDeal'
import { DeliveryCustomerMap } from '@components/Delivery/DeliveryCustomerMap'
import DeliveryMap from '@components/Delivery/DeliveryMap'
import DeliveryRating from '@components/Delivery/DeliveryRating'
import { DeliveriesTable } from '@components/Delivery/DeliveryTable'
import DriverRequestDetails from '@components/Driver/DriverRequestDetails'
import DriversTable from '@components/Driver/DriversTable'
import DriverMandate from '@components/Driver/Mandate'
import Help from '@components/Help'
import DeliveryInvoicesTable from '@components/Invoices/DeliveryPayment'
import SubscriptionInvoicesTable from '@components/Invoices/SubscriptionPayment'
import OffersTable from '@components/Offer/Table'
import { RoutePaths } from '@utils/constants/routes'

import Login from '../components/auth/Login'
import { DriverContextProvider } from '@contexts/DriverContext/index.context'
import NewDelivery from './newDelivery'
import ViewDelivery from './ViewDelivery'
import EditDelivery from './EditDelivery'
import AdminSubHeader from '@components/common/header/SubHeader/AdminsSubHeader'
import Admins from '@components/admins/Admins'
import { UserRank } from '@types_def/models/user.type'
import CalendarView from './CalendarView'
import ClientOrganizationSubHeader from '@components/common/header/SubHeader/ClientOrganizationSubHeader'
import ClientOrganization from './ClientOrganization'
import OrganizationTable from '@components/organization/OrganizationTable'
import OrganizationForm from '@components/organization/OrganizationForm'
import { DriverDeliveriesTable } from '@components/Driver/DriverHistoryTable'
import ProfileOutlet from '@components/Profile'
import Profile from '@components/Profile/Profile'
import OrganizationMembersTable from '@components/organization/OrganizationMembersTable'
import ClientOrganizationAddMember from './ClientOrganizationAddMember'

type Props = {
  subHeader?: JSX.Element
}
const AppLayout = ({ subHeader }: Props) => (
  <>
    {subHeader}
    <Outlet />
  </>
)
const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path={RoutePaths.deliveryRating} element={<DeliveryRating />} />
      <Route path={RoutePaths.deliveryCustomerConfirmation} element={<AcceptDeliveryByClient />} />
      <Route path={RoutePaths.deliveryCustomerTracking} element={<DeliveryCustomerMap />} />
      <Route path={RoutePaths.driverMandate} element={<DriverMandate />} />
      <Route path={RoutePaths.login} element={<Login />} />
      <Route element={<Authenticated />}>
        <Route path={RoutePaths.client}>
          <Route element={<AppLayout />}>
            <Route path={RoutePaths.clientHelp} element={<Help />} />
          </Route>
          <Route path={RoutePaths.clientProfile} element={<ProfileOutlet />}>
            <Route path={RoutePaths.clientProfile + '/:id'} element={<Profile />} />
          </Route>
          <Route
            path={RoutePaths.clientNewDelivery}
            element={<AppLayout subHeader={<FormSubHeader />} />}
          >
            <Route path={`${RoutePaths.clientNewDelivery}/:type`} element={<NewDelivery />} />
            <Route
              path={RoutePaths.deliveryCreationConfirmed}
              element={<DeliveryCreationConfirmed />}
            />
          </Route>
          <Route
            path={`${RoutePaths.clientOrganization}`}
            element={<AppLayout subHeader={<ClientOrganizationSubHeader />} />}
          >
            <Route path={`${RoutePaths.clientOrganization}`} element={<ClientOrganization />} />
            <Route
              path={RoutePaths.clientOrganizationAddMember}
              element={<ClientOrganizationAddMember />}
            />
            <Route
              path={RoutePaths.clientOrganizationListManager}
              element={<OrganizationMembersTable memberRank={UserRank.ORGANIZATION_MANAGER} />}
            />
            <Route
              path={RoutePaths.clientOrganizationListMember}
              element={<OrganizationMembersTable memberRank={UserRank.ORGANIZATION_MEMBER} />}
            />
          </Route>
          <Route
            path={`${RoutePaths.clientEditDelivery}/:type/:status/:contract`}
            element={<EditDelivery />}
          />
          <Route
            path={RoutePaths.clientPilot}
            element={<AppLayout subHeader={<PilotSubHeader />} />}
          >
            <Route
              path={`${RoutePaths.clientPilotDeliveryTable}/:status`}
              element={<DeliveriesTable />}
            />
            <Route path={`${RoutePaths.clientCalendarView}`} element={<CalendarView />} />

            <Route path={RoutePaths.clientPilotDeliveryOngoingMap} element={<DeliveryMap />} />
            <Route path={RoutePaths.pilotageDeliveredItem} element={<ViewDelivery />} />
          </Route>

          <Route path={RoutePaths.client} element={<Navigate to={RoutePaths.clientDashboard} />} />
          <Route
            path={RoutePaths.clientDashboard}
            element={<AppLayout subHeader={<DashboardSubHeader />} />}
          >
            <Route path={RoutePaths.clientDashboard} element={<Dashboard />} />
          </Route>
        </Route>
        <Route path={RoutePaths.admin}>
          <Route path={`${RoutePaths.adminNewDelivery}/:type`} element={<NewDelivery />} />
          <Route
            path={RoutePaths.adminPilot}
            element={<AppLayout subHeader={<PilotSubHeader />} />}
          >
            <Route path={RoutePaths.adminEditDeal} element={<EditDelivery />} />

            <Route path={RoutePaths.adminDeliveryForm} element={<ViewDelivery />} />

            <Route
              path={`${RoutePaths.adminPilotCreateDealRequested}/:type/:contract`}
              element={<CreateDeliveryDeal />}
            />
            <Route
              path={`${RoutePaths.adminPilotCreateDealConfirmed}/:type/:contract`}
              element={<CreateDeliveryDeal />}
            />
            <Route
              path={'/admin/pilot/edit-deal/waiting-for-driver/:type/:contract'}
              element={<CreateDeliveryDeal />}
            />
            <Route
              path={'/admin/pilot/edit-deal/alert/:type/:contract'}
              element={<CreateDeliveryDeal />}
            />
            <Route
              path={`${RoutePaths.adminPilotDeliveryTable}/:status`}
              element={<DeliveriesTable />}
            />
            <Route path={RoutePaths.adminPilotOffers} element={<OffersTable />} />
            <Route path={RoutePaths.adminPilotDeliveryOngoingMap} element={<DeliveryMap />} />
            <Route path={RoutePaths.adminPilotageDeliveredItem} element={<ViewDelivery />} />
          </Route>
          <Route
            path={RoutePaths.adminDrivers}
            element={<AppLayout subHeader={<DriverSubHeader />} />}
          >
            <Route
              path={`${RoutePaths.adminDriversTable}/:tableRoute`}
              element={<DriversTable />}
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTableWaitingRequests}/:id`}
              element={
                <DriverContextProvider>
                  <DriverRequestDetails
                    canEdit={false}
                    page={RoutePaths.waitingRequests.slice(1)}
                  />
                </DriverContextProvider>
              }
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTableacceptedRequests}/:id`}
              element={
                <DriverContextProvider>
                  <DriverRequestDetails
                    canEdit={true}
                    page={RoutePaths.acceptedRequests.slice(1)}
                  />
                </DriverContextProvider>
              }
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTableacceptedHistory}/:id`}
              element={<DriverDeliveriesTable />}
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTablerefusedRequests}/:id`}
              element={
                <DriverContextProvider>
                  <DriverRequestDetails
                    canEdit={false}
                    page={RoutePaths.refusedRequests.slice(1)}
                  />
                </DriverContextProvider>
              }
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTabledisabledDrivers}/:id`}
              element={
                <DriverContextProvider>
                  <DriverRequestDetails
                    canEdit={false}
                    page={RoutePaths.disabledDrivers.slice(1)}
                  />
                </DriverContextProvider>
              }
            ></Route>
            <Route
              path={`${RoutePaths.adminDriversTabledisabledDriversHistory}/:id`}
              element={<DriverDeliveriesTable />}
            ></Route>
          </Route>
          <Route
            path={RoutePaths.adminOrganization}
            element={<AppLayout subHeader={<ClientSubHeader />} />}
          >
            <Route path={RoutePaths.adminNewOrganization} element={<OrganizationForm />} />
            <Route path={RoutePaths.adminOrganizationsTable} element={<OrganizationTable />} />
            <Route
              path={`${RoutePaths.adminOrganizationsTable}/:slug`}
              element={<OrganizationForm />}
            />
            <Route
              path={`${RoutePaths.adminOrganizationsTable}/view-members/:organizationName/:slug`}
              element={<OrganizationMembersTable memberRank={UserRank.ORGANIZATION_MEMBER} />}
            />
            <Route
              path={`${RoutePaths.adminOrganizationsTable}/view-managers/:organizationName/:slug`}
              element={<OrganizationMembersTable memberRank={UserRank.ORGANIZATION_MANAGER} />}
            />
          </Route>
          <Route
            path={RoutePaths.adminManage}
            element={<AppLayout subHeader={<AdminSubHeader />} />}
          >
            <Route
              path={RoutePaths.adminManageAdmins}
              element={<Admins rank={UserRank.SUPER_ADMIN} />}
            />
            <Route
              path={RoutePaths.adminManageMaintainer}
              element={<Admins rank={UserRank.MAINTAINER} />}
            />
            <Route
              path={RoutePaths.adminManageSupport}
              element={<Admins rank={UserRank.SUPPORT} />}
            />
          </Route>

          <Route
            path={RoutePaths.adminInvoices}
            element={<AppLayout subHeader={<InvoiceSubheader />} />}
          >
            <Route
              path={`${RoutePaths.adminInvoicesDriver}/subscription`}
              element={<SubscriptionInvoicesTable />}
            />
            <Route
              path={`${RoutePaths.adminInvoicesDriver}/delivery`}
              element={<DeliveryInvoicesTable />}
            />
            {/* <Route
                path={RoutePaths.adminInvoicesClient}
                element={<SubscriptionInvoicesTable />}
              /> */}
          </Route>
          <Route path={RoutePaths.admin} element={<Navigate to={RoutePaths.adminDashboard} />} />
          <Route
            path={RoutePaths.adminDashboard}
            element={<AppLayout subHeader={<DashboardSubHeader />} />}
          >
            <Route path={RoutePaths.adminDashboard} element={<Dashboard />} />
          </Route>
        </Route>
      </Route>
      <Route path='/' element={<Navigate to={RoutePaths.login} />} />

      {/* <Route path="*" element={<AppLayout />}>
          <Route path="*" element={<Dashboard />} />
        </Route> */}
    </Routes>
  </Router>
)

export default AppRoutes
