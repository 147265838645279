const ChatIcon = () => (
  <svg width='25' height='20' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.25 6.875C16.25 3.07812 12.6133 0 8.12505 0C3.63678 0 7.242e-05 3.07812 7.242e-05 6.875C7.242e-05 8.4207 0.610227 9.84219 1.6278 10.9922C0.98757 12.2719 0.0953846 13.2312 0.0813222 13.2453C-0.000708828 13.3314 -0.0229744 13.4592 0.0250333 13.5716C0.074291 13.6836 0.179838 13.75 0.29929 13.75C1.79733 13.75 3.06334 13.3156 4.03912 12.8109C5.24224 13.4023 6.63287 13.75 8.12505 13.75C12.6133 13.75 16.25 10.6719 16.25 6.875ZM23.4219 17.332C24.4062 16.168 25 14.7109 25 13.125C25 9.32812 21.6406 6.25 17.5 6.25C17.4877 6.25 17.4758 6.2516 17.4636 6.25168C17.4805 6.45703 17.5 6.66406 17.5 6.875C17.5 10.7273 14.3875 13.9531 10.2305 14.7852C11.043 17.7773 13.9493 20 17.5 20C18.816 20 20.0516 19.6872 21.127 19.1414C22.0781 19.6094 23.2852 20 24.6992 20C24.8187 20 24.9242 19.9326 24.9734 19.8201C25.0214 19.7077 24.9991 19.58 24.9172 19.4938C24.9063 19.4805 24.0586 18.5664 23.4219 17.332Z'
      fill='#fff'
    />
  </svg>
)

export default ChatIcon
