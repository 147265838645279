import LoadingIcon from '@components/common/icons/loading.icon'

import styles from './index.module.scss'

const RatingLoading = () => (
  <div className={styles.ratingLoading}>
    <p>
      Nous collectons vos coordonnées de livraison. <br />
      Merci pour votre patience
    </p>
    <LoadingIcon />
  </div>
)

export default RatingLoading
