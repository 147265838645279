import { type GetAllInput } from '@types_def/common/filter.types'
import { type Client } from '@types_def/models/user.type'

import { sendRequest } from '../../clients/axios'

export function downloadClients() {
  sendRequest<GetAllInput<Client, 'id'>, { data: Iterable<number> }>('post', 'clients/downloadXlsx')
    .then((res) => {
      if (res.status === 201) {
        const bufferParsed = new Uint8Array(res.data.data)
        const blob = new Blob([bufferParsed], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        return URL.createObjectURL(blob)
      }
    })
    .then((fileURL) => {
      const windowReference = window.open('', '_self')
      // @ts-expect-error
      if (windowReference) {
        windowReference.location = fileURL
      }
    })
}
