import {
  Button,
  Container,
  DialogContentText,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { gql } from '@apollo/client'
import React, { useEffect } from 'react'
import NestedList from './List'
import DATA from './DATA.json'
import { useNavigate, useParams } from 'react-router-dom'
import { graphqlQuery } from '@api/clients/apollo'
import Questions from './questions'
import { sendRequest } from '@api/clients/axios'
import DriverDetailsItem from '../DriverDetails/Item'
import PieChartIcon from '@mui/icons-material/PieChart'
import StarIcon from '@components/common/icons/star.icon'
import EmptyStarIcon from '@components/common/icons/emptyStar.icon'
import Driver from '@types_def/models/driver.types'
import CustomModal from '@components/common/CustomModal'
import ReceiptIcon from '@mui/icons-material/Receipt'
import CommuteIcon from '@mui/icons-material/Commute'
import { Box, Fab } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useDriverContext } from '@contexts/DriverContext/index.context'
import { toast } from 'react-toastify'
import { adminDeleteDriver } from '@api/graphql/driver/deleteDriver'
import { UpdateDriver } from '@api/graphql/driver/updateDriver'
import './index.module.scss'
import { t } from 'i18next'
import { RoutePaths } from '@utils/constants/routes'
import { Link } from 'react-router-dom'
import { adminResetPassword } from '@api/auth/adminResetPassword'
import adminGetUser from '@api/auth/admins'

type Data = {
  getDriverById: Driver
}
type ModalConfig = {
  title: string
  content: string
}

type ButtonConfig = {
  action: () => void
  buttonColor: 'success' | 'inherit' | 'info' | 'warning' | 'error' | 'primary' | 'secondary'
  buttonText: string
  modal: ModalConfig
}

type RouteConfig = {
  [key: string]: ButtonConfig[]
}

// const prefix = import.meta.env.VITE_APP_S3_MEDIA_BUCKET_URL

// const getVideoUrl = (videoPath: string) => `${prefix}/${videoPath}`
const FloatingSauvgardeButton = ({ changes, handleSave }) => {
  return (
    <Box
      sx={{
        '& > :not(style)': { m: 1 },
        position: 'fixed',
        right: '3vw',
        marginTop: '3vh',
      }}
    >
      <Fab
        variant='extended'
        color='primary'
        sx={{ float: 'right' }}
        onClick={() => handleSave(changes)}
      >
        <SaveIcon sx={{ mr: 1 }} />
        Sauvegarde
      </Fab>
    </Box>
  )
}

type TDriverRequestDetails = {
  canEdit?: boolean
  isOrWasActive?: boolean
  page?: string
}
const DriverRequestDetails = ({
  canEdit = true,
  isOrWasActive = true,
  page = 'waiting-requests',
}: TDriverRequestDetails) => {
  const [driverData, setDriverData] = React.useState<Driver | undefined>()
  const [forceChangePassword, setForceChangePassword] = React.useState(false)
  useEffect(() => {
    if (driverData) {
      adminGetUser(driverData.email).then((res: any) => {
        if (res?.data?.UserStatus === 'FORCE_CHANGE_PASSWORD') setForceChangePassword(true)
      })
    }
  }, [driverData])
  const { id } = useParams()
  const { changes, setChanges } = useDriverContext()
  const routeConfigs: RouteConfig = {
    'accepted-requests': [
      {
        action: () => handleDisable(),
        buttonText: 'Désactiver le conducteur',
        buttonColor: 'warning',
        modal: {
          title: 'Désactiver le conducteur',
          content: 'Êtes-vous sûr de vouloir désactiver ce conducteur?',
        },
      },
    ],
    'waiting-requests': [
      {
        action: async () => await handleReject(),
        buttonText: 'Refuser la demande du conducteur',
        buttonColor: 'warning',
        modal: {
          title: 'Refuser la demande du conducteur',
          content: 'Êtes-vous sûr de vouloir refuser cette demande du conducteur?',
        },
      },
      {
        action: async () => await handleAccept(),
        buttonText: 'Accepter le conducteur',
        buttonColor: 'success',
        modal: {
          title: 'Accepter le conducteur',
          content: 'Êtes-vous sûr de vouloir accepter ce conducteur?',
        },
      },
    ],
    'refused-requests': [
      {
        action: async () => await handleCancelReject(),
        buttonText: 'Annuler le refus du conducteur',
        buttonColor: 'info',
        modal: {
          title: 'Annuler le refus du conducteur',
          content: 'Êtes-vous sûr de vouloir annuler le refus de cette demande du conducteur?',
        },
      },
    ],
    'disabled-drivers': [
      {
        action: async () => await handleEnable(),
        buttonText: 'Activer le conducteur',
        buttonColor: 'success',
        modal: {
          title: 'Activer le conducteur',
          content: 'Êtes-vous sûr de vouloir activer ce conducteur?',
        },
      },
      {
        action: async () => await handleDelete(),
        buttonColor: 'error',
        buttonText: 'Supprimer le conducteur',
        modal: {
          title: 'Supprimer le conducteur',
          content: 'Êtes-vous sûr de vouloir supprimer ce conducteur?',
        },
      },
    ],
  }
  const buttons = routeConfigs[page]
  const handleSave = (changes) => {
    const driver = { ...changes }
    if (changes?.address) {
      driver.address.id = driverData.address.id
    }

    UpdateDriver(Number(id), driver)
      .then((res) => {
        toast('Les modifications sont enregistrées', {
          position: 'bottom-right',
          type: 'success',
        })
        setChanges({})
      })
      .catch((err) => {
        toast('Une erreur est survenue', { position: 'bottom-right', type: 'error' })
      })
  }

  const navigate = useNavigate()

  const handleAccept = async () => {
    try {
      await sendRequest('post', `/drivers/confirmByAlbExpress`, { driverId: id })
      toast("L'acceptation du demande du chauffeur a été effectuée.", {
        position: 'bottom-right',
        type: 'success',
      })
      navigate('/admin/drivers/drivers-table/accepted-requests')
      return
    } catch (error) {
      toast(error ?? 'Une erreur est survenue', {
        position: 'bottom-right',
        type: 'error',
      })
    }
  }

  const handleReject = async () => {
    try {
      await sendRequest('post', `/drivers/reject-driver-request`, { email: driverData.email })
      toast('La rejection du demande du chauffeur a été effectuée.', {
        position: 'bottom-right',
        type: 'success',
      })
      navigate(-1)
      return
    } catch (error) {
      toast(error ?? 'Une erreur est survenue lors de la rejecti', {
        position: 'bottom-right',
        type: 'error',
      })
    }
  }
  const handleCancelReject = async () => {
    try {
      await sendRequest('post', `/drivers/cancel-reject-driver-request`, {
        email: driverData.email,
      })
      toast('L annulation de rejection du demande du chauffeur a été effectuée.', {
        position: 'bottom-right',
        type: 'success',
      })
      navigate(-1)
      return
    } catch (error) {
      toast(error ?? 'Une erreur est survenue lors de la rejecti', {
        position: 'bottom-right',
        type: 'error',
      })
    }
  }

  const handleDisable = async () => {
    try {
      await sendRequest('post', `/drivers/disable-driver`, { email: driverData.email })
      toast('La disactivation du compte du chauffeur a été effectuée.', {
        position: 'bottom-right',
        type: 'success',
      })
      navigate(-1)
      return
    } catch (error) {
      toast(error ?? 'Une erreur est survenue', {
        position: 'bottom-right',
        type: 'error',
      })
    }
  }

  const handleEnable = async () => {
    try {
      await sendRequest('post', `/drivers/enable-driver`, { email: driverData.email })
      toast("L'activation du compte du chauffeur a été effectuée.", {
        position: 'bottom-right',
        type: 'success',
      })
      navigate(-1)
      return
    } catch (error) {
      toast(error ?? 'Une erreur est survenue', {
        position: 'bottom-right',
        type: 'error',
      })
    }
  }

  const handleDelete = async () => {
    adminDeleteDriver(Number(driverData?.id))
      .then((res) => {
        toast('La suppression du compte du chauffeur a été effectuée.', {
          position: 'bottom-right',
          type: 'success',
        })
        navigate(-1)
      })
      .catch((err) => {
        toast(err ?? 'Une erreur est survenue', {
          position: 'bottom-right',
          type: 'error',
        })
      })
      .finally(() => {})
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await graphqlQuery<Data, { GetByIdInput: { id: number } }>(
        gql`
          query getDriverById($GetByIdInput: GetByIdInput!) {
            getDriverById(GetByIdInput: $GetByIdInput) {
              id
              confirmedByAlb
              disabled
              firstName
              lastName
              email
              birthDate
              videoPath
              phoneNumber
              address {
                id
                postalCode
                city
                address
                addressPrecision
              }
              drivingLicenseDate
              drivingPermitNumber
              drivingPermitDueDate
              drivingLicensePath
              drivingLicensePath2
              idPath
              idPath2
              kbisPath
              siret
              freelancer
              company
              mcqAnswers
              mcqScore
              iban
              ribPath
              permit_solde_path
              subdivision
              driverType
              nbDeals
              topDeals
              lossDeals
              stokeDeals
              takeOffRating
              outfitRating
              qualityRating
              correspondenceRating
              justiceRating
              totalRating
              driverOffersPrice
              driverAttentePrice
              driverAverageBills
              subscription {
                name
                price
              }
            }
          }
        `,
        {
          GetByIdInput: {
            id: Number(id),
          },
        },
      )
      if (!data) return
      setDriverData(data.getDriverById)
    })()
  }, [id])
  return (
    <Container
      sx={{
        py: 4,
      }}
      maxWidth='xl'
    >
      {driverData ? (
        <Grid container spacing={4}>
          {driverData.confirmedByAlb ? (
            <Grid item xs={12}>
              <Stack spacing={2} direction={'row'}>
                <Button variant='outlined' color='primary'>
                  <Link
                    to={`${
                      driverData.disabled
                        ? RoutePaths.adminDriversTabledisabledDriversHistory
                        : RoutePaths.adminDriversTableacceptedHistory
                    }/${driverData.id}`}
                  >
                    {t('driver.driver-history')} ({driverData?.nbDeals ?? 0})
                  </Link>
                </Button>
                {forceChangePassword && (
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => adminResetPassword({ username: driverData.email })}
                  >
                    réinitialiser le mot de passe
                  </Button>
                )}
              </Stack>
              <Divider sx={{ paddingY: 1 }} />
            </Grid>
          ) : null}
          {DATA.map((item, index) => (
            <Grid item xs={12} md={6} key={index} spacing={4}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <Grid item xs={12}>
                  <Typography variant='h5' component='h4' sx={{ color: '#00244fff' }}>
                    {item.title}
                  </Typography>
                </Grid>
                <NestedList
                  details={item.details}
                  media={item.media}
                  // @ts-ignore
                  driverData={driverData}
                  canEdit={canEdit}
                />
              </Paper>
            </Grid>
          ))}
          {!isOrWasActive ? (
            <>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#E7E6F7',
                    color: '#fff',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Grid item xs={12} spacing={2}>
                    <Stack
                      marginY={2}
                      direction='row'
                      spacing={2}
                      alignItems={'center'}
                      color={'#00244f'}
                    >
                      <CommuteIcon />
                      <Typography variant='h5' component='h4'>
                        Deals
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      color: '#00244f',
                    }}
                  >
                    {[
                      { title: 'Nombre de betdeals', number: driverData.nbDeals },
                      { title: 'Nombre de topdeals', number: driverData.topDeals },
                      { title: 'Nombre de lossdeals', number: driverData.lossDeals },
                      { title: 'Nombre de dealsinstock', number: driverData.stokeDeals },
                    ].map((deal, key) => (
                      <Grid item xs={6} key={`driver-delivery-deal-item-${key}`}>
                        <DriverDetailsItem title={deal.title}>
                          {deal.number ?? (
                            <div style={{ fontSize: 'var(--medium-font-size)' }}>
                              Pas encore d'offres
                            </div>
                          )}
                        </DriverDetailsItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#E7E6F7',
                    color: '#00244f',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <Stack margin={2} direction='row' spacing={2} alignItems={'center'}>
                      <ReceiptIcon />
                      <Typography variant='h5' component='h4'>
                        Factures
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      color: '#00244f',
                    }}
                  >
                    {[
                      {
                        title: 'Facturation total effectuée',
                        billing: driverData.driverOffersPrice,
                      },
                      {
                        title: 'Facturation en attente',
                        billing: driverData.driverAttentePrice,
                      },
                      {
                        title: 'Moyenne des factures',
                        billing: driverData.driverAverageBills,
                      },
                    ].map((invoice, key) => {
                      return (
                        <Grid item xs={6} key={`driver-delivery-invoice-item-${key}`}>
                          <DriverDetailsItem title={invoice.title}>
                            {invoice.billing ? (
                              <>{invoice.billing.toFixed(2)} €</>
                            ) : (
                              <div style={{ fontSize: 'var(--medium-font-size)' }}>
                                Pas encore de factures
                              </div>
                            )}
                          </DriverDetailsItem>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#E7E6F7',
                    color: '#00244f',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <Stack marginY={2} direction='row' spacing={2} alignItems={'center'}>
                      <PieChartIcon />
                      <Typography variant='h5' component='h4'>
                        Performance
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid container spacing={2} sx={{ color: '#00244f' }}>
                    {[
                      {
                        title: 'Ponctualité de la livraison',
                        rating: driverData.takeOffRating,
                      },
                      { title: 'Tenue du Driver', rating: driverData.outfitRating },
                      {
                        title: 'Serviabilité du Driver',
                        rating: driverData.qualityRating,
                      },
                      {
                        title: "Correspondance entre l’état du véhicule de départ  et d'arrivée",
                        rating: driverData.correspondenceRating,
                      },
                      {
                        title: 'Justesse des informations communiquées ',
                        rating: driverData.justiceRating,
                      },
                      {
                        title: 'Moyenne',
                        rating: driverData.totalRating,
                      },
                    ].map((item, key) => (
                      <Grid item xs={6} key={`driver-delivery-performance-item-${key}`}>
                        <DriverDetailsItem title={item.title}>
                          {item.rating ? (
                            <>
                              {Array.apply(0, Array(5)).map((x, i) => {
                                if (item.rating && Math.ceil(item.rating) >= i + 1) {
                                  return <StarIcon key={`star-icon-${i}`} />
                                }

                                return <EmptyStarIcon key={`empty-star-icon-${i}`} />
                              })}
                            </>
                          ) : (
                            <div style={{ fontSize: 'var(--medium-font-size)' }}>
                              Pas encore d'évaluation
                            </div>
                          )}
                        </DriverDetailsItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            {/* @ts-ignore */}
            <Questions data={driverData.mcqAnswers} />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              {buttons.map(({ action, buttonText, buttonColor, modal }) => {
                return (
                  <CustomModal
                    key={buttonText}
                    button={buttonText}
                    color={buttonColor}
                    cancelText='Annuler'
                    confirmText={'Confirmer'}
                    confirmAction={action}
                    title={modal.title}
                    buttonStyles={{
                      flexGrow: 1,
                    }}
                  >
                    <DialogContentText id='alert-dialog-description'>
                      {modal.content}
                    </DialogContentText>
                  </CustomModal>
                )
              })}
            </div>
          </Grid>

          {Object.keys(changes).length ? (
            <FloatingSauvgardeButton changes={changes} handleSave={handleSave} />
          ) : null}
        </Grid>
      ) : (
        <p>loading......</p>
      )}
    </Container>
  )
}

export default DriverRequestDetails
