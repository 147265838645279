import { type DeliveryOptions } from '@types_def/models/delivery.types'
import { capitalizeFirstLetter } from '@utils/functions/capitalizeFirstLetter'

type Props = {
  options: DeliveryOptions
  quantity: number
}
const OptionsConcat = ({ options, quantity }: Props) => (
  <>
    {options?.selected.map((option, index) => (
      <span key={option.name}>
        {capitalizeFirstLetter(option.name)}
        {index < quantity - 1 && ', '}
      </span>
    ))}
  </>
)

export default OptionsConcat
