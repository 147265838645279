import { Rating } from 'react-simple-star-rating'

import { type RatingType } from '@types_def/components/rating'

import styles from './index.module.scss'

type Props = {
  value: number
  label: string
  ratingKey: keyof RatingType
  viewOnly: boolean
  handleRatingChange: (val: number, key: keyof RatingType) => void
}

const DeliveryRatingStar = ({ value, ratingKey, label, viewOnly, handleRatingChange }: Props) => (
  <div className={`${styles.deliveryRatingStar}`}>
    <label>{label}</label>
    <Rating
      onClick={(value) => {
        handleRatingChange(value, ratingKey)
      }}
      ratingValue={value}
      size={20}
      readonly={viewOnly}
    />
  </div>
)

export default DeliveryRatingStar
