import React from 'react'

export const DriverContext = React.createContext({
  changes: {},
  setChanges: (changes: any) => {},
})

export const DriverContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [changes, setChanges] = React.useState({})

  return <DriverContext.Provider value={{ changes, setChanges }}>{children}</DriverContext.Provider>
}

export const useDriverContext = () => React.useContext(DriverContext)
