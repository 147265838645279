import React from 'react'
import { sendRequest } from '@api/clients/axios'
import MainButton from '@components/common/Button'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import LoadingButton from '@mui/lab/LoadingButton'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
type Props = {
  id: string
}

const TrackingSection = ({ id }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const handleClick = async () => {
    setLoading(true)
    const res = await sendRequest('post', `delivery/sendTrackingUrl/${id}`)
    if (res.status === 201) {
      toast('Mail et sms envoyés avec succès', {
        position: 'bottom-right',
        type: 'success',
      })
    }
    setLoading(false)
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <LoadingButton
        loading={loading}
        loadingPosition='start'
        startIcon={<ForwardToInboxIcon />}
        variant='outlined'
        onClick={handleClick}
      >
        {t('common.shared.resend-email-sms-tracking')}
      </LoadingButton>
    </Box>
  )
}

export default TrackingSection
