import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { downloadDeliveries } from '@api/Rest/download/dealsDownloadXLSX'
import { SelectInput } from '@components/common/inputs/SelectInput'
import { GenericTable } from '@components/common/table'
import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import {
  type DeepInclude,
  type KeyValuePair,
  type PrismaFilter,
  type WhereFilter,
} from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { type Delivery, DeliveryStatus, type DeliveryType } from '@types_def/models/delivery.types'
import { selectListAllOptions } from '@utils/constants/deliveryOptions'
import { deliveryStatusRoutes } from '@utils/constants/DeliveryStatus/deliveryStatusRoutes'
import { getDeliveryAttributesByStatus } from '@utils/functions/Delivery/DeliveryStatus/deliveryStatusAttributes'
import { GenericTableContext } from '@contexts/GenericTableContext/GenericTableContext'
import { DriverHistoryHeadCell } from './HeadCells/driverHistory'
import { t } from 'i18next'
import DeliveryTableTypeFilter from '@components/Delivery/DeliveryTable/Filters/TypeFilter'
import DeliveryTableDateFilter from '@components/Delivery/DeliveryTable/Filters/DateFilter'
import { useDriverDetails } from '../Querys'
import DriverQuickDetails from '../DriverQuickDetails'

type TableState = {
  filter?: WhereFilter<Delivery>
  headCells: Array<HeadCell<Delivery>>
  include?: DeepInclude<Delivery>
  deliveriesTableQueryFields: QueryType<Delivery> | undefined
  filterComponents?: JSX.Element[]
  tableFilter?: { [key: string]: any }
}
type Params = {
  status: keyof typeof deliveryStatusRoutes
}
export const DriverDeliveriesTable = () => {
  let [searchParams, setSearchParams] = useSearchParams()

  // Getting all filter from URL params
  const id = searchParams.get('id')
  const vin = searchParams.get('vin')
  const type = searchParams.get('type') as DeliveryType
  const gte = searchParams.get('gte')
  const lte = searchParams.get('lte')
  const optionNameFilter = searchParams.get('optionNameFilter')
  const page = searchParams.get('page')

  const [key, setKey] = useState(0)

  // State and hooks
  const location = useLocation()

  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1') as 'admin' | 'client'
  const { id: driverId } = useParams()
  const [tableState, setTableState] = React.useState<TableState>({
    filter: {
      selectedDriverId: parseInt(driverId),
    },
    headCells: DriverHistoryHeadCell.map((cell) => {
      return {
        ...cell,
        label: cell.label,
      }
    }),
    include: getDeliveryAttributesByStatus(DeliveryStatus.DELIVERED)[`${urlRole}DeliveryInclude`],
    deliveriesTableQueryFields: getDeliveryAttributesByStatus(DeliveryStatus.DELIVERED)[
      `${urlRole}DeliveriesTableQueryFields`
    ],
  })
  // Handlers

  const handleDateChange = (
    date: Date | undefined | null,
    prop: keyof PrismaFilter<Delivery, 'carDeliveryDeadline'>,
  ) => {
    // delete page from search params
    if (page) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
    if (date === undefined) {
      searchParams.delete(prop)
      setSearchParams(searchParams)
      return
    }
    date = new Date(date)
    if (prop == 'gte') {
      date.setHours(0)
      date.setMinutes(0)
    } else {
      date.setHours(24)
      date.setMinutes(60)
    }
    searchParams.set(prop, date.toISOString())
    setSearchParams(searchParams)
  }

  const handleTypeChange = (type: DeliveryType) => {
    if (page) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
    searchParams.set('type', type)
    setSearchParams(searchParams)
  }

  const handleTypeClear = () => {
    if (page) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
    searchParams.delete('type')
    setSearchParams(searchParams)
    if (tableState.filter?.type) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: undefined,
        },
      }))
    }
  }

  const handleOptionFilterChange = (value: string) => {
    if (page) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
    if (value === undefined) {
      searchParams.delete('optionNameFilter')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('optionNameFilter', value)
    setSearchParams(searchParams)
    return
  }
  // search by id
  useEffect(() => {
    if (id) {
      const deliveryID = id ? parseInt(id) : undefined
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          id: deliveryID,
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          id: undefined,
        },
      }))
    }
  }, [id])

  // search by vin
  useEffect(() => {
    if (vin) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          vehicles: {
            some: {
              vin: {
                contains: vin,
              },
            },
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          vehicles: undefined,
        },
      }))
    }
  }, [vin])
  const handleExport = () => {
    downloadDeliveries(tableState)
  }

  // search by type
  useEffect(() => {
    if (type) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: {
            in: [type],
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: undefined,
        },
      }))
    }
  }, [type])

  // search by optionNameFilter
  useEffect(() => {
    if (optionNameFilter) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionNameFilter,
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionNameFilter: undefined,
        },
      }))
    }
  }, [optionNameFilter])

  // search by gte
  useEffect(() => {
    if (gte) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...(prev.filter?.carDeliveryDeadline as Object),
            gte,
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...(prev.filter?.carDeliveryDeadline as Object),
            gte: undefined,
          },
        },
      }))
    }
  }, [gte])

  // search by lte
  useEffect(() => {
    if (lte) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...(prev.filter?.carDeliveryDeadline as Object),
            lte,
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...(prev.filter?.carDeliveryDeadline as Object),
            lte: undefined,
          },
        },
      }))
    }
  }, [lte])

  // handle last page
  useEffect(() => {
    if (page) {
      setTableState((prevTableState) => ({
        ...prevTableState,
        tableFilter: { ...prevTableState.tableFilter, page: page },
      }))
    } else {
      setTableState((prevTableState) => ({
        ...prevTableState,
        tableFilter: { ...prevTableState.tableFilter, page: 0 },
      }))
    }
  }, [page])
  // Filters

  const typeFilter = (
    <DeliveryTableTypeFilter
      handleTypeChange={handleTypeChange}
      tableType={type}
      handleTypeClear={handleTypeClear}
    />
  )
  const dateLabel = <>Filtre période: </>
  const toDateFilter = (
    <DeliveryTableDateFilter
      from={false}
      filter={tableState.filter!}
      handleDateChange={handleDateChange}
    />
  )
  const fromDateFilter = (
    <DeliveryTableDateFilter
      from={true}
      filter={tableState.filter!}
      handleDateChange={handleDateChange}
    />
  )
  const optionsSelectList = (
    <SelectInput<KeyValuePair>
      placeholder={t('common.shared.select')}
      label={t('layouts.sub-headers.filters.services')}
      optionTextProperty='value'
      optionValueProperty='key'
      value={`${tableState.filter?.optionNameFilter ?? ''}`}
      onChange={handleOptionFilterChange}
      options={selectListAllOptions.map((option) => ({
        ...option,
        key: option.key.toString(),
        value: t(`delivery.services.${option.key}`),
      }))}
      alignLabel
    />
  )
  const filters = [typeFilter, optionsSelectList, dateLabel, fromDateFilter, toDateFilter]

  // Cells

  const { data, isLoading, isError, error } = useDriverDetails(Number(driverId))

  // return component
  return (
    <>
      <DriverQuickDetails driver={data} />
      {tableState.headCells.length > 0 && Boolean(tableState.deliveriesTableQueryFields) && (
        <GenericTableContext.Provider value={{ key, setKey }}>
          <GenericTable<Delivery>
            key={key}
            resource='Delivery'
            graphqlQueryFieldsObject={tableState.deliveriesTableQueryFields}
            headCells={tableState.headCells}
            filter={tableState.filter}
            filters={filters}
            exportHandler={undefined}
            isReadOnly={true}
            include={tableState.include}
          />
        </GenericTableContext.Provider>
      )}
    </>
  )
}
