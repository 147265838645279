import axios from 'axios'

import { type ApiLoginResponse } from '@types_def/common/auth.types'

export function setToken() {
  const accessToken: string | undefined =
    typeof window !== 'undefined' ? localStorage.getItem('AccessToken') : 'no-token'
  axios.defaults.headers.common.Authorization = accessToken || 'no-token'
}

export function storeAuthResult(response: ApiLoginResponse) {
  localStorage.setItem('AccessToken', response.AuthenticationResult.IdToken)
  if (response.AuthenticationResult.RefreshToken) {
    localStorage.setItem('RefreshToken', response.AuthenticationResult.RefreshToken)
  }

  setToken()
}

export function removeAuth() {
  localStorage.removeItem('RefreshToken')
  localStorage.removeItem('AccessToken')
  setToken()
  //
}
