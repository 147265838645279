import { type DateTime } from 'luxon'

import { type Delivery } from './delivery.types'
import type Driver from './driver.types'
import Admin from './admin.types'

export enum InvoiceTableRoute {
  CLIENT,
  DRIVER,
}

export enum OfferStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DRIVER_CHANGED = 'DRIVER_CHANGED',
  DRIVER_CANCELED = 'DRIVER_CANCELED',
}


export type Offer = {
  id: number
  status: OfferStatus
  deliveryId: number
  delivery: Delivery
  driverId: number
  driver?: Driver
  demandedPrice: number
  accepted: boolean
  isModified: boolean
  createdAt: DateTime
  updatedAt: DateTime
  proposedByAdminId: number
  proposedByAdmin: Admin
  proposedByDriver: boolean
  acceptedAt: DateTime
}