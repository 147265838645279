import { Button } from '@mui/material'
import { type DateTime } from 'luxon'
import { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import type Driver from '@types_def/models/driver.types'
import { type Subscription } from '@types_def/models/subscription.types'
import { frenchDate } from '@utils/functions/customDates'
import { galadminDriverLink } from '@utils/functions/galadminDriverLink'
import VisibilityIcon from '@mui/icons-material/Visibility'
import HistoryIcon from '@mui/icons-material/History'
import { RoutePaths } from '@utils/constants/routes'

enum SingupProgressCellValue {
  // "Formulaire envoyé" = 2,
  'Soumettre des documents' = 2,
  'Remplir le questionnaire' = 3,
  'Soumettre une vidéo' = 17,
  'Entretien effectué',
}

const singupProgressCellValue = (step: SingupProgressCellValue) => {
  if (
    step > SingupProgressCellValue['Soumettre des documents'] &&
    step < SingupProgressCellValue['Soumettre une vidéo']
  ) {
    return {
      done: SingupProgressCellValue[3],
      next: SingupProgressCellValue[17],
    }
  }

  return {
    done: SingupProgressCellValue[step],
    next: SingupProgressCellValue[step + 1],
  }
}

export const BoldCell: FunctionComponent = ({ children }) => <em>{children}</em>

export const RatingCell: FunctionComponent = ({ children }) =>
  children ? <>{children}</> : <>Rien</>

export const DeliveriesCountCell: FunctionComponent = ({ children }) => {
  const { nbDeals } = children as Driver
  return <>{nbDeals}</>
}

export const TypeCell: FunctionComponent = ({ children }) =>
  children ? <>{children}</> : <>Non attribué </>

export const SubscriptionCell: FunctionComponent = ({ children }) =>
  children ? <>{(children as Subscription).name}</> : <>Non attribué </>

export const DateCell: FunctionComponent = ({ children }) =>
  children ? <>{frenchDate(children as DateTime)}</> : <></>

export const FullNameCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const { firstName, lastName, id } = children as Driver

  return (
    <a className='main-link' href={galadminDriverLink(id)}>
      {firstName} {lastName}
    </a>
  )
}

export const SignupProgressCell: FunctionComponent = ({ children }) => {
  const { done, next } = singupProgressCellValue(children as number)
  return (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      {next ? (
        <div
          style={{
            borderRadius: '100vh',
            background: '#000',
            padding: '0.25rem 1rem',
            color: '#fff',
          }}
        >
          {done}
        </div>
      ) : (
        <div
          style={{
            borderRadius: '100vh',
            background: '#00244F',
            padding: '0.25rem 1rem',
            color: '#fff',
          }}
        >
          {done}
        </div>
      )}
      {Boolean(next) && (
        <div
          style={{
            borderRadius: '100vh',
            background: '#FF5C8D',
            padding: '0.25rem 1rem',
            color: '#fff',
          }}
        >
          {next}
        </div>
      )}
    </div>
  )
}

export const ActionCell: FunctionComponent = ({ children }) => (
  <div style={{ display: 'flex', gap: '1.5rem' }}>
    <Link to={`${children}`}>
      <Button color='primary' variant='contained' size='small'>
        <VisibilityIcon fontSize='small' />
        Voir Driver
      </Button>
    </Link>
  </div>
)

export const RegistredActionCell: FunctionComponent = ({ children }) => {
  const { disabled, id } = children as Driver
  return (
    <div style={{ display: 'flex', gap: '.5rem', flexDirection: 'column' }}>
      <Link to={`${id}`} style={{ flexGrow: 1, maxWidth: 150 }}>
        <Button
          color='primary'
          variant='contained'
          size='small'
          fullWidth
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}
        >
          <VisibilityIcon fontSize='small' />
          Voir Driver
        </Button>
      </Link>
      <Link
        to={`${disabled ? RoutePaths.adminDriversTabledisabledDriversHistory : RoutePaths.adminDriversTableacceptedHistory}/${id}`}
        style={{ flexGrow: 1, maxWidth: 150 }}
      >
        <Button
          color='warning'
          variant='contained'
          size='small'
          fullWidth
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}
        >
          <HistoryIcon fontSize='small' />
          History
        </Button>
      </Link>
    </div>
  )
}
