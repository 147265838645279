import LockIcon from '@components/common/icons/lock.icon'

import styles from './index.module.scss'

type Props = {
  startCode: string | undefined
  destinationCode: string | undefined
}

const ConfirmedCreatedDeliveryCodes = ({ startCode, destinationCode }: Props) => {
  const codeError = (
    <i style={{ fontSize: 'var(--medium-font-size)', fontWeight: 'bold' }}>
      Erreur lors de la récupération du code
    </i>
  )
  return (
    <div className={styles.codeBoxContainer}>
      <div className={`item ${styles.codeBox}`}>
        <div>
          <LockIcon /> <span>Code de sécurité de début de réception</span>
        </div>
        <div>{startCode ?? codeError}</div>
      </div>
      <div className={`item ${styles.codeBox}`}>
        <div>
          <LockIcon /> <span>Code de sécurité de fin de réception &nbsp;&nbsp;</span>
        </div>
        <div>{destinationCode ?? codeError}</div>
      </div>
    </div>
  )
}

export default ConfirmedCreatedDeliveryCodes
