import { useEffect, useState } from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom'

import { useUserContext } from '@contexts/UserContext/index.context'
import customLocation from '@hooks/location.hook'

import FullPageLoader from '../common/FullPageLoader'
import MainHeader from '../common/header/MainHeader'
import { Unauthorized } from '../common/Unauthorized'

const Authenticated = () => {
  const { userState } = useUserContext()
  const [isConnected, setIsConnected] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const stateLocation = customLocation()
  const location = useLocation()
  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1')
  const isAuthorized =
    Boolean(urlRole) &&
    Boolean(userState) &&
    userState['custom:role']?.toLowerCase() === urlRole.toLowerCase()

  useEffect(() => {
    if (userState) {
      setIsLoading(false)
      setIsConnected(userState.connected)
    }
  }, [userState?.connected])

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>
          {isConnected && isAuthorized ? (
            <>
              <MainHeader />
              <Outlet />
            </>
          ) : isConnected && !isAuthorized ? (
            <Unauthorized />
          ) : (
            <Navigate
              to='/login'
              state={stateLocation?.from ? { from: stateLocation.from } : undefined}
            />
          )}
        </>
      )}
    </>
  )
}

export default Authenticated
