const ArrowIcon = () => (
  <svg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.5975 6.01053L16.0974 0.410181C15.5603 -0.136728 14.6901 -0.136728 14.153 0.410181C13.6159 0.95709 13.6159 1.84308 14.153 2.38999L17.3037 5.59925L1.37503 5.59925C0.6149 5.59925 1.08999e-05 6.22536 1.08322e-05 6.99934C1.07645e-05 7.77333 0.6149 8.39943 1.37503 8.39943L17.3037 8.39943L14.1507 11.61C13.6135 12.1569 13.6135 13.0429 14.1507 13.5898C14.6873 14.1363 15.5575 14.1372 16.095 13.5898L21.5951 7.98947C22.1346 7.44125 22.1346 6.55744 21.5975 6.01053Z'
      fill='#fff'
    />
  </svg>
)

export default ArrowIcon
