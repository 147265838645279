import { useEffect } from 'react'
import { Button, DialogContentText } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import CustomModal from '@components/common/CustomModal'
import { CreateClientInput } from '@types_def/models/client.types'

type TMainButtons = {
  resource: 'new-client' | 'edit-client'
  onSubmit: () => void
}

const MainButtons = ({ resource, onSubmit }: TMainButtons) => {
  const {
    getValues,
    formState: { isSubmitting },
    reset,
  } = useFormContext<CreateClientInput>()
  const IDOrName = getValues('name')
  switch (resource) {
    case 'new-client':
      return (
        <>
          <Button variant='outlined' color='warning' onClick={() => reset()}>
            Annuler
          </Button>
          <CustomModal
            fullWidth={true}
            button={`Créer Organization`}
            color='success'
            confirmText={`Créer Organization`}
            title={`création une nouvelle Organization`}
            confirmAction={onSubmit}
            disabled={isSubmitting}
          >
            <DialogContentText id='alert-dialog-description'>
              Êtes-vous sûr de vouloir Créer une nouvelle Organization
            </DialogContentText>
          </CustomModal>
        </>
      )
    case 'edit-client':
      return (
        <>
          {/*  <CustomModal
            disabled={true}
            fullWidth={true}
            button={'Supprimer'}
            color='error'
            cancelText='Retour'
            confirmText='Supprimer le client'
            confirmAction={undefined}
            title='Supprission compte du client'
          >
            <DialogContentText id='alert-dialog-description'>
              Êtes-vous sûr de vouloir supprimer le compte du <strong>{IDOrName} ?</strong>
            </DialogContentText>
          </CustomModal> */}
          <Button
            variant='outlined'
            color='warning'
            onClick={() => {
              reset()
            }}
          >
            Annuler
          </Button>
          <CustomModal
            disabled={isSubmitting}
            fullWidth={true}
            button={'Modifier'}
            color='primary'
            confirmText={'Confirmer La Modification'}
            title='Confirmation Modification du Organization'
            confirmAction={onSubmit}
          >
            <DialogContentText id='alert-dialog-description'>
              Êtes-vous sûr de vouloir confirmer la modification de Organization{' '}
              <strong>{IDOrName} ?</strong>
            </DialogContentText>
          </CustomModal>
        </>
      )
  }
}

export default MainButtons
