import TablePagination, { type LabelDisplayedRowsArgs } from '@mui/material/TablePagination'
import { type FunctionComponent } from 'react'

import styles from '../index.module.scss'

type Props = {
  total: number
  rowsPerPage: number
  page: number
  handleChangePage: (event: unknown, newPage: number) => void
}

export const EnhancedTablePagination: FunctionComponent<Props> = (props: Props) => {
  const { total, rowsPerPage, page, handleChangePage } = props
  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => (
    // Return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
    <></>
  )
  return (
    <div className={styles.paginationNumber}>
      {Boolean(total) && (
        <p>
          {page + 1} / {Math.ceil(total / rowsPerPage)}
        </p>
      )}
      <TablePagination
        sx={{
          width: '250px',
          padding: '2em',
          margin: 'auto',
          overflow: 'visible',
          '.Mui-disabled': {
            backgroundColor: 'rgba(153,153,153,0.5) !important',
          },
          '.MuiTablePagination-actions': {
            marginLeft: '0',
          },
          '.MuiTablePagination-toolbar': {
            paddingBottom: '2em',
            paddingTop: '2em',
          },
        }}
        rowsPerPageOptions={[]}
        component='div'
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        backIconButtonProps={{
          className: styles['pagination-button'],
        }}
        nextIconButtonProps={{
          className: styles['pagination-button'],
        }}
        labelDisplayedRows={labelDisplayedRows}
      ></TablePagination>
    </div>
  )
}
