import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { GenericTable } from '@components/common/table'
import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { useUserContext } from '@contexts/UserContext/index.context'
import { useCheckIfMounted } from '@hooks/mountingRef'
import { type DeepInclude, type WhereFilter } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { type Delivery } from '@types_def/models/delivery.types'
import { deliveryStatusRoutes } from '@utils/constants/DeliveryStatus/deliveryStatusRoutes'
import { GenericTableContext } from '@contexts/GenericTableContext/GenericTableContext'
import { headCells } from './HeadCells/index'
import DeliveryTableFilters from '@components/Delivery/DeliveryTable/Filters'
import FiltersSummary from '@components/Delivery/DeliveryTable/Filters/FiltersSummary'
import { UserRole } from '@types_def/models/user.type'

type TableState = {
  filter?: WhereFilter<Delivery>
  headCells: Array<HeadCell<Delivery>>
  include?: DeepInclude<Delivery>
  deliveriesTableQueryFields: QueryType<Delivery> | undefined
  filterComponents?: JSX.Element[]
}
type Params = {
  status: keyof typeof deliveryStatusRoutes
}
const SubscriptionInvoicesTable = () => {
  let [searchParams, setSearchParams] = useSearchParams()

  const deliveriesTableQueryFields: QueryType<Delivery> = {
    id: true,
    publicId: true,
    type: true,
    accepted_offer: {
      demandedPrice: true,
      driver: {
        firstName: true,
        lastName: true,
      },
    },
    deliveryOrganization: {
      name: true,
    },
    completedAt: true,
    invoice: true,
  }

  // Getting all filter from URL params
  const { userState } = useUserContext()
  const userRole = userState['custom:role']

  // Getting all filter from URL params
  const deliveryCountries = searchParams.get('deliveryCountries')
  const deliveryType = searchParams.get('deliveryType')
  const deliveryServices = searchParams.get('deliveryServices')
  const carDeliveryDeadlineFrom = searchParams.get('carDeliveryDeadlineFrom')
  const carDeliveryDeadlineTo = searchParams.get('carDeliveryDeadlineTo')
  const organizationSlug = searchParams.get('organizationSlug')
  const page = searchParams.get('page')

  const isMountedRef = useCheckIfMounted()
  const [key, setKey] = useState(0)

  // State and hooks
  const { status: statusRoute } = useParams<Params>()
  const status = deliveryStatusRoutes[statusRoute ?? 'confirmed']
  const location = useLocation()

  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1') as 'admin' | 'client'
  const organizationId = useUserContext().userState?.organizationId
  const [tableState, setTableState] = React.useState<TableState>(
    urlRole === 'admin'
      ? {
          filter: {
            invoice: {
              not: null,
            },
          },
          headCells: headCells,
          deliveriesTableQueryFields: deliveriesTableQueryFields,
        }
      : {
          filter: {
            invoice: {
              not: null,
            },
            organization: {
              slug: organizationId,
            },
          },
          headCells: headCells,
          deliveriesTableQueryFields: deliveriesTableQueryFields,
        },
  )

  // search by country
  useEffect(() => {
    if (deliveryCountries) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          OR: [
            {
              fromAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toReturnFromAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toReturnToAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
          ],
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          OR: undefined,
        },
      }))
    }
  }, [deliveryCountries])

  // search by type
  useEffect(() => {
    if (deliveryType) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: {
            in: deliveryType.split(','),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: undefined,
        },
      }))
    }
  }, [deliveryType])

  // search by optionIdFilter
  useEffect(() => {
    if (deliveryServices) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionIdFilter: deliveryServices,
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionIdFilter: undefined,
        },
      }))
    }
  }, [deliveryServices])

  // search by gte
  useEffect(() => {
    if (carDeliveryDeadlineFrom) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            gte: new Date(parseInt(carDeliveryDeadlineFrom)).toISOString(),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            gte: undefined,
          },
        },
      }))
    }
  }, [carDeliveryDeadlineFrom])

  // search by lte
  useEffect(() => {
    if (carDeliveryDeadlineTo) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            lte: new Date(parseInt(carDeliveryDeadlineTo)).toISOString(),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            lte: undefined,
          },
        },
      }))
    }
  }, [carDeliveryDeadlineTo])

  // search by organization
  useEffect(() => {
    if (organizationSlug && userRole === UserRole.ADMIN) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          organization: {
            slug: {
              in: organizationSlug?.split(','),
            },
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          organization: {
            slug: Boolean(organizationId) ? organizationId : undefined,
          },
        },
      }))
    }
  }, [organizationSlug])

  // handle last page
  useEffect(() => {
    if (page) {
      setTableState((prevTableState) => ({
        ...prevTableState,
        tableFilter: { ...prevTableState.tableFilter, page: page },
      }))
    } else {
      setTableState((prevTableState) => ({
        ...prevTableState,
        tableFilter: { ...prevTableState.tableFilter, page: 0 },
      }))
    }
  }, [page])

  const filters = <DeliveryTableFilters />
  const filtersSummary = <FiltersSummary />

  // Cells

  // return component
  return (
    <>
      {tableState.headCells.length > 0 && Boolean(tableState.deliveriesTableQueryFields) && (
        <GenericTableContext.Provider value={{ key, setKey }}>
          <GenericTable<Delivery>
            key={key}
            resource='Delivery'
            graphqlQueryFieldsObject={tableState.deliveriesTableQueryFields}
            headCells={tableState.headCells}
            filter={tableState.filter}
            filtersSummary={filtersSummary}
            filters={filters}
            exportHandler={undefined}
            isReadOnly={true}
          />
        </GenericTableContext.Provider>
      )}
    </>
  )
}

export default SubscriptionInvoicesTable
