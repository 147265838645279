import axios from 'axios'

import { type Delivery, type DeliveryStatus } from '@types_def/models/delivery.types'

type ApiUpdateResponse<Delivery> = {
  updated: Delivery
}

export async function updateBySMS(token: string, status: DeliveryStatus) {
  try {
    const url = `${import.meta.env.VITE_APP_API_BASE_URL}delivery/confirm-delivery-by-sms`

    const res = await axios.request<Delivery>({
      method: 'post',
      url,
      headers: {
        token,
      },
      data: {
        status,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

// Export async function updateBySMS(
//   updateInput: GenericUpdateObject<Delivery>,
//   graphQlFieldsObject: QueryType<Delivery>
// ) {
//   try {
//     const graphqlQueryFields = graphqlQueryFieldsBuilder(graphQlFieldsObject);
//     const res = await graphqlMutation<
//       ApiUpdateResponse<Delivery>,
//       { updateInput: GenericUpdateObject<Delivery> }
//     >(
//       gql`
//     mutation updateDeliveryBySMS($updateInput: UpdateDeliveryInput!) {
//         updated:  updateDeliveryBySMS(UpdateDeliveryInput: $updateInput)
//                 ${graphqlQueryFields}
//     }`,
//       {
//         updateInput,
//       }
//     );
//     return res.data?.updated;
//   } catch (error) {
//     throw new Error(error as string);
//   }
// }
