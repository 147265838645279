import { CSSProperties, type PropsWithChildren } from 'react'
import { Link, useLocation } from 'react-router-dom'

import MainButton from '../Button'
import styles from './index.module.scss'

type Props = {
  to: string
  includes: string | string[]
  disabled?: boolean
  isExternalLink?: boolean
  important?: boolean
  extraStyle?: CSSProperties
}
export const NavigationLink = ({ to, includes, disabled, children }: PropsWithChildren<Props>) => {
  const location = useLocation()

  const isActive =
    typeof includes === 'string'
      ? location.pathname.includes(includes)
      : includes.some((include) => location.pathname.includes(include))
  return (
    <Link
      to={to}
      className={`${styles.icon} ${isActive ? styles['active-link'] : ''} 
      ${disabled ? styles['disabled-link'] : ''}
      `}
    >
      {children}
    </Link>
  )
}

export const NavigationButton = ({
  to,
  includes,
  children,
  disabled = false,
  isExternalLink,
  important = false,
  extraStyle,
}: PropsWithChildren<Props>) => {
  const location = useLocation()
  const isActive =
    typeof includes === 'string'
      ? location.pathname.includes(includes)
      : includes.some((include) => location.pathname.includes(include))

  return (
    <Link
      to={!isExternalLink ? to : location.pathname}
      onClick={
        isExternalLink
          ? () => {
              window.open(to)
            }
          : undefined
      }
      style={extraStyle}
      className={`${isActive ? styles['active-link'] : ''} ${
        disabled ? styles['disabled-link'] : ''
      }  `}
    >
      <MainButton
        additionalClassName={`${important ? 'important-link' : ''}`}
        disabled={disabled}
        noFill={!isActive}
        style={{ width: '100%' }}
      >
        {children}
      </MainButton>
    </Link>
  )
}
