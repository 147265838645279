import { type DateTime } from 'luxon'

import frenchDate, { deliverySmsMessageTime } from '@utils/functions/customDates'

import InformationRow from '../StyledComponents/InformationRow'
type Props = {
  carPickUpDate: DateTime
}
const DateTimeSection = ({ carPickUpDate }: Props) => (
  <>
    <InformationRow
      title='Date de livraison'
      value={frenchDate(carPickUpDate, {
        day: 'numeric',
        month: 'long',
        year: '2-digit',
        weekday: 'long',
      })}
    />
    {/* <InformationRow
      title='Heure estimée de livraison'
      value={
        'Entre ' +
        deliverySmsMessageTime(clientEstimatedDeliveryDate, -15) +
        ' et ' +
        deliverySmsMessageTime(clientEstimatedDeliveryDate, 15)
      }
    /> */}
  </>
)

export default DateTimeSection
