import React, { type Dispatch, type SetStateAction } from 'react'

import { type UserContextData } from '@types_def/models/user.type'

export type UserContextContent = {
  userState: UserContextData | undefined
  handleSetUser: (user: UserContextData | undefined) => void
  keyword: string
  setKeyword: Dispatch<SetStateAction<string>>
}

export const UserContext = React.createContext<UserContextContent>({
  userState: undefined,
  handleSetUser() {},
  setKeyword() {},
  keyword: '',
})

export const useUserContext = () => React.useContext(UserContext)
