import { useRef } from 'react'

import { type HelpMenuItemProps, HelpMenuItemType } from '@types_def/components/helpMenuItem'

import HelpMenuItem from './HelpMenuItem'
import styles from './index.module.scss'

const setting = {
  color: {
    theme: '#000',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
  },
}

const helpMenuItems: HelpMenuItemProps[] = [
  {
    method: 'Nous contacter par téléphone :',
    type: HelpMenuItemType.PHONE,
    contactInfo: '09 84 52 42 69',
    responseTime: 'Ouvert du lundi au vendredi de 8h00 à 18h00.',
  },
  {
    method: 'Nous contacter par e-mail :',
    type: HelpMenuItemType.EMAIL,
    contactInfo: 'contact@alb-mobility.com',
    responseTime: 'Réponse garantie sous 24-48h00.',
  },
  {
    method: 'Nous contacter par chat :',
    type: HelpMenuItemType.CHAT,
    contactInfo: 'Lancer une conversation',
    responseTime: 'Ouvert du lundi au vendredi de 8h00 à 18h00.',
  },
]

const Help = () => {
  const chatItemRef = useRef<HTMLIFrameElement>(null)
  const helpMenuItemRef = useRef<HTMLDivElement>(null)
  const reload = () => {
    if (chatItemRef.current) {
      localStorage.removeItem('ZD-suid')
      localStorage.removeItem('randid')
      sessionStorage.clear()

      chatItemRef.current.contentWindow?.postMessage('location.reload()', '*')
      chatItemRef.current.contentWindow?.postMessage('sessionStorage.clear()', '*')
    }
  }

  return (
    <>
      <div className={styles.help} ref={helpMenuItemRef}>
        <div className={styles.header}>
          <div className='title'>Besoin d’aide ?</div>
          {/* <div>
            Hae duae provinciae bello quondam piratico catervis mixtae praedonum
            a Servilio pro consule missae sub iugum factae sunt vectigales. et
            hae quidem regiones velut in prominenti terrarum lingua positae ob
            orbe eoo monte Amano disparantur.
          </div> */}
        </div>
        <div className={`${styles['help-menu']}`}>
          {helpMenuItems.map((item, index) => (
            <HelpMenuItem key={index} {...item} />
          ))}
        </div>
      </div>
    </>
  )
}

export default Help
