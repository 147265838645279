import { type FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import getUserInfo from '@api/auth/getUserInfo'
import setNewPassword from '@api/auth/setNewPassword'
import MainForm from '@components/common/MainForm'
import { useUserContext } from '@contexts/UserContext/index.context'
import useLocation from '@hooks/location.hook'
import { checkPasswordValidaty } from '@utils/functions/inputValidators'

import { NewPasswordFormSection } from './NewPasswordFormSection'
import { useTranslation } from 'react-i18next'
type Props = {
  session: string
  email: string
}

export const SetNewPassword: FunctionComponent<Props> = ({ session, email }: Props) => {
  const { t } = useTranslation()
  const { handleSetUser } = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  const [newPasswordState, setNewPasswordState] = useState({
    password: '',
    confirmPassword: '',
  })
  const [isValid, setIsValid] = useState(false)
  const handleInputChange = (value: string | boolean | number, identifier?: string) => {
    setNewPasswordState((passwordState) => ({
      ...passwordState,
      [identifier!]: value,
    }))
  }

  const handleRedirect = () => {
    const pathName = location?.from?.pathname || '/'
    navigate(pathName)
  }

  useEffect(() => {
    setIsValid(checkPasswordValidaty(newPasswordState.password, newPasswordState.confirmPassword))
  }, [newPasswordState])

  const onSubmitClick = () => {
    setNewPassword({
      session,
      username: email,
      newPassword: newPasswordState.password,
    }).then((res) => {
      if (res.success) {
        getUserInfo().then(({ success, data }) => {
          if (success && data) {
            handleSetUser({ ...data, connected: true })
          }

          handleRedirect()
        })
      }
    })
    // Throw new Error("Function not implemented.");
  }

  return (
    <MainForm
      modalForm
      isValid={isValid}
      onSubmit={onSubmitClick}
      title={t('common.form.input.set-new-password')}
    >
      <NewPasswordFormSection
        email={email}
        handleInputChange={handleInputChange}
        password={newPasswordState.password}
        confirmPassword={newPasswordState.confirmPassword}
      />
    </MainForm>
  )
}
