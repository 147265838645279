import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AverageAuctionPriceCell,
  ContractCell,
  DateCell,
  FullAddressCell,
  OrganizationCell,
  PilotActionCell,
  PriceCell,
  RemainingTimeCell,
  SubscriptionsCell,
  WaitingForDriverAdminActionCell,
  ZeroKmCell,
} from '../CustomCells'

const WaitingForDriverHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Trajet',
    customCellComponent: FullAddressCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'clientLastName',
    label: 'Client',
    isNotSortable: false,
  },
  // {
  //   id: 'numberOfVehicles',
  //   label: 'Nb. véhicules',
  //   isNotSortable: false,
  // },
  // {
  //   id: 'subscriptions',
  //   label: 'Statut',
  //   isNotSortable: true,
  //   customCellComponent: SubscriptionsCell,
  // },
]

const AdminActionCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  customCellComponent: WaitingForDriverAdminActionCell,
  passWholeObject: true,
  isNotSortable: true,
}
const ClientActionCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  passWholeObject: true,
  customCellComponent: PilotActionCell,
  isNotSortable: true,
}

export const ClientWaitingForDriverHeadCells = [...WaitingForDriverHeadCells, ClientActionCell]

const adminExtraCells: Array<HeadCell<Delivery>> = [
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Date de livraison estimée du client",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  // {
  //   id: "adminEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Temps restant',
    customCellComponent: RemainingTimeCell,
    isNotSortable: true,
    passWholeObject: true,
  },
  {
    id: 'averageOffersPrice',
    label: 'Prix moyen',
    customCellComponent: AverageAuctionPriceCell,
    isNotSortable: true,
    passWholeObject: true,
  },
  // {
  //   id: 'minOfferPrice',
  //   label: 'Enchère la - chère',
  //   customCellComponent: PriceCell,
  //   isNotSortable: false,
  // },
  {
    id: 'number_of_offers',
    label: "nombre d'offres",
    passWholeObject: true,
  },
]

export const AdminWaitingForDriverHeadCells = [
  ...WaitingForDriverHeadCells,
  ...adminExtraCells,
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  AdminActionCell,
]
