import { useQuery } from '@tanstack/react-query'
import { memo } from 'react'

import { useUserContext } from '@contexts/UserContext/index.context'
import { getDeliveries } from '@api/graphql/delivery/getDelivery'
import uniqolor from 'uniqolor'
import CustomMap from '@components/Map'
import { Mission } from '@components/Map/type'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { UserRole } from '@types_def/models/user.type'

const DeliveryMap = () => {
  const role = useUserContext().userState?.['custom:role']?.toString()
  const slug = useUserContext().userState?.organizationId
  const getDeliveriesBasedOnRole =
    role === UserRole.ADMIN
      ? getDeliveries()
      : getDeliveries({
          status: DeliveryStatus.ONGOING,
          organization: {
            slug,
          },
        })
  const { data, isLoading } = useQuery<Mission[]>({
    queryKey: ['ongoingDeliveries'],
    queryFn: async () => {
      const res = await getDeliveriesBasedOnRole
      if (!res.success) {
        throw new Error("can't get res")
      }
      const delivery = res?.data?.records
      const data = delivery.map((delivery) => {
        const fromAddressCord = {
          lat: delivery?.fromAddress.latitude,
          lng: delivery?.fromAddress.longitude,
        }
        const toAddressCord = {
          lat: delivery?.toAddress.latitude,
          lng: delivery?.toAddress.longitude,
        }
        const resToAddressCord =
          delivery.type === DeliveryType.TWO_WAY_DELIVERY
            ? {
                lat: delivery?.toReturnToAddress.latitude,
                lng: delivery?.toReturnToAddress.longitude,
              }
            : undefined
        const color = uniqolor(Number(delivery.id) * (Math.random() * 100), {
          differencePoint: 40,
        })
        const mission: Mission = {
          origin: fromAddressCord,
          destination: toAddressCord,
          toReturn: resToAddressCord,
          id: delivery.id.toString(),
          color: color.color,
          depart: delivery.fromAddress.address,
          arrive: delivery.toAddress.address,
          restitution:
            delivery.type === DeliveryType.TWO_WAY_DELIVERY && delivery.toReturnToAddress.address,
          driver: {
            id: delivery.selectedDriver?.id?.toString(),
            name: delivery.selectedDriver?.fullName,
            avatar: delivery.selectedDriver?.avatar,
          },
          status: delivery.status,
          type: delivery.type,
        }
        return mission
      })
      return data
    },
    refetchInterval: Infinity,
  })

  return (
    <div
      style={{
        height: 'calc(100vh - 75px - 85px)',
        width: '100%',
        position: 'relative',
      }}
    >
      {!data && isLoading ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div>loading</div>
        </div>
      ) : (
        <CustomMap data={data} liveTracking newDelivery={false} />
      )}
    </div>
  )
}

export default memo(DeliveryMap)
