import { type DriverTableRoute } from '@types_def/models/driver.types'
import { driverTableRouteAttributes } from '@utils/constants/DriverTable/driverTableAttributes'

export const getDriverTableRouteAttributes = (driverTable: DriverTableRoute) =>
  driverTableRouteAttributes.find((attribute) => attribute.key == driverTable) ??
  driverTableRouteAttributes[0]

export const getDriverTableRouteHeadCells = (driverTable: DriverTableRoute) =>
  getDriverTableRouteAttributes(driverTable).headCells

export const getDriverTableRouteConfirmationStatus = (driverTable: DriverTableRoute) =>
  getDriverTableRouteAttributes(driverTable).confirmationStatus
