import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Radar } from 'react-chartjs-2'

import { getDriversRatingStats } from '@api/Rest/getDriverStats'
import { type DriverRatingStats } from '@types_def/models/driver.types'

ChartJS.register(RadialLinearScale, Legend, PointElement, LineElement, Filler, Tooltip)

export const ratings = {
  outfitRating: 'Tenue',
  takeOffRating: 'Ponctualité',
  qualityRating: 'Serviabilité',
  correspondenceRating: 'Check vehicule',
}

type PieChartData = {
  labels: string[]
  datasets: [
    {
      data: number[]
      backgroundColor: string
      borderColor: string
      borderWidth: number
      fontSize: number
    },
  ]
}

const DriverNotationRadarChart = () => {
  const [tableData, setTableData] = useState<PieChartData>()

  const options = () => ({
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        fill: false,
        min: 1,
        max: 5,
        beginAtZero: false,
        ticks: {
          stepSize: 1,
          font: {
            size: 14,
          },
        },
        pointLabels: {
          fontSize: 20,
        },
      },
    },
  })

  const getData = async () => {
    const res = await getDriversRatingStats()
    if (res.data.data) {
      const newRes: PieChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgb(155, 178, 206, 0.6)',
            borderColor: '#9BB2CE',
            borderWidth: 1,
            fontSize: 20,
          },
        ],
      }
      for (const [key, value] of Object.entries(res.data.data)) {
        const subject = ratings[key as keyof DriverRatingStats]
        if (subject) {
          newRes.labels.push(subject)
        }

        newRes.datasets[0].data.push(value)
      }

      setTableData(newRes)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return tableData ? (
    <div
      style={{
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      {/*
        // @ts-expect-error */}
      <Radar data={tableData} options={options()} />
    </div>
  ) : (
    <> </>
  )
}

export default DriverNotationRadarChart
