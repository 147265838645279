import { useMemo } from 'react'
import { useIdToken } from '@hooks/idToken'
import styles from './index.module.scss'
import { Container, Grid, Paper } from '@mui/material'
import { Delivery, DeliveryType } from '@types_def/models/delivery.types'
import { useQuery } from '@tanstack/react-query'
import CustomMap from '@components/Map'
import uniqolor from 'uniqolor'
import { Mission } from '@components/Map/type'
import { sendRequest } from '@api/clients/axios'

const getDeliveryDetailsByMissionId = async (id: number) => {
  const response = await sendRequest('get', `delivery/unAuth/${id}`)
  return response.data as Delivery
}

export const DeliveryCustomerMap = () => {
  const { id } = useIdToken()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['delivery', id],
    queryFn: () => getDeliveryDetailsByMissionId(id),
  })
  if (isLoading) {
    return <div>Loading...</div>
  }
  if (isError) {
    return <div>Error...</div>
  }
  return <Inner delivery={data} />
}

const Inner = ({ delivery }: { delivery: Delivery }) => {
  const fromAddressCord = useMemo(() => {
    return {
      lat: Number(delivery.fromAddress.latitude),
      lng: Number(delivery.fromAddress.longitude),
    }
  }, [delivery.id])
  const toAddressCord = useMemo(() => {
    return {
      lat: Number(delivery.toAddress.latitude),
      lng: Number(delivery.toAddress.longitude),
    }
  }, [delivery.id])

  const toReturn = useMemo(() => {
    return {
      lat: Number(delivery.toReturnToAddress?.latitude),
      lng: Number(delivery.toReturnToAddress?.longitude),
    }
  }, [delivery.id])

  const mission: Mission = {
    origin: fromAddressCord,
    destination: toAddressCord,
    toReturn: toReturn,
    id: delivery.id.toString(),
    depart: delivery.fromAddress.address,
    arrive: delivery.toAddress.address,
    restitution: delivery.toReturnToAddress?.address,
    color: uniqolor(delivery.id.toString()).color,
    driver: {
      id: delivery.selectedDriver.id.toString(),
      name: delivery.selectedDriver.firstName,
      avatar: delivery.selectedDriver.avatar,
    },
    status: delivery.status,
    type: DeliveryType.STANDARD,
  }

  const isMobile = window.innerWidth < 768
  const car = delivery.vehicles[0]
  return (
    <div className={styles.container}>
      <Container>
        <h1 className={styles.title}>
          <span>
            <span className={styles.special}>Mission n°:</span> {delivery.id}
          </span>
          <span>
            <span className={styles.special}>Code arrivée:</span> {delivery.destinationCode}
          </span>
        </h1>
        <Paper sx={{ p: 2, my: 4 }}>
          <h2
            style={{
              marginBottom: 20,
              color: 'var(--primary-color)',
            }}
          >
            Détails de la mission:
          </h2>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              <p className={styles.text}>
                <span className={styles.special}>Adresse départ:</span>{' '}
                {delivery.fromAddress.address.replace(', France', '')}
              </p>

              {delivery.fromAddressPrecision ? (
                <p className={styles.text}>
                  <span className={styles.special}>Précision:</span> {delivery.fromAddressPrecision}{' '}
                </p>
              ) : null}
              <div className={styles.divider}></div>

              <p className={styles.text}>
                <span className={styles.special}>Voiture Model:</span> {car.model}
              </p>

              <p className={styles.text}>
                <span className={styles.special}>Voiture Marque:</span> {car.marque}
              </p>
              {/* <p className={styles.text}>
                  <span className={styles.special}>Date de livraison estimé:</span>{' '}
                  {(deliveryDetails.adminEstimatedDeliveryDate &&
                    dateStringFormat(deliveryDetails.adminEstimatedDeliveryDate)) ||
                    (deliveryDetails.clientEstimatedDeliveryDate &&
                      dateStringFormat(deliveryDetails.clientEstimatedDeliveryDate))}
                </p> */}
            </Grid>
            {isMobile ? (
              <Grid item xs={12} md={false}>
                <div className={styles.divider}></div>
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              <p className={styles.text}>
                <span className={styles.special}>Adresse arrivée:</span>{' '}
                {delivery.toAddress.address.replace(', France', '')}
              </p>
              {delivery.toReturnToAddress ? (
                <p className={styles.text}>
                  <span className={styles.special}>Précision:</span> {delivery.toAddressPrecision}{' '}
                </p>
              ) : null}
              <div className={styles.divider}></div>
              <p className={styles.text}>
                <span className={styles.special}>Client:</span> {delivery.clientFirstName}{' '}
                {delivery.clientLastName}
              </p>

              <p className={styles.text}>
                <span className={styles.special}>Client Téléphone:</span> {delivery.clientPhone}
              </p>
            </Grid>
          </Grid>
        </Paper>
        <br />
        <div
          style={{
            height: 'calc(100dvh - 400px)',
            width: '100%',
            position: 'relative',
            borderRadius: 10,
            overflow: 'hidden',
          }}
        >
          <CustomMap data={[mission]} liveTracking />
        </div>
      </Container>
    </div>
  )
}
