import { type DeleteInput } from '@types_def/common/filter.types'

import { gql, graphqlQuery } from '../../clients/apollo'

export type DeleteResponse<T> = {
  success: boolean
  message: string
  data?: T
}

type ApiDeleteResponse<T> = {
  deleted: T
}

// Export async function deleteOne(
//   id: string | number,
//   resource: string
// ): Promise<DeleteResponse> {
//   const res = await graphqlQuery<ApiDeleteResponse, DeleteInput>(
//     gql`
//       mutation delete${resource}($deleteInput: DeleteInput!) {
//        deleted: delete${resource}(DeleteInput: $deleteInput) {
//           id
//           name
//         }
//       }
//     `,
//     {
//       getByIdInput: {id}
//     }
//   );
//   if (res?.data) {
//     return {
//       success: true,
//       message: "Ok",
//       data: res.data.deleted,
//     };
//   }
//   return { success: false, message: "La requète a échouée" };
// }

export async function deleteOne<T extends Record<string, unknown>>(
  id: number,
  resource: string,
): Promise<T> {
  try {
    const res = await graphqlQuery<ApiDeleteResponse<T>, DeleteInput>(
      gql`
        mutation delete${resource}($deleteInput: DeleteInput!) {
          deleted: delete${resource}(DeleteInput: $deleteInput) {
            id
        }        
        }
      `,
      {
        deleteInput: { id },
      },
    )
    if (res?.data?.deleted) {
      return res?.data?.deleted
    }

    throw res?.errors[0]?.message
  } catch (error) {
    throw error
  }
}
