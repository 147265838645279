import ListIcon from '@components/common/icons/list.icon'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import LinksSubHeader from '..'
import { useTranslation } from 'react-i18next'

const DriverSubHeader = () => {
  const { t } = useTranslation()
  return (
    <LinksSubHeader>
      <NavigationButton
        to={RoutePaths.adminDriversTableacceptedRequests}
        includes={RoutePaths.acceptedRequests}
      >
        <ListIcon />
        {t('layouts.sub-headers.drivers.accepted')}
      </NavigationButton>

      <NavigationButton
        to={RoutePaths.adminDriversTableWaitingRequests}
        includes={RoutePaths.waitingRequests}
      >
        <HourglassEmptyIcon />
        {t('layouts.sub-headers.drivers.demandes')}
      </NavigationButton>

      <NavigationButton
        to={RoutePaths.adminDriversTablerefusedRequests}
        includes={RoutePaths.refusedRequests}
      >
        <ReportGmailerrorredIcon />
        {t('layouts.sub-headers.drivers.rejected')}
      </NavigationButton>
      <NavigationButton
        to={RoutePaths.adminDriversTabledisabledDrivers}
        includes={RoutePaths.disabledDrivers}
      >
        <RemoveCircleOutlineIcon />
        {t('layouts.sub-headers.drivers.banned')}
      </NavigationButton>
    </LinksSubHeader>
  )
}

export default DriverSubHeader
