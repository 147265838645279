import { Grid, Typography } from '@mui/material'

import ArrowIcon from '@components/common/icons/arrow.icon'
import LocationIcon from '@components/common/icons/location.icon'
import MultipleCarsIcon from '@components/common/icons/multiple-cars.icon'
import { extraOptions, garageOptions } from '@utils/constants/deliveryOptions'
import Options from '../../DeliveryForm/OptionsSection'
import PlusIcon from '@components/common/icons/plus.icon'
import styles from './index.module.scss'
import { Delivery, DeliveryType } from '@types_def/models/delivery.types'

type Props = {
  login: string
  logout: string
  distance: number
  duration: number
  isInIdf?: boolean
  isPeriodic?: boolean
  data: Delivery
}

const CreateDeliveryDealDetails = ({
  login,
  logout,
  distance,
  duration,
  isInIdf,
  data,
  isPeriodic,
}: Props) => (
  <div className={`${styles.createDeliveryDealDetails} item`}>
    <Grid container spacing={1} alignItems='center'>
      <Grid item xs={4} className={`${styles.createDeliveryDealDetailsIconWithText}`}>
        <MultipleCarsIcon /> Courses
      </Grid>
      <Grid container item xs={4} gap={0} spacing={0}>
        <div className={`${styles.createDeliveryDealDetailsEndpoints}`}>
          <div className={`${styles.createDeliveryDealDetailsIconWithText}`}>
            <LocationIcon />
            {login}
          </div>
          <div className={styles.arrowIconsContainer}>
            <ArrowIcon />
            {Boolean(isPeriodic) && <ArrowIcon />}
          </div>
          <div className={`${styles.createDeliveryDealDetailsIconWithText}`}>
            <LocationIcon />
            {logout}
          </div>
        </div>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        Distance : {distance} km {Boolean(isPeriodic) && 'x2'}
      </Grid>
      <Grid item xs={4}>
        Temps estimé : {`${Math.floor(duration / 60)}h${duration % 60 == 0 ? '' : duration % 60}`}{' '}
        {Boolean(isPeriodic) && 'x2'}
      </Grid>
      <Grid item xs={4}>
        Périmètre : {isInIdf ? '' : 'Hors'} Île-de-France
      </Grid>
      {/* <Grid item xs={12}>
        <div className={`${styles.createDeliveryDealDetailsIconWithText}`}>
          <PlusIcon /> Services supplémentaires
        </div>
        {data.type === DeliveryType.QUALITY_CHECK && (
          <Options
            selected={data.garageOptions['selected']}
            options={garageOptions}
            disabled={true}
            title=''
          />
        )}

        <Options
          selected={data.options['selected']}
          options={extraOptions}
          disabled={true}
          title=''
        />
      </Grid> */}
    </Grid>
  </div>
)

export default CreateDeliveryDealDetails
