import { type GenericObject } from '@types_def/common/generic.types'

export function ObjectWithConditionalValues<T>(input: T) {
  const ret = {}
  for (const k in input) {
    Object.assign(ret, input[k] !== undefined && { [k]: input[k] })
  }

  return ret as T
}

export function ArraysHaveCommonElement<T extends GenericObject>(arr1?: T[], arr2?: T[]) {
  if (!arr1 || !arr2) {
    return false
  }

  const arr1Ids = arr1.map((item) => item.id)
  const arr2Ids = arr2.map((item) => item.id)
  return arr1Ids.filter((id) => arr2Ids.includes(id)).length > 0
}
