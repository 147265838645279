import { useQuery } from '@tanstack/react-query'
import { gql, graphqlQuery } from '@api/clients/apollo'
import { QueryType } from '@types_def/common/query.types'
import Driver from '@types_def/models/driver.types'

const useDriverDetails = (id: number, fields?: QueryType<Driver>, key?: string[]) => {
  if (!id) throw new Error('Invalid driver id')

  return useQuery({
    queryKey: ['delivery', id, key],
    queryFn: async () => {
      const res = await graphqlQuery<{ getDriverById: Driver }, { GetByIdInput: { id: number } }>(
        gql`
          query getDriverById($GetByIdInput: GetByIdInput!) {
            getDriverById(GetByIdInput: $GetByIdInput) {
              id
              confirmedByAlb
              disabled
              firstName
              lastName
              email
            }
          }
        `,
        {
          GetByIdInput: {
            id: Number(id),
          },
        },
      )
      return res?.data?.getDriverById
    },
  })
}

export { useDriverDetails }
