import { Container, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { sendRawRequest } from '@api/clients/axios'
import MemberForm from '@components/organization/Member/Form'
import { useUserContext } from '@contexts/UserContext/index.context'
import {
  MemberFormSchema,
  MemberFormSchemaDefaultValues,
} from '@schemas/OrganizationForm/member.schema'
import { UserRank } from '@types_def/models/user.type'

type Props = {}

const ClientOrganizationAddMember = (props: Props) => {
  const { userState } = useUserContext()
  const userRank = userState['custom:rank']
  const { t } = useTranslation()
  const disabledFields: Array<keyof typeof MemberFormSchema.shape> = []
  const values = MemberFormSchemaDefaultValues
  if (userRank == UserRank.ORGANIZATION_OWNER) {
    values.assigneeRank = UserRank.ORGANIZATION_MANAGER
  } else if (userRank == UserRank.ORGANIZATION_MANAGER) {
    disabledFields.push('assigneeRank')
    values.assigneeRank = UserRank.ORGANIZATION_MEMBER
  }

  const onSubmit = async (data) => {
    try {
      const response = await sendRawRequest<any, any>('post', '/organization/add-member', data)
      if (response.data.success) {
        toast.success(t('network.organization.member.created'), {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t('network.errors.unknown'), {
        position: 'bottom-right',
      })
    }
  }
  return (
    <Container
      maxWidth='md'
      sx={{
        marginBlock: 4,
      }}
    >
      <Paper
        component={'div'}
        elevation={4}
        sx={{
          backgroundColor: '#E7E6F7',
          borderRadius: 1,
          padding: '20px',
          height: '100%',
        }}
      >
        <MemberForm
          disabledFields={disabledFields}
          unUsedFields={['id']}
          values={values}
          onSubmit={onSubmit}
          title='user-info'
        />
      </Paper>
    </Container>
  )
}

export default ClientOrganizationAddMember
