import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AcceptedOfferDriver,
  AdminDeliveredDeliveryDetailsActionCell,
  ContractCell,
  DateCell,
  LoginCell,
  LogoutCell,
  MarginPriceCell,
  OrganizationCell,
  PilotActionCell,
  PriceCell,
  StatisfactionCell,
  SubscriptionsCell,
} from '../CustomCells'

const initialCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Arrivée',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Départ',
    customCellComponent: LogoutCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: true,
  },
]

export const clientDeliveredHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Il devait être livré à",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'completedAt',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: true,
  },
  {
    id: 'clientFinalPrice',
    label: 'Montant HT',
    customCellComponent: PriceCell,
    isNotSortable: true,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: PilotActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]

export const adminDeliveredHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Le client s'attendait à ce qu'il soit livré à",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  // {
  //   id: "adminEstimatedDeliveryDate",
  //   label: "Il devait être livré à",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'completedAt',
    label: 'Validé le',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  // {
  //   id: 'numberOfVehicles',
  //   label: 'Nb. véhicules',
  //   isNotSortable: false,
  // },
  // {
  //   id: 'subscriptions',
  //   label: 'Statut',
  //   isNotSortable: true,
  //   customCellComponent: SubscriptionsCell,
  // },
  // {
  //   id: 'acceptedOfferOld',
  //   label: 'Driver',
  //   customCellComponent: AcceptedOfferDriver,
  //   passWholeObject: true,
  //   isNotSortable: true,
  // },
  {
    id: 'finalPrice',
    label: 'Prix Final Driver',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
  {
    id: 'finalPrice',
    label: 'Margin',
    customCellComponent: MarginPriceCell,
    isNotSortable: true,
    passWholeObject: true,
  },
  {
    id: 'clientFinalPrice',
    label: 'Montant HT Client',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },

  {
    id: 'acceptedOffer',
    label: 'Satisfaction',
    customCellComponent: StatisfactionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: AdminDeliveredDeliveryDetailsActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
