import { Grid } from '@mui/material'

import Skeleton from '@components/common/Skeleton'

const CreateDeliveryDealSkeleton = () => (
  <>
    <div style={{ width: '100%', margin: '0 0 2rem 0', height: '110px' }}>
      <Skeleton />
    </div>
    <Grid container spacing={5}>
      <Grid item xs={5}>
        <div style={{ width: '100%', height: '480px' }}>
          <Skeleton />
        </div>
      </Grid>
      <Grid item xs={7}>
        <div style={{ width: '100%', height: '480px' }}>
          <Skeleton />
        </div>
      </Grid>
    </Grid>
  </>
)

export default CreateDeliveryDealSkeleton
