function CheckIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='#fff'
        d='M0 8C0 3.58125 4.02891 0 9 0C13.9711 0 18 3.58125 18 8C18 12.4187 13.9711 16 9 16C4.02891 16 0 12.4187 0 8ZM13.0711 6.61875C13.4543 6.27813 13.4543 5.72187 13.0711 5.38125C12.6879 5.04063 12.0621 5.04063 11.6789 5.38125L7.875 8.7625L6.32109 7.38125C5.93789 7.04063 5.31211 7.04063 4.92891 7.38125C4.5457 7.72187 4.5457 8.27812 4.92891 8.61875L7.17891 10.6187C7.56211 10.9594 8.18789 10.9594 8.57109 10.6187L13.0711 6.61875Z'
      />
    </svg>
  )
}

export default CheckIcon
