import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { sendRequest } from '@api/clients/axios'
import { Doc } from '@types_def/models/delivery.types'

type UpdateValidationDocsInput = {
  id: number
  validationDocs: Doc[]
}

const updateValidationDocs = async (
  input: UpdateValidationDocsInput,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  try {
    const res = await sendRequest('post', '/delivery/update-validation-docs', input)
    toast('Documents modifiés avec succès', {
      position: 'bottom-right',
      type: 'success',
    })
    await queryClient.invalidateQueries(['delivery', Number(input.id)])
  } catch (error) {
    toast('Une erreur est survenue', {
      position: 'bottom-right',
      type: 'error',
    })
  }
}

export { updateValidationDocs }
