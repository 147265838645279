import Tooltip from '@mui/material/Tooltip'
import ProgressBar from '@ramonak/react-progress-bar'
import { DateTime } from 'luxon'
import { Fragment, useState, type FunctionComponent } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import ExclamationMarkIcon from '@components/common/icons/exclamation-mark.icon'
import ListIcon from '@mui/icons-material/List'
import RetardLabel from '@components/common/Labels/Retard'
import { type Address } from '@types_def/models/address.types'
import {
  type Delivery,
  type DeliveryOptions,
  DeliveryStatus,
  DeliveryType,
} from '@types_def/models/delivery.types'
import { type Subscription } from '@types_def/models/subscription.types'
import { deliveryStatusAttributes } from '@utils/constants/DeliveryStatus/deliveryStatusAttributes'
import { smsStatus } from '@utils/constants/DeliveryStatus/smsStatus'
import { deliveryTableDate } from '@utils/functions/customDates'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'

import AdminActionCellContainer from './AdminActionCellContainer'
import CancelDeliveryContainer from './CancelDeliveryContainer'
import styles from './index.module.scss'
import CustomModal from '@components/common/CustomModal'
import { Button, ButtonGroup, DialogContentText } from '@mui/material'
import { deleteOne } from '@api/graphql/generic/delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { toast } from 'react-toastify'
import SendIcon from '@mui/icons-material/Send'
import updateDeliveryStatus from '@api/graphql/delivery/updateDeliveryStatus'
import { Info, LocalOfferOutlined } from '@mui/icons-material'
import { enumToStr } from '@utils/strEnum'
import { Organization } from '@types_def/models/Organization.types'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { useReactQueryKey } from '@contexts/ReactQueryKey/ReactQueryKey.context'
import { useQueryClient } from '@tanstack/react-query'
import { useUserContext } from '@contexts/UserContext/index.context'
import useDeliveriesCount from '@hooks/useDeliveriesCount'

export const DurationCell: FunctionComponent = ({ children }) =>
  children ? <>{(Number(children.toString()) / 60).toFixed(1)} hrs </> : <></>

export const LoginCell: FunctionComponent = ({ children }) => {
  const content = children as Delivery

  return children ? (
    <b>
      {content.fromAddress?.city}
      {Boolean(content.departedAt) && <>- {deliveryTableDate(content.departedAt)}</>}
    </b>
  ) : (
    <></>
  )
}

export const LogoutCell: FunctionComponent = ({ children }) => {
  const content = children as Delivery

  return children ? (
    <b>
      {content.toAddress?.city}
      {Boolean(content.completedAt) && <>- {deliveryTableDate(content.completedAt)}</>}
    </b>
  ) : (
    <></>
  )
}

export const AddressCell: FunctionComponent = ({ children }) => {
  const displayAddress = children as Partial<Address>
  return displayAddress ? (
    <b>
      {displayAddress.city} - {displayAddress.postalCode}{' '}
    </b>
  ) : (
    <></>
  )
}

export const dateStringFormat = (date: string | DateTime) => {
  const { i18n } = useTranslation()
  const dateLocal = i18n.language === 'fr' ? 'fr' : 'us'
  const value =
    typeof date === 'string'
      ? DateTime.fromISO(date, {
          zone: 'UTC',
        })
      : date
  return value.toFormat('dd LLL yyyy', { locale: dateLocal })
}

export const DateCell: FunctionComponent = ({ children }) => {
  return children ? (
    <div>{dateStringFormat(children as DateTime)}</div>
  ) : (
    <i style={{ textTransform: 'capitalize' }}>{t('common.shared.not-specified')}</i>
  )
}

// we will build a custom cell for organization

export const OrganizationCell: FunctionComponent = ({
  children,
}: {
  children: Partial<Organization>
}) => {
  // const { clientCompany, representativeCompany } = children as Delivery
  const name = children?.name ?? 'Non attribué'
  return <div>{name}</div>
}
export const DeliveryStatusCell: FunctionComponent = ({ children }) => {
  switch (children) {
    case DeliveryStatus.PICKUP_DRIVER:
      return <>Pris en charge</>
    case DeliveryStatus.PICKUP_CLIENT:
      return <>Validation de livraison</>
    case DeliveryStatus.ONGOING:
      return <>En route</>
    case DeliveryStatus.DELIVERED:
      return <>Voiture Livré</>
    case DeliveryStatus.SWAP_PICKUP_DRIVER:
      return <>Voiture 2, Pris en charge</>
    case DeliveryStatus.SWAP_IN_TRANSIT:
      return <>Voiture 2, En route</>
    case DeliveryStatus.SWAP_PICKUP_CLIENT:
      return <>Voiture 2, Validation de livraison</>
    case DeliveryStatus.SWAP_DELIVERED:
      return <>Voiture 2, Livré</>
    case DeliveryStatus.COMPLETED:
      return <>Complété</>
    default:
      return <>Unknown</>
  }
}

export const RetardTimeCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const estimatedDeliveryDate = DateTime.fromISO(children as string, {
    zone: 'UTC',
  })

  const timeDifference = estimatedDeliveryDate.diffNow('hours').hours
  const daysDifference = estimatedDeliveryDate.diffNow('days').days

  const hours = estimatedDeliveryDate.hour
  const minutes = estimatedDeliveryDate.minute
  // Const isLate =dateDifference < 1 && dateDifference > 0
  const isLate = daysDifference < 1 && timeDifference < 1
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span
        style={{
          fontWeight: isLate ? 'bold' : 'normal',
        }}
      >{`${hours}hr${minutes !== 0 ? minutes : ''}`}</span>
      {isLate && <RetardLabel />}
    </div>
  )
}

export const PriceCell: FunctionComponent = ({ children }) => (
  <div>{children ? children.toString().replace('.', ',') + ' € HT' : 'P/A'}</div>
)

export const AverageAuctionPriceCell: FunctionComponent = ({ children }) => {
  const { offers } = children as Delivery

  if (!offers || !offers.length) {
    return <>P/A</>
  }

  const price = offers.reduce((acc, offer) => acc + Number(offer.demandedPrice.toFixed(2)), 0)
  const averagePrice = price / offers.length

  return <div>{averagePrice.toFixed(2)} € HT</div>
}

export const MarginPriceCell: FunctionComponent = ({ children }) => {
  const { finalPrice, clientFinalPrice } = children as Delivery
  if (!finalPrice || !clientFinalPrice) {
    return <i>Error</i>
  }

  return <div>{(clientFinalPrice - finalPrice).toFixed(2)} € HT</div>
}

export const FullAddressCell: FunctionComponent = ({ children }) => {
  const { fromAddress, toAddress } = children as Partial<Delivery>
  return (
    <b>
      {fromAddress?.city} - {toAddress?.city}
    </b>
  )
}

export const SmsCell: FunctionComponent = ({ children }) => {
  const status = children as DeliveryStatus
  return <b>{smsStatus[status]?.tableText}</b>
}

export const OnGoingAdminActionCell: FunctionComponent = ({ children }) => {
  const { type, id, status } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!children) {
    return <i>Error</i>
  }

  return (
    <AdminActionCellContainer id={id} status={status} type={type}>
      <Button
        color='primary'
        variant='contained'
        size='small'
        component={RouterLink}
        to={`/admin/pilot/${strType}/${strStatus}/${id}`}
      >
        <VisibilityIcon fontSize='small' />
        Voir La Course
      </Button>
    </AdminActionCellContainer>
  )
}

export const PilotActionCell: FunctionComponent = ({ children }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1')

  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <Button
      color='primary'
      variant='contained'
      size='small'
      startIcon={<VisibilityIcon />}
      component={RouterLink}
      to={`/${urlRole}/pilot/${strType}/${strStatus}/${id}`}
    >
      {t('delivery.action.details')}
    </Button>
  )
}

export const AdminPilotActionCell: FunctionComponent = ({ children }) => {
  const location = useLocation()
  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1')
  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <CancelDeliveryContainer id={id}>
      <AdminActionCellContainer type={type} status={status} id={id}>
        <div>
          <RouterLink
            to={`/${urlRole}/pilot/${strType}/${strStatus}/${id}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Button color='primary' variant='contained' size='small'>
              <VisibilityIcon fontSize='small' />
              Voir le demande
            </Button>
          </RouterLink>
        </div>
      </AdminActionCellContainer>
    </CancelDeliveryContainer>
  )
}

export const WaitingForDriverAdminActionCell: FunctionComponent = ({ children }) => {
  const { id, number_of_offers, status, type } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <AdminActionCellContainer id={id} status={status} type={type}>
      <ButtonGroup
        disableElevation
        variant='contained'
        size='small'
        sx={{ gap: 1 }}
        orientation='vertical'
      >
        <RouterLink to={`/admin/pilot/${strType}/${strStatus}/${id}`}>
          <Button
            color='info'
            fullWidth
            startIcon={<Info fontSize='small' />}
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            Voir details
          </Button>
        </RouterLink>
        <RouterLink to={`/admin/pilot/edit-deal/${strStatus}/${strType}/${id}`}>
          <Button color='warning' fullWidth startIcon={<ListIcon fontSize='small' />}>
            Modifier l'offer
          </Button>
        </RouterLink>

        <Button
          color='primary'
          fullWidth
          disabled={number_of_offers === 0}
          startIcon={<LocalOfferOutlined fontSize='small' />}
        >
          <RouterLink to={`/admin/pilot/delivery-table/${strStatus}/${id}/offers`}>
            Voir les offers
          </RouterLink>
        </Button>
      </ButtonGroup>
    </AdminActionCellContainer>
  )
}

export const cancelledAdminActionCell: FunctionComponent = ({ children }) => {
  const { id, status, type } = children as Delivery
  const { userState } = useUserContext()
  const { updateDeliveriesCount } = useDeliveriesCount()
  const role = userState?.['custom:role']?.toString()?.toLowerCase() || ''
  const { queryKey } = useReactQueryKey()
  const queryClient = useQueryClient()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  const handleDeleteDelivery = (id: number) => {
    setIsSubmiting(true)
    const deleting = deleteOne(id, 'Delivery')
    toast
      .promise(
        deleting,
        {
          pending: 'Supprission en cours...',
          success: 'Supprission réussie',
          error: 'Échec de la Supprission',
        },
        { position: 'bottom-right' },
      )
      .then(() => {
        queryClient.setQueryData(queryKey, (previous: { total: number; records: any[] }) => {
          const total = previous.total
          const records = previous.records.filter((data) => data.id != id)
          return {
            total,
            records,
          }
        })
        updateDeliveriesCount('cancelledCount', '-')
      })
      .finally(() => {
        setIsSubmiting(false)
      })
  }
  const handleReactiveDelivery = (id: number) => {
    setIsSubmiting(true)
    const enabling = updateDeliveryStatus({ id: id, status: DeliveryStatus.REQUESTED })
    toast
      .promise(
        enabling,
        {
          pending: 'Activation en cours...',
          success: 'Activation réussie',
          error: "Échec de l'activation",
        },
        { position: 'bottom-right' },
      )
      .then(() => {
        queryClient.setQueryData(queryKey, (previous: { total: number; records: any[] }) => {
          const total = previous.total
          const records = previous.records.filter((data) => data.id != id)
          return {
            total,
            records,
          }
        })
        updateDeliveriesCount('cancelledCount', '-')
        updateDeliveriesCount('requestedCount', '+')
      })
      .finally(() => {
        setIsSubmiting(false)
      })
  }
  return (
    <CancelDeliveryContainer id={id}>
      <AdminActionCellContainer id={id} status={status} type={type}>
        <ButtonGroup size='small' sx={{ gap: 2 }}>
          <RouterLink to={`/admin/pilot/${strType}/${strStatus}/${id}`}>
            <Button variant='contained' color='success'>
              Voir
            </Button>
          </RouterLink>
          <CustomModal
            disabled={isSubmiting}
            button={'Réactive'}
            color='primary'
            confirmText={'Réactive'}
            title={`Réactive mission numero ${id}`}
            confirmAction={() => {
              handleReactiveDelivery(id)
            }}
          >
            <DialogContentText id='alert-dialog-description'>
              Êtes-vous sûr de vouloir réactive mission du numero <strong>{id} ?</strong>
            </DialogContentText>
          </CustomModal>

          <CustomModal
            disabled={isSubmiting}
            button={'Supprimer'}
            color='error'
            confirmText={'Supprimer'}
            title={`Supprimer mission numero ${id}`}
            confirmAction={() => {
              handleDeleteDelivery(id)
            }}
          >
            <DialogContentText id='alert-dialog-description'>
              Êtes-vous sûr de vouloir supprimer la deal numero <strong>{id} ?</strong>
            </DialogContentText>
          </CustomModal>
        </ButtonGroup>
      </AdminActionCellContainer>
    </CancelDeliveryContainer>
  )
}

export const cancelledClientActionCell: FunctionComponent = ({ children }) => {
  const { id, status, type } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <CancelDeliveryContainer id={id}>
      <AdminActionCellContainer id={id} status={status} type={type}>
        <ButtonGroup size='small' sx={{ gap: 2 }}>
          <RouterLink to={`/client/pilot/${strType}/${strStatus}/${id}`}>
            <Button variant='contained' color='success'>
              Voir Offer
            </Button>
          </RouterLink>
        </ButtonGroup>
      </AdminActionCellContainer>
    </CancelDeliveryContainer>
  )
}

export const ConfirmedAdminActionCell: FunctionComponent = ({ children }) => {
  // /admin/pilot/details/type/status/id
  const { id, status, type } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!id || !status || !type) {
    return <i>Error</i>
  }

  return (
    <CancelDeliveryContainer id={id}>
      <AdminActionCellContainer id={id} status={status} type={type}>
        <ButtonGroup
          disableElevation
          variant='contained'
          size='small'
          sx={{ gap: 1 }}
          orientation='vertical'
        >
          <RouterLink to={`/admin/pilot/${strType}/${strStatus}/${id}`}>
            <Button color='success' variant='contained' size='small' fullWidth>
              <VisibilityIcon fontSize='small' />
              Voir Détails
            </Button>
          </RouterLink>
          <RouterLink to={`/admin/pilot/create-deal/${strStatus}/${strType}/${id}`}>
            <Button color='primary' variant='contained' size='small' fullWidth>
              <SendIcon fontSize='small' />
              Envoyer le deal
            </Button>
          </RouterLink>
        </ButtonGroup>
      </AdminActionCellContainer>
    </CancelDeliveryContainer>
  )
}

export const AdminSetDeliveryDetailsActionCell: FunctionComponent = ({ children }) => {
  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!children) {
    return <i>Error</i>
  }

  return (
    <ButtonGroup
      disableElevation
      variant='contained'
      size='small'
      sx={{ gap: 1, width: '200px' }}
      orientation='vertical'
    >
      <RouterLink to={`/admin/pilot/${strType}/${strStatus}/${id}`}>
        <Button color='success' variant='contained' size='small' fullWidth>
          <VisibilityIcon fontSize='small' />
          Voir le deal
        </Button>
      </RouterLink>
    </ButtonGroup>
  )
}

export const AdminDeliveredDeliveryDetailsActionCell: FunctionComponent = ({ children }) => {
  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!children) {
    return <i>Error</i>
  }

  return (
    <ButtonGroup
      disableElevation
      variant='contained'
      size='small'
      sx={{ gap: 1, width: '200px' }}
      orientation='vertical'
    >
      <RouterLink to={`/admin/pilot/details/${strType}/${strStatus}/${id}`}>
        <Button color='primary' variant='contained' size='small' fullWidth>
          <VisibilityIcon fontSize='small' />
          Voir le détail
        </Button>
      </RouterLink>
    </ButtonGroup>
  )
}

export const AlertAdminActionCell: FunctionComponent = ({ children }) => {
  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!children) {
    return <i>Error</i>
  }

  return (
    <AdminActionCellContainer id={id} status={status} type={type}>
      <ButtonGroup
        disableElevation
        variant='contained'
        size='small'
        sx={{ gap: 1, width: '200px' }}
        orientation='vertical'
      >
        <RouterLink to={`/admin/pilot/${strType}/${strStatus}/${id}`}>
          <Button color='success' variant='contained' size='small' fullWidth>
            <VisibilityIcon fontSize='small' />
            Voir Détails
          </Button>
        </RouterLink>
        <RouterLink to={`/admin/pilot/edit-deal/alert/${strType}/${id}`}>
          <Button color='warning' fullWidth startIcon={<ListIcon fontSize='small' />}>
            Modifier l'offer
          </Button>
        </RouterLink>
      </ButtonGroup>
    </AdminActionCellContainer>
  )
}

export const SubscriptionsCell: FunctionComponent = ({ children }) => {
  const Subscriptions = children as Subscription[]
  return Subscriptions ? (
    <>
      {Subscriptions.map((subscription, index) => (
        <Fragment key={`subscription-${subscription.id}`}>
          {subscription.name} <br />
          {/* <>{index < Subscriptions.length - 1 ? "," : ""}</> */}
        </Fragment>
      ))}
    </>
  ) : (
    <>Non attribué </>
  )
}

export const RouteCell: FunctionComponent = ({ children }) => {
  const { fromAddress, toAddress } = children as Delivery
  return (
    <div>
      {fromAddress?.city} - {toAddress?.city}
    </div>
  )
}

export const AcceptedOfferDriver: FunctionComponent = ({ children }) => {
  const { selectedDriver } = children as Delivery
  return <div>{selectedDriver?.fullName}</div>
}

export const ZeroKmCell: FunctionComponent = ({ children }) => {
  const { selected } = children as DeliveryOptions as unknown as any
  return (
    <div>
      {selected && Boolean(selected.find((c) => c.name?.toLowerCase() === '0 km')) ? 'Oui' : 'Non'}
    </div>
  )
}

export const StatisfactionCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <></>
  }

  const {
    outfitRating,
    takeOffRating,
    qualityRating,
    communicationRating,
    correspondenceRating,
    justiceRating,
  } = children as Delivery

  const ratings = [
    outfitRating,
    takeOffRating,
    justiceRating,
    correspondenceRating,
    qualityRating,
    communicationRating,
  ].filter((rating) => rating !== undefined) as number[]
  const notRated = ratings.every((rating) => rating === null)
  const averageSatisfaction = notRated
    ? 'pas noté'
    : ratings.length > 0
      ? (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1)
      : 'pas disponible'

  return <div>{averageSatisfaction}</div>
}

export const DistanceCell: FunctionComponent = ({ children }) => <div>{children}km</div>

export const RemainingDistanceCell: FunctionComponent = ({ children }) =>
  children ? <div>{children}km</div> : <i>Error</i>

export const StatusCell: FunctionComponent = ({ children }) => {
  const status = children as DeliveryStatus
  return (
    <div
      style={{
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        padding: '0 0.5rem',
        whiteSpace: 'nowrap',
        width: 'fit-content',
      }}
    >
      {deliveryStatusAttributes.find((c) => c.key === status)?.title}
    </div>
  )
}

export const ContractCell: FunctionComponent = ({ children }) => {
  const { type, publicId, status } = children as Delivery
  const cancelled =
    status in
    [
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_ADMIN,
      DeliveryStatus.CANCELLED_BY_MANDATORY,
    ]
  const color = getDeliveryTypeColor(type)
  return (
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      {cancelled && (
        <div className={styles.exclamationMarkContainer}>
          <ExclamationMarkIcon />
        </div>
      )}
      <div
        style={{
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          background: color,
        }}
      ></div>
      <div>{publicId}</div>
    </div>
  )
}

export const CancelledByCell: FunctionComponent = ({ children }) => {
  const { status } = children as Delivery
  let cancelledBy: string
  switch (status) {
    case DeliveryStatus.CANCELLED_BY_CUSTOMER:
      cancelledBy = 'Customer'
      break
    case DeliveryStatus.CANCELLED_BY_ADMIN:
      cancelledBy = 'Admin'
      break
    case DeliveryStatus.CANCELLED_BY_MANDATORY:
      cancelledBy = 'Mandatory'
      break
    default:
      cancelledBy = 'Unknown'
      break
  }

  return (
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      <div>{cancelledBy}</div>
    </div>
  )
}

export const RemainingTimeCell: FunctionComponent = ({ children }) => {
  const { carPickUpDate, confirmedAt } = children as Delivery

  const auctionTimeLeft = (confirmedDate: DateTime, departureDate: DateTime) => {
    // Const now = new Date(Date.now()).valueOf();
    const confirmedDateFormatted = new Date(confirmedDate.toString())
    const departureDateFormatted = new Date(departureDate.toString())

    const addedDate = new Date(confirmedDateFormatted).setDate(
      new Date(confirmedDateFormatted).getDate() + 2,
    )
    const departedDifference = Number(departureDateFormatted) - Number(new Date()) - 86400000
    const confirmedDifference = addedDate - Number(new Date())
    const difference = Math.max(Math.min(departedDifference, confirmedDifference), 0)

    return Math.floor(difference / 3600000)
  }

  if (!carPickUpDate || !confirmedAt) {
    return <i>Erreur lors de l'obtention de l'heure</i>
  }

  const remainingTime = auctionTimeLeft(confirmedAt, carPickUpDate)

  return (
    <div style={{ width: '100px' }}>
      <Tooltip
        title={
          remainingTime < 0 ? (
            <>La date de livraison était prévue il y a {remainingTime * -1} heures</>
          ) : (
            <>Il reste {remainingTime}h</>
          )
        }
        arrow
        placement='top'
      >
        <div>
          <ProgressBar
            completed={remainingTime}
            bgColor='#1C6DD0'
            labelColor='#1C6DD0'
            labelSize='inherit'
            height='10px'
            margin='='
            isLabelVisible={false}
            maxCompleted={24}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export const ClientRequestedActionCell: FunctionComponent = ({ children }) => {
  const location = useLocation()
  const urlRole = location.pathname.replace(/^\/([^\/]*).*$/, '$1')

  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  return (
    <CancelDeliveryContainer id={id}>
      <div>
        <RouterLink
          to={`/${urlRole}/pilot/${strType}/${strStatus}/${id}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <Button color='primary' variant='contained' size='small'>
            <VisibilityIcon fontSize='small' />
            Voir le détail
          </Button>
        </RouterLink>
      </div>
    </CancelDeliveryContainer>
  )
}
