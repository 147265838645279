import { memo, ReactNode } from 'react'
import './styles.css'
import { Map as GoogleMaps, useApiIsLoaded } from '@vis.gl/react-google-maps'
import { AnimatePresence } from 'framer-motion'

type Props = {
  children: ReactNode
}

const mapId = import.meta.env.VITE_APP_GOOGLE_MAPS_MAPID
const center = {
  lat: 48.856614,
  lng: 2.3522219,
}

function MapInstance({ children }: Props) {
  const loaded = useApiIsLoaded()
  if (!loaded) {
    return <div>Loading...</div>
  }
  return (
    <GoogleMaps defaultCenter={center} disableDefaultUI={true} mapId={mapId} defaultZoom={10}>
      <AnimatePresence>{children}</AnimatePresence>
    </GoogleMaps>
  )
}

export default memo(MapInstance)
