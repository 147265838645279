import { useTranslation } from 'react-i18next'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

const useAddressAutoComplete = () => {
  const { i18n } = useTranslation()
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'address-search',
    requestOptions: {
      language: i18n.language,
      componentRestrictions: {
        //france, belgium, germany , monaco, netherlands
        country: ['fr', 'be', 'de', 'mc', 'nl'],
      },
    },
  })
  const handleSelect = async (address: string) => {
    setValue(address, false)
    clearSuggestions()
    try {
      const results = await getGeocode({ address })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const addressDetails = results[0].address_components.reduce((acc: any, curr) => {
        if (curr.types.includes('locality')) {
          acc.city = curr.long_name
        }
        if (curr.types.includes('postal_code')) {
          acc.postalCode = curr.long_name
        }
        return acc
      }, {})
      const formattedAddress = results[0].formatted_address
      const placeId = results[0].place_id
      const { lat, lng } = await getLatLng(results[0])
      let country: string
      // Check for France
      if (formattedAddress.includes('France')) {
        country = 'FR'
      } else if (formattedAddress.includes('Belgique') || formattedAddress.includes('Belgium')) {
        country = 'BE'
      } else if (formattedAddress.includes('Allemagne') || formattedAddress.includes('Germany')) {
        country = 'DE'
      } else if (formattedAddress.includes('Monaco')) {
        country = 'MC'
      } else if (
        formattedAddress.includes('Pays-Bas') ||
        formattedAddress.includes('Netherlands')
      ) {
        country = 'NL'
      }

      return {
        address: formattedAddress,
        latitude: lat,
        longitude: lng,
        placeId,
        city: addressDetails.city,
        postalCode: addressDetails.postalCode,
        country,
      }
    } catch (error) {}
  }

  return {
    value,
    status,
    data,
    setValue,
    clearSuggestions,
    handleSelect,
  }
}

export default useAddressAutoComplete
