import getAllFiltered from '@api/graphql/generic/getAllFiltered'
import { GetAllInput, OrderType, WhereFilter } from '@types_def/common/filter.types'
import { GenericObject } from '@types_def/common/generic.types'
import { QueryType } from '@types_def/common/query.types'
export type TableFilterType<T> = {
  filter?: WhereFilter<T>
  notEqual?: WhereFilter<T>
  page: number
  order: OrderType
  orderBy: keyof T
}
export type TableStateType<T> = {
  tableFilter: TableFilterType<T>
}
const getPaginated = async <T extends GenericObject>({
  tableState,
  filter,
  resource,
  queryField,
}: {
  tableState: TableStateType<T>
  filter?: WhereFilter<T>
  resource: string
  queryField: QueryType<T>
}): Promise<{ records: T[]; total: number }> => {
  const getAllInput: GetAllInput<T, typeof tableState.tableFilter.orderBy> = {
    pagination: { page: tableState.tableFilter.page, perPage: 5 },
    sort: {
      field: tableState.tableFilter.orderBy,
      order: tableState.tableFilter.order,
    },
    filter,
  }

  const res = await getAllFiltered<T, typeof tableState.tableFilter.orderBy>(
    getAllInput,
    resource,
    queryField,
  )

  if (!res.data) throw new Error('No data found')

  return res.data
}

export default getPaginated
