import { type FunctionComponent } from 'react'

import { type Offer } from '@types_def/models/offer.types'

export const DriverNameCell: FunctionComponent = ({ children }) =>
  Boolean(children) && Boolean((children as Offer).driver?.fullName) ? (
    <>{(children as Offer).driver?.fullName}</>
  ) : (
    <></>
  )
