import { DialogContentText, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import CustomModal from '@components/common/CustomModal'
import { TFormActions } from '../Types'
import { buttonsConfig } from './config'

type TMainButtons = {}

const MainButtons = ({}: TMainButtons) => {
  const {
    formState: { isSubmitting },
    getValues,
    reset,
  } = useFormContext()
  const formId = 'deliveryFormV2'
  const mainButtons: TFormActions = buttonsConfig({
    id: getValues('id'),
    reset,
  })
  return (
    <>
      {mainButtons.actions.map((button, index) => (
        <Grid
          item
          xs={index === mainButtons.actions.length - 1 ? 12 : 6}
          lg={12 / mainButtons.actions.length}
          key={index + button.title}
        >
          <CustomModal
            formId={formId}
            key={index + button.title}
            canOpen={button.isModal}
            fullWidth={true}
            button={button.text}
            confirmText={button.text}
            title={button?.title}
            confirmAction={button.action ?? button.action}
            disabled={isSubmitting}
            variant={button.variant}
            color={button.color}
            type={button.type}
          >
            <DialogContentText id='alert-dialog-description'>
              {button?.description}
            </DialogContentText>
          </CustomModal>
        </Grid>
      ))}
    </>
  )
}

export default MainButtons
