import { type DateTime } from 'luxon'

export const customDeliveryVerifiedAtDate = (date: Date) =>
  date
    .toLocaleDateString('fr', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(':', 'h')
    .replace(',', ' à')

export const customDeliveryScheduledForDate = (date: DateTime) =>
  new Date(date as unknown as Date)
    .toLocaleDateString('fr', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(':', 'h')
    .replace(',', ' à')
