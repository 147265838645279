import { type RatingType } from '@types_def/components/rating'

export const ratings: Array<{
  key: keyof RatingType
  label: string
}> = [
  {
    key: 'outfitRating',
    label: 'Tenue',
  },
  {
    key: 'takeOffRating',
    label: 'Ponctualité',
  },
  {
    key: 'qualityRating',
    label: 'Serviabilité',
  },
  {
    key: 'communicationRating',
    label: 'Propreté du véhicule',
  },
  {
    key: 'justiceRating',
    label: 'Justesse des informations communiquées',
  },
  {
    key: 'correspondenceRating',
    label: "Correspondance entre l’état du véhicule de départ et d'arrivée",
  },
]
