import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '../icons/search.icon'
import styles from './index.module.scss'

type Props = {
  placeholder: string
  searchKey: string
}
// create
const SearchBox = ({ placeholder, searchKey }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const formRef = useRef<HTMLFormElement>(null)
  const defaultValue = searchParams.get(searchKey) || ''
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (searchParams.get('page')) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
    const keyword = event.currentTarget.querySelector('input')?.value
    console.log({ keyword })
    if (!keyword || keyword === '') {
      searchParams.delete(searchKey)
      setSearchParams(searchParams)
      return
    }
    searchParams.set(searchKey, keyword)
    setSearchParams(searchParams)
  }
  // click on escape to clear the search :: reset form
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Escape') {
      if (searchParams.get('page')) {
        searchParams.delete('page')
        setSearchParams(searchParams)
      }
      formRef.current?.reset()
      searchParams.delete(searchKey)
      setSearchParams(searchParams)
    }
  }

  return (
    <form
      className={`${styles.search} item`}
      onSubmit={handleSubmit}
      ref={formRef}
      onKeyDown={handleKeyDown}
    >
      <input type='text' placeholder={placeholder} defaultValue={defaultValue} />
      <SearchIcon />
    </form>
  )
}

export default SearchBox
