import { Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import type * as React from 'react'
import { useTranslation } from 'react-i18next'

import { type OrderType } from '@types_def/common/filter.types'
import { type GenericObject } from '@types_def/common/generic.types'
export type HeadCell<T extends GenericObject> = {
  id: keyof T
  label: string
  passWholeObject?: boolean
  isNotSortable?: boolean
  isHidden?: boolean
  customCellComponent?: React.FunctionComponent
}
type EnhancedTableHeadProps<T extends GenericObject, K extends keyof T> = {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: OrderType
  orderBy: K
  rowCount: number
  isReadOnly: boolean
  headCells: Array<HeadCell<T>>
}

const EnhancedTableHead = <T extends GenericObject, K extends keyof T>(
  props: EnhancedTableHeadProps<T, K>,
) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isReadOnly,
  } = props
  const { t } = useTranslation()
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className={'table-head-row'}>
        {!isReadOnly && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) => {
                onSelectAllClick(event)
              }}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells.map(
          (headCell, index) =>
            !headCell.isHidden && (
              <TableCell
                key={`headCell-${index}`}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.isNotSortable ? (
                  <Typography variant='subtitle1'>
                    {t('common.table.headers.' + headCell.label)}
                  </Typography>
                ) : (
                  <TableSortLabel
                    className={'sort-arrow'}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={headCell.isNotSortable ? undefined : createSortHandler(headCell.id)}
                  >
                    <Typography variant='subtitle1'>
                      {t('common.table.headers.' + headCell.label)}
                    </Typography>
                  </TableSortLabel>
                )}
              </TableCell>
            ),
        )}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
