import { Delivery, DeliveryType } from '@types_def/models/delivery.types'

const dataFilter = (data: Delivery & { step: number }) => {
  data?.step && delete data.step
  if (data?.type === DeliveryType.TWO_WAY_DELIVERY && !data?.toReturnToAddress?.place_id)
    delete data?.toReturnToAddress
  data.clientPhone = data.clientPhone.replaceAll(' ', '')
  data.representativePhone = data.representativePhone.replaceAll(' ', '')

  return data
}

export { dataFilter }
