import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { downloadInvoice, generateInvoice } from './facture.service'
import { useTranslation } from 'react-i18next'

type Props = {
  deliveryId: number
  invoice: string
}

const Invoice = ({ invoice, deliveryId }: Props) => {
  const { t } = useTranslation()
  // if(!Boolean(invoice))
  return (
    <Button variant='outlined' fullWidth onClick={() => generateInvoice(deliveryId)}>
      {t("common.shared.download-pdf")}
    </Button>
  )
  // return (
  //   <Button variant='outlined' fullWidth onClick={() => downloadInvoice(invoice)}>
  //     Télécharger Facture
  //   </Button>
  // )
}

export default Invoice
