import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import type Driver from '@types_def/models/driver.types'

import { ActionCell, DateCell, FullNameCell } from '../CustomCells'
import SignupProgressCell from '../CustomCells/SignupProgessCell'

const registeringHeadCells: Array<HeadCell<Driver>> = [
  {
    id: 'publicId',
    label: 'ID',
    isNotSortable: false,
  },
  {
    id: 'firstName',
    label: 'Name',
    passWholeObject: true,
    customCellComponent: FullNameCell,
  },
  {
    id: 'createdAt',
    label: 'Date demande création',
    customCellComponent: DateCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: ActionCell,
    isNotSortable: true,
  },
]

export default registeringHeadCells
