/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
type ModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  imgUrl: string
}
export default function ImageMadal({ open, setOpen, imgUrl }: ModalProps) {
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <img src={imgUrl} alt='' width={'100%'} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
