import {
  Box,
  Button,
  ButtonGroup,
  DialogContent,
  DialogContentText,
  Divider,
  Fade,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useRef, useState, type FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { acceptOffer } from '@api/graphql/offer/acceptOffer'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { OfferStatus, type Offer } from '@types_def/models/offer.types'
import { toast } from 'react-toastify'
import HandshakeIcon from '@mui/icons-material/Handshake'
import CustomModal from '@components/common/CustomModal'
import { useForm } from 'react-hook-form'

import { CreateOfferInput, sendOfferGraphql } from '@api/graphql/offer/sendOffer'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useQueryClient } from '@tanstack/react-query'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { deleteOffer, rejectOffer } from '../../Querys'
import * as Sentry from '@sentry/react'

const schema = z.object({
  demandedPrice: z.coerce.number().min(10),
})

export const ActionCell: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()

  const moreRef = useRef(null)

  const { deliveryId, id, driver, status } = children as Offer
  const navigate = useNavigate()
  const confirm = useConfirm()

  const handleAcceptOffer = (deliveryId: number, offerId: number) => {
    acceptOffer({ id: offerId })
      .then((_) => {
        navigate('/admin/pilot/delivery-table/set')
      })
      .catch((err) => {
        toast(err?.message ?? 'Une erreur est survenue', {
          type: 'error',
          position: 'bottom-right',
        })
      })
  }

  const handleRejectOfferClick = () => {
    rejectOffer({ deliveryId, id }, queryClient)
  }

  const handleDeleteOfferClick = () => {
    deleteOffer({ deliveryId, id }, queryClient)
  }

  const handleProposeOfferClick = () => {
    // TODO: show a modal to propose an offer
  }

  const handleAcceptOfferClick = () =>
    void confirm({
      title: <Typography>Souhaitez-vous accepter cette offre ?</Typography>,
    }).then(() => {
      handleAcceptOffer(deliveryId, id)
    })
  return children ? (
    <Stack direction='row' spacing={1}>
      <Button
        disabled={![OfferStatus.PENDING].includes(status)}
        size='small'
        variant='contained'
        onClick={handleAcceptOfferClick}
        color='primary'
      >
        <CheckCircleIcon fontSize='small' />
        Clôturer le deal
      </Button>
      <ProposeOfferForm deliveryId={deliveryId} accepted={false} driverId={driver.id} />
      <IconButton size='small' color='primary' ref={moreRef} onClick={() => setOpen(!open)}>
        <MoreVertIcon fontSize='small' />
      </IconButton>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={moreRef.current}
        placement={'right-start'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography p={1} variant='body2' textAlign={'center'}>
                Autre actions
              </Typography>
              <Divider />
              <Stack p={2} direction='column' spacing={1}>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleRejectOfferClick}
                  color='error'
                >
                  Rejecter l'offre
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  onClick={handleDeleteOfferClick}
                  color='error'
                >
                  Supprimer l'offre
                </Button>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Stack>
  ) : (
    <></>
  )
}

const ProposeOfferForm = ({
  deliveryId,
  accepted,
  driverId,
}: Omit<CreateOfferInput, 'demandedPrice'>) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      demandedPrice: 0,
    },
  })
  const queryClient = useQueryClient()
  const sendOffer = async (data: { demandedPrice: number }) => {
    const res = await sendOfferGraphql({
      demandedPrice: Number(data.demandedPrice), // TODO: check if the value is a number (use yup or zod)
      deliveryId,
      accepted,
      driverId,
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          requestData: {
            demandedPrice: Number(data.demandedPrice),
            deliveryId,
            accepted,
            driverId,
          },
          error: error,
          component: 'ProposeOfferForm',
        },
      })
      return null
    })
    if (res) {
      toast('Offre soumise avec succès', {
        type: 'success',
        position: 'bottom-right',
      })
      queryClient.invalidateQueries({
        queryKey: ['Offers', { driver: true }, { deliveryId }],
      })
      reset()
    } else {
      toast('Une erreur est survenue', {
        type: 'error',
        position: 'bottom-right',
      })
    }
  }
  return (
    <CustomModal
      btnSize='small'
      disabled={false}
      button={
        <>
          <HandshakeIcon fontSize='small' />
          Proposer un offre
        </>
      }
      color='secondary'
      confirmText={'Confirmer'}
      title={`Proposer un offre`}
      formId={'send-offer-form'}
    >
      <DialogContent>
        <Box
          component={'form'}
          minWidth={400}
          onSubmit={handleSubmit(sendOffer)}
          id={'send-offer-form'}
        >
          <TextField
            {...register('demandedPrice')}
            label={'Price'} //optional
            fullWidth
            helperText={errors.demandedPrice?.message}
            error={Boolean(errors.demandedPrice)}
          />
          <button
            id='send-offer-form-button'
            type='submit'
            style={{
              display: 'none',
            }}
          >
            Submit
          </button>
        </Box>
      </DialogContent>
    </CustomModal>
  )
}
