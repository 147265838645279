import { Grid, Typography, Divider } from '@mui/material'
import AlbInput from '@components/common/AlbInput'
import Summary from '../summary/Summary'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type TInformationSupp = {}
const InformationSupp = ({}: TInformationSupp) => {
  const { t } = useTranslation()
  const { getValues } = useFormContext()
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform='capitalize'>
          {t('delivery.form.comment')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>
      <Grid item xs={12}>
        <AlbInput
          name='comment'
          label={t('delivery.form.comment')}
          type='TextAreaField'
          variant='outlined'
        />
      </Grid>
    </Grid>
  )
}

export default InformationSupp
