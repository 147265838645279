import React, { CSSProperties, useEffect, useState } from 'react'
import { Location, WebSocketSingleton } from '@utils/WebSocket'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { sendRequest } from '@api/clients/axios'
import styled from 'styled-components'
import { Button, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { Link } from '@mui/icons-material'
import { Link as ReactRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MarkerInner = styled.div<{ $extend?: boolean; $bgColor?: string; $opacity?: number }>`
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : 'var(--primary-color)')};
  color: white;
  opacity: ${(props) => props.$opacity ?? 1};
  border-radius: 8px;
  padding: ${(props) => (props.$extend ? '5px 15px' : '5px 10px')};
  position: relative;
  width: ${(props) => (props.$extend ? '300px' : '120px')};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => (props.$bgColor ? props.$bgColor : 'var(--primary-color)')};
  }
`
const socket = WebSocketSingleton.getInstance()
export default function DriverMarker({
  map,
  id,
  title,
  description,
  driver,
  containerStyles,
}: {
  id: string
  map: google.maps.Map
  title: string
  description: string
  driver: {
    id: string
    name: string
    avatar: string
  }
  color: string
  containerStyles: CSSProperties
}) {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)

  const [position, setPosition] = useState<google.maps.LatLngLiteral | null>(null)

  useEffect(() => {
    async function fetchLatestPosition() {
      try {
        const { data: latestLocation } = await sendRequest<any, Location>('get', '/location/' + id)
        const pos = {
          lat: +latestLocation.latitude,
          lng: +latestLocation.longitude,
        }
        if (latestLocation) {
          setPosition(pos)

          // const oldbounds = map.getBounds()
          // oldbounds.extend(pos)
          // map.fitBounds(oldbounds)
        }
      } catch (error) {
        // toast.error('Error fetching latest location pour mission ' + id)
      }
    }
    fetchLatestPosition()
  }, [])
  useEffect(() => {
    socket.onLocation((position) => {
      if (position.deliveryId.toString().trim() === id.toString().trim()) {
        const newPos = {
          lat: +position.latitude,
          lng: +position.longitude,
        }
        setPosition(newPos)
      }
    })
  }, [])

  return (
    <AdvancedMarker
      position={position}
      onClick={() => setShowDetails((v) => !v)}
      collisionBehavior={google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
    >
      <MarkerInner $extend={showDetails} $bgColor={'#535353'} style={containerStyles}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '80%',
            marginRight: 'auto',
          }}
        >
          <div>
            <h5 className='marker-title' style={{ whiteSpace: 'normal' }}>
              {title}
            </h5>
            <span className='marker-description' style={{ whiteSpace: 'normal' }}>
              {description}
            </span>
          </div>
          {showDetails ? (
            <img
              className='driver-hightlight'
              src={driver.avatar}
              width={'100%'}
              height={'100%'}
              style={{
                objectFit: 'cover',
              }}
            />
          ) : null}
        </div>
        {showDetails ? (
          <Button
            fullWidth
            variant='contained'
            color='primary'
            size='small'
            sx={{ mt: 1 }}
            endIcon={<Link />}
            component={ReactRouter}
            to={`/admin/drivers/drivers-table/accepted-requests/${driver.id}`}
          >
            {t('common.shared.driver-details')}
          </Button>
        ) : null}
        {showDetails ? (
          <IconButton
            aria-label='close'
            size='small'
            onClick={() => {}}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <Close />
          </IconButton>
        ) : null}
      </MarkerInner>
    </AdvancedMarker>
  )
}
