import { DeliveryType, type Delivery } from '@types_def/models/delivery.types'
import { Stack } from '@mui/material'
import { Button, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { enumToStr } from '@utils/strEnum'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import { useFormContext, useWatch } from 'react-hook-form'
import { DeliveryFilterSchemaType } from '@schemas/DeliveryForm/DeliveryFilterSchema'

const DeliveryTableTypeFilter = () => {
  const { t } = useTranslation()
  const { setValue } = useFormContext<DeliveryFilterSchemaType>()
  const deliveryTypeWatcher = Object.keys(DeliveryType).map((type) =>
    useWatch({
      name: `deliveryType.${type}`,
    }),
  )
  return (
    <>
      <Typography variant='body2' letterSpacing={2} fontWeight={600}>
        Delivery Type
      </Typography>
      <Divider />
      <Stack direction='row' sx={{ flexWrap: 'wrap' }} p={1} gap={1}>
        {Object.keys(DeliveryType).map((type, index) => (
          <Button
            size='small'
            variant={deliveryTypeWatcher[index] ? 'contained' : 'outlined'}
            onClick={() => setValue(`deliveryType.${type}`, !deliveryTypeWatcher[index])}
            key={`delivery-table-type-filter-${index}`}
          >
            <span
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: getDeliveryTypeColor(type as DeliveryType),
                marginRight: 5,
              }}
            ></span>
            {t(`layouts.sub-headers.deliveries.${enumToStr(type)}`)}
          </Button>
        ))}
      </Stack>
    </>
  )
}

export default DeliveryTableTypeFilter
