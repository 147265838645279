import { gql } from '@apollo/client'

import { graphqlMutation } from '@api/clients/apollo'
import { type Delivery } from '@types_def/models/delivery.types'
import { type Offer } from '@types_def/models/offer.types'

type ApiUpdateResponse = {
  accepted: {
    id: number
    demandedPrice: number
    deliveryId: number
  }
}
type AcceptOfferInput = {
  id: number
}

export async function acceptOffer(AcceptOfferInput: AcceptOfferInput) {
  try {
    const res = await graphqlMutation<ApiUpdateResponse, { AcceptOfferInput: AcceptOfferInput }>(
      gql`
        mutation acceptOffer($AcceptOfferInput: AcceptOfferInput!) {
          accepted: acceptOffer(AcceptOfferInput: $AcceptOfferInput) {
            id
          }
        }
      `,
      { AcceptOfferInput },
    )
    return res.data?.accepted
  } catch (error) {
    throw error
  }
}
