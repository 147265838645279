import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type TComponentContainer = {
  title: string
  children: React.ReactNode
}

function ComponentContainerAccordion({ title, children }: TComponentContainer) {
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Accordion
          elevation={4}
          sx={{
            backgroundColor: '#E7E6F7',
            borderRadius: 1,
            height: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Grid container item direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5' textTransform='capitalize'>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ bgcolor: '#fe5d8d' }} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid item xs={12} paddingBottom={2} gap={4} marginTop={2}>
            <AccordionDetails>{children}</AccordionDetails>
          </Grid>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default ComponentContainerAccordion

/* 

    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>

      <Grid item xs={12} paddingBottom={2} gap={4} marginTop={2}>
        {children}
      </Grid>
    </Grid>

*/
