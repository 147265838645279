import { type CreateDeliveryInput, type Delivery } from '@types_def/models/delivery.types'

import create from '../generic/create'

export const createDelivery = async (data: CreateDeliveryInput) => {
  // @ts-expect-error
  const createDeliveryResponse = await create<Partial<Delivery>>(data, 'Delivery', {
    id: true,
    status: true,
    publicId: true,
    type: true,
  })
  return createDeliveryResponse
}
