import { type PropsWithChildren } from 'react'

import styles from './index.module.scss'

type Props = {
  title: string
  main?: boolean
}

const DriverDetailsItem = ({ title, main = false, children }: PropsWithChildren<Props>) => (
  <div className={`${styles.driverDetailsItem} item`}>
    <div className={`${styles.driverDetailsItemTitle}`}>{title}</div>
    <div className={`${!main && styles.driverDetailsItemChildren}`}>{children}</div>
  </div>
)

export default DriverDetailsItem
