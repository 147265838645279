import { Stack } from '@mui/material'
import { Button, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { extraOptions } from '@utils/constants/deliveryOptions'
import { useFormContext, useWatch } from 'react-hook-form'
import { DeliveryFilterSchemaType } from '@schemas/DeliveryForm/DeliveryFilterSchema'

const DeliveryTableServiceFilter = () => {
  const { t } = useTranslation()

  const { setValue } = useFormContext<DeliveryFilterSchemaType>()

  const deliveryServicesWatcher = extraOptions.map((option) =>
    useWatch({
      name: `deliveryServices.${option.id}`,
    }),
  )
  return (
    <>
      <Typography variant='body2' letterSpacing={2} fontWeight={600} textTransform={'capitalize'}>
        {t('layouts.sub-headers.filters.services')}
      </Typography>
      <Divider />
      <Stack direction='row' sx={{ flexWrap: 'wrap' }} p={1} gap={1}>
        {extraOptions.map((option, index) => (
          <Button
            size='small'
            variant={!deliveryServicesWatcher[index] ? 'outlined' : 'contained'}
            key={option.id}
            onClick={() =>
              setValue(`deliveryServices.${option.id}`, !deliveryServicesWatcher[index])
            }
          >
            {t(`delivery.services.${option.name}`)}
          </Button>
        ))}
      </Stack>
    </>
  )
}

export default DeliveryTableServiceFilter
