// @ts-nocheck

import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCalculateTotalPrice } from '../hooks'

type TTotalPrice = {
  deliveryDetails: {
    fromAddress: Address
    toAddress: Address
    category: string
    numberOfVehicles: number
    options: { selected: SelectedOption[] }
    vehicle: Vehicle
  }[]
}

const TotalPrice = ({ deliveryDetails }: TTotalPrice) => {
  const { totalPrice } = useCalculateTotalPrice(deliveryDetails)

  return (
    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
      <Typography
        fontWeight='900'
        fontSize={'1.3rem'}
        sx={{ borderBottom: '3px solid #fe5d8d' }}
        variant='overline'
      >
        {Boolean(deliveryDetails[1]?.toAddress?.place_id) ? (
          'Prix Total Du Delivery: '
        ) : (
          <span style={{ color: 'red' }}>Prix sans restitution: </span>
        )}
        {totalPrice.toFixed(2)} €
      </Typography>
    </Grid>
  )
}

export default TotalPrice
