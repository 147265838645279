import { ButtonGroup, Grid, Paper } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { CreateClientInput } from '@types_def/models/client.types'
import EnterpriseName from '../EnterpriseName'
import Responsible from '../Responsible'
import MainButtons from '../MainButtons'

type TForm = {
  onSubmit: (data: any) => void
  resource: 'new-client' | 'edit-client'
}

const Form = ({ onSubmit, resource }: TForm) => {
  const { handleSubmit } = useFormContext<CreateClientInput>()
  return (
    <Grid container component={'form'} spacing={4}>
      <Grid item xs={12}>
        <Paper
          elevation={4}
          sx={{
            backgroundColor: '#E7E6F7',
            borderRadius: 1,
            padding: '20px',
            height: '100%',
          }}
        >
          <EnterpriseName />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={4}
          sx={{
            backgroundColor: '#E7E6F7',
            borderRadius: 1,
            padding: '20px',
            height: '100%',
          }}
        >
          <Responsible />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup fullWidth size='large' sx={{ gap: 5 }}>
          <MainButtons resource={resource} onSubmit={handleSubmit(onSubmit)} />
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

export default Form
