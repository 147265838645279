import { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import getAllFilteredDeep from '@api/graphql/generic/getAllFiltered'
import Client from '@types_def/models/client.types'
import Box from '@mui/material/Box'
type Props = {
  onSelect: (client) => void
}

const ClientSelector = ({ onSelect }: Props) => {
  const [clients, setClients] = useState([])
  const [client, setClient] = useState<Client | undefined>(undefined)
  const handleChange = (event: SelectChangeEvent) => {
    const client = clients.find((el) => el.id === (event.target.value as string))
    setClient(client)
    onSelect(client)
  }
  useEffect(() => {
    ;(async () => {
      const getAllInput = {
        keyword: '',
        pagination: { page: 0, perPage: 10 },
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
        filter: {
          partner: true,
        },
        include: {
          _count: true,
        },
      }
      //   @ts-ignore
      const { data, success } = await getAllFilteredDeep<Client>(getAllInput, 'Clients', {
        id: true,
        assigneeFirstName: true,
        assigneeLastName: true,
        assigneeEmail: true,
        assigneePhone: true,
        name: true,
        siret: true,
      })
      if (data && success) {
        setClients(data.records)
      }
    })()
  }, [])

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl fullWidth>
        <InputLabel id='select-client'>Choisir le mandataire</InputLabel>
        <Select
          labelId='select-client'
          id='select-client'
          value={client?.id + '' || ''}
          label='Choisir le mandataire'
          onChange={handleChange}
        >
          {clients.map((client: Client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.assigneeFirstName} {client.assigneeLastName} ({client.name})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ClientSelector
