import { Stack } from '@mui/material'
import { Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ControlledInput from '@components/common/ControlledInput'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

const DeliveryTableDeliveryDateFilter = () => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant='body2' letterSpacing={2} fontWeight={600}>
        {' '}
        {t('common.table.headers.Date de livraison')}
      </Typography>
      <Divider />
      <Stack
        margin={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('fr')}>
          <ControlledInput name='carDeliveryDeadlineFrom' label={t('common.shared.from')}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </ControlledInput>
          <ControlledInput name='carDeliveryDeadlineTo' label={t('common.shared.to')}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </ControlledInput>
        </LocalizationProvider>
      </Stack>
    </>
  )
}

export default DeliveryTableDeliveryDateFilter
