import { Grid } from '@mui/material'
import React from 'react'

import EyeIcon from '@components/common/icons/eye.icon'
import XIcon from '@components/common/icons/x.icon'
import { type DamagedCarImages } from '@types_def/models/vehicle.types'

import styles from './index.module.scss'

const CarImagesLabels: DamagedCarImages = {
  topImage: 'Photo du haut',
  topLeftImage: 'Photo du haut à gauche',
  topRightImage: 'Photo du haut à droite',
  bottomImage: 'Photo du bas',
  bottomLeftImage: 'Photo du bas à gauche',
  bottomRightImage: 'Photo du bas à droite',
  // ExtraImages: "Images supplémentaires",
}

type Props = {
  images: DamagedCarImages
  handleSetCarImagesModalOpen: (open: boolean) => void
  title: string
}

const DamagedVehicleImages = ({ images, handleSetCarImagesModalOpen, title }: Props) => {
  const imageUrlPrefix = import.meta.env.VITE_APP_S3_MEDIA_BUCKET_URL
  return (
    <div className={styles.images}>
      <div className={styles.title}>
        <div>{title}</div>
        <div
          className={styles.X}
          onClick={() => {
            handleSetCarImagesModalOpen(false)
          }}
        >
          <XIcon />
        </div>
      </div>
      <Grid container columnGap='2rem'>
        {Object.keys(images ?? {}).map((key) => {
          const image = images[key as keyof DamagedCarImages]
          const url = image.includes('http') ? image : `${imageUrlPrefix}${image}`
          return (
            <React.Fragment key={`car-images-after-${key}`}>
              {image !== '' && key !== 'extraImages' && (
                <Grid item xs={6} className={styles.image}>
                  <div className={styles.label}>
                    {CarImagesLabels[key as keyof DamagedCarImages]}:
                    <a href={url} target='_blank' rel='noreferrer'>
                      <EyeIcon />
                    </a>
                  </div>
                </Grid>
              )}
            </React.Fragment>
          )
        })}
      </Grid>
    </div>
  )
}

export default DamagedVehicleImages
