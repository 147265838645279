const Countries = [
    {
        "code": "FR",
        "name": "France"
    },
    {
        code: "BE",
        name: "Belgium"
    },
]

export default Countries;