import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import type Driver from '@types_def/models/driver.types'

import { DeliveriesCountCell, FullNameCell, RegistredActionCell } from '../CustomCells'
import { DateCell } from '@components/Delivery/DeliveryTable/CustomCells'

const registeredHeadCells: Array<HeadCell<Driver>> = [
  {
    id: 'publicId',
    label: 'ID',
    isNotSortable: false,
  },
  {
    id: 'firstName',
    label: 'Name',
    passWholeObject: true,
    customCellComponent: FullNameCell,
  },
  {
    id: 'phoneNumber',
    label: 'Numéro',
  },
  {
    id: 'nbDeals',
    label: 'Total Deliveries',
    passWholeObject: true,
    isNotSortable: false,
    customCellComponent: DeliveriesCountCell,
  },
  {
    id: 'createdAt',
    label: 'Date de création',
    customCellComponent: DateCell,
  },
  {
    id: 'id',
    label: 'Action',
    passWholeObject: true,
    customCellComponent: RegistredActionCell,
    isNotSortable: true,
  },
]

export default registeredHeadCells
