function GarageIcon() {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='#fff'
        d='M0 15.2464V5.30162C0 4.48205 0.448031 3.74099 1.13147 3.43639L8.66531 0.0718537C8.87906 -0.0239512 9.12094 -0.0239512 9.33469 0.0718537L16.8694 3.43639C17.5528 3.74099 18 4.48205 18 5.30162V15.2464C18 15.664 17.6991 16 17.325 16H15.975C15.6009 16 15.3 15.664 15.3 15.2464V6.9282C15.3 6.40066 14.8978 5.92337 14.4 5.92337H3.6C3.10219 5.92337 2.7 6.40066 2.7 6.9282V15.2464C2.7 15.664 2.39766 16 2.025 16H0.675C0.302344 16 0 15.664 0 15.2464ZM5.31281 10.4137L5.85281 8.63643C6.0975 7.93304 6.69375 7.45888 7.36312 7.45888H10.6369C11.2809 7.45888 11.9025 7.93304 12.1219 8.63643L12.6872 10.4137C13.1709 10.7403 13.5 11.3181 13.5 11.9807V14.9952C13.5 15.551 13.0978 16 12.6 16C12.1022 16 11.7 15.551 11.7 14.9952V14.4927H6.27469V14.9952C6.27469 15.551 5.89781 16 5.37469 16C4.90219 16 4.47469 15.551 4.47469 14.9952V11.9807C4.47469 11.3181 4.82906 10.7403 5.31281 10.4137ZM6.81187 10.2222H11.1881L10.8478 9.13571C10.8169 9.03522 10.7325 8.93788 10.6369 8.93788H7.36312C7.2675 8.93788 7.18313 9.03522 7.15219 9.13571L6.81187 10.2222ZM6.75 13.2367C7.12406 13.2367 7.425 12.9007 7.425 12.4831C7.425 12.0654 7.12406 11.7294 6.75 11.7294C6.37594 11.7294 6.075 12.0654 6.075 12.4831C6.075 12.9007 6.37594 13.2367 6.75 13.2367ZM11.25 11.7294C10.8759 11.7294 10.575 12.0654 10.575 12.4831C10.575 12.9007 10.8759 13.2367 11.25 13.2367C11.6241 13.2367 11.925 12.9007 11.925 12.4831C11.925 12.0654 11.6241 11.7294 11.25 11.7294Z'
      />
    </svg>
  )
}

export default GarageIcon
