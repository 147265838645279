export const RoutePaths = {
  login: '/login',
  dashboard: '/dashboard',
  user: '/user',
  profile: '/profile',
  account: '/account',
  driverMandate: '/driver-mandate',
  client: '/client',
  help: '/help',
  newOrganization: '/new-organization',
  organizations: '/organizations',
  admins: '/admins',
  maintainers: '/maintainers',
  support: '/support',
  organizationsTable: '/organizations-table',
  admin: '/admin',
  deliveryRating: '/delivery-rating',
  deliveryCustomerConfirmation: '/delivery-customer-confirmation',
  deliveryCustomerTracking: '/delivery-customer-tracking',
  deliveryMandate: '/driver-mandate',
  newDelivery: '/new-delivery',
  editDelivery: '/edit-delivery',
  viewDelivery: '/view-delivery',
  deliveryTable: '/delivery-table',
  deliveryMap: '/delivery-map',
  requested: '/requested',
  completed: '/completed',
  cancelled: '/cancelled',
  createDeal: '/create-deal',
  editDeal: '/edit-deal',
  created: '/created',
  pilot: '/pilot',
  drivers: '/drivers',
  driver: '/driver',
  invoices: '/invoices',
  driversTable: '/drivers-table',
  alert: '/alert',
  ongoing: '/ongoing',
  confirmed: '/confirmed',
  set: '/set',
  waitingForDriver: '/waiting-for-driver',
  messages: '/messages',
  acceptedRequests: '/accepted-requests',
  driverHistory: '/history',
  waitingRequests: '/waiting-requests',
  disabledDrivers: '/disabled-drivers',
  refusedRequests: '/refused-requests',
  manageAdmins: '/manage-admins',
  calendarView: '/calendar-view',
  organization: '/organization',
  organizationAddMember: '/add-member',
  organizationListMembers: '/list-members',
  organizationListManagers: '/list-managers',
  get clientOrganization() {
    return this.client + this.organization
  },
  get clientOrganizationAddMember() {
    return this.client + this.organization + this.organizationAddMember
  },
  get clientProfile() {
    return this.client + this.profile;
  },
  get clientProfileAccount() {
    return this.client + this.profile + this.account;
  },
  get adminProfile() {
    return this.admin + this.profile;
  },
  get adminProfileAccount() {
    return this.admin + this.profile + this.account;
  },
  get clientOrganizationListMember() {
    return this.client + this.organization + this.organizationListMembers
  },
  get clientOrganizationListManager() {
    return this.client + this.organization + this.organizationListManagers
  },
  get clientCalendarView() {
    return this.clientPilot + this.calendarView
  },
  get clientNewDelivery() {
    return this.client + this.newDelivery
  },
  get clientEditDelivery() {
    return this.client + this.editDelivery
  },
  get clientViewDelivery() {
    return this.clientPilot + this.viewDelivery
  },
  get clientPilot() {
    return this.client + this.pilot
  },
  get clientHelp() {
    return this.client + this.help
  },
  get clientPilotDeliveryOngoingMap() {
    return this.clientPilot + this.ongoing + this.deliveryMap
  },
  get clientPilotDeliveryTable() {
    return this.clientPilot + this.deliveryTable
  },
  get clientDashboard() {
    return this.client + this.dashboard
  },
  get adminDashboard() {
    return this.admin + this.dashboard
  },
  get adminPilot() {
    return this.admin + this.pilot
  },
  get adminNewDelivery() {
    return this.admin + this.newDelivery
  },

  get adminInvoices() {
    return this.admin + this.invoices
  },
  get adminInvoicesDriver() {
    return this.adminInvoices + this.driver
  },
  get adminInvoicesClient() {
    return this.adminInvoices + this.client
  },
  get adminDrivers() {
    return this.admin + this.drivers
  },
  get adminOrganization() {
    return this.admin + this.organizations
  },
  get adminNewOrganization() {
    return this.adminOrganization + this.newOrganization
  },
  get adminManage() {
    return this.admin + this.manageAdmins
  },
  get adminManageAdmins() {
    return this.admin + this.manageAdmins + this.admins
  },
  get adminManageMaintainer() {
    return this.admin + this.manageAdmins + this.maintainers
  },
  get adminManageSupport() {
    return this.admin + this.manageAdmins + this.support
  },
  get adminPilotCreateDeal() {
    return this.adminPilot + this.createDeal
  },
  get adminPilotEditDeal() {
    return this.adminPilot + this.editDeal
  },
  get adminPilotCreateDealRequested() {
    return this.adminPilotCreateDeal + this.requested
  },
  get adminPilotCreateDealConfirmed() {
    return this.adminPilotCreateDeal + this.confirmed
  },
  get adminPilotAlert() {
    return this.adminPilot + this.alert
  },
  get adminPilotDeliveryTable() {
    return this.adminPilot + this.deliveryTable
  },
  get adminPilotDeliveryOngoingMap() {
    return this.adminPilot + this.ongoing + this.deliveryMap
  },
  get adminPilotDeliveryTableWaitingForDriver() {
    return this.adminPilotDeliveryTable + this.waitingForDriver
  },
  get adminPilotDeliveryTableRequested() {
    return this.adminPilotDeliveryTable + this.requested
  },
  get clientPilotDeliveryTableRequested() {
    return this.clientPilotDeliveryTable + this.requested
  },
  get adminPilotDeliveryTableOngoing() {
    return this.adminPilotDeliveryTable + this.ongoing
  },
  get clientPilotDeliveryTableOngoing() {
    return this.clientPilotDeliveryTable + this.ongoing
  },
  get adminPilotDeliveryTableConfirmed() {
    return this.adminPilotDeliveryTable + this.confirmed
  },
  get clientPilotDeliveryTableConfirmed() {
    return this.clientPilotDeliveryTable + this.confirmed
  },
  get adminPilotDeliveryTableSet() {
    return this.adminPilotDeliveryTable + this.set
  },
  get adminPilotDeliveryAlert() {
    return this.adminPilotDeliveryTable + this.alert
  },
  get clientPilotDeliveryTableWaitingForDriver() {
    return this.clientPilotDeliveryTable + this.waitingForDriver
  },
  get clientPilotDeliveryTableDelivered() {
    return this.clientPilotDeliveryTable + this.completed
  },
  get adminPilotDeliveryTableDelivered() {
    return this.adminPilotDeliveryTable + this.completed
  },
  get adminPilotDeliveryTableCancelled() {
    return this.adminPilotDeliveryTable + this.cancelled
  },
  get clientPilotDeliveryTableCancelled() {
    return this.clientPilotDeliveryTable + this.cancelled
  },
  get adminPilotOffers() {
    return this.adminPilotDeliveryTableWaitingForDriver + '/:id/offers'
  },
  get adminDriversTable() {
    return this.adminDrivers + this.driversTable
  },
  get adminDriversTableacceptedRequests() {
    return this.adminDriversTable + this.acceptedRequests
  },
  get adminDriversTableacceptedHistory() {
    return this.adminDriversTable + this.acceptedRequests + this.driverHistory
  },
  get adminDriversTabledisabledDrivers() {
    return this.adminDriversTable + this.disabledDrivers
  },
  get adminDriversTabledisabledDriversHistory() {
    return this.adminDriversTable + this.disabledDrivers + this.driverHistory
  },
  get adminDriversTablerefusedRequests() {
    return this.adminDriversTable + this.refusedRequests
  },
  get adminDriversTableWaitingRequests() {
    return this.adminDriversTable + this.waitingRequests
  },
  get adminOrganizationsTable() {
    return this.adminOrganization + this.organizationsTable
  },
  get deliveryCreationConfirmed() {
    return `${this.clientNewDelivery}/:type${this.created}/:uuid/:publicId/:stamp`
  },
  get pilotageDeliveredItem() {
    return `${this.clientPilot}/:type/:status/:contract`
  },
  get adminPilotageDeliveredItem() {
    return `${this.adminPilot}/details/:type/:status/:contract`
  },
  get adminDeliveryForm() {
    return `${this.adminPilot}/:type/:status/:contract`
  },
  get adminEditDeal() {
    return `${this.adminPilot}/edit-deal/:type/:status/:contract`
  },
}
