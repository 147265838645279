import { gql } from '@apollo/client'
import { graphqlQuery } from '@api/clients/apollo'
import { DeliveryLog } from '@types_def/models/delivery.types'

const GET_DELIVERY_LOGS = gql`
  query FindDeliveryLogs($deliveryId: Int!, $page: Int, $pageSize: Int) {
    findDeliveryLogs(deliveryId: $deliveryId, page: $page, pageSize: $pageSize) {
      totalCount
      logs {
        id
        log
        createdAt
        deliveryId
      }
    }
  }
`
type DeliveryLogResponse = {
  totalCount: number
  logs: DeliveryLog[]
}
const findDeliveryLogs = async (deliveryId: number, page: number, pageSize: number) => {
  try {
    const res = await graphqlQuery<
      { findDeliveryLogs: DeliveryLogResponse },
      { deliveryId: number; page: number; pageSize: number }
    >(GET_DELIVERY_LOGS, {
      deliveryId,
      page,
      pageSize,
    })
    return res.data.findDeliveryLogs
  } catch (e) {}
}

export { findDeliveryLogs }
