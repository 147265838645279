import { Divider, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AlbInput from '@components/common/AlbInput'
type TMissionDate = {
  settings?: {
    one_date: boolean
    no_docs: boolean
  }
}

const MissionDate = ({ settings }: TMissionDate) => {
  const { t } = useTranslation()
  const dateFields = [
    {
      name: 'carDeliveryDeadline',
      label: t('delivery.form.car-delivery-deadline'),
      greaterThan: 'carPickUpDate',
    },
  ]

  const { control, setValue } = useFormContext()

  if (!settings?.one_date)
    dateFields.unshift(
      {
        name: 'carAvailabilityDate',
        label: t('delivery.form.car-availability-date'),
        greaterThan: null,
      },
      {
        name: 'carPickUpDate',
        label: t('delivery.form.car-pick-up-date'),
        greaterThan: 'carAvailabilityDate',
      },
    )

  const carDeliveryDeadline = useWatch({
    name: 'carDeliveryDeadline',
    control,
  })

  useEffect(() => {
    if (settings?.one_date) {
      setValue('carPickUpDate', carDeliveryDeadline)
      setValue('carAvailabilityDate', carDeliveryDeadline)
    }
  }, [carDeliveryDeadline])
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform={'capitalize'}>
          {t('delivery.form.mission-date')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>
      {dateFields.map((dateField) => (
        <Grid item xs={12} lg={12 / dateFields.length} key={dateField.name}>
          <AlbInput
            name={dateField.name}
            label={dateField.label}
            type='DateTimeField'
            disablePast={true}
            greaterThan={dateField.greaterThan}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default MissionDate
