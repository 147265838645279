const NotebookIcon = () => (
  <svg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.83333 0H9.16667C10.1793 0 11 0.895313 11 2V14C11 15.1047 10.1793 16 9.16667 16H1.83333C0.820703 16 0 15.1047 0 14V2C0 0.895313 0.822135 0 1.83333 0ZM5.04167 11H8.70833C8.96156 11 9.16667 10.775 9.16667 10.5C9.16667 10.225 8.96156 10 8.70833 10H5.04167C4.78849 10 4.58333 10.2239 4.58333 10.5C4.58333 10.7761 4.78958 11 5.04167 11ZM2.29167 8H8.70833C8.96156 8 9.16667 7.775 9.16667 7.5C9.16667 7.225 8.96156 7 8.70833 7H2.29167C2.03958 7 1.83333 7.225 1.83333 7.5C1.83333 7.775 2.03958 8 2.29167 8ZM2.29167 5H8.70833C8.96156 5 9.16667 4.775 9.16667 4.5C9.16667 4.225 8.96156 4 8.70833 4H2.29167C2.03958 4 1.83333 4.225 1.83333 4.5C1.83333 4.775 2.03958 5 2.29167 5Z'
      fill='#fff'
    />
  </svg>
)

export default NotebookIcon
