import React from 'react'
import { Container, Paper } from '@mui/material'
import { useFindMe } from './Querys'
import { useUserContext } from '@contexts/UserContext/index.context'
import { updateProfile } from '../organization/Querys'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import { useQueryClient } from '@tanstack/react-query'
import MemberForm from '../organization/Member/Form'

type Props = {}

const Profile = (props: Props) => {
  const { userState } = useUserContext()
  const { data, isLoading, isError } = useFindMe(userState.sub)
  const queryClient = useQueryClient()
  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error...</div>
  const invalidateFN = () => queryClient.invalidateQueries({ queryKey: ['findMe', userState.sub] })

  const onSubmit = async (data) => {
    try {
      await updateProfile(data)
      invalidateFN()
      toast.success(t('network.organization.member.updated'), {
        position: 'bottom-right',
      })
    } catch (error) {
      if (error?.response?.status === 403)
        toast.error(t('network.errors.403'), {
          position: 'bottom-right',
        })
      else
        toast.error(error?.response?.data?.message ?? t('network.errors.unknown'), {
          position: 'bottom-right',
        })
    }
  }
  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'grid',
        placeContent: 'center',
        paddingTop: '100px',
      }}
    >
      <Paper
        component={'div'}
        elevation={4}
        sx={{
          backgroundColor: '#E7E6F7',
          borderRadius: 1,
          padding: '20px',
          height: '100%',
        }}
      >
        <MemberForm
          disabledFields={['assigneeEmail']}
          title='user-info'
          unUsedFields={['assigneeRank']}
          values={data}
          onSubmit={onSubmit}
          label='save'
        />
      </Paper>
    </Container>
  )
}

export default Profile
