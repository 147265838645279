import { Link, useLocation } from 'react-router-dom'

import { useUserContext } from '@contexts/UserContext/index.context'

import ListIcon from '../icons/list.icon'
import LocationIcon from '../icons/location.icon'
import styles from './index.module.scss'

const MapsAndTableToggle = () => {
  const location = useLocation()
  const { userState } = useUserContext()

  const table = location.pathname.includes('/delivery-table/ongoing')
  const map = location.pathname.includes('/delivery-map')
  const role = userState?.['custom:role']?.toString().toLowerCase()

  return (
    <div className={`${styles.mapsAndTableToggle} ${table ? styles.table : ''}`}>
      <Link to={`/${role}/pilot/ongoing/delivery-map`} className={map ? styles.active : ''}>
        <LocationIcon />
      </Link>
    </div>
  )
}

export default MapsAndTableToggle
