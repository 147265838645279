import { Container } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Delivery, DeliveryStatus, Doc } from '@types_def/models/delivery.types'
import CustomModal from '@components/common/CustomModal'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { updateValidationDocs } from '@components/Delivery/DeliveryDetails/Querys'
import FileUpload from '@components/fileUpload'
import { deleteDeliveryDoc } from '@components/Delivery/DeliveryFormV2/Querys'
import { UserRank } from '@types_def/models/user.type'

type Props = {
  data: Delivery
  isAdmin?: boolean
  rank?: UserRank
}

const UpdateValidationDocs = ({ data, isAdmin = false, rank = undefined }: Props) => {
  if (data?.status !== DeliveryStatus.COMPLETED) return null
  if (!isAdmin || ![UserRank.SUPER_ADMIN, UserRank.MAINTAINER].includes(rank)) return null
  const queryClient = useQueryClient()
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        id: z.number(),
        validationDocs: z.array(
          z.object({
            name: z.string(),
            key: z.optional(z.string()),
            url: z.string(),
            deleted: z.boolean().nullish(),
          }),
        ),
      }),
    ),
    values: data,
  })
  const handleUpdateValidationDocs = (data) => {
    const { id, validationDocs } = data
    const docs = validationDocs.filter((doc) => !doc.deleted)
    const deletedDocs = validationDocs.filter((doc) => doc.deleted)
    deletedDocs.map((doc) => {
      deleteDeliveryDoc(doc.key)
    })
    updateValidationDocs({ id, validationDocs: docs }, queryClient)
  }

  const handleCancelUpdateValidationDocs = (data) => {
    const { validationDocs } = data
    const deletedDocs = validationDocs.filter(
      (newDoc: Doc) => !data.validationDocs.some((oldDoc) => newDoc.key === oldDoc.key),
    )
    deletedDocs.map((doc) => {
      deleteDeliveryDoc(doc.key)
    })
    methods.reset({ ...data })
  }
  return (
    <Container maxWidth='md' sx={{ padding: 2 }}>
      <CustomModal
        button='Modifier Validation Documents'
        open={false}
        fullWidth
        confirmText='Modifier'
        confirmAction={() => methods.handleSubmit((data) => handleUpdateValidationDocs(data))()}
        cancelAction={() =>
          methods.handleSubmit((data) => handleCancelUpdateValidationDocs(data))()
        }
      >
        <FormProvider {...methods}>
          <FileUpload name='validationDocs' forceAllowEdit={true} id={data.id} remove={false} />
        </FormProvider>
      </CustomModal>
    </Container>
  )
}

export { UpdateValidationDocs }
