import { type FunctionComponent } from 'react'
import { Delivery } from '@types_def/models/delivery.types'

export const PriceCell: FunctionComponent = ({ children }) => {
  const price = (children as Delivery)?.accepted_offer?.demandedPrice

  if (!price) {
    return <i>Error</i>
  }

  return <div>{price} €</div>
}
