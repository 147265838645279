import { Button, Chip, Stack, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Countries from '@utils/constants/county'
import { enumToStr } from '@utils/strEnum'
import { extraOptions } from '@utils/constants/deliveryOptions'

type TFilterSummary = {
  name: string
  tooltip: string
  paramKey: string[]
  handleRemoveFilter: (paramKeys: string[]) => void
  options?: string[]
}

const FilterSummary = ({
  tooltip,
  name,
  options,
  handleRemoveFilter,
  paramKey,
}: TFilterSummary) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        size='medium'
        variant='outlined'
        endIcon={<CloseIcon fontSize='small' />}
        onClick={() => handleRemoveFilter(paramKey)}
      >
        {name} &nbsp;{' '}
        {options ? (
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
              flexWrap: 'wrap',
            }}
          >
            {options.map((option) => (
              <Chip key={option} color='secondary' size='small' label={option} />
            ))}
          </Stack>
        ) : (
          ''
        )}
      </Button>
    </Tooltip>
  )
}

type Props = {}

const FiltersSummary = (props: Props) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const onRemoveFilter = (paramKeys: string[]) => {
    paramKeys.map((paramKey) => searchParams.delete(paramKey))
    setSearchParams(searchParams)
  }
  const filters = []

  const vin = searchParams.get('vin')
  const deliveryId = searchParams.get('deliveryId')
  const deliveryCountries = searchParams.get('deliveryCountries')?.split(',')
  const deliveryType = searchParams.get('deliveryType')?.split(',')
  const deliveryServices = searchParams.get('deliveryServices')?.split(',')
  const carDeliveryDeadlineFrom = searchParams.get('carDeliveryDeadlineFrom')
  const carDeliveryDeadlineTo = searchParams.get('carDeliveryDeadlineTo')
  const organizationSlug = searchParams.get('organizationSlug')?.split(',')

  if (vin) {
    filters.push({
      name: `VIN`,
      tooltip: `VIN: ${vin}`,
      options: [vin],
      paramKey: ['vin'],
    })
  }

  if (deliveryId) {
    filters.push({
      name: `${t('common.shared.delivery')} ID`,
      tooltip: `${t('common.shared.delivery')} N° ${deliveryId}`,
      options: [deliveryId],
      paramKey: ['deliveryId'],
    })
  }

  if (deliveryCountries) {
    const countryNames = Countries.filter((country) =>
      deliveryCountries.includes(country.code),
    ).map((country) => country.name)
    filters.push({
      name: t('common.shared.market'),
      tooltip: countryNames.join(', '),
      options: countryNames,
      paramKey: ['deliveryCountries'],
    })
  }

  if (deliveryType) {
    const deliveryTypeNames = deliveryType.map((type) =>
      t(`layouts.sub-headers.deliveries.${enumToStr(type)}`),
    )
    filters.push({
      name: 'Delivery Type',
      tooltip: deliveryTypeNames?.join(', '),
      options: deliveryTypeNames,
      paramKey: ['deliveryType'],
    })
  }

  if (deliveryServices) {
    const deliveryServicesNames = extraOptions
      .filter((option) => deliveryServices.includes(option.id))
      .map((option) => t(`delivery.services.${option.name}`))
    filters.push({
      name: 'Delivery Services',
      tooltip: deliveryServicesNames?.join(', '),
      options: deliveryServicesNames,
      paramKey: ['deliveryServices'],
    })
  }

  if (carDeliveryDeadlineFrom || carDeliveryDeadlineTo) {
    filters.push({
      name: t('common.table.headers.Date de livraison'),
      tooltip: `${t('common.table.headers.Date de livraison')}: ${carDeliveryDeadlineFrom ? t('common.shared.from') + ' ' + new Date(parseInt(carDeliveryDeadlineFrom)).toLocaleDateString() : ''} ${carDeliveryDeadlineTo ? t('common.shared.to') + ' ' + new Date(parseInt(carDeliveryDeadlineTo)).toLocaleDateString() : ''}`,
      paramKey: ['carDeliveryDeadlineFrom', 'carDeliveryDeadlineTo'],
    })
  }

  if (organizationSlug) {
    filters.push({
      name: 'Organization',
      tooltip: organizationSlug.join(', '),
      options: organizationSlug,
      paramKey: ['organizationSlug'],
    })
  }

  return (
    <>
      {filters.map((filter) => (
        <FilterSummary
          key={filter?.name}
          name={filter?.name}
          tooltip={filter?.tooltip}
          options={filter?.options}
          handleRemoveFilter={onRemoveFilter}
          paramKey={filter.paramKey}
        />
      ))}
    </>
  )
}

export default FiltersSummary
