import { gql } from '@apollo/client'
import { graphqlMutation } from '@api/clients/apollo'

type ApiSendResponse = {
  rejectOffer: RejectOfferInput
}
export type RejectOfferInput = {
  id: number
}

export async function rejectOfferGraphql(RejectOfferInput: RejectOfferInput) {
  try {
    const res = await graphqlMutation<ApiSendResponse, { RejectOfferInput: RejectOfferInput }>(
      gql`
        mutation rejectOffer($RejectOfferInput: RejectOfferInput!) {
          rejectOffer(RejectOfferInput: $RejectOfferInput) {
            id
            driverId
            deliveryId
            status
          }
        }
      `,
      { RejectOfferInput },
    )
    return res.data.rejectOffer
  } catch (error) {
    throw error
  }
}
