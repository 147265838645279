const LocationIcon = () => (
  <svg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.61719 11.7304C6.97656 10.2218 10 6.56543 10 4.51168C10 2.01991 7.76146 0 5 0C2.23958 0 0 2.01991 0 4.51168C0 6.56543 3.04687 10.2218 4.38281 11.7304C4.70312 12.0899 5.29687 12.0899 5.61719 11.7304ZM5 6.01557C4.08073 6.01557 3.33333 5.34117 3.33333 4.51168C3.33333 3.68219 4.08073 3.00778 5 3.00778C5.91927 3.00778 6.66667 3.68219 6.66667 4.51168C6.66667 5.34117 5.91927 6.01557 5 6.01557Z'
      fill='#fff'
    />
  </svg>
)

export default LocationIcon
