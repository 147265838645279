import debounceInput from 'lodash.debounce'
import { type DateTime } from 'luxon'
import type { ConditionalKeys } from 'type-fest'

import { type PrismaFilter, type WhereFilter } from '@types_def/common/filter.types'

import DatePickerControl from '../../inputs/DatePicker'

type Props<T> = {
  filter: WhereFilter<T>
  dateField: ConditionalKeys<T, DateTime | undefined>
  prismaFilter: keyof PrismaFilter<T, keyof T>
  debounce?: boolean
  debounceDuration?: number
  handleDateChange: (
    date: Date | DateTime | undefined,
    prop: keyof PrismaFilter<T, keyof T>,
  ) => void
}

const DateFilter = <T extends Record<string, unknown>>({
  filter,
  dateField,
  prismaFilter,
  debounce = true,
  debounceDuration = 500,
  handleDateChange,
}: Props<T>) => {
  const date = filter[dateField] as PrismaFilter<T, keyof T>

  const changeDate = (date: DateTime | undefined) => {
    handleDateChange(date, prismaFilter)
  }

  const debouncedChangeDate = debounceInput(changeDate, debounceDuration)

  return (
    <>
      {
        <DatePickerControl
          onChange={debounce ? debouncedChangeDate : changeDate}
          validation={false}
          value={
            Boolean(date) && Boolean(date[prismaFilter]) ? (date[prismaFilter] as DateTime) : null
          }
        />
      }
    </>
  )
}

export default DateFilter
