import styled from 'styled-components'

import { device } from '@styles/mediaQuery'

export const ConfirmationButtonContainer = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  button {
    margin: 1em;
  }
  @media ${device.mobileL} {
    flex-direction: column;
  }
`
