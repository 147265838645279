const RoadIcon = () => (
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.88889 2.28571C8.88889 2.91786 9.38542 3.42857 10 3.42857C10.6146 3.42857 11.1111 2.91786 11.1111 2.28571V0H13.7083C14.6493 0 15.4861 0.61 15.8021 1.5225L19.8924 13.4C19.9618 13.6071 20 13.8286 20 14.05C20 15.125 19.1493 16 18.1042 16H11.1111V13.7143C11.1111 13.0821 10.6146 12.5714 10 12.5714C9.38542 12.5714 8.88889 13.0821 8.88889 13.7143V16H1.89618C0.848958 16 0 15.125 0 14.05C0 13.8286 0.0368056 13.6071 0.108785 13.4L4.19792 1.5225C4.48264 0.61 5.35069 0 6.29167 0H8.85764L8.88889 2.28571ZM11.1111 6.85714C11.1111 6.225 10.6146 5.71429 10 5.71429C9.38542 5.71429 8.88889 6.225 8.88889 6.85714V9.14286C8.88889 9.775 9.38542 10.2857 10 10.2857C10.6146 10.2857 11.1111 9.775 11.1111 9.14286V6.85714Z'
      fill='#fff'
    />
  </svg>
)

export default RoadIcon
