import { gql } from '@apollo/client'
import { graphqlMutation } from '@api/clients/apollo'

type ApiSendResponse = {
  deleteInput: DeleteInput
}
export type DeleteInput = {
  id: number
}

export async function deleteOfferGraphql(DeleteInput: DeleteInput) {
  try {
    const res = await graphqlMutation<ApiSendResponse, { DeleteInput: DeleteInput }>(
      gql`
        mutation deleteOffer($DeleteInput: DeleteInput!) {
          deleteOffer(DeleteInput: $DeleteInput) {
            id
            driverId
            deliveryId
            status
          }
        }
      `,
      { DeleteInput },
    )
    return res.data.deleteInput
  } catch (error) {
    throw error
  }
}
