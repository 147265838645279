import { ReactNode, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Box, Breakpoint, DialogTitle, SxProps, Theme } from '@mui/material'

type Props = {
  noAction?: boolean
  title?: string
  open?: boolean
  btnColor?: 'success' | 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'warning'
  disabled?: boolean
  button?: ReactNode
  children?: ReactNode
  containerStyles?: any
  buttonStyles?: SxProps<Theme>
  size?: Breakpoint
  confirmAction?: any
  cancelAction?: any
  formId?: string
  confirmText?: string
  cancelText?: string
  fullWidth?: boolean
  color?: 'success' | 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'warning'
  variant?: 'text' | 'outlined' | 'contained'
  canOpen?: boolean
  display?: boolean
  type?: 'button' | 'submit' | 'reset'
  btnSize?: "small" | "medium" | "large"
}

const CustomModal = ({
  type = 'submit',
  title,
  disabled,
  children,
  formId,
  confirmAction,
  cancelAction,
  open: InitialOpen = false,
  button,
  size = 'md',
  buttonStyles,
  confirmText = 'Créer le compte',
  cancelText = 'Annuler',
  fullWidth,
  color = 'primary',
  variant = 'contained',
  canOpen = true,
  display = true,
  btnSize = 'medium',
  noAction = false,
}: Props) => {
  const [open, setOpen] = useState(InitialOpen)
  const handleClose = () => {
    if (cancelAction) cancelAction()
    setOpen(false)
  }

  return (
    <>
      <Button
        sx={{ display: display ? 'flex' : 'none', ...buttonStyles }}
        size={btnSize}
        fullWidth={fullWidth}
        disabled={disabled}
        onClick={() => {
          if (canOpen) {
            setOpen((v) => !v)
          } else {
            confirmAction && confirmAction()
          }
        }}
        color={color}
        variant={variant}
      >
        {button}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={size}
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {noAction ? null : <DialogActions>
          <Box sx={{ gap: 2, padding: 1, width: '100%', display: 'flex' }}>
            <Button
              form={formId}
              type='reset'
              color='warning'
              variant='outlined'
              fullWidth
              onClick={handleClose}
            >
              {cancelText}
            </Button>
            <Button
              form={formId}
              type={type}
              onClick={() => {
                confirmAction && confirmAction(setOpen)
              }}
              variant='contained'
              disableElevation
              color='success'
              fullWidth
            >
              {confirmText}
            </Button>
          </Box>
        </DialogActions>}
      </Dialog>
    </>
  )
}

export default CustomModal
