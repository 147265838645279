import axios from 'axios'
import { deletePushNotificationToken } from '@api/Rest/pushNotification'

import { sendRawRequest } from '@api/clients/axios'
import { type LoginResponse } from '@types_def/common/auth.types'
import { type AxiosHttpError } from '@types_def/common/error.types'
import { removeAuth, storeAuthResult } from '@utils/tokenManagement.util'

export type LoginInput = {
  username: string
  password: string
}

export type ResendCodeInput = {
  username: string
}
export const login = async (attempt: LoginInput) => {
  try {
    const { data } = await sendRawRequest<LoginInput, LoginResponse>(
      'post',
      '/auth/login-gestion',
      attempt,
    )

    switch (data.ChallengeName) {
      case undefined:
        storeAuthResult(data)
        return {
          success: true,
          action: 'LOGIN',
          result: data.AuthenticationResult,
        }
      case 'NEW_PASSWORD_REQUIRED':
        return {
          success: true,
          action: 'NEW_PASSWORD_REQUIRED',
          session: data.Session,
        }
    }
  } catch (err) {
    const axiosError = err as AxiosHttpError
    if (axiosError.response?.data) {
      if (axiosError.response.data.message === 'User is not confirmed.') {
        /* try {
          await axios.post<ResendCodeInput, never>('/auth/resend-confirmation-code', {
            username: attempt.username,
          })
        } catch (error) {
          // Should not happen
          console.error('Cannot resend confirmation code')
        } */

        return { success: true, action: 'need-confirm' }
      }
    }
  }

  return {
    success: false,
    action: 'Error',
  }
}

type ConfirmInput = {
  username: string
  code: string
  password: string
}
export const confirmAccount = async (confirmInput: ConfirmInput) => {
  try {
    const { data } = await sendRawRequest<ConfirmInput, LoginResponse>(
      'post',
      '/auth/confirm-signup',
      confirmInput,
    )

    switch (data.ChallengeName) {
      case undefined:
        storeAuthResult(data)
        return {
          success: true,
          action: 'LOGIN',
          result: data.AuthenticationResult,
        }
      case 'NEW_PASSWORD_REQUIRED':
        return {
          success: true,
          action: 'NEW_PASSWORD_REQUIRED',
          session: data.Session,
        }
    }
  } catch (err) {
    const axiosError = err as AxiosHttpError
    if (axiosError.response?.data) {
      if (axiosError.response.data.message === 'User is not confirmed.') {
        try {
          await axios.post<ResendCodeInput, never>('/auth/resend-confirmation-code', {
            username: confirmInput.username,
          })
        } catch (error) {
          // Should not happen
          console.error('Cannot resend confirmation code')
        }

        return { success: true, action: 'need-confirm' }
      }
    }
  }

  return {
    success: false,
    action: 'Error',
  }
}

export const logout = () => {
  const pushToken = JSON.parse(localStorage.getItem('pushToken'))
  deletePushNotificationToken({ id: pushToken?.tokenId })
  localStorage.removeItem('pushToken')
  removeAuth()
}
