import { type FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { changePassword, type ChangePasswordInput } from '@api/auth/changePassword'
import sendForgotPasswordCode from '@api/auth/sendForgotPasswordCode'
import MainForm from '@components/common/MainForm'
import { checkPasswordValidaty } from '@utils/functions/inputValidators'

import { NewPasswordFormSection } from '../SetNewPassword/NewPasswordFormSection'
import { AuthenticateCode } from './AuthenticateCode'
import { AuthenticateEmail } from './AuthenticateEmail'
import styles from './index.module.scss'
type Props = {
  closeModal: () => void
}
export const ForgotPassword: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { closeModal } = props
  const changePasswordModal = {
    password: '',
    confirmPassword: '',
    email: '',
    code: '',
  }
  const [newPassword, setNewPassword] = useState(changePasswordModal)
  const [step, setStep] = useState(1)
  const [feedback, setFeedback] = useState('')
  const [isValid, setIsValid] = useState(false)

  const onCodeNextClick = async () => {
    const changePasswordInput: ChangePasswordInput = {
      username: newPassword.email,
      code: newPassword.code,
      password: newPassword.password,
    }

    const res = await changePassword(changePasswordInput)

    if (res && res.success) {
      setFeedback('Le mot de passe a été changé avec succès! redirection...')

      setNewPassword(changePasswordModal)
      setTimeout(() => {
        closeModal()
        setFeedback('')
        setStep(1)
      }, 2000)
    } else {
      setFeedback(res?.message || "Une erreur est survenue. Réessayez plus tard s'il vous plaît.")
    }
  }

  const handleInputChange = (value: string | boolean | number, identifier?: string) => {
    setNewPassword((passwordState) => ({
      ...passwordState,
      [identifier!]: value,
    }))
  }

  const sendForgotPasswordModal = async () => {
    if (newPassword.email) {
      const res = await sendForgotPasswordCode({ username: newPassword.email })
      if (res && res.success) {
        setStep(2)
      }
    }
  }

  const onEmailNextClick = () => {
    sendForgotPasswordModal()
  }

  useEffect(() => {
    setIsValid(checkPasswordValidaty(newPassword.password, newPassword.confirmPassword))
  }, [newPassword])

  const onSubmitClick = () => {
    onCodeNextClick()
  }

  return (
    <>
      {step == 1 && (
        <MainForm
          modalForm={true}
          isValid={isValid}
          onSubmit={onEmailNextClick}
          showButton={false}
          title={t('common.form.input.set-new-password')}
        >
          <AuthenticateEmail
            value={newPassword.email}
            identifier='email'
            handleInputChange={handleInputChange}
            onNextClick={true}
            remark={t('login.code-will-sent-to-email')}
          />
        </MainForm>
      )}
      {step == 2 && (
        <MainForm
          modalForm={true}
          isValid={isValid}
          onSubmit={onSubmitClick}
          showButton={step == 2}
          title={t('common.form.input.set-new-password')}
        >
          <p className={styles.forget_password_error}>{feedback}</p>
          <AuthenticateCode
            value={newPassword.code}
            identifier='code'
            handleInputChange={handleInputChange}
          />
          <NewPasswordFormSection
            email={newPassword.email}
            handleInputChange={handleInputChange}
            password={newPassword.password}
            confirmPassword={newPassword.confirmPassword}
          />
        </MainForm>
      )}
    </>
  )
}
