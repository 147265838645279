const EyeIcon = () => (
  <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.28157 3.43929C7.35449 3.43125 7.42741 3.42857 7.50033 3.42857C8.8806 3.42857 10.0004 4.55625 10.0004 6C10.0004 7.41964 8.8806 8.57143 7.50033 8.57143C6.09662 8.57143 5.00022 7.41964 5.00022 6C5.00022 5.925 5.00282 5.85 5.01064 5.775C5.25283 5.89554 5.5341 6 5.83359 6C6.7529 6 7.50033 5.23125 7.50033 4.28571C7.50033 3.97768 7.39876 3.68839 7.28157 3.43929ZM12.5162 2.15893C13.735 3.32143 14.5501 4.69018 14.9355 5.67054C15.0215 5.88214 15.0215 6.11786 14.9355 6.32946C14.5501 7.28571 13.735 8.65446 12.5162 9.84107C11.2896 11.0143 9.60459 12 7.50033 12C5.39607 12 3.7111 11.0143 2.48501 9.84107C1.2662 8.65446 0.451585 7.28571 0.0640946 6.32946C-0.0213649 6.11786 -0.0213649 5.88214 0.0640946 5.67054C0.451585 4.69018 1.2662 3.32143 2.48501 2.15893C3.7111 0.986786 5.39607 0 7.50033 0C9.60459 0 11.2896 0.986786 12.5162 2.15893ZM7.50033 2.14286C5.42993 2.14286 3.75017 3.87054 3.75017 6C3.75017 8.12946 5.42993 9.85714 7.50033 9.85714C9.57073 9.85714 11.2505 8.12946 11.2505 6C11.2505 3.87054 9.57073 2.14286 7.50033 2.14286Z'
      fill='#000'
    />
  </svg>
)

export default EyeIcon
