import { type KeyValuePair } from '@types_def/common/filter.types'
import { type SelectedOption } from '@types_def/models/delivery.types'

export const extraOptions: SelectedOption[] = [
  { id: '000', name: 'W Garage', photo: '', price: 50 },
  {
    id: '001',
    name: 'Carburant',
    photo: '',
    price: 20,
  },
  {
    id: '002',
    name: 'Lavage',
    photo: '',
    price: 10,
  },
]
export const garageOptions: SelectedOption[] = [
  // { name: 'Pneumatique', photo: '' },
  // { name: 'Électronique du moteur', photo: '' },
  // { name: 'Allumage et électricité moteur', photo: '' },
  // { name: 'Disques ou tambours', photo: '' },
  // { name: 'Plaquettes de frein ou garnissage', photo: '' },
  // { name: 'Alimentation (pompe d’injection, injection)', photo: '' },
  // { name: 'Chaîne ou courroie de distribution', photo: '' },
  // { name: 'Refroidissement du moteur', photo: '', price: 10 },
  // { name: 'Éclairages (phares, feux, ampoules)', photo: '' },
  // { name: 'Vitres et rétroviseurs', photo: '', price: 60 },
  // { name: 'Chauffage et la climatisation', photo: '', price: 50 },
  // { name: 'Défaillance carosserie', photo: '' },
  // { name: 'Autres', photo: '' },
]
const selectListExtraOptions: KeyValuePair[] = extraOptions.map((c) => ({
  value: c.name,
  key: c.name,
}))
const selectListGarageOptions: KeyValuePair[] = garageOptions.map((c) => ({
  value: c.name,
  key: c.name,
}))
export const selectListAllOptions: KeyValuePair[] = [
  ...selectListExtraOptions,
  ...selectListGarageOptions,
]
