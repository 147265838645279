import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import getUser from '@api/graphql/general/getUser'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'
import { type AdminRank, adminRanks } from '@utils/constants/adminRanks'
import { dashboardSubheaderDate } from '@utils/functions/customDates'
import { isAdmin } from '@utils/functions/User/checkUser'
import * as Sentry from '@sentry/react'

import styles from '../index.module.scss'

const DashboardSubHeader = () => {
  const { userState } = useUserContext()
  const [username, setUsername] = useState('')
  const [adminRank, setAdminRank] = useState<AdminRank>()
  const [date, setDate] = useState(dashboardSubheaderDate(DateTime.now()))
  const role = userState?.['custom:role']?.toLowerCase()
  const get = () => {
    if (userState?.sub && userState?.['custom:role']) {
      getUser(userState?.sub, userState?.['custom:role'])
        .then((res) => {
          if (res) {
            setUsername(isAdmin(res) ? res.firstName : res.assigneeFirstName)
            if (isAdmin(res)) {
              const rank = adminRanks.find((c) => c.rank === res.rank)
              setAdminRank(rank)
            }
          } else {
            console.error('no user found')
          }
        })
        .catch((err) => {
          Sentry.captureException(err, {
            extra: {
              component: 'DashboardSubHeader',
              userState: userState,
              err: err,
            },
          })
        })
    } else {
      console.error('no user exists')
    }
  }

  useEffect(() => {
    get()
    const timer = setInterval(() => {
      setDate(dashboardSubheaderDate(DateTime.now()))
    }, 10 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className={`${styles['sub-header']} ${styles.dashboard}`}>
      <div className={'title'}>
        {/* {role === UserRole.CLIENT.toLowerCase() && <img src='/images/arval-logo.webp' />} */}
        <span>{username}</span>
        {adminRank && (
          <span
            style={{
              marginLeft: '.6em',
              display: 'inline',
            }}
          >
            <span>
              <img
                style={{
                  display: 'inline',
                  width: '25px',
                  verticalAlign: 'revert',
                }}
                src={adminRank.logo}
              />
            </span>
            <span
              style={{
                marginLeft: '.3em',
                fontSize: '20px',
                verticalAlign: 'text-top',
                color: '#646464',
              }}
            >
              {adminRank.text}
            </span>
          </span>
        )}
      </div>
      <div style={{ fontWeight: 700 }}>{date}</div>
    </div>
  )
}

export default DashboardSubHeader
