import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Offer } from '@types_def/models/offer.types'

import { ActionCell } from '../CustomCells/ActionCell'
import { DateCell } from '../CustomCells/DateCell'
import { DriverFirstNameCell } from '../CustomCells/DriverFirstNameCell'
import { DriverLastNameCell } from '../CustomCells/DriverLastNameCell'
import { DriverRatingCell } from '../CustomCells/DriverRatingCell'
import PriceCell from '../CustomCells/PriceCell'
import { OfferStatusCell } from '../CustomCells/OfferStatusCell/OfferStatusCell'

const offerHeadCell: Array<HeadCell<Offer>> = [
  {
    id: 'driver',
    label: 'Nom et Prénom :',
    customCellComponent: DriverFirstNameCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'demandedPrice',
    label: 'Prix :',
    customCellComponent: PriceCell,
    // IsNotSortable: true,
  },
  /*   {
    id: 'driver',
    label: 'Évaluation :',
    customCellComponent: DriverRatingCell,
    isNotSortable: false,
    passWholeObject: true,
  },
  {
    id: 'createdAt',
    label: 'Date :',
    customCellComponent: DateCell,
    isNotSortable: true,
  }, */
  {
    id: 'status',
    label: 'status :',
    customCellComponent: OfferStatusCell,
    passWholeObject: true,
  },
  {
    id: 'id',
    label: 'Action :',
    customCellComponent: ActionCell,
    isNotSortable: true,
    passWholeObject: true,
  },
]

export default offerHeadCell
