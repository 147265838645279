const PlusIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 4.5C7.58438 4.5 7.25 4.83438 7.25 5.25V7.25H5.25C4.83438 7.25 4.5 7.58438 4.5 8C4.5 8.41563 4.83438 8.75 5.25 8.75H7.25V10.75C7.25 11.1656 7.58438 11.5 8 11.5C8.41563 11.5 8.75 11.1656 8.75 10.75V8.75H10.75C11.1656 8.75 11.5 8.41563 11.5 8C11.5 7.58438 11.1656 7.25 10.75 7.25H8.75V5.25C8.75 4.83438 8.41563 4.5 8 4.5Z'
      fill='#fff'
    />
  </svg>
)

export default PlusIcon
