import { type GetAllInput } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

import { gql, graphqlQuery } from '../../clients/apollo'

type ApiGetAllResponse<T> = {
  findAll: { records: T[]; total: number }
}
async function getAllFilteredDeep<T extends Record<string, unknown>, K extends keyof T>(
  getAllInput: GetAllInput<T, K>,
  resource: string,
  graphQlFieldsObject: QueryType<T>,
  customizedInputName?: string,
) {
  if (getAllInput.sort && getAllInput.sort.field === 'publicId') {
    getAllInput.sort.field = 'id' as K
  }
  if (resource === 'Organizations' && getAllInput.sort && getAllInput.sort.field === 'id') {
    getAllInput.sort.field = 'slug' as K
  }

  const graphqlQueryFields = graphqlQueryFieldsBuilder<T>(graphQlFieldsObject)
  const res = await graphqlQuery<ApiGetAllResponse<T>, { getAllInput: GetAllInput<T, K> }>(
    gql`
      query findAll${resource}($getAllInput: ${
        customizedInputName ? customizedInputName + '!' : 'GetAllInput!'
      }) {
      findAll:  findAll${resource}(GetAllInput: $getAllInput) {
          records 
           ${graphqlQueryFields}
         
          total
        }
      }
    `,
    {
      getAllInput,
    },
  )
  if (res?.data) {
    return {
      success: true,
      message: 'Ok',
      data: res.data.findAll,
    }
  }

  return { success: false, message: 'Request failed' }
}

export default getAllFilteredDeep
