import dayjs from 'dayjs'
import { z } from 'zod'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { DeliveryType } from '@types_def/models/delivery.types'
import { Vehicle } from './vehicule'
import errorMessages from '../errorMessages'

dayjs.extend(isSameOrAfter)

const TCarType = ['A', 'B', 'C'] as const
const TVehicleType = ['GAS', 'HYBRID', 'ELECTRIC'] as const
const TCategory = ['STANDARD', 'OKM', 'EXPRESS'] as const

const isDayjsDate = (value: any) => {
  return value && dayjs(value).isValid()
}

function disablePast(date: string | Date | any) {
  return dayjs(date)?.isSameOrAfter(dayjs(), 'minute')
}

function areSameOrAfter(date1: dayjs.Dayjs | any, date2: dayjs.Dayjs | any) {
  return date1?.isSameOrAfter(date2, 'minute')
}

function isAfter(date1: dayjs.Dayjs | any, date2: dayjs.Dayjs | any) {
  return date1?.isAfter(date2, 'minute')
}

const Client = z.object({
  name: z.string(),
})

const Address = z.object({
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  city: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  address: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  postalCode: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  place_id: z.coerce.string(),
  country: z.string(),
  addressPrecision: z.nullable(z.string()).optional(),
})

const NullableAddress = z.object({
  latitude: z.coerce.number().nullish(),
  longitude: z.coerce.number().nullish(),
  city: z.string().nullish(),
  address: z.string().nullish(),
  postalCode: z.string().nullish(),
  place_id: z.string().nullish(),
  country: z.string().nullish(),
  addressPrecision: z.string().nullish(),
})

export const TwoWayDeliverySchema = z.object({
  //! AutoFilled
  twoWayDeliverType: z.enum(['TO_RETURN', 'EXCHANGE']).nullish(),
  type: z.nativeEnum(DeliveryType),

  //! Step 1
  //Client
  clientFirstName: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  clientLastName: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  clientEmail: z.string().refine((value) => /\S+@\S+\.\S+/.test(value), {
    message: errorMessages.invalidEmail,
  }),
  clientPhone: z.string(),
  clientSiret: z.nullable(z.string()),
  clientCompany: z.nullable(z.string()),
  clientDrivingLicenceNumber: z.string().optional(),

  //Mandaire
  clientId: z.coerce.number(),
  representativeFirstName: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  representativeLastName: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  representativePhone: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),
  representativeEmail: z.string().refine((value) => value.trim() !== '', {
    message: errorMessages.required,
  }),

  //Delivery dates
  carAvailabilityDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carPickUpDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carDeliveryDeadline: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),

  //Delivery documents
  docs: z.array(
    z.object({
      name: z.string(),
      key: z.optional(z.string()),
      url: z.string(),
    }),
  ),

  //! Step 2
  // Addresses de depart
  fromAddress: Address,

  // Addresses de destination
  toAddress: Address,

  //Vehicules //!this are shared with step 3
  vehicles: z.array(Vehicle),

  //! Step 3
  // Adresses de depart du restitution
  toReturnFromAddress: Address,

  // Adresses de destination du restitution
  toReturnToAddress: NullableAddress.nullish(),

  //! Step 4
  //Options
  options: z.object({
    selected: z.array(z.string()),
  }),

  //Garage options
  garageOptions: z.object({
    selected: z.array(z.string()),
  }),

  //Category
  category: z.enum(TCategory),

  //Comment
  comment: z.string(),

  //* those are autofilled
  status: z.string().optional(),
  numberOfVehicles: z.number(),
  id: z.number().optional(),
})

//  .refine(
//     ({ carPickUpDate, carAvailabilityDate }) => areSameOrAfter(carPickUpDate, carAvailabilityDate),
//     {
//       message: errorMessages.pickUpDateAfterAvailabilityDate,
//       path: ['carPickUpDate'],
//     },
//   )
//   .refine(({ carDeliveryDeadline, carPickUpDate }) => isAfter(carDeliveryDeadline, carPickUpDate), {
//     message: errorMessages.DeliveryDeadlineAfterPickUpDate,
//     path: ['carDeliveryDeadline'],
//   })
