import { type FunctionComponent } from 'react'

import MainButton from '@components/common/Button'
import SimpleInputControl from '@components/common/inputs/SimpleInput'
import globalStyles from '@styles/globals.module.scss'
import { isValidEmail } from '@utils/functions/inputValidators'
import { useTranslation } from 'react-i18next'
type Props = {
  value: string
  handleInputChange: (value: string | boolean | number, identifier?: string) => void
  onNextClick?: boolean
  identifier: string
  remark?: string
}
export const AuthenticateEmail: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const isValid = isValidEmail(props.value)
  const { value, handleInputChange, onNextClick, identifier, remark } = props
  return (
    <>
      <SimpleInputControl
        value={value}
        onChange={handleInputChange}
        type='text'
        remark={remark}
        identifier={identifier}
        label={t('common.form.input.email')}
      />
      {!isValid && value.length > 0 && (
        <small style={{ textTransform: 'capitalize' }}>{t('common.form.invalid.email')}</small>
      )}
      {onNextClick && (
        <div className={globalStyles['next-button-container']}>
          <MainButton disabled={!isValid} type='submit'>
            {t('common.navigation.next')}
          </MainButton>
        </div>
      )}
    </>
  )
}
