import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import getDeliveryStatisticsByType from '@api/graphql/delivery/getStatsByType'
import DatePickerControl from '@components/common/inputs/DatePicker'
import { AdminDeliveryStats } from '@types_def/models/delivery.types'

import DeliveryAdminChart from '../DeliveryAdminChart'
import styles from './index.module.scss'

type Chart = { name: string; amount: number }

const AdminDeliveryTypesChart = () => {
  const year = new Date().getFullYear()
  const start = DateTime.local(year, 1, 1)
  const end = DateTime.local(year, 12, 31)

  const [typeChartData, setTypeChartData] = useState<Chart[]>([{ name: '', amount: 0 }])

  const [startDate, setStartDate] = useState<DateTime | undefined>(start)
  const [endDate, setEndDate] = useState<DateTime | undefined>(end)
  const getTypeStats = () => {
    getDeliveryStatisticsByType({
      fromDate: startDate,
      toDate: endDate,
    }).then((res) => {
      setTypeChartData(res)
    })
  }

  useEffect(() => {
    getTypeStats()
  }, [startDate, endDate])

  const fromDateChangeHandler = (date: DateTime | undefined) => {
    setStartDate(date?.isValid ? date.set({ hour: 0, minute: 0 }) : undefined)
  }

  const toDateChangeHandler = (date: DateTime | undefined) => {
    setEndDate(date?.isValid ? date.set({ hour: 24, minute: 60 }) : undefined)
  }

  return (
    <>
      <div className={`${styles.datepickerContainer}`}>
        <div className={styles.filtersText}>Filtrer par période : </div>
        <DatePickerControl
          inChart={true}
          value={startDate ?? null}
          onChange={fromDateChangeHandler}
          maxDate={endDate?.minus({ days: 1 })}
        />
        <DatePickerControl
          inChart={true}
          value={endDate ?? null}
          onChange={toDateChangeHandler}
          minDate={startDate?.plus({ days: 1 })}
        />
      </div>

      {typeChartData.length > 0 ? (
        <DeliveryAdminChart type={AdminDeliveryStats.ByType} data={typeChartData} />
      ) : (
        <div className={styles.noDataText}>Aucune donnée disponible pour cette période</div>
      )}
    </>
  )
}

export default AdminDeliveryTypesChart
