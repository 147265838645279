import { Grid, Autocomplete, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import useAddressAutoComplete from '@hooks/useAddressAutoComplete'
import { CreateOrganizationInput } from '@types_def/models/Organization.types'

type TAddress = {
  identifier: 'owner.address' | 'owner.billingClerkAddress'
}

const Address = ({ identifier }: TAddress) => {
  const { value, data, setValue: setAddress, handleSelect } = useAddressAutoComplete()
  const {
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useFormContext<CreateOrganizationInput>()

  const handleAutoFill = (data: any) => {
    const { longitude, latitude, address, placeId, postalCode, city } = data
    setValue(`${identifier}.longitude`, longitude, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${identifier}.latitude`, latitude, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${identifier}.address`, address, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${identifier}.place_id`, placeId, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${identifier}.postalCode`, postalCode, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${identifier}.city`, city, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Controller
          name={`${identifier}.address`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              freeSolo
              disableClearable
              filterOptions={(x) => x}
              options={data.map((option) => option.description)}
              onChange={(event, searchedAddress) => {
                handleSelect(searchedAddress).then((data) => handleAutoFill(data))
              }}
              value={
                !value && getValues(`${identifier}.address`)
                  ? getValues(`${identifier}.address`)
                  : !getValues(`${identifier}.address`) && value
                    ? getValues(`${identifier}.address`)
                    : value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors?.[identifier]?.address)}
                  helperText={errors?.[identifier]?.address?.message}
                  variant='standard'
                  label='Rechercher une adresse'
                  onChange={(e) => {
                    setAddress(e.target.value)
                  }}
                  InputLabelProps={
                    getValues(`${identifier}.address`) || value ? { shrink: true } : {}
                  }
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={`${identifier}.city`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant='standard'
              InputLabelProps={{ shrink: true }}
              disabled
              label='Ville'
              error={Boolean(errors?.[identifier]?.city)}
              helperText={errors?.[identifier]?.city?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={`${identifier}.postalCode`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant='standard'
              InputLabelProps={{ shrink: true }}
              disabled
              label='Code postal'
              error={Boolean(errors?.[identifier]?.postalCode)}
              helperText={errors?.[identifier]?.postalCode?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
export default Address
