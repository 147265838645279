const PhoneIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.9765 12.0933L15.2499 15.2432C15.1478 15.6885 14.7564 16 14.2979 16C6.41311 15.9994 0 9.5872 0 1.70322C0 1.24512 0.311561 0.853266 0.756947 0.751708L3.90724 0.0251827C4.36604 -0.0811244 4.83483 0.156988 5.02548 0.591404L6.47936 3.98186C6.64932 4.38121 6.53468 4.8465 6.19871 5.12086L4.51605 6.47173C5.57803 8.63475 7.33694 10.3934 9.5009 11.4559L10.8785 9.77469C11.1501 9.43783 11.6189 9.32128 12.0183 9.49424L15.4093 10.9476C15.8171 11.1621 16.0828 11.6371 15.9765 12.0933Z'
      fill='#000'
    />
  </svg>
)

export default PhoneIcon
