import { sendRequest } from '../../clients/axios'

// ? is this more optimized?

export function downloadDriverInvoice(id: string) {
  sendRequest<undefined, { data: Iterable<number> }>('get', `invoice/downloadPDF/${id}`).then(
    (res) => {
      window.open(
        URL.createObjectURL(
          new Blob([new Uint8Array(res.data.data)], {
            type: 'application/pdf',
          }),
        ),
        '_blank',
      )
    },
  )
}

// Export async function downloadDriverInvoice(id: string) {
//   await sendRequest<undefined, { data: Iterable<number> }>(
//     "get",
//     `Offer/downloadPDF/${id}`
//   ).then(res => {
//     const bufferParsed = new Uint8Array(res.data.data);
//     const blob = new Blob([bufferParsed], {
//       type: "application/pdf",
//     });
//     window.open(URL.createObjectURL(blob), "_blank");
//   });
// }
