import { SMSDiv, TitleDiv } from '../StyledComponents'

type Props = {
  clientFirstName: string
}

const Welcome = ({ clientFirstName }: Props) => (
  <>
    <TitleDiv>Bonjour Monsieur/Mme {clientFirstName}</TitleDiv>
    <br />
    <SMSDiv>Voici votre récapitulatif de livraison</SMSDiv>
  </>
)

export default Welcome
