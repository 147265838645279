export enum HelpMenuItemType {
  PHONE,
  EMAIL,
  CHAT,
}

export type HelpMenuItemProps = {
  method: string
  type: HelpMenuItemType
  contactInfo: string
  responseTime: string
}
