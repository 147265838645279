import { type DateTime } from 'luxon'

import { type Address } from './address.types'
import { type Delivery } from './delivery.types'
import { type DeliveryPayment } from './deliveryPayment.types'
import { type Offer } from './offer.types'
import { type Subscription } from './subscription.types'
import { type SubscriptionPayment } from './subscriptionPayment'
import { type VehicleNumberEnum } from './vehicle.types'

export enum DriverTableRoute {
  ACCEPTED_REQUESTS,
  WAITING_REQUESTS,
  REFUSED_REQUESTS,
  DISABLED_DRIVERS,
}

export type DriverRatingStats = {
  communicationRating: number
  outfitRating: number
  justiceRating: number
  qualityRating: number
  takeOffRating: number
  correspondenceRating: number
}

enum SubscriptionStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type Driver = {
  id: number
  sub?: string
  createdAt: DateTime
  updatedAt: DateTime
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  fullName: string //* Resolve field
  avatar?: string
  birthDate?: DateTime
  freelancer: boolean
  confirmedByAlb: boolean
  disabled: boolean

  publicId: string
  mcqScore: number
  mcqAnswers?: JSON
  videoPath: string
  idPath?: string
  drivingLicensePath?: string
  kbisPath?: string
  siret: string
  professional: boolean
  company?: string

  drivingLicenseDate?: DateTime
  drivingPermitNumber?: string
  drivingPermitDueDate?: DateTime
  addressId?: number
  address?: Address
  addressPrecision?: string
  subdivision?: string
  activeDays?: {
    days: string[]
  }
  specificNote?: string
  globalNote?: string
  outfitRating?: number
  correspondenceRating?: number
  justiceRating?: number
  takeOffRating?: number
  qualityRating?: number
  totalRating?: number
  subscribedToEmail?: boolean
  registrationStep?: number
  nbrOfVehicles?: VehicleNumberEnum
  offers: Offer[]
  nbDeals: number
  topDeals: number
  lossDeals: number
  stokeDeals: number
  driverOffersPrice: number
  driverAttentePrice: number
  driverAverageBills: number
  rejectedByAlb?: boolean
  subscription?: Subscription
  subscriptionId?: number
  subscriptionStatus?: SubscriptionStatus
  stripeSubscriptionId?: string
  stripeCustomerId?: string
  stripeAccountId?: string
  iban?: string
  bic?: string

  deliveries: Delivery[]
  subscriptionPayments: SubscriptionPayment[]
  deliveryPayments: DeliveryPayment[]
}
export default Driver
