import { type FunctionComponent } from 'react'

import MainButton from '@components/common/Button'
import SimpleInputControl from '@components/common/inputs/SimpleInput'
import globalStyles from '@styles/globals.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string
  handleInputChange: (value: string | boolean | number, identifier?: string) => void
  onNextClick?: boolean
  identifier: string
  remark?: string
}
export const AuthenticateCode: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { value, handleInputChange, onNextClick, identifier, remark } = props
  return (
    <>
      <SimpleInputControl
        value={value}
        onChange={handleInputChange}
        type='text'
        remark={remark}
        identifier={identifier}
        label={t('common.form.input.authentication-code')}
      />
      {onNextClick && (
        <div className={globalStyles['next-button-container']}>
          <MainButton disabled={value.length == 0} type='submit'>
            {t('common.navigation.next')}
          </MainButton>
        </div>
      )}
    </>
  )
}
