import 'react-toastify/dist/ReactToastify.css'
import './styles/mixins.scss'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify'

import UserContextProvider from './contexts/UserContext/index.provider'
import AppRoutes from './routes'
import { APIProvider, useApiIsLoaded } from '@vis.gl/react-google-maps'
import { ThemeProvider } from '@mui/material'
import { theme } from '../theme'
const googleMapsApiKey = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY

const queryClient = new QueryClient()

const Inner = () => {
  const isLoaded = useApiIsLoaded()
  if (!isLoaded) {
    return <div>Loading...</div>
  }
  return (
    <div className='App'>
      <UserContextProvider>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </UserContextProvider>
    </div>
  )
}
const App: React.FunctionComponent = () => {
  return (
    <APIProvider apiKey={googleMapsApiKey} libraries={['places']}>
      <QueryClientProvider client={queryClient}>
        <Inner />
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </APIProvider>
  )
}

export default App
