import styled from 'styled-components'

import { device } from '@styles/mediaQuery'

type Props = {
  title: string
  value: string
}
export const InformationTd = styled.td`
  @media ${device.mobileL} {
    font-size: 12px;
  }
`
const InformationRow = ({ title, value }: Props) => (
  <tr>
    <InformationTd> {title} </InformationTd>
    <InformationTd>{value}</InformationTd>
  </tr>
)

export default InformationRow
