import { Delivery } from '@types_def/models/delivery.types'
import getPaginated from '../query'
import { t } from 'i18next'

const SearchOptions = ({
  organizationId,
  isAdmin,
}: {
  organizationId: string | undefined
  isAdmin: boolean
}) => {
  return [
    {
      id: 0,
      name: t('common.table.headers.ID'),
      fn: (id: string, tableState: any) => {
        return getPaginated<Delivery>({
          tableState,
          filter: {
            id: Number(id),
            organization: {
              slug: isAdmin ? undefined : organizationId,
            },
          },
          resource: 'Delivery',
          queryField: {
            id: true,
            status: true,
            type: true,
          },
        })
      },
    },
    {
      id: 1,
      name: t('delivery.form.vehicle.vin'),
      fn: (vin: string, tableState: any) => {
        return getPaginated<Delivery>({
          tableState,
          filter: {
            vehicles: {
              some: {
                vin: {
                  contains: vin,
                },
              },
            },
            organization: {
              slug: isAdmin ? undefined : organizationId,
            },
          },
          resource: 'Delivery',
          queryField: {
            id: true,
            status: true,
            type: true,
          },
        })
      },
    },
    {
      id: 2,
      name: t('delivery.form.vehicle.licence-plate'),
      fn: (vehicleCode: string, tableState: any) => {
        return getPaginated<Delivery>({
          tableState,
          filter: {
            vehicles: {
              some: {
                vehicleCode: {
                  contains: vehicleCode,
                },
              },
            },
            organization: {
              slug: isAdmin ? undefined : organizationId,
            },
          },
          resource: 'Delivery',
          queryField: {
            id: true,
            status: true,
            type: true,
          },
        })
      },
    },
  ]
}

export default SearchOptions
