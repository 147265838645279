import { gql } from '@apollo/client'

import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type DeliveryStatus } from '@types_def/models/delivery.types'

import { graphqlQuery } from '../../clients/apollo'
type DeliveryStatistic = Array<{
  name: DeliveryStatus
  amount: number
}>

export async function getDeliveryStatistics(): Promise<DeliveryStatistic> {
  try {
    const res = await graphqlQuery<ApiGetResponse<DeliveryStatistic>, never>(gql`
      query {
        data: getDeliveryStatistic {
          amount
          name
        }
      }
    `)
    return res.data.data ?? []
  } catch (error) {
    console.error(error)
    throw new Error((error as Error).message)
  }
}

export default getDeliveryStatistics
