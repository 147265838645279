import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { downloadDeliveries } from '@api/Rest/download/dealsDownloadXLSX'
import { GenericTable } from '@components/common/table'
import { useUserContext } from '@contexts/UserContext/index.context'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'
import { deliveryStatusRoutes } from '@utils/constants/DeliveryStatus/deliveryStatusRoutes'
import { GenericTableContext } from '@contexts/GenericTableContext/GenericTableContext'
import DeliveryTableFilters from './Filters'
import FiltersSummary from './Filters/FiltersSummary'
import { UserRole } from '@types_def/models/user.type'
import { useTableState } from '@/hooks/deliveryFilters'

type Params = {
  status: keyof typeof deliveryStatusRoutes
}

export const DeliveriesTable = () => {
  const { userState } = useUserContext()
  const userRole = userState!['custom:role'] as UserRole
  const { status: statusRoute } = useParams<Params>()
  const statusKey = deliveryStatusRoutes[statusRoute ?? 'confirmed']
  const urlRole = userRole === UserRole.ADMIN ? 'admin' : 'client'
  const [key, setKey] = useState(0)

  const { tableState } = useTableState(statusKey.key, urlRole)

  const handleExport = () => {
    downloadDeliveries(tableState)
  }

  return (
    <>
      {tableState.headCells.length > 0 && tableState.deliveriesTableQueryFields && (
        <GenericTableContext.Provider value={{ key, setKey }}>
          <GenericTable<Delivery>
            key={key}
            resource='Delivery'
            graphqlQueryFieldsObject={tableState.deliveriesTableQueryFields}
            headCells={tableState.headCells}
            filter={tableState.filter}
            filtersSummary={<FiltersSummary />}
            filters={<DeliveryTableFilters handleExpand={() => {}} />}
            exportHandler={statusKey.key === DeliveryStatus.ONGOING ? undefined : handleExport}
            isReadOnly={true}
            include={tableState.include}
          />
        </GenericTableContext.Provider>
      )}
    </>
  )
}
