const PersonIcon = () => (
  <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7 8.00047C4.79062 8.00047 3 6.20943 3 4.00023C3 1.79104 4.79062 0 7 0C9.20937 0 11 1.79104 11 4.00023C11 6.20943 9.20937 8.00047 7 8.00047ZM5.41562 9.50056H8.58437C11.5747 9.50056 14 11.9257 14 14.9165C14 15.5147 13.515 16 12.9169 16H1.0825C0.484375 16.0009 0 15.5165 0 14.9165C0 11.9257 2.425 9.50056 5.41562 9.50056Z'
      fill='#fff'
    />
  </svg>
)

export default PersonIcon
