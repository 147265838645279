import { gql } from '@apollo/client'

import { graphqlMutation } from '@api/clients/apollo'
import { type GenericUpdateObject } from '@types_def/common/generic.types'
import { type QueryType } from '@types_def/common/query.types'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

type ApiUpdateResponse<T> = {
  updated: T
}

export async function update<T extends Record<string, unknown>>(
  updateInput: GenericUpdateObject<T>,
  resource: string,
  graphQlFieldsObject: QueryType<T>,
) {
  try {
    const graphqlQueryFields = graphqlQueryFieldsBuilder(graphQlFieldsObject)
    const res = await graphqlMutation<
      ApiUpdateResponse<T>,
      { updateInput: GenericUpdateObject<T> }
    >(
      gql`
    mutation update${resource}($updateInput: Update${resource}Input!) {
        updated:  update${resource}(Update${resource}Input: $updateInput)
                ${graphqlQueryFields}
    }`,
      {
        updateInput,
      },
    )
    return res.data?.updated
  } catch (error) {
    throw new Error(error as string)
  }
}

export default update
