import { useEffect, memo, useState } from 'react'
import './styles.css'
import { Mission } from './type'
import { WebSocketSingleton } from '@utils/WebSocket'
const socket = WebSocketSingleton.getInstance()
import MissionMarkers from './missionMarkers'
import MapInstance from './mapInstance'
import { useMap } from '@vis.gl/react-google-maps'

type Props = {
  data?: Mission[]
  liveTracking?: boolean
  newDelivery?: boolean
  height?: string
}

function CustomMap({ data, liveTracking = false }: Props) {
  const map = useMap()

  const [selectedMission, setSelectedMission] = useState<string | null>(() => {
    return data.length === 1 ? data[0].id.toString() : null
  })

  console.log({ selectedMission })

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds()
    if (map) {
      data.map((d) => {
        bounds.extend(d.destination)
      })
      map.fitBounds(bounds)
    }
  }, [map])

  useEffect(() => {
    if (liveTracking) {
      socket.connect()
      data.map((mission) => {
        socket.joinRoom(+mission.id)
      })
    }
    return () => {
      if (liveTracking) {
        socket.close()
      }
    }
  }, [liveTracking])

  return (
    <MapInstance>
      {Boolean(data?.length)
        ? data
            // .filter((d) => d.id.toString() === '638')
            .map((mission) => (
              <MissionMarkers
                key={mission.id}
                mission={mission}
                selected={selectedMission}
                setSelectedMission={setSelectedMission}
              />
            ))
        : null}
    </MapInstance>
  )
}

export default memo(CustomMap)
