import { DateTime } from 'luxon'
import { type FunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ExclamationMarkIcon from '@components/common/icons/exclamation-mark.icon'
import { type Delivery, DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { deliveryTableDate } from '@utils/functions/customDates'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'

import styles from './index.module.scss'
import { Button, ButtonGroup } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { enumToStr } from '@utils/strEnum'
import { Organization } from '@types_def/models/Organization.types'
import { useTranslation } from 'react-i18next'

export const LoginCell: FunctionComponent = ({ children }) => {
  const content = children as Delivery

  return children ? (
    <b>
      {content.fromAddress?.city}
      {Boolean(content.departedAt) && <>- {deliveryTableDate(content.departedAt)}</>}
    </b>
  ) : (
    <></>
  )
}

export const LogoutCell: FunctionComponent = ({ children }) => {
  const content = children as Delivery

  return children ? (
    <b>
      {content.toAddress?.city}
      {Boolean(content.completedAt) && <>- {deliveryTableDate(content.completedAt)}</>}
    </b>
  ) : (
    <></>
  )
}

export const dateStringFormat = (date: string | DateTime) => {
  const { i18n } = useTranslation()
  const dateLocal = i18n.language === 'fr' ? 'fr' : 'us'
  const value =
    typeof date === 'string'
      ? DateTime.fromISO(date, {
          zone: 'UTC',
        })
      : date
  return value.toFormat('dd LLL yyyy', { locale: dateLocal })
}

// we will build a custom cell for organization

export const OrganizationCell: FunctionComponent = ({
  children,
}: {
  children: Partial<Organization>
}) => {
  // const { clientCompany, representativeCompany } = children as Delivery
  const name = children?.name ?? 'Non attribué'
  return <div>{name}</div>
}
export const DeliveryStatusCell: FunctionComponent = ({ children }) => {
  switch (children) {
    case DeliveryStatus.CANCELLED:
      return <>Annulée</>
    case DeliveryStatus.CANCELLED_BY_ADMIN:
      return <>Annulée par l'administrateur</>
    case DeliveryStatus.CANCELLED_BY_CUSTOMER:
      return <>Annulée par le client</>
    case DeliveryStatus.CANCELLED_BY_MANDATORY:
      return <>Annulée par le mandataire</>
    case DeliveryStatus.SET:
      return <>Attributer</>
    case DeliveryStatus.PICKUP_DRIVER:
      return <>Pris en charge</>
    case DeliveryStatus.PICKUP_CLIENT:
      return <>Validation de livraison</>
    case DeliveryStatus.ONGOING:
      return <>En route</>
    case DeliveryStatus.DELIVERED:
      return <>Voiture Livré</>
    case DeliveryStatus.SWAP_PICKUP_DRIVER:
      return <>Voiture 2, Pris en charge</>
    case DeliveryStatus.SWAP_IN_TRANSIT:
      return <>Voiture 2, En route</>
    case DeliveryStatus.SWAP_PICKUP_CLIENT:
      return <>Voiture 2, Validation de livraison</>
    case DeliveryStatus.SWAP_DELIVERED:
      return <>Voiture 2, Livré</>
    case DeliveryStatus.COMPLETED:
      return <>Complété</>
    default:
      return <>Unknown</>
  }
}

export const PriceCell: FunctionComponent = ({ children }) => (
  <div>{children ? children.toString().replace('.', ',') + ' € HT' : 'P/A'}</div>
)

export const MarginPriceCell: FunctionComponent = ({ children }) => {
  const { finalPrice, clientFinalPrice } = children as Delivery
  if (!finalPrice || !clientFinalPrice) {
    return <i>Error</i>
  }

  return <div>{(clientFinalPrice - finalPrice).toFixed(2)} € HT</div>
}

export const AdminDeliveredDeliveryDetailsActionCell: FunctionComponent = ({ children }) => {
  const { type, status, id } = children as Delivery
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)

  if (!children) {
    return <i>Error</i>
  }

  return (
    <ButtonGroup
      disableElevation
      variant='contained'
      size='small'
      sx={{ gap: 1, width: '200px' }}
      orientation='vertical'
    >
      <RouterLink to={`/admin/pilot/details/${strType}/${strStatus}/${id}`}>
        <Button color='primary' variant='contained' size='small' fullWidth>
          <VisibilityIcon fontSize='small' />
          Voir le détail
        </Button>
      </RouterLink>
    </ButtonGroup>
  )
}

export const ContractCell: FunctionComponent = ({ children }) => {
  const { type, publicId, status } = children as Delivery
  const cancelled =
    status in
    [
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_ADMIN,
      DeliveryStatus.CANCELLED_BY_MANDATORY,
    ]
  const color = getDeliveryTypeColor(type)
  return (
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      {cancelled && (
        <div className={styles.exclamationMarkContainer}>
          <ExclamationMarkIcon />
        </div>
      )}
      <div
        style={{
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          background: color,
        }}
      ></div>
      <div>{publicId}</div>
    </div>
  )
}
