const rankToRole = (rank: number): string => {
    switch (rank) {
        case 1:
            return 'Super Admin'
            break
        case 2:
            return 'Manager'
            break
        case 3:
            return 'Support'
            break
        case 4:
            return 'Organization Owner'
            break
        case 5:
            return 'Organization Manager'
            break
        case 6:
            return 'Organization Member'
            break
        default:
            return 'Unknown'
            break
    }
}

const ranksToArrayObject = (ransk: number[]) => {
    return ransk.map(rank => {
        return {
            label: rankToRole(rank),
            value: rank
        }
    })
}

export { ranksToArrayObject }
    

export default rankToRole