import { type DeliveryOptions } from '@types_def/models/delivery.types'

import InformationRow, { InformationTd } from '../StyledComponents/InformationRow'
import OptionsConcat from './OptionsConcat'
type Props = {
  options?: DeliveryOptions
  garageOptions?: DeliveryOptions
}
const Options = ({ options, garageOptions }: Props) => {
  const optionsQuantity = options?.selected.length
  const garageOptionsQuantity = garageOptions?.selected.length
  return (
    <>
      {Boolean(optionsQuantity) || Boolean(garageOptionsQuantity) ? (
        <tr>
          <InformationTd>Service optionel </InformationTd>
          {
            <InformationTd>
              {optionsQuantity && <OptionsConcat options={options} quantity={optionsQuantity} />}
              {garageOptionsQuantity && (
                <OptionsConcat options={garageOptions} quantity={garageOptionsQuantity} />
              )}
            </InformationTd>
          }
        </tr>
      ) : (
        <InformationRow title='Pas de services optionels' value={''} />
      )}
    </>
  )
}

export default Options
