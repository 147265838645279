import { UpdateOrganizationInput } from '@types_def/models/Organization.types'
import update from '../generic/update'

export const updateOrganization = async (data: UpdateOrganizationInput) => {
  try {
    return await update<UpdateOrganizationInput>(data, 'Organization', {
      slug: true,
      name: true,
    })
  } catch (error) {
    if (error?.message?.includes('ApolloError:'))
      throw new Error(error?.message?.replace('ApolloError: ', '') as string)
    throw new Error(error as string)
  }
}
