const VerificationIcon = () => (
  <svg
    width='150'
    height='150'
    viewBox='0 0 150 150'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='75' cy='75' r='75' fill='#FF5C8D' fillOpacity='0.05' />
    <path
      d='M56.7503 66.2429C58.3589 63.6083 61.2545 62 64.2491 62H85.6443C88.7502 62 91.6458 63.6083 93.2545 66.2429L104.701 84.9085C106.433 87.7321 106.433 91.2679 104.701 94.0915L93.2545 112.752C91.6458 115.392 88.7502 117 85.6443 117H64.2491C61.2545 117 58.3589 115.392 56.7503 112.752L45.2991 94.0915C43.567 91.2679 43.567 87.7321 45.2991 84.9085L56.7503 66.2429ZM89.3318 84.0737C90.6806 82.7355 90.6806 80.5502 89.3318 79.2121C87.983 77.8739 85.7804 77.8739 84.4316 79.2121L71.0426 92.4955L65.5732 87.0692C64.2244 85.731 62.0217 85.731 60.6729 87.0692C59.3241 88.4074 59.3241 90.5926 60.6729 91.9308L68.5925 99.7879C69.9413 101.126 72.1439 101.126 73.4927 99.7879L89.3318 84.0737Z'
      fill='#FF5C8D'
    />
    <path
      d='M78.8764 35.8719L84.8627 36.6999C85.3585 36.7663 85.775 37.0944 85.9333 37.5436C86.0916 37.9966 85.9625 38.4887 85.6042 38.8246L81.2634 42.8163L82.2882 48.5422C82.3716 49.0109 82.1674 49.4874 81.7508 49.7647C81.3384 50.042 80.7927 50.0771 80.347 49.8545L74.998 47.1791L69.6533 49.8545C69.2033 50.0771 68.6576 50.042 68.2452 49.7647C67.8328 49.4874 67.6245 49.0109 67.712 48.5422L68.7368 42.8163L64.3951 38.8246C64.0365 38.4887 63.909 37.9966 64.0664 37.5436C64.2235 37.0944 64.6388 36.7663 65.1383 36.6999L71.1196 35.8719L73.8024 30.7034C74.0232 30.2731 74.4898 30 74.998 30C75.5104 30 75.977 30.2731 76.1978 30.7034L78.8764 35.8719Z'
      fill='#FF5C8D'
    />
    <path
      d='M52.4668 44.8167L56.2763 45.3549C56.5917 45.3981 56.8568 45.6114 56.9576 45.9033C57.0583 46.1978 56.9761 46.5177 56.7482 46.736L53.9858 49.3306L54.638 53.0524C54.691 53.3571 54.5611 53.6668 54.296 53.847C54.0335 54.0273 53.6863 54.0501 53.4026 53.9054L49.9987 52.1664L46.5975 53.9054C46.3112 54.0501 45.9639 54.0273 45.7015 53.847C45.439 53.6668 45.3065 53.3571 45.3622 53.0524L46.0143 49.3306L43.2514 46.736C43.0232 46.5177 42.9421 46.1978 43.0423 45.9033C43.1422 45.6114 43.4065 45.3981 43.7244 45.3549L47.5307 44.8167L49.2379 41.4572C49.3784 41.1775 49.6753 41 49.9987 41C50.3248 41 50.6217 41.1775 50.7622 41.4572L52.4668 44.8167Z'
      fill='#FF5C8D'
    />
    <path
      d='M102.467 44.8167L106.276 45.3549C106.592 45.3981 106.857 45.6114 106.958 45.9033C107.058 46.1978 106.976 46.5177 106.748 46.736L103.986 49.3306L104.638 53.0524C104.691 53.3571 104.561 53.6668 104.296 53.847C104.034 54.0273 103.686 54.0501 103.403 53.9054L99.9987 52.1664L96.5975 53.9054C96.3112 54.0501 95.9639 54.0273 95.7015 53.847C95.439 53.6668 95.3065 53.3571 95.3622 53.0524L96.0143 49.3306L93.2514 46.736C93.0232 46.5177 92.9421 46.1978 93.0423 45.9033C93.1422 45.6114 93.4065 45.3981 93.7244 45.3549L97.5307 44.8167L99.2379 41.4572C99.3784 41.1775 99.6753 41 99.9987 41C100.325 41 100.622 41.1775 100.762 41.4572L102.467 44.8167Z'
      fill='#FF5C8D'
    />
  </svg>
)

export default VerificationIcon
