import styled from 'styled-components'

import { device } from '@styles/mediaQuery'

export const InformationTabe = styled.table`
  margin: 2em auto;
  table-layout: fixed;
  @media ${device.mobileL} {
  }
`
export const SMSDiv = styled.div<{ textAlign?: string }>`
  font-size: 20px;
  text-align: ${(props) => props.textAlign || 'center'};
  @media ${device.mobileL} {
    font-size: 12px;
    text-align: ${(props) => props.textAlign || 'left'};
  }
`
export const TitleDiv = styled(SMSDiv)`
  font-size: 22px;

  @media ${device.mobileL} {
    font-size: 15px;
  }
`
