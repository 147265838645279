import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { DeliveryType } from '@types_def/models/delivery.types'
import { useNavigate } from 'react-router-dom'
import { enumToStr } from '@utils/strEnum'
import { Box, Typography } from '@mui/material'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import { useTranslation } from 'react-i18next'
const localizer = dayjsLocalizer(dayjs)

type Event = {
  id: number
  title: string | number
  start: Date
  end: Date
  type: DeliveryType
  address: string
}
type TClientCalendar = {
  handleRangeChange: (range: any) => void
  data: Event[]
}
const ClientCalendar = ({ data, handleRangeChange }: TClientCalendar) => {
  const { t } = useTranslation()
  const CustomEvent = ({ event }) => {
    return (
      <Typography variant='body2'>
        <span style={{ textTransform: 'capitalize' }}>{t('calendar.mission')}</span> N°{event.id}
        <br />
        {event.address}
      </Typography>
    )
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: getDeliveryTypeColor(event.type),
      borderRadius: '4px',
      color: 'white',
    }
    return {
      style,
    }
  }
  const navigate = useNavigate()
  const handleSelectEvent = (event: Event) => {
    const strType = enumToStr<DeliveryType>(event.type)
    navigate(`/client/pilot/standard/${strType}/${event.id}`)
  }
  return (
    <Calendar
      popup
      eventPropGetter={eventStyleGetter}
      style={{ height: 600 }}
      localizer={localizer}
      events={data}
      startAccessor='start'
      endAccessor='end'
      onSelectEvent={handleSelectEvent}
      onRangeChange={handleRangeChange}
      messages={{
        next: t('common.navigation.next'),
        previous: t('common.navigation.previous'),
        today: t('calendar.today'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
        date: t('calendar.date'),
        time: t('calendar.time'),
        event: t('calendar.event'),
        noEventsInRange: t('calendar.noEventsInRange'),
        showMore: (total) => `+ ${total} ${t('calendar.showMore')}`,
      }}
      components={{
        event: CustomEvent,
      }}
    />
  )
}
export default ClientCalendar
