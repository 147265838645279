import {
  Button,
  ButtonGroup,
  DialogContentText,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import { type FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { GenericTable } from '@components/common/table'
import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { DeepInclude } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import type Client from '@types_def/models/client.types'
import { RoutePaths } from '@utils/constants/routes'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import EngineeringIcon from '@mui/icons-material/Engineering'
import PeopleIcon from '@mui/icons-material/People'

import {
  AssigneeCell,
  AssigneeCellPhone,
  DateCell,
  TotalDeliveriesCell,
  TotalMembersCell,
} from './CustomCells'
import CustomModal from '@components/common/CustomModal'
import { toast } from 'react-toastify'
import { Organization } from '@types_def/models/Organization.types'
import { deleteOrganization } from '../Querys'
import { t } from 'i18next'
import { sendRawRequest } from '@api/clients/axios'
import MemberForm from '../Member/Form'
import { downloadClients } from '@api/Rest/download/clientsDownloadXLSX'

const OrganizationsTable = () => {
  // This is temporary
  const [reInitiate, setReInitiate] = useState<boolean>()

  const ActionCell: FunctionComponent = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState<PopperPlacementType>()
    const { slug, name } = children as Organization
    const onAddMember = async (data) => {
      try {
        const response = await sendRawRequest<any, any>('post', '/organization/add-member', {
          ...data,
          organizationSlug: slug,
        })
        if (response.data.success) {
          toast.success(t('network.organization.member.created'), {
            position: 'bottom-right',
          })
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? t('network.errors.unknown'), {
          position: 'bottom-right',
        })
      }
    }
    const handleDeleteClick = () => {
      deleteOrganization(slug)
        .then(() => {
          setReInitiate((prev) => !prev)
          toast(t('network.organization.deleted'), {
            type: 'success',
            position: 'bottom-right',
          })
        })
        .catch((err) => {
          toast(err ?? t('network.errors.unknown'), { type: 'error', position: 'bottom-right' })
        })
    }

    const handleClick =
      (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen((prev) => placement !== newPlacement || !prev)
        setPlacement(newPlacement)
      }
    ;+9 / 0.3

    return (
      <ButtonGroup
        disableElevation
        variant='contained'
        size='small'
        sx={{ gap: 1 }}
        orientation='horizontal'
      >
        <Popper
          // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
          sx={{ zIndex: 1200 }}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                component='div'
                elevation={4}
                sx={{
                  p: 1,
                  gap: 2,
                }}
              >
                <ButtonGroup
                  disableElevation
                  variant='contained'
                  size='small'
                  sx={{ gap: 1 }}
                  orientation='vertical'
                >
                  <CustomModal
                    noAction
                    button={'Ajouter member'}
                    color='success'
                    cancelText='Retour'
                    confirmText='Ajouter member'
                    confirmAction={() => alert(5)}
                    title={`Ajout d'un member à ${name}`}
                    fullWidth
                  >
                    <DialogContentText id='alert-dialog-description' component='div'>
                      <Paper
                        component={'div'}
                        elevation={4}
                        sx={{
                          backgroundColor: '#E7E6F7',
                          borderRadius: 1,
                          padding: '20px',
                          height: '100%',
                        }}
                      >
                        <MemberForm
                          unUsedFields={['id']}
                          onSubmit={onAddMember}
                          title='user-info'
                        />
                      </Paper>
                    </DialogContentText>
                  </CustomModal>
                  <Link to={`${RoutePaths.adminOrganizationsTable}/view-members/${name}/${slug}`}>
                    <Button color='info' fullWidth>
                      <PeopleIcon fontSize='small' />
                      Members
                    </Button>
                  </Link>
                  <Link to={`${RoutePaths.adminOrganizationsTable}/view-managers/${name}/${slug}`}>
                    <Button color='warning' fullWidth>
                      <EngineeringIcon fontSize='small' />
                      Managers
                    </Button>
                  </Link>

                  <CustomModal
                    button={'Supprimer'}
                    color='error'
                    cancelText='Retour'
                    confirmText='Supprimer le Organization'
                    confirmAction={handleDeleteClick}
                    title="La suppression d'un Organization"
                    fullWidth
                  >
                    <DialogContentText id='alert-dialog-description'>
                      Ê Vous êtes sur le point de supprimer définitivement{' '}
                      <strong>Un Organization ?</strong>
                    </DialogContentText>
                  </CustomModal>
                </ButtonGroup>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Link to={`${RoutePaths.adminOrganizationsTable}/${slug}`}>
          <Button color='primary' fullWidth startIcon={<VisibilityIcon fontSize='small' />}>
            Détails
          </Button>
        </Link>
        <Button onClick={handleClick('top')} startIcon={<PlaylistAddIcon />} color='success'>
          Plus d'action
        </Button>
      </ButtonGroup>
    )
  }

  const headCells: Array<HeadCell<Client>> = [
    {
      id: 'name',
      label: 'Organisation',
      isNotSortable: true,
    },
    {
      id: 'createdAt',
      label: 'Creé le',
      passWholeObject: true,
      customCellComponent: DateCell,
      isNotSortable: true,
    },
    {
      id: 'assigneeFirstName',
      label: 'le nom du propriétaire',
      passWholeObject: true,
      customCellComponent: AssigneeCell,
      isNotSortable: true,
    },
    {
      id: 'assigneePhone',
      label: 'Numéro',
      isNotSortable: true,
      passWholeObject: true,
      customCellComponent: AssigneeCellPhone,
    },
    {
      id: 'organization',
      label: 'Total Members',
      passWholeObject: true,
      customCellComponent: TotalMembersCell,
      isNotSortable: true,
    },
    {
      id: 'organization',
      label: 'Total Deliveries',
      passWholeObject: true,
      customCellComponent: TotalDeliveriesCell,
      isNotSortable: true,
    },
    {
      id: 'id',
      label: 'Action',
      passWholeObject: true,
      customCellComponent: ActionCell,
      isNotSortable: true,
    },
  ]
  const OrganizationTableQueryFields: QueryType<Organization> = {
    name: true,
    slug: true,
    owner: {
      id: true,
      assigneeFirstName: true,
      assigneePhone: true,
      createdAt: true,
      sub: true,
    },
    members: {
      id: true,
    },
    deliveries: {
      id: true,
    },
  }

  // const clientInclude: PrismaInclude<Client> = {
  //   address: true,
  // }

  const include: DeepInclude<Organization> = {
    members: true,
    owner: true,
    deliveries: true,
  }

  return (
    <>
      <ConfirmProvider>
        <GenericTable<Organization>
          resource='Organizations'
          forceUpdater={reInitiate}
          graphqlQueryFieldsObject={OrganizationTableQueryFields}
          //@ts-ignore
          headCells={headCells}
          orderBy='name'
          include={include}
          exportHandler={() => {
            downloadClients()
          }}
          isReadOnly={true}
          // include={clientInclude}
        />
      </ConfirmProvider>
    </>
  )
}

export default OrganizationsTable
