import { type PropsWithChildren } from 'react'

import styles from './index.module.scss'

type Props = {
  buttonTheme?: 'primary' | 'secondary' | 'course'
  noFill?: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  form?: string
  additionalClassName?: string
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const MainButton = ({
  buttonTheme = 'secondary',
  form,
  noFill = true,
  onClick,
  type,
  children,
  additionalClassName,
  className,
  disabled = false,
  style,
}: PropsWithChildren<Props>) => (
  <button
    disabled={disabled}
    onClick={onClick}
    type={type ?? 'button'}
    form={form}
    style={style}
    className={`${styles.btn} ${styles[buttonTheme]} ${
      additionalClassName ? styles[additionalClassName] : ''
    } ${noFill ? styles.noFill : ''} ${className}`}
  >
    {children}
  </button>
)

export default MainButton
