import { Grid, Paper } from '@mui/material'
import ComponentContainer from '../ComponentContainer'
import FileUpload from '@components/fileUpload'
import { useWatch } from 'react-hook-form'
import { useParams } from 'react-router'
import {
  extractOrganisationSettings,
  getDocumentFromSetting,
} from '@/utils/constants/organizations'
import type { LynkCoSettings } from '@/utils/constants/organizations'
import { useUserContext } from '@/contexts/UserContext/index.context'
import { useTranslation } from 'react-i18next'
import Link from '@mui/icons-material/Link'

type Props = {
  canEdit: boolean
}

const DocumentsComponent = ({ canEdit }: Props) => {
  const params = useParams<{ type: string }>()
  if (!params.type) throw new Error('Missing type param')
  if (!['standard', 'to-return', 'okm', 'two-way-delivery'].includes(params.type))
    throw new Error('Invalid type param')
  const type = params.type as 'two-way-delivery' | 'standard' | 'to-return' | 'okm'
  const { t } = useTranslation()
  const { userState } = useUserContext()
  if (!userState) throw new Error('Missing userState')
  const { settings, isLynkCo } = extractOrganisationSettings(userState)
  const address = useWatch({ name: 'toAddress' })
  const handover =
    address.country && isLynkCo
      ? getDocumentFromSetting(
          type,
          address.country,
          settings.defaultValues as LynkCoSettings['defaultValues'],
        )
      : null

  const defaultValues = handover ? [handover] : []
  console.log('defaultValues', defaultValues)
  return (
    <>
      {handover && (
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <ComponentContainer title={t('common.shared.handover-docs')}>
              <p>
                {t('common.shared.handover-docs-note')}
                <br />
                <span style={{ color: 'grey' }}>{t('common.shared.handover-docs-note-red')}</span>
              </p>
              <br />
              <a
                href={handover.url}
                target='_blank'
                rel='noreferrer'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link />
                {handover.name}
              </a>
            </ComponentContainer>
          </Paper>
        </Grid>
      )}
      {!settings?.no_docs && (
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <FileUpload remove={!canEdit} forceAllowEdit initialValue={defaultValues} />
          </Paper>
        </Grid>
      )}
    </>
  )
}

export default DocumentsComponent
