import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import registeredHeadCells from '@components/Driver/DriversTable/HeadCells/registered'
import registeringHeadCells from '@components/Driver/DriversTable/HeadCells/registering'
import type Driver from '@types_def/models/driver.types'
import { DriverTableRoute } from '@types_def/models/driver.types'

type GetDriverTableRouteAttributes = Array<{
  key: DriverTableRoute
  title: string
  headCells: Array<HeadCell<Driver>>
  confirmationStatus?: boolean
}>

export const driverTableRouteAttributes: GetDriverTableRouteAttributes = [
  {
    key: DriverTableRoute.ACCEPTED_REQUESTS,
    title: '',
    headCells: registeredHeadCells,
    confirmationStatus: true,
  },
  {
    key: DriverTableRoute.WAITING_REQUESTS,
    title: '',
    headCells: registeringHeadCells,
    confirmationStatus: false,
  },
  {
    key: DriverTableRoute.REFUSED_REQUESTS,
    title: '',
    headCells: registeringHeadCells,
    confirmationStatus: false,
  },
  {
    key: DriverTableRoute.DISABLED_DRIVERS,
    title: '',
    headCells: registeredHeadCells,
    confirmationStatus: false,
  },
]
