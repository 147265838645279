import * as React from 'react'
import { IMaskInput } from 'react-imask'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const PhoneMask = React.forwardRef<HTMLInputElement, CustomProps>(function PhoneMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      radix='.'
      mask=' 0 00 00 00 00'
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const IbanMask = React.forwardRef<HTMLInputElement, CustomProps>(function IbanMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='FR00 0000 0000 0000 0000 0000 000'
      definitions={{
        F: 'F',
        R: 'R',
        '0': /[0-9,A-Z]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const formatPhoneNumber = (value: any) => {
  return value?.replace(/(.{3})/g, '$1 ')
}

export { PhoneMask, IbanMask, formatPhoneNumber }
