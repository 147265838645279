import { Link, useNavigate, useParams } from 'react-router-dom'

import MainButton from '@components/common/Button'
import { deliveryTypesRoutes } from '@utils/constants/DeliveryType/deliveryTypesRoutes'

import ConfirmedCreatedDeliveryCodes from './CodeSection'
import useDeliveryCodes from '@hooks/useDeliveryCodes'

const DeliveryCreationConfirmed = () => {
  const {
    type: typeRoute,
    uuid,
    publicId,
  } = useParams<{
    type: keyof typeof deliveryTypesRoutes
    uuid: string
    publicId: string
    stamp: string
  }>()
  const navigate = useNavigate()
  const type = deliveryTypesRoutes[typeRoute ?? 'standard']

  const { data: codes, isLoading, isError } = useDeliveryCodes(publicId!)

  if (isError) {
    return navigate(`/client/new-delivery/${typeRoute ?? 'standard'}`)
  }
  return (
    <div style={{ padding: '2rem' }}>
      <div className='title' style={{ textAlign: 'center' }}>
        {type.title} confirmée <br />
        (Contrat N° {publicId})
      </div>
      <div style={{ textAlign: 'center', padding: '2rem 0' }}>
        Votre demande est en attente de livreurs et sera validé dans moins de 48 heures.
      </div>
      <ConfirmedCreatedDeliveryCodes
        startCode={isLoading ? 'Chargement...' : codes?.startCode}
        destinationCode={isLoading ? 'Chargement...' : codes?.destinationCode}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Link to={`/client/pilot/${type.key.toLowerCase().replaceAll('_', '-')}/requested/${uuid}`}>
          <MainButton type='button'>Suivre ma demande</MainButton>
        </Link>

        <Link to={`/client/new-delivery/${type.key.toLowerCase().replaceAll('_', '-')}`}>
          <MainButton type='button'>{type.title}</MainButton>
        </Link>
      </div>
    </div>
  )
}

export default DeliveryCreationConfirmed
