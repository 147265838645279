import { DeepInclude, GetBySlugInput } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

import { gql, graphqlQuery } from '../../clients/apollo'
import { Organization } from '@types_def/models/Organization.types'

type ApiGetResponse<T> = {
  findBySlug: T
}

async function getDeepBySlug<T extends Record<string, unknown>>(
  slug: string,
  resource: string,
  graphQlFieldsObject: QueryType<T>,
  include?: DeepInclude<Organization>,
): Promise<T> {
  try {
    const res = await graphqlQuery<ApiGetResponse<T>, GetBySlugInput<Organization>>(
      gql`
        query ($getBySlugInput: GetBySlugInput!) {
         findBySlug: find${resource}BySlug(GetBySlugInput: $getBySlugInput) 
            ${graphqlQueryFieldsBuilder(graphQlFieldsObject)}
          
        }
      `,
      {
        getBySlugInput: { slug: slug, include },
      },
    )
    return res.data.findBySlug
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export default getDeepBySlug
