import CarIcon from '@components/common/icons/car.icon'
import PersonIcon from '@components/common/icons/person.icon'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'

import LinksSubHeader from '..'

const InvoiceSubheader = () => (
  <LinksSubHeader>
    <NavigationButton
      to={`${RoutePaths.adminInvoicesDriver}/subscription`}
      includes={RoutePaths.driver}
    >
      <CarIcon />
      Drivers
    </NavigationButton>
    <NavigationButton
      to='https://axonaut.com/dashboard/index'
      includes={RoutePaths.client}
      isExternalLink
    >
      <PersonIcon />
      Clients
    </NavigationButton>
  </LinksSubHeader>
)

export default InvoiceSubheader
