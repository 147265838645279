import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { usePDF } from '@react-pdf/renderer'
import PdfDoc from './pdfDoc'
import { Delivery } from '@types_def/models/delivery.types'
import { useTranslation } from 'react-i18next'
type Props = {
  delivery: Delivery
}

const PdfSection = ({ delivery }: Props) => {
  const { t } = useTranslation()

  const [instance, update] = usePDF({ document: <PdfDoc delivery={delivery} /> })
  return (
    <ButtonGroup fullWidth sx={{ gap: 2 }}>
      <Button
        variant='contained'
        color='primary'
        disabled={instance.loading}
        onClick={() => {
          window.open(instance.url, '_blank')
        }}
        startIcon={<PictureAsPdfIcon />}
      >
        {t('common.shared.generate-pdf')}
      </Button>
      <Button
        variant='contained'
        disabled={instance.loading}
        color='info'
        startIcon={<CloudDownloadIcon />}
        LinkComponent={'a'}
        href={instance.url}
        download={`rapport d'états de livraison ${delivery.id}.pdf`}
      >
        {t('common.shared.download-pdf')}
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {}}
        startIcon={<AttachFileIcon />}
      >
        {t('common.shared.send-via-email')}
      </Button>
    </ButtonGroup>
  )
}

export default PdfSection
