import { ConfirmProvider } from 'material-ui-confirm'
import { useParams } from 'react-router-dom'

import { GenericTable } from '@components/common/table'
import CreateDeliveryDealTable from '@components/Delivery/CreateDeliveryDeal/Table'
import { useDeliveryDetails } from '@components/Delivery/DeliveryFormV2/Querys'
import { type QueryType } from '@types_def/common/query.types'
import { type Offer } from '@types_def/models/offer.types'

import offerHeadCell from './HeadCells/offer'

const OffersTable = () => {
  const { id } = useParams()
  const offerTableQueryFields: QueryType<Offer> = {
    id: true,
    demandedPrice: true,
    createdAt: true,
    deliveryId: true,
    proposedByDriver: true,
    status: true,
    driver: {
      id: true,
      firstName: true,
      lastName: true,
      totalRating: true,
    },
    proposedByAdminId: true,
  }
  if (!id) {
    return <>le livraison n'existe pas </>
  }
  const { data, isLoading, isError, error } = useDeliveryDetails({
    id: true,
    publicId: true,
    carPickUpDate: true,
    representativeCompany: true,
    client: {
      name: true,
    },
    fromAddress: {
      city: true,
    },
    toAddress: {
      city: true,
    },
    numberOfVehicles: true,
  })
  if (isLoading || !data) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error}</span>
  }
  return (
    <>
      <CreateDeliveryDealTable
        id={data?.publicId}
        date={data.carPickUpDate!}
        representative={data.representativeCompany ?? data.client.name ?? ''}
        login={data.fromAddress!.city!}
        logout={data.toAddress!.city!}
        numberOfVehicles={data.numberOfVehicles}
      />
      {offerHeadCell.length > 0 && (
        <ConfirmProvider>
          <GenericTable<Offer>
            resource='Offers'
            customEmptyMessage='Aucun offre est soumit'
            graphqlQueryFieldsObject={offerTableQueryFields}
            headCells={offerHeadCell}
            filter={{ deliveryId: Number(id) }}
            exportHandler={() => {}}
            include={{ driver: true }}
            isReadOnly={true}
          />
        </ConfirmProvider>
      )}
    </>
  )
}

export default OffersTable
