import { useQuery } from '@tanstack/react-query'
import React from 'react'
import CheckIcon from '@components/common/icons/check.icon'
import FlagIcon from '@components/common/icons/flag.icon'
import HourglassIcon from '@components/common/icons/hourglass.icon'
import PlusIcon from '@components/common/icons/plus.icon'
import RoadIcon from '@components/common/icons/road.icon'
import WarningIcon from '@components/common/icons/warning.icon'
import { NavigationButton } from '@components/common/NavigationLink'
import { useUserContext } from '@contexts/UserContext/index.context'
import { DeliveriesCount } from '@types_def/models/delivery.types'
import { UserRole } from '@types_def/models/user.type'
import { RoutePaths } from '@utils/constants/routes'
import LinksSubHeader from '..'
import AutoDeleteIcon from '@mui/icons-material/AutoDelete'
import CalendarIcon from '@components/common/icons/calendar.icon'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import AddNewDeals from '../addNewDeals'
import getDeliveriesCountByAdmin from '@api/graphql/delivery/getDeliveriesCountByAdmin'
import getDeliveriesCountByClient from '@api/graphql/delivery/getDeliveriesCountByClient'

const fetchDeliveriesCounts = async (role: string) => {
  if (RoutePaths.admin.includes(role)) {
    return await getDeliveriesCountByAdmin()
  } else if (RoutePaths.client.includes(role)) {
    return await getDeliveriesCountByClient()
  } else {
    throw new Error('Unauthorized path')
  }
}

const PilotSubHeader = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const role = userState?.['custom:role']?.toString()?.toLowerCase() || ''
  const isAdmin = userState?.['custom:role'] === UserRole.ADMIN
  const isClient = userState?.['custom:role'] === UserRole.CLIENT

  const { data: deliveriesCount, error } = useQuery({
    queryKey: ['getDeliveriesCounts', role],
    queryFn: () => fetchDeliveriesCounts(role),
    staleTime: 1000 * 60,
  })

  if (error) {
    console.error('Error fetching delivery counts:', error)
  }

  const numberOfDeliveriesText = (deliveryCount: keyof DeliveriesCount): string => {
    if (!deliveriesCount) {
      return ''
    }

    const count = deliveriesCount[deliveryCount]
    if (!count) {
      return ''
    }

    return ` : ${count}`
  }

  return (
    <LinksSubHeader>
      {isClient && (
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => {
            sessionStorage.removeItem('new-delivery-form_standard')
            sessionStorage.removeItem('new-delivery-form_to-return')
            sessionStorage.removeItem('new-delivery-form_two-way-delivery')
            navigate(`/client/new-delivery/standard`)
          }}
        >
          {t('layouts.header.new-delivery')}
        </Button>
      )}
      {isAdmin && <AddNewDeals key={'addNewDeals'} />}
      <NavigationButton
        to={
          isAdmin
            ? RoutePaths.adminPilotDeliveryTableRequested
            : RoutePaths.clientPilotDeliveryTableRequested
        }
        includes={RoutePaths.requested}
      >
        <PlusIcon /> {t('layouts.sub-headers.deliveries.requests')}{' '}
        {numberOfDeliveriesText('requestedCount')}
      </NavigationButton>
      <NavigationButton
        to={
          isAdmin
            ? RoutePaths.adminPilotDeliveryTableConfirmed
            : RoutePaths.clientPilotDeliveryTableConfirmed
        }
        includes={RoutePaths.confirmed}
      >
        <CheckIcon /> {t('layouts.sub-headers.deliveries.confirmed')}{' '}
        {numberOfDeliveriesText('confirmedCount')}
      </NavigationButton>
      {isAdmin && (
        <>
          <NavigationButton
            to={RoutePaths.adminPilotDeliveryTableWaitingForDriver}
            includes={RoutePaths.waitingForDriver}
          >
            <HourglassIcon /> {t('layouts.sub-headers.deliveries.waiting-for-driver')}
            {numberOfDeliveriesText('waitingDriverCount')}
          </NavigationButton>
          <NavigationButton to={RoutePaths.adminPilotDeliveryTableSet} includes={RoutePaths.set}>
            <CheckIcon />
            {t('layouts.sub-headers.deliveries.assigned-to-driver')}{' '}
            {numberOfDeliveriesText('setCount')}
          </NavigationButton>
          <NavigationButton
            important
            to={RoutePaths.adminPilotDeliveryAlert}
            includes={RoutePaths.alert}
          >
            <WarningIcon />
            {t('layouts.sub-headers.deliveries.alert')} {numberOfDeliveriesText('alertCount')}
          </NavigationButton>
        </>
      )}
      <>
        <NavigationButton
          to={
            isAdmin
              ? RoutePaths.adminPilotDeliveryTableOngoing
              : RoutePaths.clientPilotDeliveryTableOngoing
          }
          includes={['ongoing', 'pickup-driver', 'pickup-client']}
        >
          <RoadIcon /> {t('layouts.sub-headers.deliveries.ongoing')}
          {numberOfDeliveriesText('onGoingCount')}
        </NavigationButton>
        <NavigationButton
          to={
            isClient
              ? RoutePaths.clientPilotDeliveryTableDelivered
              : RoutePaths.adminPilotDeliveryTableDelivered
          }
          includes={RoutePaths.completed}
        >
          <FlagIcon />
          {t('layouts.sub-headers.deliveries.delivered')} {numberOfDeliveriesText('deliveredCount')}
        </NavigationButton>
        <NavigationButton
          to={
            isAdmin
              ? RoutePaths.adminPilotDeliveryTableCancelled
              : RoutePaths.clientPilotDeliveryTableCancelled
          }
          includes={RoutePaths.cancelled}
        >
          <AutoDeleteIcon />
          {t('layouts.sub-headers.deliveries.cancelled')} {numberOfDeliveriesText('cancelledCount')}
        </NavigationButton>
        {isClient && (
          <NavigationButton
            to={RoutePaths.clientCalendarView}
            includes={RoutePaths.calendarView}
            extraStyle={{ gridColumnEnd: -1 }}
          >
            <CalendarIcon />
            {t('layouts.sub-headers.deliveries.calendar')}
          </NavigationButton>
        )}
      </>
    </LinksSubHeader>
  )
}

export default PilotSubHeader
