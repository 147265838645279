import { Divider, Grid, TextField, Typography, Paper } from '@mui/material'
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { CreateClientInput } from '@types_def/models/client.types'
import Address from '../Address'
import nameToSlug from '@utils/nameToSlug'
import { useEffect } from 'react'
import { CreateOrganizationInput } from '@types_def/models/Organization.types'

const EnterpriseName = () => {
  const {
    formState: { errors },
    getValues,
    control,
    setValue,
  } = useFormContext<CreateOrganizationInput>()

  const organizationName = useWatch({
    name: 'name',
    control: control,
  })

  const organizationSlug = useWatch({
    name: 'slug',
    control: control,
  })

  useEffect(() => {
    setValue('slug', nameToSlug(organizationName))
  }, [organizationName])

  useEffect(() => {
    setValue('slug', nameToSlug(organizationSlug))
  }, [organizationSlug])

  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>Nom de l’entreprise</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={getValues('name') ? { shrink: true } : {}}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              fullWidth
              variant='standard'
              label='Nom'
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='slug'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={organizationSlug ? { shrink: true } : {}}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              fullWidth
              variant='standard'
              label='Slug'
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Address identifier='owner.address' />
      </Grid>
    </Grid>
  )
}
export const EnterpriseNameWithContext = ({ defaultValues }) => {
  const methods = useForm<CreateClientInput>({
    defaultValues,
  })

  const {
    formState: { errors },
    getValues,
    control,
    setValue,
  } = methods
  return (
    <Paper
      elevation={4}
      sx={{
        backgroundColor: '#E7E6F7',
        borderRadius: 1,
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Grid container direction={'row'} spacing={2} paddingBottom={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>Nom de l’entreprise</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ bgcolor: '#fe5d8d' }} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={getValues('name') ? { shrink: true } : {}}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  fullWidth
                  variant='standard'
                  label='Nom'
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='slug'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={field.value ? { shrink: true } : {}}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  fullWidth
                  variant='standard'
                  label='Slug'
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Address identifier='owner.address' />
          </Grid>
        </Grid>
      </FormProvider>
    </Paper>
  )
}
export default EnterpriseName
