import { Skeleton } from '@mui/material'
import { type FunctionComponent } from 'react'

type Props = {
  colspan: number
}
export const LoadingRow: FunctionComponent<Props> = (props: Props) => {
  const { colspan } = props
  return (
    <tr>
      <td colSpan={colspan}>
        {Array(5)
          .fill(1)
          .map((key, index) => (
            <Skeleton key={`skeleton-${index}`} height={51} animation='wave' />
          ))}
      </td>
    </tr>
  )
}
