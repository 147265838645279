import { DateTime } from 'luxon'

import { capitalizeFirstLetter } from './capitalizeFirstLetter'
import i18n from '@/i18n'

const getLocal = () => (i18n.language === 'fr' ? 'fr' : 'us')

export const frenchDate = (
  date: DateTime,
  options?: Intl.DateTimeFormatOptions,
  fetchedFromDb = true,
) => {
  const dateLocal = getLocal()
  const parsedDate = fetchedFromDb
    ? DateTime.fromISO(date.toString(), {
        zone: 'ECT',
      })
    : date
  const formattedDate = parsedDate.setLocale(dateLocal).toLocaleString(options)
  return formattedDate
}

export const frenchTimeDate = (date: DateTime) => {
  if (!date) return 'Error'
  const dateLocal = getLocal()
  const parsedDate = DateTime.fromISO(date.toString(), {
    zone: 'ECT',
  })
  const formattedDate = parsedDate.setLocale(dateLocal).toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
  const formattedDay = frenchDate(date)
  const result = formattedDay + ' à ' + formattedDate
  return result
}

export const deliveryTableDate = (date: DateTime) =>
  frenchDate(date, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).replace(',', '')

export const offerTableDate = (date: DateTime) =>
  frenchDate(date, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
    .replace(', ', ' (')
    .replace(':', 'h')
    .concat(')')

export const dashboardSubheaderDate = (date: DateTime) =>
  capitalizeFirstLetter(
    frenchDate(
      date,
      {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      },
      false,
    )
      .replace(':', 'h')
      .replace(',', ' -'),
  )

export const requestedDeliveryCreationDate = (date: DateTime) =>
  frenchDate(date, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).replace(',', '')

export const getTimeFromDate = (date: Date | string | number) => {
  const formattedDate = new Date(date)
  return `${formattedDate.getHours()}:${formattedDate.getMinutes()}`
}

export const deliverySmsStatusDate = (date: DateTime) =>
  frenchDate(date, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
    .replace(',', ' à')
    .replace(':', 'h')

export const deliverySmsMessageTime = (date: DateTime, delta: number) => {
  try {
    const dateLocal = i18n.language === 'fr' ? 'fr' : 'us'
    const parsedDate = DateTime.fromISO(date.toString(), {
      zone: 'ECT',
    })
      .setLocale(dateLocal)
      .plus({ minutes: delta })
      .toFormat('HH:mm')
    return parsedDate
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const customDeliveryScheduledPickupTimeDate = (date: DateTime) => {
  const beforeDate = deliverySmsMessageTime(date, -15)
  const afterDate = deliverySmsMessageTime(date, 15)
  return `${beforeDate} / ${afterDate}`
}

export default frenchDate
