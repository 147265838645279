import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'

export type Mission = {
  status: DeliveryStatus | 'NEW'
  origin: google.maps.LatLngLiteral
  destination: google.maps.LatLngLiteral
  toReturn: google.maps.LatLngLiteral
  restitution: string
  type: DeliveryType
  id: string
  color: string
  depart: string
  arrive: string
  originCity?: string
  destinationCity?: string
  driver?: {
    id: string
    name: string
    avatar: string
  }
}

export enum PreviewType {
  'AB',
  'BC',
  'ABC',
}
