import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AcceptedOfferDriver,
  AdminSetDeliveryDetailsActionCell,
  ContractCell,
  DateCell,
  DistanceCell,
  OrganizationCell,
  PriceCell,
  RouteCell,
  SubscriptionsCell,
} from '../CustomCells'

const setHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'clientLastName',
    label: 'Client',
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Trajet',
    customCellComponent: RouteCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  // {
  //   id: 'numberOfVehicles',
  //   label: 'Nb. véhicules',
  //   isNotSortable: false,
  // },
  // {
  //   id: 'subscriptions',
  //   label: 'Statut',
  //   isNotSortable: true,
  //   customCellComponent: SubscriptionsCell,
  // },
  {
    id: 'acceptedOffer',
    label: 'Driver',
    customCellComponent: AcceptedOfferDriver,
    passWholeObject: true,
    isNotSortable: true,
  },
  // {
  //   id: 'createdAt',
  //   label: 'Créé à',
  //   customCellComponent: DateCell,
  //   isNotSortable: false,
  // },
]

const extraCells: Array<HeadCell<Delivery>> = [
  {
    id: 'distance',
    label: 'Distance totale',
    customCellComponent: DistanceCell,
    isNotSortable: false,
  },
  {
    id: 'finalPrice',
    label: 'Prix',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
  {
    id: 'startCode',
    label: 'Code Départ',
    isNotSortable: true,
  },
  {
    id: 'destinationCode',
    label: 'Code Arrivée',
    isNotSortable: true,
  },
]

export const clientSetHeadCells: Array<HeadCell<Delivery>> = [
  ...setHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  ...extraCells,
]
export const adminSetHeadCells: Array<HeadCell<Delivery>> = [
  ...setHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Date de livraison estimée du client",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  // {
  //   id: "adminEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  ...extraCells,
  {
    id: 'id',
    label: 'Action',
    customCellComponent: AdminSetDeliveryDetailsActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
