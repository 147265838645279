import { Button, Divider, Stack, Typography } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DeliveryFilterSchemaType } from '@schemas/DeliveryForm/DeliveryFilterSchema'
import Countries from '@utils/constants/county'

const DeliveryTableCountryFilter = () => {
  const { t } = useTranslation()
  const { setValue } = useFormContext<DeliveryFilterSchemaType>()
  const deliveryCountriesWatcher = Countries.map((country) =>
    useWatch({
      name: `deliveryCountries.${country.code}`,
    }),
  )
  return (
    <>
      <Typography variant='body2' letterSpacing={2} fontWeight={600} textTransform={'capitalize'}>
        {t('common.shared.market')}
      </Typography>
      <Divider />
      <Stack direction='row' sx={{ flexWrap: 'wrap' }} p={1} gap={1}>
        {Countries.map((country, index) => (
          <Button
            size='small'
            variant={deliveryCountriesWatcher[index] ? 'contained' : 'outlined'}
            onClick={() =>
              setValue(`deliveryCountries.${country.code}`, !deliveryCountriesWatcher[index])
            }
            key={`delivery-table-country-filter-${index}`}
          >
            <img
              style={{
                marginRight: 5,
                width: 20,
                height: 20,
                objectFit: 'contain',
              }}
              loading='lazy'
              width='20'
              srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
              alt=''
            />
            {country.name}
          </Button>
        ))}
      </Stack>
    </>
  )
}

export default DeliveryTableCountryFilter
