import MainButton from '@components/common/Button'
import { DeliveryStatus } from '@types_def/models/delivery.types'

import { SMSDiv } from '../StyledComponents'
import { ConfirmationButtonContainer } from './ButtonContainer'
import styles from './index.module.scss'

type Props = {
  updateDeliveryStatusBySMS: (status: DeliveryStatus) => void
}
const Buttons = ({ updateDeliveryStatusBySMS }: Props) => (
  <>
    <SMSDiv>Veuillez valider ces informations en cliquant sur les lien ci-dessous.</SMSDiv>
    <ConfirmationButtonContainer>
      <MainButton
        buttonTheme='secondary'
        noFill={false}
        onClick={() => {
          updateDeliveryStatusBySMS(DeliveryStatus.CONFIRMED_BY_CUSTOMER)
        }}
        className={styles.acceptanceButtons}
      >
        Valider les informations
      </MainButton>
      <div
        onClick={() => {
          updateDeliveryStatusBySMS(DeliveryStatus.CANCELLED_BY_CUSTOMER)
        }}
        className={styles.cancelLink}
      >
        Signaler une anomalie
      </div>
    </ConfirmationButtonContainer>
  </>
)

export default Buttons
