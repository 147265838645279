import { Delivery, DeliveryStatus, DeliveryType, Doc } from '@types_def/models/delivery.types'
import { deleteDeliveryDoc, useCreateDelivery, useUpdateDelivery } from '../Querys'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { useUserContext } from '@contexts/UserContext/index.context'
import { TFormActions } from '../Types'
import { enumToStr } from '@utils/strEnum'
import { dataFilter } from '../Filter'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

function filterObjects(data) {
  return data
    .filter((obj) => {
      const hasDeletedKey = obj.hasOwnProperty('deleted')
      const isDeleted = hasDeletedKey && obj.deleted
      return !isDeleted
    })
    .map((obj) => {
      // Skip returning the "deleted" key if it exists and is falsy
      const { deleted, ...filteredObject } = obj
      return filteredObject
    })
}

const buttonsConfig = ({ id, reset }: { id: number; reset: () => void }): TFormActions => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const isAdmin = userState['custom:role'] === 'ADMIN'
  const { type: typeRoute, contract } = useParams<Params>()
  const { mutate: updateDelivery } = useUpdateDelivery()
  const { mutate: createDelivery } = useCreateDelivery()
  const navigation = useNavigate()
  const resource = !contract ? 'NEW_DELIVERY' : 'EDIT_DELIVERY'

  const onUpdate = async (data: Delivery & { step: number }) => {
    data = { ...dataFilter(data) }
    if (data?.docs?.length > 0) {
      try {
        data.docs.map(async (doc: Doc) => {
          if (doc?.deleted) {
            return await deleteDeliveryDoc(doc.key)
          }
        })
      } catch (error) {
        return -1
      }
    }
    const cleanDocs = filterObjects(data.docs)
    updateDelivery(
      { ...data, docs: JSON.stringify(cleanDocs) },
      {
        onSuccess: () => {
          navigation(-1)
        },
      },
    )
    const srtType = enumToStr<DeliveryType>(data.type)
    const strStatus = enumToStr<DeliveryStatus>(data.status)
    queryClient.removeQueries({
      queryKey: ['delivery', id.toString(), ['edit', srtType, strStatus]],
    })
    queryClient.removeQueries({
      queryKey: ['delivery', id.toString(), ['view', srtType, strStatus]],
    })
  }

  const onCreate = async (data: Delivery & { step: number }) => {
    data = { ...dataFilter(data) }
    const srtType = enumToStr<DeliveryType>(data.type)
    createDelivery(
      { ...data, docs: JSON.stringify(data.docs) },
      {
        onSuccess: (res) => {
          if (isAdmin) {
            navigation(`/admin/pilot/create-deal/confirmed/${srtType}/${res.id}`)
            return
          }
          const timestamp = Date.now()
          navigation(
            `/client/new-delivery/${typeRoute}/created/${res.id}/${res.publicId}/${timestamp}`,
          )
        },
      },
    )
  }

  if (['NEW_DELIVERY'].includes(resource)) {
    return {
      submit: onCreate,
      actions: [
        {
          type: 'submit',
          isModal: true,
          text: t('delivery.action.create.text'),
          variant: 'contained',
          color: 'success',
          title: t('delivery.action.create.title'),
          description: t('delivery.action.create.description'),
        },
      ],
    }
  } else if (['EDIT_DELIVERY'].includes(resource)) {
    return {
      submit: onUpdate,
      actions: [
        {
          type: 'submit',
          isModal: true,
          text: t('delivery.action.update.text'),
          variant: 'contained',
          color: 'primary',
          title: t('delivery.action.update.title'),
          description: t('delivery.action.update.description'),
        },
      ],
    }
  }
}

export { buttonsConfig }
