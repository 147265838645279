const LockIcon = () => (
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.0844 0.188748C10.3469 0.37656 10.5 0.678435 10.5 0.999998V5H11C11.5531 5 12 5.44687 12 6C12 6.55312 11.5531 7 11 7H8C7.44687 7 7 6.55312 7 6C7 5.44687 7.44687 5 8 5H8.5V2.3875L8.31563 2.44875C7.79375 2.62344 7.225 2.34031 7.05312 1.81625C6.87812 1.29219 7.15937 0.725935 7.68437 0.551248L9.18437 0.0513103C9.4875 -0.0503147 9.825 0.000813455 10.0844 0.188748ZM3.085 9.1875C3.34687 9.37812 3.5 9.67812 3.5 10V14H4C4.55312 14 5 14.4469 5 15C5 15.5531 4.55312 16 4 16H1.00031C0.448124 16 0.000267335 15.5531 0.000267335 15C0.000267335 14.4469 0.448124 14 1.00031 14H1.50031V11.3875L1.31656 11.45C0.792499 11.6219 0.226249 11.3406 0.0515923 10.8156C-0.123064 10.2937 0.160092 9.725 0.684061 9.55L2.18406 9.05C2.48906 8.95 2.82437 9 3.085 9.1875ZM4 9.47809e-07C5.10625 9.47809e-07 6 0.895311 6 2V5C6 6.10312 5.10625 7 4 7H2.00031C0.895624 7 0.000267335 6.10312 0.000267335 5V2C0.000267335 0.895311 0.895624 9.47809e-07 2.00031 9.47809e-07H4ZM2.00031 5H4V2H2.00031V5ZM6 11C6 9.89687 6.89687 9 8 9H10C11.1063 9 12 9.89687 12 11V14C12 15.1031 11.1063 16 10 16H8C6.89687 16 6 15.1031 6 14V11ZM10 11H8V14H10V11ZM16.5 5.97187C17.8813 5.97187 19 7.11875 19 8.47187V9.97187C19.5531 9.97187 20 10.4469 20 11V15C20 15.5531 19.5531 16 19 16H14C13.4469 16 13 15.5531 13 15V11C13 10.4469 13.4469 9.97187 14 9.97187V8.47187C14 7.11875 15.1187 5.97187 16.5 5.97187ZM16.5 7.47187C15.9469 7.47187 15.5 7.94687 15.5 8.47187V9.97187H17.5V8.47187C17.5 7.94687 17.0531 7.47187 16.5 7.47187Z'
      fill='#000'
    />
  </svg>
)

export default LockIcon
