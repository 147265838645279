import { Chip } from '@mui/material'
import { type FunctionComponent } from 'react'

import { type Offer } from '@types_def/models/offer.types'
import { getOfferColorByStatus } from '@utils/Offer'

export const OfferStatusCell: FunctionComponent = ({ children }) => {
  const { status } = children as Offer
  return <Chip label={status} color={getOfferColorByStatus(status)} />
}
