import { Divider, Grid, Typography, Paper } from '@mui/material'

type TComponentContainer = {
  title: string
  children: React.ReactNode
}

function ComponentContainer({ title, children }: TComponentContainer) {
  return (
    <Grid container direction={'row'} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform="capitalize">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>

      <Grid item xs={12} gap={4} marginTop={2}>
        {children}
      </Grid>
    </Grid>
  )
}

export default ComponentContainer
