const SteeringWheelIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8ZM5.27812 9H2.08281C2.50438 11.5125 4.4875 13.4969 7 13.9156V11.2156L5.27812 9ZM9 11.2156V13.9156C11.5125 13.4969 13.4969 11.5125 13.9156 9H10.7219L9 11.2156ZM11 7H13.9156C13.4406 4.1625 10.9719 2 8 2C5.02813 2 2.55906 4.1625 2.08281 7H5L5.225 6.55312C5.39375 6.2125 5.74063 6 6.11875 6H9.88125C10.2594 6 10.6062 6.2125 10.775 6.55312L11 7Z'
      fill='#fff'
    />
  </svg>
)

export default SteeringWheelIcon
