import { UserRank } from '@types_def/models/user.type'
import { useDeleteAdmin, useGetAdminsByRanks } from './Query'
import {
  ButtonGroup,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Container } from '@mui/material'
import formatDate from '@utils/formatDate'
import EditModal from './Modal'
import CustomModal from '../common/CustomModal'
import { useSearchParams } from 'react-router-dom'
import frenchDate from '@utils/functions/customDates'
type TAdmins = {
  rank: UserRank
}

const rankToRole = (rank: UserRank) =>
  rank === UserRank.SUPER_ADMIN
    ? 'Super Administrateur'
    : rank === UserRank.MAINTAINER
      ? 'Mainteneur'
      : 'Support'

const Admins = ({ rank }: TAdmins) => {
  const [searchParams] = useSearchParams()
  const focus = Number(searchParams.get('focus')) || undefined
  const { data, isLoading } = useGetAdminsByRanks({ filter: { rank } })
  const { isLoading: isRequesting, mutate: deleteAdmin } = useDeleteAdmin()

  if (isLoading) return <div>Loading...</div>

  return (
    <Container maxWidth='xl'>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} align='center'>
                {rankToRole(rank)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align='left'>Nom et Prénom</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Rank</TableCell>
              <TableCell align='left'>Crée le</TableCell>
              <TableCell align='left'>Modifiée le</TableCell>
              <TableCell align='left'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((admin) => (
              <TableRow
                key={admin.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: admin.id === focus ? '#fffec9' : 'transparent',
                }}
              >
                <TableCell component='th' scope='admin'>
                  {admin.id}
                </TableCell>
                <TableCell align='left'>{admin.lastName + ' ' + admin.firstName}</TableCell>
                <TableCell align='left'>{admin.email}</TableCell>
                <TableCell align='left'>{rankToRole(Number(admin.rank))}</TableCell>
                <TableCell align='left'>{frenchDate(admin.createdAt)}</TableCell>
                <TableCell align='left'>{frenchDate(admin.updatedAt)}</TableCell>
                <TableCell align='left'>
                  <ButtonGroup
                    disabled={isRequesting || isLoading}
                    orientation='vertical'
                    sx={{ gap: 1 }}
                  >
                    <EditModal admin={admin} />
                    <CustomModal
                      fullWidth={true}
                      button={'Supprimer'}
                      color='error'
                      cancelText='Retour'
                      confirmText='Supprimer le client'
                      confirmAction={() => deleteAdmin(admin)}
                      title='Supprission compte du client'
                    >
                      <DialogContentText id='alert-dialog-description'>
                        Êtes-vous sûr de vouloir supprimer le compte du{' '}
                        <strong>{admin.lastName + ' ' + admin.firstName} ?</strong>
                      </DialogContentText>
                    </CustomModal>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default Admins
