import { type DeliveryType } from '@types_def/models/delivery.types'
import { deliveryTypeAttributes } from '@utils/constants/DeliveryType/deliveryTypeAttributes'

export const getDeliveryTypeAttributes = (deliveryType: DeliveryType) =>
  deliveryTypeAttributes.find((c) => c.key === deliveryType) ?? deliveryTypeAttributes[0]

export const getDeliveryTypeColor = (deliveryType: DeliveryType) =>
  getDeliveryTypeAttributes(deliveryType).color

export const getDeliveryTypeAcronym = (deliveryType: DeliveryType) =>
  getDeliveryTypeAttributes(deliveryType).acronym

export const getDeliveryTypeTitle = (deliveryType: DeliveryType) =>
  getDeliveryTypeAttributes(deliveryType).title
