import { Paper, Table, TableBody, TableContainer, TableCell } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useState, useCallback, PropsWithChildren, FunctionComponent } from 'react'
import { useSearchParams } from 'react-router-dom'

import getAllFiltered from '@api/graphql/generic/getAllFiltered'
import EnhancedTableHead, { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { EnhancedTablePagination } from '@components/common/table/EnhancedTablePagination'
import { NoDataRow } from '@components/common/table/NoDataRow'
import { EnhancedTableRow } from '@components/common/table/StyledTableRow'
import {
  type DeepInclude,
  type GetAllInput,
  type OrderType,
  type WhereFilter,
} from '@types_def/common/filter.types'
import { type GenericObject } from '@types_def/common/generic.types'
import { type QueryType } from '@types_def/common/query.types'

import styles from './index.module.scss'
import { LoadingRow } from './LoadingRow'
import TableToolbar from './TableToolbar'
import { ReactQueryKeyContextProvider } from '@contexts/ReactQueryKey/ReactQueryKey.context'

function filterByFullName(arr, searchString) {
  return arr.filter((item) => item.fullName.toLowerCase().includes(searchString.toLowerCase()))
}

type TableData<T> = {
  rows: T[]
  total: number
}

type TableFilterType<T> = {
  filter?: WhereFilter<T>
  notEqual?: WhereFilter<T>
  page: number
  order: OrderType
  orderBy: keyof T
  keyword: string
}

type TableStateType<T> = {
  tableFilter: TableFilterType<T>
  selected: string[]
}

type TableProps<T extends GenericObject> = {
  headCells: Array<HeadCell<T>>
  resource: string
  isReadOnly: boolean
  graphqlQueryFieldsObject: QueryType<T>
  isInForm?: boolean
  filters?: JSX.Element
  filter?: WhereFilter<T>
  include?: DeepInclude<T>
  notEqual?: WhereFilter<T>
  exportHandler?: () => void
  forceUpdater?: boolean
  customEmptyMessage?: string
  nameFilter?: string
  orderBy?: keyof T
  filtersSummary?: JSX.Element
}

export const rowsPerPage = 10
type InjectableComponentProps = { CustomComponent: FunctionComponent }

const InjectableComponent = ({
  CustomComponent,
  children,
}: PropsWithChildren<InjectableComponentProps>) => <CustomComponent>{children}</CustomComponent>

export const GenericTable = <T extends GenericObject>(props: TableProps<T>) => {
  const {
    headCells,
    isReadOnly,
    resource,
    filters,
    isInForm,
    filter,
    graphqlQueryFieldsObject,
    include,
    exportHandler,
    customEmptyMessage,
    nameFilter = '',
    filtersSummary,
    orderBy: initialOrderBy,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const initialOrder = (searchParams.get('order') as OrderType) ?? 'desc'
  const initialOrderByParam = (searchParams.get('orderBy') as keyof T) ?? 'id'
  const page = parseInt(searchParams.get('page') ? searchParams.get('page') : '0')

  const [tableState, setTableState] = useState<TableStateType<T>>({
    tableFilter: {
      page,
      order: initialOrder,
      orderBy: initialOrderByParam,
      keyword: '',
    },
    selected: [],
  })

  const getData = useCallback(async () => {
    const getAllInput: GetAllInput<T, typeof tableState.tableFilter.orderBy> = {
      keyword: tableState.tableFilter.keyword,
      pagination: { page, perPage: rowsPerPage },
      sort: { field: tableState.tableFilter.orderBy, order: tableState.tableFilter.order },
      filter,
      include,
    }

    try {
      const response = await getAllFiltered<T, typeof tableState.tableFilter.orderBy>(
        getAllInput,
        resource,
        graphqlQueryFieldsObject,
      )
      const { data, success } = response

      if (success && data) {
        if (nameFilter.length > 0) {
          data.records = filterByFullName(data.records, nameFilter)
        }
        return data
      }
    } catch (error) {
      console.error({ error })
      throw new Error("Couldn't get all records")
    }
  }, [
    tableState.tableFilter.keyword,
    tableState.tableFilter.order,
    tableState.tableFilter.orderBy,
    page,
    filter,
    include,
    resource,
    graphqlQueryFieldsObject,
    nameFilter,
  ])

  const queryKey = [resource, include, filter, tableState.tableFilter, page]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: getData,
  })

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc =
      tableState.tableFilter.orderBy === property && tableState.tableFilter.order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'

    setTableState((prevTableState) => ({
      ...prevTableState,
      tableFilter: {
        ...prevTableState.tableFilter,
        order: newOrder,
        orderBy: property,
        page: 0,
      },
    }))

    searchParams.set('orderBy', property.toString())
    searchParams.set('order', newOrder)
    setSearchParams(searchParams)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data?.records?.map((n) => `${n.id}`) ?? []
      setTableState((prevTableState) => ({
        ...prevTableState,
        selected: newSelecteds,
      }))
    } else {
      setTableState((prevTableState) => ({
        ...prevTableState,
        selected: [],
      }))
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const { selected } = tableState
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setTableState((prevTableState) => ({
      ...prevTableState,
      selected: newSelected,
    }))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    searchParams.set('page', newPage.toString())
    setSearchParams(searchParams)
  }

  const isSelected = (name: string) => tableState.selected.includes(name)

  return (
    <>
      <TableToolbar
        total={data?.total ?? 0}
        filters={filters}
        filtersSummary={filtersSummary}
        exportHandler={exportHandler}
      />
      <Paper className={`${styles['data-table-paper']} ${isInForm ? ' isInForm ' : ''}`}>
        <TableContainer className={styles['data-table-container']}>
          <Table
            className={'data-table'}
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'medium'}
          >
            <EnhancedTableHead
              isReadOnly={isReadOnly}
              numSelected={tableState.selected.length}
              order={tableState.tableFilter.order}
              orderBy={tableState.tableFilter.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data?.records?.length ?? 0}
              headCells={headCells}
            />
            <TableBody>
              {!isLoading ? (
                data && data.records.length > 0 ? (
                  data.records.map((row, index) => {
                    const isItemSelected = isSelected(`${row.id}`)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <ReactQueryKeyContextProvider
                        key={`${index}_${queryKey}`}
                        queryKey={queryKey}
                      >
                        <EnhancedTableRow
                          isReadOnly={isReadOnly}
                          handleClick={handleClick}
                          isItemSelected={isItemSelected}
                          id={row.id}
                          className={styles.deliveryTableRow}
                          labelId={labelId}
                          key={index}
                        >
                          {headCells.map(
                            (headCell, index) =>
                              !headCell.isHidden && (
                                <TableCell key={`cell-${index}-${headCell.id.toString()}`}>
                                  {headCell.customCellComponent ? (
                                    <InjectableComponent
                                      CustomComponent={headCell.customCellComponent}
                                    >
                                      {headCell.passWholeObject ? row : row[headCell.id]}
                                    </InjectableComponent>
                                  ) : (
                                    <>{row[headCell.id]}</>
                                  )}
                                </TableCell>
                              ),
                          )}
                        </EnhancedTableRow>
                      </ReactQueryKeyContextProvider>
                    )
                  })
                ) : (
                  <NoDataRow
                    message={`${customEmptyMessage ?? 'Aucun ajouté !'} `}
                    colspan={headCells.length + 1}
                  />
                )
              ) : (
                <LoadingRow colspan={headCells.length + 1} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <EnhancedTablePagination
        total={data?.total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}
