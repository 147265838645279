import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import LoadingIcon from '@components/common/icons/loading.icon'
import { useCheckIfMounted } from '@hooks/mountingRef'

import styles from './index.module.scss'

type GetDocumentUrlResponse = {
  DocumentURL: string
}

const DriverMandate = () => {
  const [searchParams] = useSearchParams()
  const mandateId = searchParams.get('MandateId')
  const [documentUrl, setDocumentUrl] = useState<string>()
  const isMountedRef = useCheckIfMounted()

  const getDocumentUrl = () => {
    axios
      .request<GetDocumentUrlResponse>({
        method: 'GET',
        url: `${import.meta.env.VITE_APP_API_BASE_URL}drivers/getMandate?mandateId=${mandateId}`,
      })
      .then((res) => {
        if (isMountedRef) {
          setDocumentUrl(res.data.DocumentURL)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (mandateId) {
      getDocumentUrl()
    }
  }, [mandateId])

  return (
    <div className={styles.driverMandateContainer}>
      <div className={styles.driverMandate}>
        {documentUrl ? (
          <>
            <header className={styles.header}>
              <img src='/images/logo.svg' alt='logo' className={styles.logo} />
              <div className={styles.title}>Merci pour votre confirmation !</div>
            </header>
            <main>
              <div>
                Vous pouvez consulter et télécharger votre mandat de prélèvement en cliquant sur le
                lien ci-dessous.
              </div>
              <a href={documentUrl} target='_blank' rel='noreferrer'>
                <div className={styles.redirect}>Voir mon mandat</div>
              </a>
            </main>
          </>
        ) : (
          <LoadingIcon />
        )}
      </div>
    </div>
  )
}

export default DriverMandate
