import * as React from 'react'
import Container from '@mui/material/Container'
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MainButton from '@components/common/Button'
import { useNavigate } from 'react-router'
import formatDate from '@utils/formatDate'
import { Stack } from '@mui/material'
import { useUserContext } from '@contexts/UserContext/index.context'
import { Delivery } from '@types_def/models/delivery.types'
import { useTranslation } from 'react-i18next'
import frenchDate, { frenchTimeDate } from '@utils/functions/customDates'

const categories = {
  STANDARD: 'STANDARD',
  EXPRESS: 'EXPRESS (Moins 48H)',
  OKM: 'OKM',
}

interface SummaryProps {
  isRestitution: boolean
  data: Delivery
  isCreation?: boolean
}

type DataItem = {
  key: string
  value: any
}

type TDataComponent = {
  title: string
  data: DataItem[]
}

const DataComponent = ({ title, data = [] }: TDataComponent) => {
  return (
    <Paper sx={{ backgroundColor: 'transparent', padding: 3, height: '100%' }}>
      <Typography variant='h5' color='#ff5c8d' textTransform='capitalize'>
        {title}
      </Typography>
      <Stack direction='column' marginTop={2} spacing={1}>
        {data.map((item, index) => (
          <Typography key={`${title}_${index}`} variant='body1'>
            <Typography fontWeight={900} component='span' textTransform='capitalize'>
              {item.key}{' '}
            </Typography>
            {valueOrNotSpecified(item.value)}
          </Typography>
        ))}
      </Stack>
    </Paper>
  )
}

const valueOrNotSpecified = (value: any) => {
  const { t } = useTranslation()
  return value ? (
    value
  ) : (
    <span
      style={{
        textTransform: 'capitalize',
        color: 'gray',
      }}
    >
      {t('delivery.summary.not-specified')}
    </span>
  )
}

export default function Summary({ data, isRestitution }: SummaryProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userState } = useUserContext()
  console.log('data', data)
  if (!data || !userState) return null
  const isAdmin = userState['custom:role'] === 'ADMIN'
  const mondataire: {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  } = {
    firstName: data.representativeFirstName,
    lastName: data.representativeLastName,
    phoneNumber: data.representativePhone,
    email: data.representativeEmail,
  }
  return (
    <Container maxWidth='xl' component='main' className='summary'>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.delivery-address')}
            data={[
              { key: t('delivery.summary.departure'), value: data.fromAddress?.address },
              { key: t('delivery.summary.arrival'), value: data.toAddress?.address },
            ]}
          />
        </Grid>

        {isRestitution && (
          <Grid item xs={12} xl={6}>
            <DataComponent
              title={t('delivery.summary.restitution-address')}
              data={[
                { key: t('delivery.summary.departure'), value: data.toReturnFromAddress?.address },
                { key: t('delivery.summary.arrival'), value: data.toReturnToAddress?.address },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.agent-information')}
            data={[
              {
                key: t('delivery.summary.name'),
                value: `${mondataire.firstName} ${mondataire.lastName}`,
              },
              { key: t('common.form.input.phone'), value: mondataire.phoneNumber },
              { key: t('common.form.input.email'), value: mondataire.email },
            ]}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.client-information')}
            data={[
              {
                key: t('delivery.summary.name'),
                value: `${data?.clientFirstName} ${data?.clientLastName}`,
              },
              { key: t('common.form.input.phone'), value: data?.clientPhone },
              { key: t('common.form.input.email'), value: data?.clientEmail },
              { key: t('client.form.siret'), value: data?.clientSiret },
              { key: t('client.form.company'), value: data?.clientCompany },
              { key: t('driver.driving-licence-number'), value: data?.clientDrivingLicenceNumber },
            ]}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.availability')}
            data={[
              {
                key: t('delivery.summary.pick-up-date'),
                value: frenchTimeDate(data.carPickUpDate!),
              },
              {
                key: t('delivery.summary.availability-date'),
                value: frenchTimeDate(data.carAvailabilityDate!),
              },
              {
                key: t('delivery.summary.arrival-date'),
                value: frenchTimeDate(data.carDeliveryDeadline!),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.delivered-vehicle-information')}
            data={[
              { key: 'VIN', value: data?.vehicles[0]?.vin },
              {
                key: t('delivery.form.vehicle.licence-plate'),
                value: data?.vehicles[0]?.vehicleCode,
              },
              { key: t('delivery.form.vehicle.brand'), value: data?.vehicles[0]?.marque },
              { key: t('delivery.form.vehicle.model'), value: data?.vehicles[0]?.model },
              {
                key: t('delivery.form.vehicle.motorization.title'),
                value: data?.vehicles[0]?.carType,
              },
            ]}
          />
        </Grid>
        {isRestitution && (
          <Grid item xs={12} xl={6}>
            <DataComponent
              title={t('delivery.summary.restituted-vehicle-information')}
              data={[
                { key: 'VIN', value: data?.vehicles[1]?.vin },
                {
                  key: t('delivery.form.vehicle.licence-plate'),
                  value: data?.vehicles[1]?.vehicleCode,
                },
                { key: t('delivery.form.vehicle.brand'), value: data?.vehicles[1]?.marque },
                { key: t('delivery.form.vehicle.model'), value: data?.vehicles[1]?.model },
                {
                  key: t('delivery.form.vehicle.motorization.title'),
                  value: data?.vehicles[1]?.carType,
                },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.additional-options')}
            data={
              data?.options['selected']?.length > 0
                ? data?.options['selected']?.map((option, index) => {
                    if (option?.id) {
                      return {
                        key: `- ${option?.name} : ${option?.price.toFixed(2)} €   `,
                        value:
                          option?.photo?.length > 0 ? (
                            <Tooltip title='Voir Photo du option'>
                              <IconButton onClick={() => window.open(option?.photo)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ' '
                          ),
                      }
                    } else if (option?.photo?.length > 0) {
                      return {
                        key: `Photo ${index + 1}`,
                        value: (
                          <Tooltip title='Voir Photo du option'>
                            <IconButton onClick={() => window.open(option?.photo)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }
                    }
                  })
                : [{ key: ' ', value: t('delivery.summary.no-options') }]
            }
          />
        </Grid>
        {data.selectedDriver && (
          <Grid item xs={12} xl={6}>
            <DataComponent
              title={t('delivery.summary.driver-information')}
              data={[
                {
                  key: t('delivery.summary.name'),
                  value: data?.selectedDriver?.firstName + ' ' + data?.selectedDriver?.lastName,
                },
                { key: t('common.form.input.phone'), value: data?.selectedDriver?.phoneNumber },
                { key: t('common.form.input.email'), value: data?.selectedDriver?.email },
                isAdmin && {
                  key: ' ',
                  value: (
                    <MainButton
                      style={{ height: 'fit-content' }}
                      onClick={() =>
                        navigate(
                          `/admin/drivers/drivers-table/accepted-requests/${data?.selectedDriver?.id}`,
                        )
                      }
                    >
                      {t('delivery.summary.show-more')}
                    </MainButton>
                  ),
                },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.summary.mission-category')}
            data={[
              {
                key: ' ',
                value: categories[data?.category],
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <DataComponent
            title={t('delivery.form.comment')}
            data={[
              {
                key: ' ',
                value: data?.comment,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
