import { Grid, Divider, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import DeliveryPrice from '../DeliveryPrice'
import AlbInput from '@components/common/AlbInput'
import Options from '@components/common/AlbInput/options'
import TotalPrice from './TotalPrice'
import { useTranslation } from 'react-i18next'

type category = {
  value: string
  name: string
}

type TMissionDetails = {
  isRestitution?: boolean
  type: string
}

const MissionDetails = ({ isRestitution = false, type }: TMissionDetails) => {
  const is0Km = type === 'okm'
  const { t } = useTranslation()
  const categories: category[] = is0Km
    ? [{ value: 'OKM', name: t('delivery.form.category.okm') }]
    : [
        { value: 'STANDARD', name: t('delivery.form.category.standard') },
        { value: 'EXPRESS', name: t('delivery.form.category.express') },
      ]
  const { setValue, control } = useFormContext()

  const deliveryDetails = useWatch({
    control,
    name: [
      'fromAddress',
      'toAddress',
      'category',
      'numberOfVehicles',
      'options',
      'vehicles.0',
      ...(isRestitution ? ['toReturnFromAddress', 'toReturnToAddress', 'vehicles.1'] : []),
    ],
  })

  useEffect(() => {
    if (is0Km) {
      setValue('category', 'OKM')
      categories.push({ value: 'OKM', name: t('delivery.form.category.okm') })
    }
  }, [is0Km])

  const deliveryData = [
    {
      fromAddress: deliveryDetails[0],
      toAddress: deliveryDetails[1],
      category: deliveryDetails[2],
      numberOfVehicles: deliveryDetails[3],
      options: deliveryDetails[4],
      vehicle: deliveryDetails[5],
    },
  ]

  if (isRestitution) {
    deliveryData.push({
      fromAddress: deliveryDetails[6],
      toAddress: deliveryDetails[7],
      category: deliveryDetails[2], // Same category
      numberOfVehicles: deliveryDetails[3], // Same number of vehicles
      options: { selected: [] },
      vehicle: deliveryDetails[8],
    })
  }

  return (
    <Grid container direction={'row'} spacing={4}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform='capitalize'>
          {t('delivery.form.price-and-options')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 2 }} />
      </Grid>
      <Grid container item xs={12} md={isRestitution ? 12 : 6}>
        <AlbInput
          isEditAllowed={!is0Km}
          name='category'
          label='Categorie de la mission'
          type='SelectField'
          options={categories}
        />

        <Options />
      </Grid>
      <Grid item xs={12} md={6}>
        <DeliveryPrice {...{ ...deliveryData[0], title: t('delivery.form.delivery-details') }} />
      </Grid>
      {isRestitution && (
        <Grid item xs={12} md={6}>
          <DeliveryPrice
            {...{ ...deliveryData[1], title: t('delivery.form.restitution-details') }}
          />
        </Grid>
      )}
      {isRestitution && <TotalPrice {...{ deliveryDetails: deliveryData }} />}
    </Grid>
  )
}

export default MissionDetails
