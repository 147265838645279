import { DeliveryStatus } from '@types_def/models/delivery.types'

export function addRoutes(
  map,
  data: {
    index: number
    color?: string
    weight?: number
    origin: google.maps.LatLngLiteral
    destination: google.maps.LatLngLiteral
  },
) {
  const { color = '#00244f', weight = 4, origin, destination, index = 1 } = data
  const directionsService = new google.maps.DirectionsService()
  const directionsRenderer = new google.maps.DirectionsRenderer({
    routeIndex: index,
    suppressMarkers: true,
    // preserveViewport: true,
    polylineOptions: {
      strokeWeight: weight,
      strokeColor: color,
    },
  })
  directionsRenderer.setMap(map)
  directionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
    },
    function (result, status) {
      if (status == 'OK') {
        directionsRenderer.setDirections(result)
      }
    },
  )
  return directionsRenderer
}

export const isOnGoing = (status: DeliveryStatus) =>
  [
    DeliveryStatus.PICKUP_DRIVER,
    DeliveryStatus.ONGOING,
    DeliveryStatus.DELIVERED,
    DeliveryStatus.PICKUP_CLIENT,
    DeliveryStatus.SWAP_PICKUP_DRIVER,
    DeliveryStatus.SWAP_IN_TRANSIT,
    DeliveryStatus.SWAP_PICKUP_CLIENT,
    DeliveryStatus.SWAP_DELIVERED,
  ].includes(status)
