import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import AlbInput from '@components/common/AlbInput'
import { Delivery, DeliveryType } from '@types_def/models/delivery.types'
import { DialogContentText, Grid, Typography } from '@mui/material'
import Slider from '../PriceSlider'
import publishDeliverySchema from '@schemas/DeliveryForm/publishDelivery'
import CustomModal from '@components/common/CustomModal'
import { useTranslation } from 'react-i18next'

type Props = {
  data: Delivery
  drivers: {
    label: string
    value: number
  }[]
  onSubmit: (data: Delivery) => void
}

const CreateDeliveryDealForm = ({ data, drivers, onSubmit }: Props) => {
  const { t } = useTranslation()
  const methods = useForm<Delivery>({
    resolver: zodResolver(publishDeliverySchema),
    defaultValues: {
      ...data,
      clientFinalPrice: data.clientFinalPrice ? data.clientFinalPrice : data.clientInitialPrice,
      selectedDriver: drivers.find((driver) => driver.value === data.selectedDriverId) ?? undefined,
    },
  })
  const minPrice = useWatch({
    control: methods.control,
    name: 'minPrice',
  })
  const maxPrice = useWatch({
    control: methods.control,
    name: 'maxPrice',
  })

  const wantedPrice = useWatch({
    control: methods.control,
    name: 'wantedPrice',
  })

  const clientFinalPrice = useWatch({
    control: methods.control,
    name: 'clientFinalPrice',
  })

  const selectedDriver = useWatch({
    control: methods.control,
    name: 'selectedDriver',
  })

  useEffect(() => {
    if (clientFinalPrice !== data.clientFinalPrice) {
      methods.setValue('minPrice', Number(Number(clientFinalPrice * 0.3).toFixed(2)))
      methods.setValue('maxPrice', Number(Number(clientFinalPrice * 0.55).toFixed(2)))
      methods.setValue('wantedPrice', Number(Number(clientFinalPrice * 0.425).toFixed(2)))
    }
  }, [clientFinalPrice])

  useEffect(() => {
    if (!wantedPrice) {
      methods.setValue('minPrice', Number(Number(clientFinalPrice * 0.3).toFixed(2)))
      methods.setValue('maxPrice', Number(Number(clientFinalPrice * 0.55).toFixed(2)))
      methods.setValue('wantedPrice', Number(Number(clientFinalPrice * 0.425).toFixed(2)))
    }
  }, [wantedPrice, clientFinalPrice])

  const dateFields = [
    {
      name: 'carAvailabilityDate',
      label: t('delivery.form.car-availability-date'),
      greaterThan: null,
    },
    {
      name: 'carPickUpDate',
      label: t('delivery.form.car-pick-up-date'),
      greaterThan: 'carAvailabilityDate',
    },
    {
      name: 'carDeliveryDeadline',
      label: t('delivery.form.car-delivery-deadline'),
      greaterThan: 'carPickUpDate',
    },
  ]

  return (
    <FormProvider {...methods}>
      <Grid container spacing={4} component={'form'}>
        <Grid item xs={6}>
          <AlbInput
            label='Envoyer à un driver'
            name='selectedDriver'
            type='Autocomplete'
            options={drivers}
            variant='outlined'
          />
        </Grid>

        {dateFields.map((dateField) => (
          <Grid item xs={6} key={dateField.name}>
            <AlbInput
              variant='standard'
              name={dateField.name}
              label={''}
              type='DateTimeField'
              disablePast={true}
              greaterThan={dateField.greaterThan}
            />
          </Grid>
        ))}
        <Grid item xs={6}>
          <h3 style={{ marginBottom: 10 }}>Amplitude des enchères driver </h3>
          <Slider
            min={minPrice}
            max={maxPrice}
            wantedPrice={wantedPrice}
            setWantedPrice={(value) => {
              methods.setValue('wantedPrice', value)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: 25 }}>
            Prix proposé au client{' '}
            {data.type === DeliveryType.TWO_WAY_DELIVERY ? (
              <Typography component={'span'} variant='body1' color={'red'}>
                (Prix Restitution Inclus)
              </Typography>
            ) : null}
          </h3>
          <div
            style={{
              display: 'flex',
              gap: 10,
              background: 'var(--primary-color)',
              padding: 10,
              paddingInline: 40,
              color: 'white',
              fontWeight: 'bold',
              fontSize: '2rem',
              borderRadius: 20,
            }}
          >
            <input
              style={{
                background: 'transparent',
                border: 'unset',
                outline: 'unset',
                color: 'white',
                width: '100%',
              }}
              value={clientFinalPrice}
              onChange={(e) => {
                if (e.target.value === '.' || Number(e.target.value) >= 0)
                  methods.setValue('clientFinalPrice', e.target.value as unknown as number)
              }}
              id='prix-proposé-au-client'
            />
            <p>Euro</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <CustomModal
            type='submit'
            fullWidth={true}
            button={'Envoyer le deal'}
            confirmText='Confirmer'
            title='Envoyer le deal'
            color='success'
            confirmAction={methods.handleSubmit(onSubmit)}
          >
            <DialogContentText id='alert-dialog-description'>
              Vous êtes sur le point d'envoyer le deal{' '}
              <strong>
                {' '}
                {selectedDriver
                  ? (selectedDriver as unknown as { label: string }).label
                  : 'au marche'}
              </strong>
              , êtes-vous sûr de vouloir continuer ?
            </DialogContentText>
          </CustomModal>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default CreateDeliveryDealForm
