import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AdminDeliveredDeliveryDetailsActionCell,
  ContractCell,
  DeliveryStatusCell,
  LoginCell,
  LogoutCell,
  MarginPriceCell,
  OrganizationCell,
  PriceCell,
} from '../CustomCells'

export const DriverHistoryHeadCell: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Arrivée',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Départ',
    customCellComponent: LogoutCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'finalPrice',
    label: 'Prix Final Driver',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
  {
    id: 'finalPrice',
    label: 'Margin',
    customCellComponent: MarginPriceCell,
    isNotSortable: true,
    passWholeObject: true,
  },
  {
    id: 'clientFinalPrice',
    label: 'Montant HT Client',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'status',
    label: 'status',
    isNotSortable: false,
    customCellComponent: DeliveryStatusCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: AdminDeliveredDeliveryDetailsActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
