import { useEffect, useMemo, useState } from 'react'
import { generateInitialDelivery } from '@components/Delivery/DeliveryFormV2/Querys'
import { useParams } from 'react-router-dom'

import { Delivery } from '@types_def/models/delivery.types'
import { useUserContext } from '@contexts/UserContext/index.context'
import { SchemaKeys, generateValidationFieldsObject } from '@utils/functions/Delivery/schema'
import StepComponent from '@components/Delivery/DeliveryFormV2/Steps'
import { useTranslation } from 'react-i18next'

const NewDelivery = () => {
  const { t } = useTranslation()
  const { type } = useParams<{
    type?: SchemaKeys
  }>()
  if (!type) throw new Error('Missing type param')
  const userState = useUserContext().userState
  if (!userState) throw new Error('Missing userState')
  const settings = userState.settings

  const [steps, setSteps] = useState([1, 2, 3, 4])
  const validationFields = generateValidationFieldsObject(type, settings)
  const formId = `new-delivery-form_${type}`

  useEffect(() => {
    if (['standard', 'to-return', 'okm'].includes(type)) {
      setSteps([1, 2, 3])
    } else {
      setSteps([1, 2, 3, 4])
    }
  }, [type])

  const initialData = generateInitialDelivery(type)

  const localData = useMemo(() => {
    const _formId = sessionStorage.getItem(formId)
    const localData: Delivery = _formId ? JSON.parse(_formId) : null

    return localData
  }, [formId])

  const data = localData || initialData
  return (
    <StepComponent
      title={t('common.shared.new-delivery')}
      type={type}
      formId={formId}
      stepsInputs={validationFields}
      steps={steps}
      data={data}
    />
  )
}

export default NewDelivery
