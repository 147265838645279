import { gql } from '@apollo/client'
import { type DateTime } from 'luxon'

import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type DeliveryStatus } from '@types_def/models/delivery.types'

import { graphqlQuery } from '../../clients/apollo'
type DeliveryStatistic = Array<{
  name: DeliveryStatus
  amount: number
}>

type GetDeliveryChartDatesInput = {
  fromDate: DateTime | undefined
  toDate: DateTime | undefined
}

export async function getDeliveryStatisticsByType(
  GetDeliveryChartDatesInput: GetDeliveryChartDatesInput,
): Promise<DeliveryStatistic> {
  try {
    const res = await graphqlQuery<
      ApiGetResponse<DeliveryStatistic>,
      { GetDeliveryChartDatesInput: GetDeliveryChartDatesInput }
    >(
      gql`
        query getDeliveryStatisticsByType(
          $GetDeliveryChartDatesInput: GetDeliveryChartDatesInput!
        ) {
          data: getDeliveryStatisticsByType(
            GetDeliveryChartDatesInput: $GetDeliveryChartDatesInput
          ) {
            name
            amount
          }
        }
      `,
      { GetDeliveryChartDatesInput },
    )
    return res.data.data ?? []
  } catch (error) {
    console.error(error)
    throw new Error((error as Error).message)
  }
}

export default getDeliveryStatisticsByType
