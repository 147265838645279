import { type PropsWithChildren } from 'react'

import CalendarIcon from '@components/common/icons/calendar.icon'
import StatsIcon from '@components/common/icons/stats.icon'
import { AdminDeliveryStats } from '@types_def/models/delivery.types'

import styles from './index.module.scss'

type Props = {
  title: string
  type: AdminDeliveryStats
}

const AdminDashboardDataBox = ({ type, title, children }: PropsWithChildren<Props>) => (
  <div className={`${styles.dashboardDataBox} item`}>
    <div className={`${styles.dashboardDataBoxTitle}`}>
      {type == AdminDeliveryStats.ByStatus && <CalendarIcon />}
      {type == AdminDeliveryStats.ByType && <StatsIcon />}
      {title}
    </div>

    {children}
  </div>
)

export default AdminDashboardDataBox
