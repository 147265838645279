import { Checkbox, FormControlLabel, Grid, Stack } from '@mui/material'
import React from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { SelectedOption } from '@types_def/models/delivery.types'
import { extraOptions } from '@utils/constants/deliveryOptions'
import { useTranslation } from 'react-i18next'

type Props = {
  isEditAllowed?: boolean
}

const Options = ({ isEditAllowed = true }: Props) => {
  const { t } = useTranslation()
  const { control, getValues, setValue } = useFormContext()
  const { field } = useController({
    control,
    name: 'options',
  })

  const options = useWatch({ name: 'options' })
  return (
    <>
      {extraOptions.map(({ id, name, price }, index) => (
        <Grid item xs={12} key={id}>
          <FormControlLabel
            sx={{
              width: '100%',
              '& .MuiFormControlLabel-label': {
                width: '100%',
              },
            }}
            control={
              <Checkbox
                disabled={!isEditAllowed}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const currentOptions = getValues(field.name)
                  const newSelected = event.target.checked
                    ? [...currentOptions?.selected, extraOptions[index]]
                    : currentOptions?.selected?.filter((idx: SelectedOption) => idx.id !== id)

                  setValue(field.name, {
                    selected: newSelected,
                  })
                }}
                checked={Boolean(options.selected.find((idx: SelectedOption) => idx.id === id))}
              />
            }
            label={
              <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                <span style={{ textTransform: 'uppercase' }}>{t(`delivery.services.${name}`)}</span>
                <span>{price} € TTC</span>
              </Stack>
            }
          />
        </Grid>
      ))}
    </>
  )
}

export default Options
