import React, { useEffect, useState } from 'react'
import { useDeliveryDetails } from '@components/Delivery/DeliveryFormV2/Querys'
import { useParams } from 'react-router-dom'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { UpdateDeliveryQueryFields } from '@components/Delivery/DeliveryFormV2/Querys/queryFields'
import { strToEnum } from '@utils/strEnum'
import { useUserContext } from '@contexts/UserContext/index.context'
import StepComponent from '@components/Delivery/DeliveryFormV2/Steps'
import { SchemaKeys, generateValidationFieldsObject } from '@utils/functions/Delivery/schema'
import { useTranslation } from 'react-i18next'

const EditDelivery = () => {
  const { t } = useTranslation()
  const { type, status } = useParams<{
    type?: SchemaKeys
    status?: DeliveryStatus
  }>()
  if (!type || !status) {
    return null
  }
  const deliveryType = strToEnum<DeliveryType>(type)
  const settings = useUserContext().userState.settings
  const stepsInputs = generateValidationFieldsObject(type, settings)
  const fields = {
    ...UpdateDeliveryQueryFields[deliveryType],
  }
  const { data, isLoading, isError, error } = useDeliveryDetails(fields, ['edit', type, status])
  const [steps, setSteps] = useState([1, 2, 3, 4])
  const formId = `edit-delivery-form_${type}_${data?.id}}`

  useEffect(() => {
    if (['standard', 'to-return', 'okm'].includes(type)) {
      setSteps([1, 2, 3])
    } else {
      setSteps([1, 2, 3, 4])
    }
  }, [type])
  if (isLoading) return <span>Loading...</span>
  //@ts-ignore
  if (isError) return <span>Error: {error?.message}</span>
  return (
    <StepComponent
      type={type}
      formId={formId}
      stepsInputs={stepsInputs}
      steps={steps}
      data={data}
      title={t('common.shared.edit-delivery') + ' ' + data?.id}
    />
  )
}

export default EditDelivery
