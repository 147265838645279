const HourglassIcon = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 0C11.5531 0 12 0.447812 12 1C12 1.55219 11.5531 2 11 2V2.34313C11 3.66875 10.4719 4.94063 9.53438 5.87813L7.41563 8L8.5375 9.09688C8.19375 9.81563 8 10.65 8 11.5C8 13.3594 8.925 15.0031 10.3375 16H1C0.447812 16 0 15.5531 0 15C0 14.4469 0.447812 14 1 14V13.6562C1 12.3313 1.52688 11.0594 2.46438 10.1219L4.58437 8L2.46438 5.87813C1.52688 4.94063 1 3.66875 1 2.34313V2C0.447812 2 0 1.55219 0 1C0 0.447812 0.447812 0 1 0H11ZM3.47187 4H8.5C8.825 3.5125 9 2.93688 9 2.34313V2H3V2.34313C3 2.93688 3.175 3.5125 3.47187 4ZM18 11.5C18 13.9844 15.9844 16 13.5 16C11.0156 16 9 13.9844 9 11.5C9 9.01562 11.0156 7 13.5 7C15.9844 7 18 9.01562 18 11.5ZM12.9719 9.5V11.5C12.9719 11.775 13.225 12 13.4719 12H15C15.275 12 15.5 11.775 15.5 11.5C15.5 11.225 15.275 11 15 11H13.9719V9.5C13.9719 9.225 13.775 9 13.4719 9C13.225 9 12.9719 9.225 12.9719 9.5Z'
      fill='#fff'
    />
  </svg>
)

export default HourglassIcon
