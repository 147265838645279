import { DateTime } from 'luxon'
import { gql } from '@apollo/client'

import { graphqlQuery } from '@api/clients/apollo'
import { type ApiGetResponse } from '@types_def/common/auth.types'

import {
  type Delivery,
  DeliveryStatus,
  CreateDeliveryInput,
} from '@types_def/models/delivery.types'
import type Driver from '@types_def/models/driver.types'

import update from '../generic/update'
import { assignDelivery } from './assignDelivery'

export const setDelivery = async (
  id: number,
  clientFinalPrice: number,
  wantedPrice: number,
  carAvailabilityDate: DateTime,
  carPickUpDate: DateTime,
  carDeliveryDeadline: DateTime,
  selectedDriverId: number | undefined,
) => {
  if (selectedDriverId) {
    return assignDelivery({
      id,
      driverId: selectedDriverId,
      wantedPrice,
      status: DeliveryStatus.SET,
      carAvailabilityDate,
      carPickUpDate,
      carDeliveryDeadline,
      isEditable: true,
      clientFinalPrice,
    })
  }
  const res = await graphqlQuery<ApiGetResponse<Delivery>, { id: number }>(
    gql`
      mutation {
        data: allowDelivery(
          AdminAllowDeliveryInput: {
            id: ${id}
            isEditable: ${true}
            clientFinalPrice: ${clientFinalPrice}
            wantedPrice: ${wantedPrice}
            putInAuctionAt: "${new Date(Date.now())}"
            status: ${DeliveryStatus.WAITING_FOR_DRIVER}
            carAvailabilityDate: "${carAvailabilityDate}"
            carPickUpDate: "${carPickUpDate}"
            carDeliveryDeadline: "${carDeliveryDeadline}"
          }
        ) {
          id
        }
      }
    `,
  )
  if (res?.data?.data) {
    return res?.data?.data
  }

  throw res?.errors[0]?.message
}

export const updateDeliveryStatus = async (id: number, status: DeliveryStatus) => {
  const updatedDeliveryResponse = await update<Delivery>(
    { id, status, isEditable: true, confirmedAt: DateTime.now() },
    'Delivery',
    { id: true, status: true, publicId: true },
  )
  return updatedDeliveryResponse
}

export const adminUpdateDeliveryForm = async (id: number, data: CreateDeliveryInput) => {
  const updatedDeliveryResponse = await update<CreateDeliveryInput & { id: number }>(
    { ...data, id, isEditable: true },
    'Delivery',
    {
      id: true,
      status: true,
      publicId: true,
    },
  )
  return updatedDeliveryResponse
}
