import type React from 'react'
import { useEffect, useState } from 'react'

import getUserInfo from '@api/auth/getUserInfo'
import { type UserContextData } from '@types_def/models/user.type'

import { UserContext } from './index.context'

const UserContextProvider = ({ children }: { children: React.ReactNode | React.ReactElement }) => {
  const [userState, setUserState] = useState<UserContextData>()
  const [keyword, setKeyword] = useState('')

  const handleSetUser = (user: UserContextData | undefined) => {
    setUserState(user)
  }

  const initUserInfo = async () => {
    const { success, data } = await getUserInfo()
    if (success && data) {
      setUserState({ ...data, connected: true })
    } else {
      setUserState((previous) => ({ ...previous, connected: false }))
    }
  }

  useEffect(() => {
    initUserInfo()
  }, [])

  return (
    <UserContext.Provider value={{ userState, handleSetUser, keyword, setKeyword }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
