const ALL_FIELDS = {
  id: true,
  sub: true,
  email: true,
  firstName: true,
  lastName: true,
  status: true,
  createdAt: true,
  updatedAt: true,
  rank: true,
}

export { ALL_FIELDS }
