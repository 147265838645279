import { type FunctionComponent } from 'react'

type Props = {
  colspan: number
  message: string
}
export const NoDataRow: FunctionComponent<Props> = (props: Props) => {
  const { message, colspan } = props
  return (
    <tr>
      <td style={{ textAlign: 'center' }} colSpan={colspan}>
        {message}
      </td>
    </tr>
  )
}
