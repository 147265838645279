import { type QueryType } from '@types_def/common/query.types'

export const graphqlQueryFieldsBuilder = <T extends Record<string, unknown>>(
  input: QueryType<T>,
): string =>
  '{ \n ' +
  Object.entries(input)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0 && typeof value[0] === 'object') {
          return key + graphqlQueryFieldsBuilder(value[0] as QueryType<T>)
        }

        return ' ' + key + ' \n '
      }

      if (typeof value === 'object' && !(value instanceof Date)) {
        return key + graphqlQueryFieldsBuilder(value as QueryType<T>)
      }

      return ' ' + key + ' \n '
    })
    .join('\n') +
  '}'
