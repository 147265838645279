const ParkedCarIcon = () => (
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.5 0C9.32812 0 10 0.671562 10 1.5V4.2625C9.07812 4.64062 8.34062 5.40625 8.00937 6.39375L7.99687 6.40938C7.96562 6.19063 7.75625 6 7.5 6H6.5C6.225 6 6 6.225 6 6.5V7.5C6 7.775 6.225 8 6.5 8H7.475L7.3 8.52812C6.51562 9.06875 6 9.975 6 11V15H1.5C0.671562 15 0 14.3281 0 13.5V1.5C0 0.671562 0.671562 0 1.5 0H8.5ZM2.5 6C2.22375 6 2 6.225 2 6.5V7.5C2 7.775 2.22375 8 2.5 8H3.5C3.775 8 4 7.775 4 7.5V6.5C4 6.225 3.775 6 3.5 6H2.5ZM2.5 3C2.22375 3 2 3.225 2 3.5V4.5C2 4.775 2.22375 5 2.5 5H3.5C3.775 5 4 4.775 4 4.5V3.5C4 3.225 3.775 3 3.5 3H2.5ZM6 4.5C6 4.775 6.225 5 6.5 5H7.5C7.775 5 8 4.775 8 4.5V3.5C8 3.225 7.775 3 7.5 3H6.5C6.225 3 6 3.225 6 3.5V4.5ZM2.5 9C2.22375 9 2 9.225 2 9.5V10.5C2 10.775 2.22375 11 2.5 11H3.5C3.775 11 4 10.775 4 10.5V9.5C4 9.225 3.775 9 3.5 9H2.5ZM11.3313 5H15.6687C16.7437 5 17.7 5.6875 18.0406 6.70937L18.8719 9.2C19.5375 9.52187 20 10.2063 20 11V15C20 15.5531 19.5531 16 19 16H18.5C17.9469 16 17.5 15.5531 17.5 15V14H9.5V15C9.5 15.5531 9.05313 16 8.5 16H8C7.44688 16 7 15.5531 7 15V11C7 10.2063 7.4625 9.52187 8.12813 9.2L8.95938 6.70937C9.3 5.6875 10.2563 4.97188 11.3313 4.97188V5ZM11.3313 7C11.1156 7 10.925 7.1375 10.8562 7.34062L10.3031 9H16.6969L16.1437 7.34062C16.075 7.1375 15.8844 7 15.6687 7H11.3313ZM9.5 12.25C9.91562 12.25 10.25 11.9156 10.25 11.5C10.25 11.0844 9.91562 10.75 9.5 10.75C9.08438 10.75 8.75 11.0844 8.75 11.5C8.75 11.9156 9.08438 12.25 9.5 12.25ZM17.5 10.75C17.0844 10.75 16.75 11.0844 16.75 11.5C16.75 11.9156 17.0844 12.25 17.5 12.25C17.9156 12.25 18.25 11.9156 18.25 11.5C18.25 11.0844 17.9156 10.75 17.5 10.75Z'
      fill='#fff'
    />
  </svg>
)

export default ParkedCarIcon
