import { QueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { deleteOfferGraphql } from '@api/graphql/offer/deleteOffer'
import { rejectOfferGraphql } from '@api/graphql/offer/rejectOffer'

const deleteOffer = async (data: { id: number; deliveryId: number }, queryClient: QueryClient) => {
  await deleteOfferGraphql({
    id: data.id,
  })
    .then((res) => {
      toast('Offre supprimée avec succès', {
        type: 'success',
        position: 'bottom-right',
      })
      queryClient.invalidateQueries({
        queryKey: ['Offers', { driver: true }, { deliveryId: data.deliveryId }],
      })
    })
    .catch((err) => {
      toast('Une erreur est survenue', {
        type: 'error',
        position: 'bottom-right',
      })
    })
}

const rejectOffer = async (data: { id: number; deliveryId: number }, queryClient: QueryClient) => {
  rejectOfferGraphql({
    id: data.id,
  })
    .then((res) => {
      toast('Offre rejetée avec succès', {
        type: 'success',
        position: 'bottom-right',
      })
      queryClient.invalidateQueries({
        queryKey: ['Offers', { driver: true }, { deliveryId: data.deliveryId }],
      })
    })
    .catch((err) => {
      toast('Une erreur est survenue', {
        type: 'error',
        position: 'bottom-right',
      })
    })
}

export { deleteOffer, rejectOffer }
