import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Doc } from '@types_def/models/delivery.types'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'

type Props = {
  docs: Doc[]
  disabled?: boolean
  deletedFiles?: Doc[]
  changeFileState?: (file: Doc, deleted: boolean) => void
}

const FilesGrid = ({ docs, changeFileState, disabled = false }: Props) => {
  const parsedDocs = typeof docs === 'string' ? JSON.parse(docs) : docs
  return (
    <>
      {docs?.length > 0 && (
        <Box
          component={'ul'}
          sx={{
            textDecoration: 'none',
            listStyle: 'none',
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          {parsedDocs?.map((file) => (
            <li
              key={file.name}
              id={'file'}
              style={{
                textAlign: 'center',
                width: 90,
                height: 100,
                display: 'grid',
                placeItems: 'center',
                position: 'relative',
              }}
            >
              {changeFileState ? (
                <IconButton
                  onClick={() => {
                    changeFileState(file, file?.deleted ? false : true)
                  }}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '24px',
                    width: '24px',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                  aria-label='delete'
                  disabled={disabled}
                >
                  {file?.deleted ? (
                    <SettingsBackupRestoreIcon fontSize='small' color={'success'} />
                  ) : (
                    <DeleteIcon fontSize='small' color={disabled ? 'disabled' : 'warning'} />
                  )}
                </IconButton>
              ) : null}

              <a href={file.url} download title={file.name}>
                {file.key.includes('.pdf') ? (
                  <img
                    src='https://upload.wikimedia.org/wikipedia/commons/4/42/Pdf-2127829.png'
                    alt='PDF Icon'
                    style={{ width: 65, opacity: file?.deleted ? 0.3 : 1 }}
                  />
                ) : (
                  <img
                    src='/images/adobe.png'
                    alt={file.name}
                    style={{ width: 65, opacity: file?.deleted ? 0.3 : 1 }}
                  />
                )}

                <Tooltip title={file.name}>
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: 60, // Adjust the width as needed
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      verticalAlign: 'middle',
                      fontSize: 12,
                      textAlign: 'center',
                    }}
                  >
                    {file.name}
                  </span>
                </Tooltip>
              </a>
            </li>
          ))}
        </Box>
      )}
    </>
  )
}

function AdobeIcon(props: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='800'
      height='800'
      version='1.1'
      viewBox='0 0 495 495'
      xmlSpace='preserve'
      {...props}
    >
      <path
        fill='#891C00'
        d='M247.5 0L247.5 40 455 40 455 455 247.5 455 247.5 495 495 495 495 0z'
      ></path>
      <path fill='#CD2A00' d='M40 455L40 40 247.5 40 247.5 0 0 0 0 495 247.5 495 247.5 455z'></path>
      <path
        fill='#CD2A00'
        d='M92.583 393.755l12.569 7.496c2.582 2.457 5.04 2.457 10.079 2.457 20.192 0 45.392-25.105 75.663-77.993 2.489-2.583 2.489-2.583 5.072-2.583 25.074-10.112 57.928-15.151 100.767-20.192 25.232 12.663 55.378 17.734 75.537 17.734 10.112 0 17.735-2.583 22.775-5.071 4.913-5.04 7.496-12.663 9.954-15.119 0-5.041 0-10.081-2.458-15.152-7.496-12.663-27.688-17.577-57.96-17.577-10.08 0-20.159 2.458-32.728 2.458-10.079-5.041-15.12-10.08-22.742-15.12-15.152-15.151-30.145-37.8-40.256-65.488v-2.584c10.111-32.729 17.607-73.08 0-90.688-5.041-2.583-10.08-5.04-15.12-5.04h-5.041c-10.11 0-17.734 10.111-20.19 20.192-10.08 35.185-5.04 52.92 5.039 83.191-7.496 22.616-15.119 47.848-27.688 73.079-10.08 17.577-17.702 32.729-25.231 45.266-10.079 5.071-17.576 10.112-22.617 12.695-30.273 17.575-45.424 40.223-48.007 52.887 0 5.072 0 7.528 2.583 12.569v2.583zm239.463-108.423h4.914c35.31 0 45.422 7.529 48.005 10.111 0 2.458 2.457 2.458 2.457 2.458v2.583h-7.529c-12.662 0-30.271-5.041-47.848-12.664v-2.488zM228.694 103.861c2.458 0 2.458-2.458 2.458-2.458l2.583 2.458v2.582c2.456 2.458 2.456 12.538 0 25.231v5.041c0 7.496-2.583 12.537-5.041 22.617-5.071-22.617-5.071-40.351 0-55.471zm-22.648 173.847c9.954-22.617 20.065-42.809 25.105-60.417 10.08 22.649 25.231 42.84 37.894 57.96 2.458 2.457 4.914 5.04 9.954 7.497-20.034 2.583-50.306 10.112-83.033 22.774h-5.072c5.072-10.08 10.112-17.703 15.152-27.814zm-65.582 63c2.583-2.456 7.497-5.04 12.536-7.497-14.993 30.271-30.145 45.392-45.266 47.881 2.458-7.529 15.12-25.233 32.73-40.384z'
      ></path>
    </svg>
  )
}

export default FilesGrid
