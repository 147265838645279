import { type GetBySubInput } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import type Admin from '@types_def/models/admin.types'
import type Client from '@types_def/models/client.types'
import type Driver from '@types_def/models/driver.types'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

import { gql, graphqlQuery } from '../../clients/apollo'

type ApiGetResponse<T> = {
  findBySub: T
}

async function getUserBySub<T extends Admin | Client | Driver>(
  sub: string,
  resource: 'Admin' | 'Client' | 'Driver',
  graphQlFieldsObject: QueryType<T>,
): Promise<T> {
  try {
    const res = await graphqlQuery<ApiGetResponse<T>, GetBySubInput>(
      gql`
        query ($getBySubInput: GetBySubInput!) {
         findBySub: find${resource}BySub(GetBySubInput: $getBySubInput) 
            ${graphqlQueryFieldsBuilder(graphQlFieldsObject)}
          
        }
      `,
      {
        getBySubInput: { sub },
      },
    )
    return res.data.findBySub
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export default getUserBySub
