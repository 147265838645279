import ListIcon from '@components/common/icons/list.icon'
import PersonIcon from '@components/common/icons/person.icon'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'

import LinksSubHeader from '..'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { useTranslation } from 'react-i18next'
import CustomModal from '@components/common/CustomModal'
import AddAdminForm from '@forms/addAdmin'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

const AdminSubHeader = () => {
  const { t } = useTranslation()
  return (
    <LinksSubHeader>
      <CustomModal
        cancelText={t('common.actions.cancel')}
        confirmText={t('common.actions.submit')}
        color='primary'
        button={
          <>
            <PersonAddAltIcon /> {t('layouts.header.new-manager')}
          </>
        }
        formId='addAdmin'
      >
        <AddAdminForm />
      </CustomModal>
      <NavigationButton to={RoutePaths.adminManageAdmins} includes={RoutePaths.admins}>
        <AdminPanelSettingsIcon />
        {t('layouts.sub-headers.managers.super-admins')}
      </NavigationButton>
      <NavigationButton to={RoutePaths.adminManageMaintainer} includes={RoutePaths.maintainers}>
        <EngineeringIcon />
        {t('layouts.sub-headers.managers.maintainers')}
      </NavigationButton>
      <NavigationButton to={RoutePaths.adminManageSupport} includes={RoutePaths.support}>
        <SupportAgentIcon />
        {t('layouts.sub-headers.managers.assistants')}
      </NavigationButton>
    </LinksSubHeader>
  )
}

export default AdminSubHeader
