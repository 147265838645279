import { type HeadCell } from '@components/common/table/EnhancedTableHead'

import { DateCell } from '../CustomCells/DateCell'
import { DriverNameCell } from '../CustomCells/NameCell'
import { PriceCell } from '../CustomCells/PriceCell'
import { Delivery } from '@types_def/models/delivery.types'
import {
  ContractCell,
  OrganizationCell,
  PilotActionCell,
} from '@components/Delivery/DeliveryTable/CustomCells'
import AdminInvoiceAction from '@components/Delivery/DeliveryTable/CustomCells/AdminInvoiceAction'

export const headCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'deliveryOrganization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'accepted_offer',
    label: 'Driver',
    passWholeObject: false,
    isNotSortable: false,
    customCellComponent: DriverNameCell,
  },
  {
    id: 'completedAt',
    label: 'Date',
    customCellComponent: DateCell,
  },
  {
    id: 'finalPrice',
    label: 'Montant HT',
    isNotSortable: false,
    passWholeObject: true,
    customCellComponent: PriceCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: AdminInvoiceAction,
    passWholeObject: true,
    isNotSortable: true,
  },
]
