import { type DateTime } from 'luxon'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { downloadDeliveryInvoices } from '@api/Rest/download/deliveryInvoicesDownload'
import MainButton from '@components/common/Button'
import { GenericTable } from '@components/common/table'
import DateFilter from '@components/common/table/DateFilter'
import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import {
  type DeepInclude,
  type PrismaFilter,
  type WhereFilter,
} from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { type DeliveryPayment } from '@types_def/models/deliveryPayment.types'

import { headCells } from './HeadCells/index'

type TableState = {
  filter?: WhereFilter<DeliveryPayment>
  headCells: Array<HeadCell<DeliveryPayment>>
}

const DeliveryInvoicesTable = () => {
  const [tableState, setTableState] = useState<TableState>({
    filter: {},
    headCells,
  })

  const navigate = useNavigate()
  const isSubscription = useLocation().pathname.includes('subscription')

  const handleDateChange = (
    date: DateTime | undefined,
    prop: keyof PrismaFilter<DeliveryPayment, 'createdAt'>,
  ) => {
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        createdAt: {
          ...prev.filter?.createdAt,
          [prop]: date?.isValid
            ? prop == 'gte'
              ? date.set({ hour: 0, minute: 0 })
              : date.set({ hour: 24, minute: 60 })
            : undefined,
        },
      },
    }))
  }

  const SubscriptionPaymentButton = (
    <MainButton
      noFill={!isSubscription}
      onClick={() => {
        navigate('/admin/invoices/driver/subscription')
      }}
    >
      Subscription
    </MainButton>
  )

  const DeliveryPaymentButton = (
    <MainButton
      noFill={isSubscription}
      onClick={() => {
        navigate('/admin/invoices/driver/delivery')
      }}
    >
      Livraison
    </MainButton>
  )

  const dateLabel = <>Filtrer par période :</>

  const toDateFilter = (
    <DateFilter<DeliveryPayment>
      filter={tableState.filter!}
      dateField='createdAt'
      prismaFilter='lte'
      handleDateChange={handleDateChange}
    />
  )
  const fromDateFilter = (
    <DateFilter<DeliveryPayment>
      filter={tableState.filter!}
      dateField='createdAt'
      prismaFilter='gte'
      handleDateChange={handleDateChange}
    />
  )
  const filters = [
    SubscriptionPaymentButton,
    DeliveryPaymentButton,
    dateLabel,
    fromDateFilter,
    toDateFilter,
  ]

  const invoiceTableQueryFields: QueryType<DeliveryPayment> = {
    id: true,
    createdAt: true,
    amount: true,
    status: true,
    deliveryId: true,
    driver: {
      fullName: true,
    },
  }

  const invoiceInclude: DeepInclude<DeliveryPayment> = {
    driver: true,
  }

  return (
    <>
      {tableState.headCells.length > 0 && (
        <GenericTable<DeliveryPayment>
          resource='DeliveryPayments'
          graphqlQueryFieldsObject={invoiceTableQueryFields}
          headCells={tableState.headCells}
          filters={filters}
          filter={tableState.filter}
          include={invoiceInclude}
          exportHandler={() => {
            downloadDeliveryInvoices(tableState)
          }}
          isReadOnly={true}
        />
      )}
    </>
  )
}

export default DeliveryInvoicesTable
