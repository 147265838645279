import { z } from 'zod'
import { cancellationReasonKeys } from '@utils/constants/deliveryCancelReasons'
import errorMessages from '../errorMessages'

const CancelDeliverySchema = z
  .object({
    reason: z.enum(cancellationReasonKeys, {
      required_error: errorMessages.required,
      invalid_type_error: errorMessages.required,
    }),
    additionalInfo: z.string().optional(), // Optional field for additional notes
  })
  .refine(
    (data) => {
      // Enforce that 'additionalInfo' must be provided if the 'reason' is 'other'
      if (data.reason === 'other' && (!data.additionalInfo || data.additionalInfo.trim() === '')) {
        return false
      }
      return true
    },
    {
      message: errorMessages.required,
      path: ['additionalInfo'], // The path to the field that needs additional validation
    },
  )

type TCancelDelivery = z.infer<typeof CancelDeliverySchema>

export default CancelDeliverySchema
export type { TCancelDelivery }
