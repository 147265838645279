import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'

import { AdminDashboard } from './AdminDashboard'
import { ClientDashboard } from './ClientDashboard'

export const Dashboard = () => {
  const { userState } = useUserContext()
  const role = userState?.['custom:role']?.toLowerCase()

  return (
    <>
      {role === UserRole.CLIENT.toLowerCase() && <ClientDashboard />}

      {role === UserRole.ADMIN.toLowerCase() && <AdminDashboard />}
    </>
  )
}
