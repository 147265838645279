import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  MenuItem,
  Select,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { PhoneMask } from '@components/common/InputFormat'
import { CreateClientInput } from '@types_def/models/client.types'
import { MuiTelInput } from 'mui-tel-input'
import { CreateOrganizationInput } from '@types_def/models/Organization.types'
import ControlledInput from '@components/common/ControlledInput'
import { ranksToArrayObject } from '@utils/rankToRole'
import { UserRank } from '@types_def/models/user.type'

const Responsible = ({ title = 'Informations de Responsable' }) => {
  const {
    formState: { errors },
    getValues,
    control,
  } = useFormContext<CreateOrganizationInput>()
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneeFirstName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.owner?.assigneeFirstName)}
              helperText={errors.owner?.assigneeFirstName?.message}
              fullWidth
              variant='standard'
              label='Nom'
              InputLabelProps={
                Boolean(getValues('owner.assigneeFirstName')) ? { shrink: true } : {}
              }
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneeLastName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.owner?.assigneeLastName)}
              helperText={errors.owner?.assigneeLastName?.message}
              fullWidth
              variant='standard'
              label='Prénom'
              InputLabelProps={Boolean(getValues('owner.assigneeLastName')) ? { shrink: true } : {}}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneePhone'
          control={control}
          render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => (
            <MuiTelInput
              {...fieldProps}
              value={value ?? ''}
              inputRef={fieldRef}
              label='Téléphone'
              error={fieldState.invalid}
              variant='standard'
              InputLabelProps={Boolean(getValues('owner.assigneePhone')) ? { shrink: true } : {}}
              helperText={errors.owner?.assigneePhone?.message}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneeEmail'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.owner?.assigneeEmail)}
              helperText={errors.owner?.assigneeEmail?.message}
              fullWidth
              label='Email'
              variant='standard'
              InputLabelProps={Boolean(getValues('owner.assigneeEmail')) ? { shrink: true } : {}}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneeFunction'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.owner?.assigneeFunction)}
              helperText={errors.owner?.assigneeFunction?.message}
              fullWidth
              label='Fonction'
              variant='standard'
              InputLabelProps={Boolean(getValues('owner.assigneeFunction')) ? { shrink: true } : {}}
            />
          )}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Controller
          name='owner.assigneeService'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.owner?.assigneeService)}
              helperText={errors.owner?.assigneeService?.message}
              fullWidth
              label='Service'
              variant='standard'
              InputLabelProps={Boolean(getValues('owner.assigneeService')) ? { shrink: true } : {}}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth variant='standard'>
          <InputLabel id='level-label'>Rank</InputLabel>
          <ControlledInput
            name='assigneeRank'
            label={'rank'}
            defaultValue={UserRank.ORGANIZATION_OWNER}
            disabled={true}
          >
            <Select labelId='level-label'>
              {ranksToArrayObject([UserRank.ORGANIZATION_OWNER]).map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </ControlledInput>
        </FormControl>
      </Grid>
    </Grid>
  )
}
export default Responsible
