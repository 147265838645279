import { Avatar, Button, IconButton, Typography } from '@mui/material'
import React from 'react'
import { UserContextData, UserRole } from '@types_def/models/user.type'
import rankToRole from '@utils/rankToRole'
import MenuIcon from '@mui/icons-material/Menu'
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import { logout } from '@api/auth/login'
import { useUserContext } from '@contexts/UserContext/index.context'
import { useNavigate } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { RoutePaths } from '@utils/constants/routes'
import HelpIcon from '@mui/icons-material/Help'
import { t } from 'i18next'

type Props = {
  userState: UserContextData
}

const UserAvatar = ({ userState }: Props) => {
  const { handleSetUser } = useUserContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    handleSetUser({ connected: false })
    navigate('/login')
  }
  const [expanded, setExpanded] = React.useState(false)
  const rank = Number(userState?.['custom:rank']) ?? undefined
  if (!userState) return null

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 1,
        position: 'relative',
      }}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Avatar sizes='small' sx={{ bgcolor: 'secondary.main' }}>
          {userState.firstname?.charAt(0).toUpperCase() +
            userState.lastname?.charAt(0).toUpperCase()}
        </Avatar>

        <motion.span
          style={{
            marginInline: 10,
          }}
          initial={{
            width: 0,
            visibility: 'hidden',
          }}
          animate={{
            width: expanded ? 'auto' : 0,
            visibility: expanded ? 'visible' : 'hidden',
          }}
          transition={{
            duration: 0.5,
            delay: expanded ? 0 : 0.5,
          }}
        >
          <motion.span
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: expanded ? 1 : 0,
            }}
            transition={{
              delay: 0.5,
              duration: 0.5,
            }}
          >
            <Typography variant='body1' fontWeight={'bold'}>
              {userState.firstname} {userState.lastname}
            </Typography>
            <Typography variant='body2'>{rankToRole(rank)}</Typography>
          </motion.span>
        </motion.span>

        <IconButton>
          {!expanded ? (
            <MenuIcon fontSize='large' sx={{ fill: 'white' }} />
          ) : (
            <CloseIcon fontSize='large' sx={{ fill: 'white' }} />
          )}
        </IconButton>
      </span>
      <motion.span
        style={{
          marginTop: 11,
          position: 'absolute',
          top: '100%',
          right: 0,
          width: '100%',
          background: 'rgba(241,241,241,1)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          zIndex: 1000,
          borderRadius: 6,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'start',
          flexDirection: 'column',
          gap: 10,
          overflow: 'hidden',
        }}
        initial={{
          height: 0,
          padding: 0,
          paddingTop: 0,
        }}
        animate={{
          height: expanded ? 'max-content' : 0,
          padding: expanded ? 10 : 0,
          paddingTop: expanded ? 20 : 0,
        }}
        transition={{
          duration: 0.5,
          delay: !expanded ? 0 : 0.5,
        }}
      >
        {userState['custom:role'] === UserRole.CLIENT ? (
          <>
            <Button
              color='secondary'
              variant='outlined'
              startIcon={<AccountCircleIcon />}
              onClick={() =>
                navigate(
                  userState['custom:role'] === UserRole.ADMIN
                    ? RoutePaths.adminProfileAccount
                    : RoutePaths.clientProfileAccount,
                )
              }
              fullWidth
            >
              Profile
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              startIcon={<HelpIcon />}
              onClick={() => navigate(`/client/help`)}
              fullWidth
            >
              {t('layouts.header.support-faq')}
            </Button>
          </>
        ) : null}
        <Button
          color='secondary'
          variant='outlined'
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          fullWidth
        >
          Logout
        </Button>
      </motion.span>
    </span>
  )
}

export default UserAvatar
