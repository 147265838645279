import { gql, graphqlQuery } from '@api/clients/apollo'
import Admin from '@types_def/models/admin.types'
import { UserRank } from '@types_def/models/user.type'
import { ALL_FIELDS } from './queryFields'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'
import { QueryType } from '@types_def/common/query.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import update from '@api/graphql/generic/update'
import { toast } from 'react-toastify'
import { deleteOne } from '@api/graphql/generic/delete'
import { t } from 'i18next'

// GraphQL Queries and Mutations
const FIND_ALL_ADMINS = (fields: string) => gql`
  query FindAllAdmins($filter: AdminFilterInput!) {
    findAllAdmins(filter: $filter) ${fields}
  }
`

const UPDATE_ADMIN_MUTATION = gql`
  mutation UpdateAdmin($updateAdminInput: UpdateAdminInput!) {
    updateAdmin(updateAdminInput: $updateAdminInput) {
      id
    }
  }
`

// API Calls
const updateAdmin = async (id: number, data: Admin) => {
  try {
    return await update<Admin & { id: number }>({ id, ...data }, 'Admin', {
      id: true,
      createdAt: true,
      updatedAt: true,
    })
  } catch (error) {
    const errorMessage = error?.message?.includes('ApolloError:')
      ? error.message.replace('ApolloError: ', '')
      : error.message || 'An unknown error occurred'
    throw new Error(errorMessage)
  }
}

const deleteAdmin = async (id: number) => {
  return await deleteOne(id, 'Admin')
}

// Hooks
const useUpdateAdmin = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (data: Admin & { id: number }) => updateAdmin(data.id, data),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['admins', Number(data.rank)] })
      toast(t('network.managers.updated'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

const useDeleteAdmin = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (data: Admin) => deleteAdmin(data.id),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['admins', Number(data.rank)] })
      toast(t('network.managers.deleted'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

type TFilter = {
  filter: {
    rank: UserRank
  }
}

const findAllAdmins = async (filter: TFilter) => {
  type ApiGetResponse<T> = {
    findAllAdmins: T[]
  }
  const fields = graphqlQueryFieldsBuilder(ALL_FIELDS)
  const res = await graphqlQuery<ApiGetResponse<Admin>, TFilter>(FIND_ALL_ADMINS(fields), filter)
  return res.data.findAllAdmins
}

const useGetAdminsByRanks = (filter: TFilter) => {
  return useQuery({
    queryKey: ['admins', Number(filter.filter.rank)],
    queryFn: () => findAllAdmins(filter),
  })
}

export { useGetAdminsByRanks, useUpdateAdmin, useDeleteAdmin }
