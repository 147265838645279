import { InputContainer } from '../InputContainer'
import styles from './index.module.scss'

type Props = {
  type?: 'text' | 'number' | 'password'
  placeholder?: string
  required?: boolean
  alignLabel?: boolean
  disabled?: boolean
  identifier: string
  remark?: string
  value?: string | number
  label?: string
  min?: string | number
  max?: string | number
  onChange?: (value: string | number, identifier: string) => void
}

const SimpleInputControl = ({
  placeholder,
  label,
  type = 'text',
  disabled = false,
  alignLabel = false,
  required = false,
  onChange,
  remark = '',
  value,
  min,
  identifier,
  max,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value
    const eventValueResult = type === 'number' ? parseFloat(eventValue) : eventValue.trim()
    if (onChange) {
      onChange(eventValueResult, identifier)
    }
  }

  return (
    <InputContainer required={required} label={label} alignLabel={alignLabel}>
      <input
        type={type}
        placeholder={placeholder}
        value={value || ''}
        min={min}
        max={max}
        className={`${styles.input} item`}
        disabled={disabled}
        onChange={handleChange}
      />
      <small style={{ paddingTop: '.5em', textTransform: 'capitalize' }}>{remark}</small>
    </InputContainer>
  )
}

export default SimpleInputControl
