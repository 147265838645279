import { sendRawRequest } from '@api/clients/axios'

type SetNewPasswordInput = {
  username: string
}
type SendForgotPasswordCodeResponse = {
  CodeDeliveryDetails: CodeDeliveryDetails
}
type CodeDeliveryDetails = {
  Destination: string
  DeliveryMedium: string
  AttributeName: string
}

export default async function sendForgotPasswordCode(input: SetNewPasswordInput) {
  try {
    const res = await sendRawRequest<SetNewPasswordInput, SendForgotPasswordCodeResponse>(
      'post',
      '/auth/forgot-password',
      input,
    )
    const {
      data: { CodeDeliveryDetails },
    } = res

    if (CodeDeliveryDetails) {
      return { success: true, data: res.data }
    }
  } catch (error: unknown) {
    return { success: false }
  }
}
