import { sendRawRequest } from '@api/clients/axios'
import { type AxiosHttpError } from '@types_def/common/error.types'

export type TAdminResetPassword = {
  username: string
}
export const adminResetPassword = async (input: TAdminResetPassword) => {
  try {
    const res = await sendRawRequest<TAdminResetPassword, any>(
      'post',
      '/auth/admin-reset-password',
      input,
    )
    return { success: true, data: res.data }
  } catch (err: unknown) {
    const axiosError = err as AxiosHttpError
    const message = axiosError.response?.data.message
    return {
      success: false,
      message: typeof message === 'string' ? message : message?.join(' '),
    }
  }
}
