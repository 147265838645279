import { type FunctionComponent } from 'react'

import { type DeliveryPaymentStatus } from '@types_def/models/deliveryPayment.types'

import styles from './index.module.scss'

const statusText: Record<DeliveryPaymentStatus, string> = {
  PAID: 'Payé',
  CANCELLED: 'Annulé',
  PENDING: 'Transféré',
  FAILED: 'Échoué',
}

export const StatusCell: FunctionComponent = ({ children }) => {
  if (!children) {
    return <i>Error</i>
  }

  const status = children as DeliveryPaymentStatus
  return (
    <div className={styles.container}>
      <div className={[styles.circle, styles[status]].join(' ')}></div>
      <div>{statusText[status]}</div>
    </div>
  )
}
