import { type QueryType } from '@types_def/common/query.types'
import { ObjectWithConditionalValues } from '@utils/functions/conditionalValues'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

import { gql, graphqlMutation } from '../../clients/apollo'
import * as Sentry from '@sentry/react'

type ApiCreateResponse<T> = {
  created: T
}
async function create<T extends Record<string, unknown>>(
  createInput: T,
  resource: string,
  graphQlFieldsObject: QueryType<T>,
) {
  const graphqlQueryFields = graphqlQueryFieldsBuilder<T>(graphQlFieldsObject)
  // eslint-disable-next-line new-cap
  const params = ObjectWithConditionalValues<T>(createInput)
  try {
    const res = await graphqlMutation<
      ApiCreateResponse<T>,
      {
        createInput: T
      }
    >(
      gql`mutation create${resource}($createInput: Create${resource}Input!) {
          created:  create${resource}(Create${resource}Input: $createInput)
            ${graphqlQueryFields}   
        }`,
      {
        createInput: params,
      },
    )
    return res.data?.created
  } catch (error: unknown) {
    const id = Sentry.captureException(error, {
      extra: {
        resource,
        createInput,
        graphQlFieldsObject,
      },
    })
    throw new Error(error as string)
  }
}

export default create
