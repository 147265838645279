import { Chip } from '@mui/material'
import { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { type Offer } from '@types_def/models/offer.types'
import { RoutePaths } from '@utils/constants/routes'
//
export const DriverFirstNameCell: FunctionComponent = ({ children }) => {
  const { proposedByAdminId, proposedByDriver } = children as Offer
  const { firstName, lastName } = (children as Offer).driver
  const routePachAdminRank = RoutePaths.admins
  const driverLink = `/admin/drivers/drivers-table/accepted-requests/${(children as Offer).driver?.id}`
  const adminLink = `${RoutePaths.admin}${RoutePaths.manageAdmins}${routePachAdminRank}?focus=${proposedByAdminId}`

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Link to={proposedByDriver ? driverLink : adminLink}>
        {proposedByDriver ? (
          <Chip label={'DRIVER'} color='primary' />
        ) : (
          <Chip label={'ADMIN'} color='info' />
        )}
      </Link>

      <Link style={{ color: 'blue', textDecoration: 'underline' }} to={driverLink}>
        {firstName + ' ' + lastName}
      </Link>
    </div>
  )
}
