import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AddressCell,
  AdminPilotActionCell,
  ClientRequestedActionCell,
  ContractCell,
  DateCell,
  LoginCell,
  LogoutCell,
  OrganizationCell,
  PilotActionCell,
  PriceCell,
  SmsCell,
  ZeroKmCell,
} from '../CustomCells'

const RequestedHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'id',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Lieu de ramassage',
    customCellComponent: AddressCell,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Lieu de remise',
    customCellComponent: AddressCell,
    isNotSortable: true,
  },
  {
    id: 'clientLastName',
    label: 'Client',
    isNotSortable: false,
  },
]

const numberOfVehiclesCell: HeadCell<Delivery> = {
  id: 'numberOfVehicles',
  label: 'Nb. véhicules',
  isNotSortable: false,
}

const zeroKmOptionCell: HeadCell<Delivery> = {
  id: 'options',
  label: '0 km',
  customCellComponent: ZeroKmCell,
  isNotSortable: false,
}

// Const requestedActionCell: HeadCell<Delivery> = {
//   id: "id",
//   label: "Action",
//   customCellComponent: PilotActionCell,
//   passWholeObject: true,
//   isNotSortable: true,
// };

// export const ClientRequestedHeadCells: HeadCell<Delivery>[] = [
//   ...RequestedHeadCells,
//   {
//     id: "clientEstimatedDeliveryDate",
//     label: "Votre date de livraison estimée",
//     customCellComponent: DateCell,
//     isNotSortable: true,
//   },
//   numberOfVehiclesCell,
//   zeroKmOptionCell,
//   requestedActionCell,
// ];

export const AdminRequestedHeadCells: Array<HeadCell<Delivery>> = [
  ...RequestedHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Date de livraison estimée du client",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  // {
  //   id: 'status',
  //   label: 'SMS',
  //   customCellComponent: SmsCell,
  //   isNotSortable: false,
  // },
  // numberOfVehiclesCell,
  // zeroKmOptionCell,
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: AdminPilotActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]

export const ClientRequestedHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Arrivée',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Départ',
    customCellComponent: LogoutCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Il devait être livré à",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'clientInitialPrice',
    label: 'Montant HT',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: PilotActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
