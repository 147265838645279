import { Box, Button, Modal, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Admin from '@types_def/models/admin.types'
import { useState } from 'react'
import AdminForm from '../Form'
import { useUpdateAdmin } from '../Query'

type Props = {
  admin: Admin
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(95vw, 700px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 6,
  borderRadius: '10px',
}

const EditModal = ({ admin }: Props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { isLoading, mutate: updateAdmin } = useUpdateAdmin()
  return (
    <>
      <Button variant='contained' onClick={handleOpen}>
        <EditIcon fontSize='small' />
        Modifier
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='transition-modal-title'
            variant='h5'
            component='h2'
            sx={{
              color: 'var(--secondary-color)',
            }}
          >
            Modifier compte du gestionnaire:
          </Typography>
          <Typography id='transition-modal-description' sx={{ mt: 1, mb: 4 }}>
            Veuillez remplir les champs ci-dessous.
          </Typography>
          <AdminForm
            handleClose={handleClose}
            admin={admin}
            onSubmit={(data: Admin) => updateAdmin({ id: admin.id, ...data })}
            disaled={isLoading}
          />
        </Box>
      </Modal>
    </>
  )
}

export default EditModal
