import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'
import LinksSubHeader from '..'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import GroupsIcon from '@mui/icons-material/Groups'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRank } from '@types_def/models/user.type'

const ClientOrganizationSubHeader = () => {
  const { userState } = useUserContext()
  const { t } = useTranslation()
  return (
    <LinksSubHeader>
      <NavigationButton
        to={RoutePaths.clientOrganizationAddMember}
        includes={RoutePaths.organizationAddMember}
      >
        <PersonAddAltIcon />
        {t('layouts.sub-headers.organization.add-member')}
      </NavigationButton>
      {UserRank.ORGANIZATION_OWNER == userState['custom:rank'] ? (
        <NavigationButton
          to={RoutePaths.clientOrganizationListManager}
          includes={RoutePaths.organizationListManagers}
        >
          <GroupsIcon />
          {t('layouts.sub-headers.organization.managers')}
        </NavigationButton>
      ) : null}
      <NavigationButton
        to={RoutePaths.clientOrganizationListMember}
        includes={RoutePaths.organizationListMembers}
      >
        <GroupsIcon />
        {t('layouts.sub-headers.organization.members')}
      </NavigationButton>
    </LinksSubHeader>
  )
}

export default ClientOrganizationSubHeader
