const CarIcon = () => (
  <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.23781 5.15L2.3375 2.00906C2.75844 0.805625 3.89375 0 5.16875 0H10.8313C12.1062 0 13.2406 0.805625 13.6625 2.00906L14.7625 5.15C15.4875 5.45 16 6.16563 16 7V13C16 13.5531 15.5531 14 15 14H14C13.4469 14 13 13.5531 13 13V11.5H3V13C3 13.5531 2.55219 14 2 14H1C0.447812 14 0 13.5531 0 13V7C0 6.16563 0.51125 5.45 1.23781 5.15ZM3.40937 5H12.5906L11.775 2.66875C11.6344 2.26875 11.2563 2 10.8313 2H5.16875C4.74375 2 4.36562 2.26875 4.225 2.66875L3.40937 5ZM3 7C2.44781 7 2 7.44688 2 8C2 8.55313 2.44781 9 3 9C3.55312 9 4 8.55313 4 8C4 7.44688 3.55312 7 3 7ZM13 9C13.5531 9 14 8.55313 14 8C14 7.44688 13.5531 7 13 7C12.4469 7 12 7.44688 12 8C12 8.55313 12.4469 9 13 9Z'
      fill='#fff'
    />
  </svg>
)

export default CarIcon
