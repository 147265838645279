const MailInboxIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 16.293C9.36016 16.293 8.71562 16.1328 8.16875 15.7024L0 9.80082V18.125C0 19.1602 0.839844 20 1.875 20H18.125C19.1602 20 20 19.1602 20 18.1602V9.80082L11.832 15.7031C11.2852 16.0938 10.6406 16.293 10 16.293ZM19.2812 6.36723C18.9375 6.0938 18.6094 5.83989 18.125 5.47271V3.74927C18.125 2.71412 17.2852 1.87428 16.25 1.87428L13.2227 1.87434C13.1006 1.78645 12.9932 1.70833 12.8662 1.62044C12.2109 1.13795 10.9102 -0.01451 10 0.000138346C9.09375 -0.01451 7.78906 1.13795 7.13281 1.62116C7.00586 1.70905 6.89844 1.78717 6.77637 1.87506H3.75C2.71484 1.87506 1.875 2.7149 1.875 3.75006V5.47349C1.3918 5.83989 1.06445 6.0938 0.717969 6.36723C0.263672 6.71879 0 7.26567 0 7.84379V8.25863L3.75 10.9684V3.75006H16.25V10.9649L20 8.25512V7.84379C20 7.26567 19.7383 6.71879 19.2812 6.36723ZM6.875 9.96488H13.125C13.4702 9.96488 13.75 9.68503 13.75 9.37504C13.75 9.0298 13.4702 8.75004 13.125 8.75004H6.875C6.52984 8.75004 6.25 9.0298 6.25 9.37504C6.25 9.71878 6.53125 9.96488 6.875 9.96488ZM6.875 7.46489H13.125C13.4702 7.46489 13.75 7.18504 13.75 6.83989C13.75 6.49466 13.4702 6.25005 13.125 6.25005H6.875C6.52984 6.25005 6.25 6.52981 6.25 6.83989C6.25 7.21879 6.53125 7.46489 6.875 7.46489Z'
      fill='white'
    />
  </svg>
)

export default MailInboxIcon
