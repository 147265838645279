import dayjs from 'dayjs'
import { z } from 'zod'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { DeliveryType } from '@types_def/models/delivery.types'
import { Vehicle } from './vehicule'
import errorMessages from '../errorMessages'

dayjs.extend(isSameOrAfter)

const TCategory = ['STANDARD', 'OKM', 'EXPRESS'] as const

function disablePast(date: string | Date | any) {
  return dayjs(date)?.isSameOrAfter(dayjs(), 'minute')
}

const Options = z.object({
  selected: z
    .array(
      z
        .object({
          id: z.string().nullish(),
          name: z.string().nullish(),
          photo: z.string().nullish(),
          price: z.number().nullish(),
        })
        .nullish(),
    )
    .nullish(),
})

const Address = z.object({
  latitude: z.number(),
  longitude: z.number(),
  city: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  address: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  postalCode: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  place_id: z.string(),
  country: z.string(),
  addressPrecision: z.nullable(z.string()).optional(),
})

export const StandardDeliverySchema = z.object({
  type: z.nativeEnum(DeliveryType),
  twoWayDeliverType: z.enum(['TO_RETURN', 'EXCHANGE']).nullish(),
  status: z.string().optional(),
  clientId: z.coerce.number(),
  numberOfVehicles: z.number(),
  comment: z.string(),
  clientFirstName: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  clientLastName: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  clientEmail: z.string().refine((value) => /\S+@\S+\.\S+/.test(value), {
    message: errorMessages.invalidEmail,
  }),
  carAvailabilityDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carPickUpDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carDeliveryDeadline: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  clientPhone: z.string(),
  clientSiret: z.nullable(z.string()),
  clientCompany: z.nullable(z.string()),
  clientDrivingLicenceNumber: z.string().optional(),
  representativeFirstName: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  representativeLastName: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  representativePhone: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  representativeEmail: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  fromAddress: Address,
  toAddress: Address,
  representativeSiret: z.nullable(z.string()),
  representativeCompany: z.nullable(z.string()),
  vehicles: z.array(Vehicle),
  options: Options.nullish(),
  garageOptions: z.object({
    selected: z.array(z.string()),
  }),
  category: z.enum(TCategory),
  id: z.number().optional(),

  docs: z.array(
    z.object({
      name: z.string(),
      key: z.optional(z.string()),
      url: z.string(),
      deleted: z.boolean().nullish(),
    }),
  ),
})

//  .refine(
//     ({ carPickUpDate, carAvailabilityDate }) => areSameOrAfter(carPickUpDate, carAvailabilityDate),
//     {
//       message: errorMessages.pickUpDateAfterAvailabilityDate,
//       path: ['carPickUpDate'],
//     },
//   )
//   .refine(({ carDeliveryDeadline, carPickUpDate }) => isAfter(carDeliveryDeadline, carPickUpDate), {
//     message: errorMessages.DeliveryDeadlineAfterPickUpDate,
//     path: ['carDeliveryDeadline'],
//   })
