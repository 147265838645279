import { Grid, Container } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import Form from './Form'
import { OrganizationFormSchema } from '@schemas/OrganizationForm'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'
import { useCreateOrganization, useOrganizationDetails, useUpdateOrganization } from './Querys'
import {
  CreateOrganizationInput,
  UpdateOrganizationInput,
} from '@types_def/models/Organization.types'

const OrganizationForm = () => {
  const { slug } = useParams()

  const { data } = useOrganizationDetails({
    slug,
    include: {
      owner: {
        include: {
          address: true,
        },
      },
    },
  })
  const { mutate: createOrganization } = useCreateOrganization()
  const { mutate: updateOragnization } = useUpdateOrganization()

  const methods = useForm<CreateOrganizationInput>({
    resolver: zodResolver(OrganizationFormSchema),
    values: data,
  })

  const onSubmit = (data: CreateOrganizationInput) => {
    data = {
      ...data,
      owner: {
        ...data.owner,
        assigneePhone: data.owner.assigneePhone.replace(/\D/g, ''),
      },
    }
    createOrganization(data, { onSuccess: () => methods.reset() })
  }
  const onUpdate = (data: UpdateOrganizationInput) => {
    //const values = getDirtyFields(methods.formState.dirtyFields, methods.getValues())
    if (data.owner?.assigneePhone)
      data.owner.assigneePhone = data.owner.assigneePhone.replace(/\D/g, '')
    updateOragnization(data, {
      onSuccess: () => {},
    })
  }

  return (
    <Container maxWidth='lg'>
      <Grid container sx={{ position: 'relative', top: '80px', paddingBottom: 10 }}>
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <Form
              onSubmit={!slug ? onSubmit : onUpdate}
              resource={!slug ? 'new-client' : 'edit-client'}
            ></Form>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrganizationForm
