import { sendRequest } from '@api/clients/axios'
import { type ApiGetResponse } from '@types_def/common/auth.types'

export default async function adminGetUser(email: string): Promise<ApiGetResponse<any>> {
  try {
    const res = await sendRequest<{ email: string }, any>('post', '/admins/admin-get-user', {
      email,
    })
    return { success: true, data: res.data }
  } catch (error: unknown) {
    return { success: false }
  }
}
