import type Admin from '@types_def/models/admin.types'
import type Client from '@types_def/models/client.types'
import { UserRole } from '@types_def/models/user.type'

import getUserBySub from '../user/getUseBySub'

export const getUser = async (sub: string, role: UserRole, fullObject?: boolean) => {
  const getUserResponse =
    role == UserRole.ADMIN
      ? await getUserBySub<Admin>(sub, 'Admin', { firstName: true, rank: true })
      : fullObject
        ? await getUserBySub<Client>(sub, 'Client', {
            siret: true,
            assigneeEmail: true,
            assigneeFirstName: true,
            assigneeFunction: true,
            assigneeLastName: true,
            assigneePhone: true,
            assigneeService: true,
          })
        : await getUserBySub<Client>(sub, 'Client', { assigneeFirstName: true })
  return getUserResponse
}

export default getUser
