import styled from 'styled-components'

const Retard = styled.div`
  display: inline;
  background-color: #ffc700;
  padding: 2px 15px;
  width: max-content;
  margin-left: 5px;
  border-radius: 100px;
  box-shadow: 0 4px 4px lightgrey;
  & span {
    color: white;
  }
  & svg * {
    fill: white !important;
  }
`

function RetardLabel() {
  return (
    <Retard>
      <svg
        width='12'
        height='12'
        viewBox='0 0 16 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.8214 12.0312L9.1555 0.65625C8.64517 -0.21875 7.35731 -0.21875 6.84354 0.65625L0.180799 12.0312C-0.332032 12.9031 0.30671 14 1.33459 14H14.6663C15.6901 14 16.3308 12.9062 15.8214 12.0312ZM7.24918 4.25C7.24918 3.83594 7.58513 3.5 7.99921 3.5C8.41328 3.5 8.74923 3.8375 8.74923 4.25V8.25C8.74923 8.66406 8.41328 9 8.02733 9C7.64138 9 7.24918 8.66562 7.24918 8.25V4.25ZM7.99921 12C7.45669 12 7.01667 11.56 7.01667 11.0175C7.01667 10.475 7.45637 10.035 7.99921 10.035C8.54204 10.035 8.98174 10.475 8.98174 11.0175C8.98049 11.5594 8.54297 12 7.99921 12Z'
          fill='white'
        />
      </svg>

      <span style={{ fontSize: 12 }}>RETARD</span>
    </Retard>
  )
}

export default RetardLabel
