import {
  Grid,
  InputLabel,
  Typography,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  Divider,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import getAllFilteredDeep from '@api/graphql/generic/getAllFiltered'
import AlbInput from '@components/common/AlbInput'
import { formatPhoneNumber } from '@components/common/InputFormat'
import Client from '@types_def/models/client.types'
import { Delivery } from '@types_def/models/delivery.types'
import { useUserContext } from '@contexts/UserContext/index.context'
import { sendRawRequest } from '@api/clients/axios'
import { useTranslation } from 'react-i18next'
import getUserBySub from '@api/graphql/user/getUseBySub'

const getAllInput = {
  keyword: '',
  pagination: { page: 0, perPage: 100000 },
  sort: {
    field: 'createdAt',
    order: 'desc',
  },
  filter: {
    partner: true,
    is_organization_owner: true,
  },
  include: {
    _count: true,
    organization: true,
  },
}

type TMandataire = {}

const Mandataire = ({}: TMandataire) => {
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const isAdmin = userState['custom:role'] === 'ADMIN'
  const { setValue, getValues } = useFormContext<Delivery>()

  const fillMandataireForm = (client: Client) => {
    setValue('clientId', Number(client?.id), { shouldDirty: true })
    setValue('representativeFirstName', client?.assigneeFirstName, { shouldDirty: true })
    setValue('representativeLastName', client?.assigneeLastName, { shouldDirty: true })
    setValue('representativeEmail', client?.assigneeEmail, { shouldDirty: true })
    setValue('representativePhone', formatPhoneNumber(client?.assigneePhone), { shouldDirty: true })
    setValue('representativeCompany', client?.organization?.name, { shouldDirty: true })
    setValue('representativeSiret', client?.siret, { shouldDirty: true })
  }

  const { data, isLoading } = useQuery({
    queryKey: ['mandataires', getAllInput],
    queryFn: async () => {
      if (isAdmin) {
        const response = await sendRawRequest<any, any>('post', '/organization/get-all-owners')
        return response.data
      } else {
        const response = await getUserBySub(userState?.sub, 'Client', {
          id: true,
          assigneeFirstName: true,
          assigneeLastName: true,
          assigneeEmail: true,
          assigneePhone: true,
          siret: true,
          clientOrganization: {
            name: true,
            slug: true,
          },
        })
        return response
      }
    },
  })

  const [clients, setClients] = useState<Client[] | undefined>(undefined)
  const [client, setClient] = useState<Client | undefined>(undefined)

  const handleChange = (event: SelectChangeEvent) => {
    const selectedClient = clients?.find((el) => el?.id === Number(event.target.value))
    if (isAdmin) setClient(selectedClient)
    fillMandataireForm(selectedClient)
  }

  useEffect(() => {
    if (!isLoading) {
      if (isAdmin) {
        const clients = data?.records
        setClients(clients)
        if (getValues('clientId')) {
          const selectedClient = clients?.find((el) => el?.id === Number(getValues('clientId')))
          setClient(selectedClient)
        }
      } else {
        fillMandataireForm({ ...data, organization: data.clientOrganization })
      }
    }
  }, [isLoading])

  if (isLoading) return <></>

  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>Mandataire</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='select-client'>Select Mandataire</InputLabel>
            <Select
              id='select-client'
              label='Select Mandataire'
              value={client?.id ? client?.id.toString() : ''}
              onChange={handleChange}
              MenuProps={{
                sx: {
                  '& ul': {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {clients?.length > 0 &&
                clients.map((client: Client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.assigneeFirstName} {client.assigneeLastName} (
                    {client.organization?.name})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativeFirstName'
          label={t('common.form.input.first-name')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativeLastName'
          label={t('common.form.input.last-name')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativePhone'
          label={t('common.form.input.phone')}
          type='PhoneField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativeEmail'
          label={t('common.form.input.email')}
          type='TextField'
          inputType='email'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativeCompany'
          label={t('client.form.company')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AlbInput
          name='representativeSiret'
          label={t('client.form.siret')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
    </Grid>
  )
}

export default Mandataire
