import * as React from 'react'
import { Box, Fab, Grid, Stack, TextField, Typography } from '@mui/material'
import styles from './index.module.scss'
import type { Driver } from './types'
import ImageMadal from './ImageModal'

import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import {
  DriverContext,
  DriverContextProvider,
  useDriverContext,
} from '@contexts/DriverContext/index.context'

type NestedListProps = {
  canEdit?: boolean
  editable?: boolean
  title: string
  media?: Array<{
    label: string
    key: string
    type: string
  }>
  details: Array<{ label: string; key: string; editable?: boolean }>
  driverData: Driver
}

const Row = ({ label, data, id, disabled: defaultDisabled, canEdit, editable }) => {
  const [disabled, setDisabled] = React.useState(true)
  const [value, setValue] = React.useState(isDate(data) ? dayjs(data) : data)
  const [newValue, setNewValue] = React.useState(isDate(data) ? dayjs(data) : data)
  const { changes, setChanges } = useDriverContext()
  const [adress, setAdress] = React.useState({})

  const handleAdressChange = (inputValue, key) => {
    setAdress((previous) => ({ ...previous, [key]: inputValue }))
    setNewValue((previous) => ({ ...previous, [key]: inputValue }))
  }

  // Functional Component with useState
  const deepEqual = (value1, value2) => {
    // Check if the types are the same
    if (typeof value1 !== typeof value2) {
      return false
    }

    // Handle primitives (including null and undefined)
    if (value1 === value2) {
      return true
    }

    // Handle arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length) {
        return false
      }
      for (let i = 0; i < value1.length; i++) {
        if (!deepEqual(value1[i], value2[i])) {
          return false
        }
      }
      return true
    }

    // Handle objects
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      const keys1 = Object.keys(value1)
      const keys2 = Object.keys(value2)
      if (keys1.length !== keys2.length) {
        return false
      }
      for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(value1[key], value2[key])) {
          return false
        }
      }
      return true
    }

    // If none of the above cases match, the values are not equal
    return false
  }
  function deleteMatchingKeys(obj1, obj2, setter) {
    for (const key1 in obj1) {
      for (const key2 in obj2) {
        if (obj1[key1] === obj2[key2]) {
          delete obj2[key2]
        }
      }
    }
    setter({ ...obj2 })
  }
  const handleChanges = (e) => {
    if (!deepEqual(value, newValue)) {
      if (isDate(data)) {
        setChanges((previous) => ({ ...previous, [id]: newValue.format('YYYY-MM-DD') }))
      } else if (typeof data === 'string') {
        setChanges((previous) => ({ ...previous, [id]: newValue }))
      } else {
        deleteMatchingKeys(value, adress, setAdress)
        if (Object.keys(adress).length > 0)
          setChanges((previous) => ({ ...previous, [id]: adress }))
        else {
          const updatedChanges = { ...changes }
          delete updatedChanges[id]
          setChanges({ ...updatedChanges })
        }
        setChanges((previous) => ({ ...previous, [id]: adress }))
      }
    } else {
      const updatedChanges = { ...changes }
      delete updatedChanges[id]
      setChanges({ ...updatedChanges })
    }
    setDisabled(true)
  }
  const resetChanges = () => {
    setChanges((previous) =>
      Object.fromEntries(Object.entries(previous).filter(([key, value]) => key !== id)),
    )
    if (!(isDate(data) || typeof data === 'string')) {
      const valueCopy = { ...value }

      setNewValue(valueCopy)
    } else {
      setNewValue(value)
    }

    setDisabled(true)
  }

  function isDate(_date) {
    const _regExp = new RegExp(
      '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$',
    )
    return _regExp.test(_date)
  }
  return (
    <>
      <Stack marginY={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
        <strong style={{ width: '150px', color: '#00244fff' }}>{label}</strong>
        {isDate(data) ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '50%' }}
              value={dayjs(newValue)}
              slotProps={{ textField: { variant: 'standard', id: id } }}
              disabled={disabled}
              onChange={(e) => setNewValue(e)}
            />
          </LocalizationProvider>
        ) : typeof data === 'string' ? (
          <TextField
            id={id}
            sx={{ width: '50%' }}
            variant='standard'
            value={newValue}
            disabled={disabled}
            onChange={(e) => setNewValue(e.target.value)}
          />
        ) : (
          <>
            <Grid container spacing={2} sx={{ width: '50%' }}>
              {Object.entries(newValue).map(([key, value]) =>
                key !== 'id' ? (
                  <Grid item xs={6} key={key}>
                    <TextField
                      id={key}
                      label={key}
                      variant='standard'
                      value={value}
                      disabled={disabled}
                      onChange={(e) => {
                        handleAdressChange(e.target.value, key)
                      }}
                    />
                  </Grid>
                ) : (
                  ''
                ),
              )}
            </Grid>
          </>
        )}

        <Grid item spacing={1} xs={2}>
          {canEdit && editable ? (
            <>
              {disabled ? (
                <Grid item xs={12}>
                  <IconButton
                    onClick={() => {
                      setDisabled(!disabled)
                    }}
                    size='medium'
                    disabled={defaultDisabled}
                  >
                    <EditIcon sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Grid>
              ) : (
                <Stack direction={'row'}>
                  <Grid item xs={6}>
                    <IconButton onClick={handleChanges} size='medium'>
                      <CheckIcon color='success' sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton onClick={resetChanges} size='medium'>
                      <CloseIcon color='error' sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Grid>
                </Stack>
              )}
            </>
          ) : (
            ''
          )}
        </Grid>
      </Stack>
    </>
  )
}
export default function NestedList({
  title,
  details,
  driverData,
  media,
  canEdit = true,
}: NestedListProps) {
  const [open, setOpen] = React.useState(false)
  const [imgSrc, setImgSrc] = React.useState('')
  const handleImgClick = (imgsrc: string) => {
    setImgSrc(imgsrc)
    setOpen(true)
  }

  const prefix = import.meta.env.VITE_APP_S3_MEDIA_BUCKET_URL

  const generateMediaUrl = (path: string) => (path.includes(prefix) ? path : `${prefix}/${path}`)

  return (
    <>
      <Grid item xs={12}>
        <hr className={styles.devider} />
        <div>
          {details.map(({ label, key, editable }) => (
            <>
              {driverData?.[key] ? (
                <Row
                  key={key}
                  label={label}
                  data={driverData[key]}
                  disabled={false}
                  id={key}
                  canEdit={canEdit}
                  editable={editable}
                />
              ) : (
                '----'
              )}
            </>
          ))}
        </div>
        <hr className={styles.devider} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' component='h5' sx={{ mb: 1 }}>
          {media?.length || 0} pièce jointe
        </Typography>
        <div className={styles.imgContainer}>
          {media
            ? media.map(({ key, label, type }) => {
                const mediaImg = driverData?.[key] as string
                const imgUrl = mediaImg ? generateMediaUrl(mediaImg) : ''
                return type === 'image' ? (
                  <img
                    src={imgUrl}
                    alt={label}
                    key={label}
                    className={styles.img}
                    onClick={() => {
                      handleImgClick(imgUrl)
                    }}
                  />
                ) : (
                  // pdf file placeholder
                  <a href={imgUrl} download title={label} key={label}>
                    <img
                      key={label}
                      className={styles.img}
                      src='https://upload.wikimedia.org/wikipedia/commons/4/42/Pdf-2127829.png'
                      alt={label}
                    />
                  </a>
                )
              })
            : null}
        </div>
      </Grid>
      <ImageMadal open={open} setOpen={setOpen} imgUrl={imgSrc} />
    </>
  )
}
