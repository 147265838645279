import { type FunctionComponent } from 'react'
import { Delivery } from '@types_def/models/delivery.types'

import { type Offer } from '@types_def/models/offer.types'

export const DriverNameCell: FunctionComponent = ({ children }) => {
  const fullName =
    (children as Offer)?.driver?.firstName + ' ' + (children as Offer)?.driver?.lastName
  return fullName ? <>{fullName}</> : <></>
}
