import { Button } from '@mui/material'
import { type FunctionComponent } from 'react'
import { type Delivery } from '@types_def/models/delivery.types'
import { downloadInvoice } from '@components/Delivery/invoices/facture.service'
import DownloadIcon from '@mui/icons-material/Download'

const AdminInvoiceAction: FunctionComponent = ({ children }) => {
  // /admin/pilot/details/type/status/id
  const { invoice } = children as Delivery

  if (!invoice) {
    return <i>Error</i>
  }

  return (
    <div>
      <Button
        color='primary'
        variant='contained'
        size='small'
        onClick={() => downloadInvoice(invoice)}
      >
        <DownloadIcon fontSize='small' />
        Télécharger
      </Button>
    </div>
  )
}

export default AdminInvoiceAction
