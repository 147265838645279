import { type GetByIdInput } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { graphqlQueryFieldsBuilder } from '@utils/functions/graphqlQueryBuilder'

import { gql, graphqlQuery } from '../../clients/apollo'

type ApiGetResponse<T> = {
  findById: T
}

async function getDeep<T extends Record<string, unknown>>(
  id: string,
  resource: string,
  graphQlFieldsObject: QueryType<T>,
): Promise<T> {
  try {
    const res = await graphqlQuery<ApiGetResponse<T>, GetByIdInput>(
      gql`
        query ($getByIdInput: GetByIdInput!) {
         findById: find${resource}ById(GetByIdInput: $getByIdInput) 
            ${graphqlQueryFieldsBuilder(graphQlFieldsObject)}
          
        }
      `,
      {
        getByIdInput: { id: Number(id) },
      },
    )
    return res.data.findById
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export default getDeep
