import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type DeliveryPayment } from '@types_def/models/deliveryPayment.types'

import { ActionCell } from '../CustomCells/ActionCell'
import { DateCell } from '../CustomCells/DateCell'
import { DriverNameCell } from '../CustomCells/NameCell'
import { PriceCell } from '../CustomCells/PriceCell'
import { StatusCell } from '../CustomCells/StatusCell'

export const headCells: Array<HeadCell<DeliveryPayment>> = [
  {
    id: 'id',
    label: 'Numéro de facture',
  },
  {
    id: 'driver',
    label: 'Driver',
    passWholeObject: true,
    customCellComponent: DriverNameCell,
  },
  {
    id: 'createdAt',
    label: 'Date',
    customCellComponent: DateCell,
  },
  {
    id: 'status',
    label: 'Statut',
    customCellComponent: StatusCell,
    isNotSortable: true,
  },
  {
    id: 'amount',
    label: 'Montant',
    customCellComponent: PriceCell,
  },
  {
    id: 'deliveryId',
    label: 'Action',
    customCellComponent: ActionCell,
    isNotSortable: true,
  },
]
