import type React from 'react'

import { type Delivery } from '@types_def/models/delivery.types'

import styles from './index.module.scss'

type Props = {
  min: number
  max: number
  wantedPrice: number
  setWantedPrice: (p: number) => void
}

const CreateDeliveryDealPriceSlider = ({ min, max, wantedPrice, setWantedPrice }: Props) => {
  return (
    <>
      <div className={`${styles.createDeliveryDealPriceSliderContainer}`}>
        <div className={`${styles.createDeliveryDealPriceSliderPrices}`}>
          <div>{min} €</div>
          <div>{((max + min) / 2).toFixed(2)} €</div>
          <div>{max} €</div>
        </div>
        <div className={`${styles.createDeliveryDealPriceSlider}`}>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <span></span>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <input
            type='range'
            min={min}
            max={max}
            value={wantedPrice}
            step={0.01}
            onChange={(e) => setWantedPrice(Number(e.target.value))}
          />
        </div>
        <div className={`${styles.createDeliveryDealPriceSliderLabels}`}>
          <div>Montant min.</div>
          <div>Médiane</div>
          <div>Montant max.</div>
        </div>
      </div>
      Prix recommandé pour les drivers : {wantedPrice ?? <>Error</>}
    </>
  )
}

export default CreateDeliveryDealPriceSlider
