import { type DateTime } from 'luxon'
import { deliveryTableDate } from '@utils/functions/customDates'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  type?: 'logout' | 'login'
  date: DateTime | undefined
  km: number | undefined
  fuel: number | undefined
  anomaly: boolean | string
  handleSetCarImagesModalOpen: (open: boolean) => void
}

const DeliveryDetailsEndpoint = ({
  type = 'logout',
  date,
  km,
  fuel,
  anomaly,
  handleSetCarImagesModalOpen,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%', textTransform: 'capitalize' }}>
      <div className={styles.deliveryDetailsEndPointTitle}>
        {type == 'login' ? t('common.shared.arrived') : t('common.shared.departed')}
      </div>
      {date && (
        <>
          {t('common.shared.date')} : {deliveryTableDate(date)} <br />
        </>
      )}
      {km && (
        <>
          {t('common.shared.mileage')} : {km} km <br />
        </>
      )}
      {fuel >= 0 && (
        <>
          <div className={styles.fuelPointContainer}>
            {t('common.shared.fuel')} : (
            {[...Array<never>(8)].map((_, i) => (
              <div
                className={styles.fuelPoint}
                style={{
                  opacity: fuel > i ? 1 : 0.25,
                }}
                key={i}
              />
            ))}
            )
          </div>
        </>
      )}
      {t('common.shared.photos')} :{' '}
      {anomaly ? (
        <>
          {t('common.shared.yes')}{' '}
          <span
            className={styles.deliveryDetailsEndPointAnomaly}
            onClick={() => {
              handleSetCarImagesModalOpen(true)
            }}
          >
            ({t('common.shared.show-photos')})
          </span>
        </>
      ) : (
        t('common.shared.no')
      )}
    </div>
  )
}

export default DeliveryDetailsEndpoint
