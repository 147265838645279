import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type ClientDashboardMonthDeliveryData } from '@types_def/models/delivery.types'

import { gql, graphqlQuery } from '../../clients/apollo'

type Response = Omit<ClientDashboardMonthDeliveryData, 'averagePrice' | 'averageDistance'> & {
  month: number
}

type DeliveryStats = {
  total: number
  result: Response[]
}

export async function getDeliveryStats(slug: string): Promise<DeliveryStats | undefined> {
  try {
    const res = await graphqlQuery<ApiGetResponse<DeliveryStats>, { slug: string }>(
      gql`
        query {
          data: getClientStatistic(
            organization_id:  "${slug}"
          ) {
            total
            result {
              month
              totalPrice
              totalDistance
              totalDeliveries
            }
          }
        }
      `,
      {
        slug,
      },
    )
    return res.data.data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
