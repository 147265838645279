import { Box, Container, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React from 'react'
import { getDeliveries } from '@api/graphql/delivery/getDelivery'
import ClientCalendar from '@components/common/calendar'
import { useUserContext } from '@contexts/UserContext/index.context'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import 'dayjs/locale/fr'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import { Info, InfoOutlined, StarBorderPurple500Outlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
dayjs.locale('fr')

type Props = {}
type Range = {
  start: Date
  end: Date
}

const CalendarView = (props: Props) => {
  const { t } = useTranslation()
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const { userState } = useUserContext()
  const [range, setRange] = React.useState<Range>({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  })
  const organizationId = userState?.organizationId
  const { data, isLoading } = useQuery({
    queryKey: ['delivery', { start: range.start, end: range.end }],
    queryFn: async () => {
      const data = await getDeliveries(
        {
          status: {
            in: [DeliveryStatus.SET, DeliveryStatus.CONFIRMED, DeliveryStatus.WAITING_FOR_DRIVER],
          },
          organization: {
            slug: organizationId,
          },
          carDeliveryDeadline: {
            gte: range.start,
            lte: range.end,
          },
        },

        {
          id: true,
          type: true,
          carDeliveryDeadline: true,
          toAddress: {
            address: true,
          },
          toReturnToAddress: {
            address: true,
          },
          fromAddress: {
            address: true,
          },
          toReturnFromAddress: {
            address: true,
          },
        },
        undefined,
        {
          page: 0,
          perPage: 1000,
        },
      )
      if (!data.success) {
        console.error(data.message)
        throw new Error(data.message)
      }
      const formatedData = data.data.records.map((delivery) => ({
        id: delivery.id,
        title: `Mission N°${delivery.id}\nType: ${
          delivery.type === DeliveryType.STANDARD ? 'Standard' : 'Restitution'
        }\nDépart Livraison: ${delivery.fromAddress.address}\nArrivée Livraison: ${
          delivery?.toAddress?.address
        }\n${
          delivery.type === DeliveryType.TWO_WAY_DELIVERY
            ? `Arrivée Restitution: ${
                Boolean(delivery?.toReturnToAddress?.address)
                  ? delivery?.toReturnToAddress?.address
                  : 'pas scpécifié'
              }\n`
            : ''
        }`,
        start: new Date(delivery.carDeliveryDeadline.toString()),
        end: new Date(dayjs(delivery.carDeliveryDeadline.toString()).add(2, 'hour').toString()),
        type: delivery.type,
        address:
          delivery.type === DeliveryType.STANDARD
            ? delivery?.toAddress?.address
            : Boolean(delivery?.toReturnToAddress?.address)
              ? delivery?.toReturnToAddress?.address
              : 'pas scpécifié',
      }))
      return formatedData
    },
  })
  const handleRangeChange = (range: any) => {
    if (Array.isArray(range)) {
      if (range.length > 1) {
        const start = new Date(new Date(range[0]).setHours(0, 0, 0, 0))
        const end = new Date(new Date(range[range.length - 1]).setHours(23, 59, 59, 999))
        setRange({ start, end })
      } else {
        setRange({
          start: new Date(new Date(range[0]).setHours(0, 0, 0, 0)),
          end: new Date(new Date(range[0]).setHours(23, 59, 59, 999)),
        })
      }
    } else
      setRange({
        start: new Date(new Date(range.start).setHours(0, 0, 0, 0)),
        end: new Date(new Date(range.end).setHours(23, 59, 59, 999)),
      })
  }
  return (
    <Container maxWidth='xl' sx={{ padding: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Box flexDirection='row' display='flex' alignItems={'center'} gap={1}>
                <StarBorderPurple500Outlined />
                <Typography variant='h6'>{t('calendar.accordion-one.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1'>
                <i>{t('calendar.accordion-one.paragragh-one')}</i>
                <br />
                <i>{t('calendar.accordion-one.paragragh-two')}</i>
                <br />
                <br />
                {t('calendar.accordion-one.paragragh-three')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
            >
              <Box flexDirection='row' display='flex' alignItems={'center'} gap={1}>
                <InfoOutlined />
                <Typography variant='h6'>{t('calendar.accordion-two.title')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1'>
                {t('calendar.accordion-two.paragragh-one')}
                <ul
                  style={{
                    listStyle: 'outside',
                    padding: 0,
                    margin: 0,
                    marginLeft: 20,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <li style={{ marginBottom: 5, display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: 24,
                        height: 24,
                        backgroundColor: getDeliveryTypeColor(DeliveryType.STANDARD),
                        borderRadius: '50%',
                      }}
                    ></span>
                    <span>{t('calendar.accordion-two.paragragh-two')}</span>
                  </li>
                  <li style={{ marginBottom: 5, display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: 24,
                        height: 24,
                        backgroundColor: getDeliveryTypeColor(DeliveryType.TWO_WAY_DELIVERY),
                        borderRadius: '50%',
                      }}
                    ></span>
                    {t('calendar.accordion-two.paragragh-three')}
                  </li>
                </ul>{' '}
                {t('calendar.accordion-two.paragragh-four')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <ClientCalendar data={data} handleRangeChange={handleRangeChange} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default CalendarView
