const errorMessages = {
    required: 'required',
    invalidEmail: "invalidEmail",
    invalidPhoneNumber: 'invalidPhoneNumber',
    disablePast: 'disablePast',
    pickUpDateAfterAvailabilityDate:
      'pickUpDateAfterAvailabilityDate',
    DeliveryDeadlineAfterPickUpDate:
      'DeliveryDeadlineAfterPickUpDate',
  }

  export default errorMessages