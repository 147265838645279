import React from 'react'
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Driver from '@types_def/models/driver.types'

type TDriverQuickDetails = {
  driver: Driver
}

const DriverQuickDetails = ({ driver }: TDriverQuickDetails) => {
  if (!driver) return null
  const { id, firstName, lastName, disabled } = driver
  return (
    <Container maxWidth='md'>
      <Table sx={{ padding: 2, marginTop: 4, border: 1, borderColor: '#ccc' }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nom et Prénom</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{id}</TableCell>
            <TableCell>
              {firstName} {lastName}
            </TableCell>
            <TableCell>{disabled ? 'Désactivé' : 'Activé'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  )
}

export default DriverQuickDetails
