import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { getDelivery } from '@api/graphql/delivery/getDelivery'
import { updateBySMS } from '@api/graphql/delivery/updateDeliveryBySMS'
import { useIdToken } from '@hooks/idToken'
import { device } from '@styles/mediaQuery'
import { primaryColor } from '@styles/variables'
import { type QueryType } from '@types_def/common/query.types'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'

import AddressSection from './Address'
import Buttons from './Buttons'
import Cancel from './Cancel'
import ContactInformation from './ContactInformation'
import DateTimeSection from './DateTime'
import IncorrectLink from './IncorrectLink'
import Options from './Options'
import PostConfirmation from './PostConfimation'
import { InformationTabe } from './StyledComponents'
import Vehicles from './Vehicles'
import Welcome from './Welcome'

export const ConfirmationContainer = styled.div`
  background-color: ${primaryColor};
  width: 100%;
  height: 100%;
  color: white;
  @media ${device.mobileL} {
    padding: 1em;
  }
  padding: 5em;
`
export const ConfirmationContent = styled.div`
  margin: auto;
`

const deliveryQuery: QueryType<Delivery> = {
  id: true,
  twoWayDeliverType: true,
  type: true,
  status: true,
  clientFirstName: true,
  clientPhone: true,
  clientEmail: true,
  options: undefined,
  garageOptions: undefined,
  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,
  vehicles: {
    marque: true,
    model: true,
    toRetrieve: true,
    vehicleCode: true,
  },
  fromAddress: {
    postalCode: true,
    city: true,
    address: true,
  },
  toAddress: {
    postalCode: true,
    city: true,
    address: true,
  },
}

enum DeliveryFeedBack {
  LOADING,
  CONFIRMED,
  CANCELED,
  PENDING,
  INCORRECT,
}
export const AcceptDeliveryByClient = () => {
  const [feedback, setFeedback] = useState<DeliveryFeedBack>(DeliveryFeedBack.LOADING)
  const [delivery, setDelivery] = useState<Delivery>()

  const { id, token } = useIdToken()

  const updateDeliveryStatusBySMS = (status: DeliveryStatus) => {
    if (Boolean(id) && Boolean(token)) {
      setFeedback(DeliveryFeedBack.LOADING)
      updateBySMS(token, status)
        .then((delivery) => {
          if (!delivery) {
            return
          }

          setFeedback(
            status === DeliveryStatus.CONFIRMED_BY_CUSTOMER
              ? DeliveryFeedBack.CONFIRMED
              : DeliveryFeedBack.CANCELED,
          )
        })
        .catch(() => {
          setFeedback(DeliveryFeedBack.PENDING)
        })
    }
  }

  useEffect(() => {
    if (!id) {
      return
    }

    getDelivery(`${id}`, deliveryQuery).then((delivery) => {
      if (!delivery) {
        return
      }

      setDelivery(delivery)
      if (delivery.status !== DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION) {
        setFeedback(DeliveryFeedBack.INCORRECT)
      } else {
        setFeedback(DeliveryFeedBack.PENDING)
      }
    })
  }, [])

  return (
    <>
      {delivery && (
        <ConfirmationContainer>
          <ConfirmationContent>
            <>
              <img style={{ margin: 'auto' }} src='/images/logo.svg' />
              <br></br>
            </>
            {feedback === DeliveryFeedBack.INCORRECT && <IncorrectLink />}
            {feedback === DeliveryFeedBack.LOADING && (
              <>
                <CircularProgress color='secondary' />
              </>
            )}
            {feedback === DeliveryFeedBack.CONFIRMED && <PostConfirmation />}
            {feedback === DeliveryFeedBack.CANCELED && <Cancel />}
            {feedback === DeliveryFeedBack.PENDING && (
              <>
                <Welcome clientFirstName={delivery.clientFirstName} />
                <InformationTabe>
                  {Boolean(delivery) && Boolean(delivery.carPickUpDate) && (
                    <DateTimeSection carPickUpDate={delivery.carPickUpDate} />
                  )}

                  {Boolean(delivery) && Boolean(delivery.toAddress?.address) && (
                    <AddressSection address={delivery.toAddress.address} />
                  )}

                  <Vehicles vehicles={delivery.vehicles} />
                  <Options garageOptions={delivery.garageOptions} options={delivery.options} />
                  <ContactInformation email={delivery?.clientEmail} phone={delivery?.clientPhone} />
                </InformationTabe>

                <Buttons updateDeliveryStatusBySMS={updateDeliveryStatusBySMS} />
              </>
            )}
          </ConfirmationContent>
        </ConfirmationContainer>
      )}
    </>
  )
}
