import { Grid, Typography, Divider, TextField, Stack, Button } from '@mui/material'
import { Params, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useGetSmsLink, useSendSMS } from '../Querys'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { formatRoute } from '@utils/functions/formatRoute'
import { Delivery, DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.locale(fr)

type TSMSStatus = {
  data: Delivery
}

const SMSStatus = ({ data }: TSMSStatus) => {
  const smsSentAt = data.smsSentAt
  const smsConfirmedAt = data.confirmedByCustomerAt
  const smsConfirmedAtColor = smsConfirmedAt ? 'success' : 'error'
  const smsSentAtColor = smsSentAt ? 'success' : 'error'
  const smsSentAtIcon = smsSentAt ? (
    <CheckCircleOutlineRoundedIcon color={smsSentAtColor} fontSize='small' />
  ) : (
    <HighlightOffRoundedIcon color={smsSentAtColor} fontSize='small' />
  )
  const smsConfirmedAtIcon = smsConfirmedAt ? (
    <CheckCircleOutlineRoundedIcon color={smsConfirmedAtColor} fontSize='small' />
  ) : (
    <HighlightOffRoundedIcon color={smsConfirmedAtColor} fontSize='small' />
  )
  const smsSentAtText = smsSentAt
    ? dayjs(smsSentAt.toString()).format('[SMS envoyé le ] dddd DD MMMM YY [à] HH:mm')
    : 'SMS Non envoyé'
  const smsConfirmedAtText = smsConfirmedAt
    ? dayjs(smsConfirmedAt.toString()).format('[SMS confirmé le ] dddd DD MMMM YY [à] HH:mm')
    : 'SMS Non confirmé'

  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' spacing={1}>
        {smsSentAtIcon}
        <Typography variant='body1'>{smsSentAtText}</Typography>
      </Stack>
      <Stack direction='row' spacing={1}>
        {smsConfirmedAtIcon}
        <Typography variant='body1'>{smsConfirmedAtText}</Typography>
      </Stack>
    </Stack>
  )
}

type TSMSForm = {
  data: Delivery
  isRestitution?: boolean
}

const SMSForm = ({ data, isRestitution = false }: TSMSForm) => {
  const { data: smsLink } = useGetSmsLink(data.id)
  const { type, status } = useParams<Params>()
  const { mutate: sendSMS } = useSendSMS()
  const navigation = useNavigate()

  const onSendSMS = () => {
    sendSMS(
      { id: data.id, message: message, clientPhone: data.clientPhone },
      {
        onSuccess: () => {
          navigation(
            `/admin/pilot/${formatRoute(type as DeliveryType)}/pending-customer-confirmation/${
              data.id
            }`,
          )
        },
      },
    )
  }

  const deliveryDate = dayjs(data.carPickUpDate.toString()).add(80, 'minute')
  const formattedDateTime = `${deliveryDate.format(
    'dddd DD MMMM YY [entre] HH:mm [et] ',
  )} ${deliveryDate.add(30, 'minute').format('HH:mm')}`

  const commun = `Bonjour, nous avons été mandatés par la société ${data.representativeCompany} \
  pour effectuer la livraison de votre véhicule : ${data.vehicles[0].marque} ${data.vehicles[0].model} (immatriculation : ${data.vehicles[0].vehicleCode}).\
  La livraison est prévue le ${formattedDateTime} à l'adresse suivante : ${data.toAddress.address} ${data.toAddress.postalCode}, ${data.toAddress.city}.`

  const restitution = `Et aussi pour effectuer la restitution de votre véhicule : ${data.vehicles[1]?.marque} ${data.vehicles[1]?.model} (immatriculation : ${data.vehicles[1]?.vehicleCode}).
  à l'adresse suivante : ${data?.toReturnToAddress?.address} ${data?.toReturnToAddress?.postalCode}, ${data?.toReturnToAddress?.city}.`

  const end = `\nVeuillez confirmer votre présence en cliquant sur le lien : ${smsLink}`

  const message = isRestitution ? commun + restitution + end : commun + end

  return (
    <Grid container spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>Envoi d’un SMS </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item xs={12}>
        <TextField type='text' label='SMS Message' fullWidth disabled multiline value={message} />
        <Stack direction='column' spacing={1} marginTop={2}>
          <SMSStatus data={data} />
          <Button
            variant='contained'
            color='primary'
            sx={{ width: '200px' }}
            disabled={Boolean(data.smsSentAt) || status.toUpperCase() !== DeliveryStatus.REQUESTED}
            onClick={onSendSMS}
          >
            Envoiyer SMS
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SMSForm
