import { Autocomplete, Divider, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { sendRawRequest } from '@api/clients/axios'
import { DeliveryFilterSchemaType } from '@schemas/DeliveryForm/DeliveryFilterSchema'

const OrganizationFilter = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['mandataires', 'organizationsTableFilter'],
    queryFn: () => sendRawRequest<any, any>('post', '/organization/get-all-owners'),
  })
  useEffect(() => {
    if (!isLoading && !isError)
      setOrganizations(
        data.data?.records
          ?.filter((client) => Boolean(client?.organization))
          .map((client) => {
            return { label: client.organization.name, slug: client.organization.slug }
          }),
      )
  }, [isLoading])
  const [organizations, setOrganizations] = useState<{ label: string; slug: string }[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState([])

  const { setValue, getValues } = useFormContext<DeliveryFilterSchemaType>()
  const selectedSlugs = getValues('organizationSlug')?.split(',')
  useEffect(() => {
    if (organizations.length) {
      const recent = organizations.filter((organization) =>
        selectedSlugs?.includes(organization.slug),
      )
      setSelectedOrganizations(recent)
    }
  }, [organizations.length])

  if (isLoading) return <></>
  return (
    <>
      <Typography variant='body2' letterSpacing={2} fontWeight={600}>
        Organization
      </Typography>
      <Divider />
      <Stack direction='row' sx={{ flexWrap: 'wrap' }} p={1} gap={1}>
        <Autocomplete
          fullWidth
          multiple
          onChange={(_, values) => {
            const slugs = values.map((value) => value.slug)
            setValue('organizationSlug', slugs.join(','))
            setSelectedOrganizations(
              organizations.filter((organization) => slugs.includes(organization.slug)),
            )
          }}
          value={selectedOrganizations}
          options={organizations}
          getOptionDisabled={(option) =>
            selectedOrganizations.map((org) => org.slug).includes(option.slug)
          }
          disablePortal
          renderInput={(params) => (
            <TextField {...params} label='Organization' size='small' fullWidth />
          )}
        />
      </Stack>
    </>
  )
}

export default OrganizationFilter
