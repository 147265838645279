import { Box, type Breakpoint, Button, Card, Dialog, Fade, Typography } from '@mui/material'
import React, { type PropsWithChildren, type ReactNode } from 'react'

import styles from './index.module.scss'
type Props = {
  className?: string
  children: ReactNode
  isOpen: boolean
  fullScreen?: boolean
  handleOpen?: () => void
  handleClose: () => void
  label?: string
  icon?: JSX.Element
  maxWidth?: Breakpoint | false
  fullWidth?: boolean
  showButton?: boolean
  backgroundColor?: string
  noBorder?: boolean
}

export const ModalComponent: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  isOpen,
  fullScreen,
  handleOpen,
  handleClose,
  label,
  icon,
  showButton = true,
  maxWidth = 'md',
  fullWidth = false,
  backgroundColor,
  noBorder,
}) => {
  const Icon = ({ icon, onClick }: { icon: JSX.Element; onClick?: () => void }) =>
    icon &&
    React.cloneElement(icon, {
      style: {
        padding: '.4vh',
        width: '2vh',
        height: '2vh',
        borderRadius: '50%',
        backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      },
      onClick,
    })
  const LabelComponent = ({
    handleOpen,
    icon,
    label,
    showButton,
  }: {
    handleOpen?: () => void
    icon?: JSX.Element
    label?: string
    showButton: boolean
  }) => (
    <>
      {Boolean(label) && showButton && <Typography>{label}</Typography>}
      {showButton && handleOpen && (
        <Button onClick={handleOpen}>{icon && <Icon icon={icon} />}</Button>
      )}
      {!showButton && icon && <Icon onClick={handleOpen} icon={icon} />}
    </>
  )

  return (
    <>
      <LabelComponent handleOpen={handleOpen} icon={icon} label={label} showButton={showButton} />
      <Dialog
        keepMounted={false}
        open={isOpen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        fullScreen={fullScreen}
        className={className}
      >
        <Fade timeout={1000} in={isOpen}>
          <Box className={styles['modal-box']} sx={{ minWidth: 400 }}>
            <Card
              className={styles['modal-card']}
              style={{
                border: noBorder ? '0px' : '2px solid #ff5c8d',
                backgroundColor: backgroundColor ? backgroundColor : '#00244f',
              }}
            >
              {children}
            </Card>
          </Box>
        </Fade>
      </Dialog>
    </>
  )
}
