import { type FunctionComponent } from 'react'

import { downloadDriverInvoice } from '@api/Rest/download/driverInvoice.pdf'
import EyeIcon from '@components/common/icons/eye.icon'

import styles from './index.module.scss'
import { Button } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
export const ActionCell: FunctionComponent = ({ children }) =>
  children ? (
    <div
      title='en development'
      className={`${styles.invoiceTableDownloadDriverInvoicePdf}`}
      onClick={() => {
        downloadDriverInvoice(children as string)
      }}
    >
      <Button color='primary' variant='contained' size='small'>
        <VisibilityIcon fontSize='small' />
        Voir le détail
      </Button>
    </div>
  ) : (
    <></>
  )
