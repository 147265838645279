import { OfferStatus } from '@types_def/models/offer.types'

const getOfferColorByStatus = (status: OfferStatus) => {
  switch (status) {
    case OfferStatus.ACCEPTED:
      return 'success'
    case OfferStatus.REJECTED:
      return 'error'
    case OfferStatus.PENDING:
      return 'warning'
    default:
      return 'info'
  }
}

export { getOfferColorByStatus }
