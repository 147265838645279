import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  ConfirmedAdminActionCell,
  ContractCell,
  DateCell,
  DistanceCell,
  OrganizationCell,
  PilotActionCell,
  PriceCell,
  RouteCell,
  StatusCell,
} from '../CustomCells'

const confirmedHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Trajet',
    customCellComponent: RouteCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
]

const clientPilotCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  customCellComponent: PilotActionCell,
  passWholeObject: true,
  isNotSortable: true,
}

const adminPilotCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  customCellComponent: ConfirmedAdminActionCell,
  isNotSortable: true,
  passWholeObject: true,
}

const clientPriceCell: HeadCell<Delivery> = {
  id: 'clientInitialPrice',
  label: 'Montant Client',
  customCellComponent: PriceCell,
  isNotSortable: false,
}

const TotalDistanceCell: HeadCell<Delivery> = {
  id: 'distance',
  label: 'Distance totale',
  customCellComponent: DistanceCell,
  isNotSortable: false,
}

export const clientConfirmedHeadCells: Array<HeadCell<Delivery>> = [
  ...confirmedHeadCells,
  TotalDistanceCell,
  clientPriceCell,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  clientPilotCell,
]

export const adminConfirmedHeadCells: Array<HeadCell<Delivery>> = [
  ...confirmedHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Date de livraison estimée du client",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  TotalDistanceCell,
  clientPriceCell,
  adminPilotCell,
]
