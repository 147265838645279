import React, { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import Form from '@components/Delivery/DeliveryFormV2/Form'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import { useTranslation } from 'react-i18next'
import { Delivery } from '@/types/models/delivery.types'

const StepComponent = ({
  type,
  formId,
  stepsInputs,
  steps,
  data,
  title,
}: {
  type: 'two-way-delivery' | 'standard' | 'to-return' | 'okm'
  formId: string
  stepsInputs: any
  steps: any
  data: Delivery
  title: string
}) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    setCurrentStep(0)
  }, [type])
  const completedSteps = useCallback(() => {
    return Object.keys(completed).length
  }, [])
  const totalSteps = () => {
    return steps.length
  }
  const isLastStep = () => {
    return currentStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean
  }>({})
  const handleNext = () => {
    // ! handle form validation
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : currentStep + 1
    setCurrentStep(newActiveStep)
  }

  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleStep = (step: number) => () => {
    setCurrentStep(step)
  }
  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} padding={4}>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            fontWeight={600}
            textAlign={'center'}
            textTransform={'capitalize'}
          >
            {title}
          </Typography>
        </Grid>
        <Container
          disableGutters
          maxWidth='md'
          component='main'
          sx={{ pb: 4, textAlign: 'center', color: 'GrayText' }}
        >
          <Typography variant='body1' className='pricing-title'>
            {currentStep === 0
              ? `${t('delivery.form.step')} 1 : ${t('delivery.form.steps.step-one')}`
              : currentStep === 1
                ? `${t('delivery.form.step')} 2 : ${t('delivery.form.steps.step-two')}`
                : currentStep === 2
                  ? `${t('delivery.form.step')} 3 : ${t('delivery.form.steps.step-three')}`
                  : `${t('delivery.form.step')} 4 : ${t('delivery.form.steps.step-four')}`}
          </Typography>
        </Container>
        <Grid item xs={12}>
          <Stepper
            activeStep={currentStep}
            sx={{
              mb: 8,
            }}
          >
            {steps.map((label: string, index: any) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{
                  '& .MuiStepLabel-label': {
                    color: 'white',
                    xs: {
                      fontSize: '0.7rem',
                      fontWeight: 600,
                      textTransform: 'capitalize',
                      m: 1,
                    },
                    md: {
                      fontSize: '1rem',
                    },
                  },
                  '& .MuiStepLabel-iconContainer svg': {
                    xs: {
                      width: '1.5rem',
                      height: '1.5rem',
                    },
                    md: {
                      width: '2rem',
                      height: '2rem',
                    },
                  },
                }}
              >
                <StepButton color='inherit' onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={12}>
          <Form
            key={type}
            isRestitution={type === 'two-way-delivery'}
            formId={formId}
            stepsInputs={stepsInputs}
            data={data}
            type={type}
            step={currentStep}
            isLastStep={isLastStep()}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default StepComponent
