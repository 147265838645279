import { Stack } from '@mui/material'
import React from 'react'
import { NavigationButton, NavigationLink } from '../common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'
import { UserRole } from '@types_def/models/user.type'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useUserContext } from '@contexts/UserContext/index.context'

type Props = {}

const SideBar = (props: Props) => {
  const { userState } = useUserContext()
  return (
    <Stack
      height={'100%'}
      width={'100%'}
      sx={{
        borderRight: '2px solid #f1f1f1',
      }}
      padding={2}
      gap={2}
    >
      <NavigationButton
        to={
          userState['custom:role'] === UserRole.ADMIN
            ? RoutePaths.adminProfileAccount
            : RoutePaths.clientProfileAccount
        }
        includes={RoutePaths.account}
      >
        <AccountCircleIcon />
        Account
      </NavigationButton>
    </Stack>
  )
}

export default SideBar
