import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Params, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios, { sendRequest } from '@api/clients/axios'
import { confirmDelivery } from '@api/graphql/delivery/confirmDelivery'
import { createDelivery } from '@api/graphql/delivery/createDelivery'
import { getAdminRequestedDelivery } from '@api/graphql/delivery/getDelivery'
import { adminUpdateDeliveryForm } from '@api/graphql/delivery/updateDelivery'
import updateDeliveryStatus from '@api/graphql/delivery/updateDeliveryStatus'
import updateDeliveryType from '@api/graphql/delivery/updateDeliveryType'
import { findDeliveryLogs } from '@api/graphql/deliveryLog'
import { deleteOne } from '@api/graphql/generic/delete'
import { QueryType } from '@types_def/common/query.types'
import {
  CreateDeliveryInput,
  Delivery,
  DeliveryStatus,
  DeliveryType,
  TCarType,
  TwoWayDelivery,
} from '@types_def/models/delivery.types'
import { Vehicle, VehicleType } from '@types_def/models/vehicle.types'
import { RoutePaths } from '@utils/constants/routes'
import { SchemaKeys } from '@utils/functions/Delivery/schema'
import { t } from 'i18next'
import { TCancelDelivery } from '@schemas/DeliveryForm/CancelDeliverySchema'
import cancelDelivery from '@api/graphql/delivery/cancelDelivery'

const URL = import.meta.env.VITE_APP_API_BASE_URL

const initialAddress = {
  latitude: '',
  longitude: '',
  city: '',
  address: '',
  postalCode: '',
  place_id: '',
  addressPrecision: '',
}

const commun = {
  comment: '',
  clientFirstName: '',
  clientLastName: '',
  clientEmail: '',
  clientDrivingLicenceNumber: '',
  carAvailabilityDate: null,
  carPickUpDate: null,
  carDeliveryDeadline: null,
  clientPhone: '',
  clientSiret: '',
  clientCompany: '',
  representativeFirstName: '',
  representativeLastName: '',
  fromAddress: initialAddress,
  toAddress: initialAddress,
  representativeEmail: '',
  representativePhone: '',
  representativeSiret: '',
  representativeCompany: '',
  options: { selected: [] as string[] },
  garageOptions: { selected: [] as string[] },
  category: 'STANDARD',
  status: 'PROFESSIONAL',
  docs: [
    {
      name: 'ALB_PV.pdf',
      key: 'https://alb-media.s3.eu-west-3.amazonaws.com/defaults/PV+ALB.pdf',
      url: 'https://alb-media.s3.eu-west-3.amazonaws.com/defaults/PV+ALB.pdf',
    },
  ],
}
const vehicle = {
  vin: '',
  carType: 'A' as TCarType,
  vehicleCode: '',
  marque: '',
  model: '',
  toRetrieve: false,
  type: VehicleType.GAS,
}

const StandardDeliveryData = {
  ...commun,
  numberOfVehicles: 1,
  type: DeliveryType.STANDARD,
  twoWayDeliverType: null,
  vehicles: [vehicle],
}

const ToReturnDeliveryData = {
  ...StandardDeliveryData,
  type: DeliveryType.TO_RETURN,
}
const TwoWayDeliveryData = {
  ...commun,
  numberOfVehicles: 2,
  type: DeliveryType.TWO_WAY_DELIVERY,
  twoWayDeliverType: TwoWayDelivery.EXCHANGE,
  toReturnFromAddress: initialAddress,
  toReturnToAddress: undefined,
  vehicles: [
    vehicle,
    {
      ...vehicle,
      toRetrieve: true,
    },
  ],
}
const initialDelivery = {
  standard: {
    ...commun,
    numberOfVehicles: 1,
    type: DeliveryType.STANDARD,
    twoWayDeliverType: null,
    vehicles: [vehicle],
  },
  'to-return': {
    ...commun,
    numberOfVehicles: 1,
    type: DeliveryType.TO_RETURN,
    twoWayDeliverType: null,
    vehicles: [vehicle],
  },
  'two-way-delivery': {
    ...commun,
    numberOfVehicles: 2,
    twoWayDeliverType: TwoWayDelivery.EXCHANGE,
    type: DeliveryType.TWO_WAY_DELIVERY,
    toReturnFromAddress: initialAddress,
    toReturnToAddress: undefined,
    vehicles: [
      vehicle,
      {
        ...vehicle,
        toRetrieve: Boolean(true),
      },
    ],
  },
} as unknown as Delivery

export type defaultValues = {
  car?: Vehicle
  documents?: Array<{ name: string; key: string; url: string; deliveryType: DeliveryType }>
}

export const generateInitialDelivery = (type: SchemaKeys) => {
  switch (type) {
    case 'standard':
      return StandardDeliveryData
    case 'okm':
      return {
        ...{
          ...StandardDeliveryData,
          type: DeliveryType['OKM'],
        },
      }
    case 'to-return':
      return {
        ...ToReturnDeliveryData,
      }
    case 'two-way-delivery':
      return {
        ...TwoWayDeliveryData,
      }
    default:
      return {}
  }
}
const deleteDeliveryDoc = async (key: string) => {
  const fileKeyToDelete = encodeURIComponent(key)
  await axios.delete('/s3/' + fileKeyToDelete)
}
const useFindDeliveryLog = ({
  deliveryId,
  page,
  pageSize = 5,
}: {
  deliveryId: number
  page: number
  pageSize?: number
}) => {
  return useQuery({
    queryKey: ['deliveryLog', deliveryId, page, pageSize],
    queryFn: async () => {
      const res = await findDeliveryLogs(deliveryId, page, pageSize)
      return res
    },
  })
}

const useDeliveryDetails = (fields?: QueryType<Delivery>, key?: string[]) => {
  const { contract, id } = useParams<Params>()
  if (!contract && !id) throw new Error('Invalid delivery id')
  const idToUse = contract ?? id

  return useQuery({
    queryKey: ['delivery', idToUse, key],
    queryFn: async () => {
      const res = await getAdminRequestedDelivery(idToUse, fields)

      return { ...res, docs: typeof res.docs === 'string' ? JSON.parse(res.docs) : res.docs } //! it's IMPORTANT to parse the docs string to JSON or schema will be complinig about expected array recived string
    },
  })
}

const useUpdateDelivery = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (data: CreateDeliveryInput & { id: number }) =>
      adminUpdateDeliveryForm(data.id, data),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['delivery', data.id] })
      toast(t('network.delivery.updated'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

const useCreateDelivery = () => {
  return useMutation({
    mutationFn: createDelivery,
    onSuccess: () => {
      toast(t('network.delivery.created'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      console.log('useCreateDelivery::error', e)
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

const useConfirmDelivery = () => {
  return useMutation({
    mutationFn: (id: number) => confirmDelivery(id),
    onSuccess: () => {
      toast(t('network.delivery.confirmed'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

const useCancelDelivery = () => {
  return useMutation({
    mutationFn: (data: { id: number; reason: TCancelDelivery }) => cancelDelivery(data),
    onSuccess: () => {
      toast(t('network.delivery.cancelled'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
      console.error(e)
    },
  })
}

const useDeleteDelivery = () => {
  return useMutation({
    mutationFn: (id: number) => deleteOne(id, 'Delivery'),
    onSuccess: () => {
      toast(t('network.delivery.deleted'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
      console.error(e)
    },
  })
}

const useUpdateDeliveryStatus = () => {
  return useMutation({
    mutationFn: (data: { id: number; status: DeliveryStatus }) => updateDeliveryStatus(data),
    onSuccess: () => {
      toast(t('network.delivery.status.updated'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
      console.error(e)
    },
  })
}

const useUpdateDeliveryType = () => {
  return useMutation({
    mutationFn: (data: { id: number; type: DeliveryType }) => updateDeliveryType(data),
    onSuccess: () => {
      toast(t('network.delivery.type.updated'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
      console.error(e)
    },
  })
}

const useGetSmsLink = (id: number) => {
  return useQuery({
    queryKey: ['smsLink', id],
    queryFn: async () => {
      const res = await sendRequest<never, string>(
        'get',
        `${URL}delivery/sms-confirmation-url/${id}`,
      )
      const apiUrl = URL
      const route = RoutePaths.deliveryCustomerConfirmation
      const token = res.data
      return `${apiUrl}${route}?token=${token}`
    },
  })
}

const useSendSMS = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (data: { id: number; clientPhone: string; message: string }) =>
      axios.request({
        method: 'POST',
        url: `${URL}sms/send-delivery-confirmation`,
        data: {
          deliveryId: data.id,
          recipient: data.clientPhone,
          message: data.message,
        },
      }),
    onSuccess: (data: any) => {
      toast(t('network.delivery.sms.sent'), {
        position: 'bottom-right',
        type: 'success',
      })
      client.invalidateQueries({ queryKey: ['deliveryDetails', data?.id] })
    },
    onError: (e: any) => {
      toast(e?.message?.split(': ')[1] ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

export {
  useDeliveryDetails,
  useUpdateDelivery,
  useCreateDelivery,
  useConfirmDelivery,
  useDeleteDelivery,
  initialDelivery,
  useCancelDelivery,
  useGetSmsLink,
  useSendSMS,
  useUpdateDeliveryStatus,
  useUpdateDeliveryType,
  deleteDeliveryDoc,
  useFindDeliveryLog,
}
