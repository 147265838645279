import { z } from 'zod'
import errorMessages from '../errorMessages'
const TCarType = ['A', 'B', 'C'] as const
const TVehicleType = ['GAS', 'HYBRID', 'ELECTRIC'] as const

export const Vehicle = z.object({
  vin: z.string().optional(),
  carType: z.enum(TCarType),
  vehicleCode: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  marque: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  model: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  toRetrieve: z.nullable(z.boolean()),
  garageImages: z
    .object({
      photo1: z.string(),
      photo2: z.string(),
      photo3: z.string(),
      photo4: z.string(),
      photo5: z.string(),
      photo6: z.string(),
    })
    .or(z.undefined()),
  type: z.enum(TVehicleType),
})
