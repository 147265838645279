const ListIcon = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.6129 16C3.09556 16 3.48387 15.5865 3.48387 15.0769V13.2308C3.48387 12.7192 3.09556 12.3077 2.6129 12.3077H0.870968C0.390121 12.3077 0 12.7192 0 13.2308V15.0769C0 15.5865 0.390121 16 0.870968 16H2.6129ZM16.8387 15.3846C17.481 15.3846 18 14.8335 18 14.1538C18 13.4731 17.481 12.9231 16.8387 12.9231H6.3871C5.74476 12.9231 5.22581 13.4731 5.22581 14.1538C5.22581 14.8335 5.74476 15.3846 6.3871 15.3846H16.8387ZM16.8387 9.23077C17.481 9.23077 18 8.68077 18 8C18 7.31923 17.481 6.76923 16.8387 6.76923H6.3871C5.74476 6.76923 5.22581 7.31923 5.22581 8C5.22581 8.68077 5.74476 9.23077 6.3871 9.23077H16.8387ZM16.8387 3.07692C17.481 3.07692 18 2.52692 18 1.84615C18 1.16538 17.481 0.615384 16.8387 0.615384H6.3871C5.74476 0.615384 5.22581 1.16538 5.22581 1.84615C5.22581 2.52692 5.74476 3.07692 6.3871 3.07692H16.8387ZM0 8.92308C0 9.43462 0.390121 9.84615 0.870968 9.84615H2.6129C3.09556 9.84615 3.48387 9.43462 3.48387 8.92308V7.07692C3.48387 6.56538 3.09556 6.15385 2.6129 6.15385H0.870968C0.390121 6.15385 0 6.56538 0 7.07692V8.92308ZM2.6129 3.69231C3.09556 3.69231 3.48387 3.28077 3.48387 2.76923V0.923078C3.48387 0.411539 3.09556 0 2.6129 0H0.870968C0.390121 0 0 0.411539 0 0.923078V2.76923C0 3.28077 0.390121 3.69231 0.870968 3.69231H2.6129Z'
      fill='#fff'
    />
  </svg>
)

export default ListIcon
