import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  AcceptedOfferDriver,
  AddressCell,
  ContractCell,
  DateCell,
  DeliveryStatusCell,
  DistanceCell,
  LoginCell,
  OnGoingAdminActionCell,
  OrganizationCell,
  PilotActionCell,
} from '../CustomCells'

const initialCells: Array<HeadCell<Delivery>> = [
  {
    id: 'id',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  // {
  //   id: 'createdAt',
  //   label: 'Créé à',
  //   customCellComponent: DateCell,
  //   isNotSortable: false,
  // },
]

export const clientOngoingHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Livraison prévue le",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'clientLastName',
    label: 'Client',
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Departe',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Livraison',
    customCellComponent: AddressCell,
    isNotSortable: false,
  },

  {
    id: 'distance',
    label: 'Distance totale',
    customCellComponent: DistanceCell,
    isNotSortable: false,
  },
  // {
  //   id: 'remainingDistance',
  //   label: 'Distance restante',
  //   customCellComponent: RemainingDistanceCell,
  //   isNotSortable: false,
  // },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: PilotActionCell,
    isNotSortable: true,
    passWholeObject: true,
  },
]

export const adminOngoingHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  {
    id: 'status',
    label: 'status',
    customCellComponent: DeliveryStatusCell,
    isNotSortable: false,
  },
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Le client s'attend à être livré le",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },

  // {
  //   id: "adminEstimatedDeliveryDate",
  //   label: "Livraison prévue le",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'clientLastName',
    label: 'Client',
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Departe',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Arrivée',
    customCellComponent: AddressCell,
    isNotSortable: true,
  },
  // {
  //   id: 'numberOfVehicles',
  //   label: 'Nb. véhicules',
  //   isNotSortable: false,
  // },
  {
    id: 'acceptedOffer',
    label: 'Driver',
    customCellComponent: AcceptedOfferDriver,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: OnGoingAdminActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
