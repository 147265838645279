import { type PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import FlagIcon from '@components/common/icons/flag.icon'
import HourglassIcon from '@components/common/icons/hourglass.icon'
import RoadIcon from '@components/common/icons/road.icon'
import { DeliveryProgress } from '@types_def/models/delivery.types'

import styles from './index.module.scss'

type Props = {
  title: string
  type?: DeliveryProgress
  link?: string
  linkText?: string
  icon?: JSX.Element
}

const DashboardDataBox = ({
  type,
  icon,
  title,
  link,
  linkText,
  children,
}: PropsWithChildren<Props>) => (
  <div className={`${styles.dashboardDataBox} item`}>
    <div className={`${styles.dashboardDataBoxTitle}`}>
      {type == DeliveryProgress.DELIVERED && <FlagIcon />}
      {type == DeliveryProgress.IN_PROGRESS && <RoadIcon />}
      {type == DeliveryProgress.PENDING && <HourglassIcon />}
      {icon}
      {title}
    </div>
    {children}
    {link && (
      <div>
        <Link to={link} className={`${styles.moreDetails}`}>
          {linkText}
        </Link>
      </div>
    )}
  </div>
)

export default DashboardDataBox
