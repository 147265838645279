import axios from 'axios'
import { type PropsWithChildren, useEffect, useState } from 'react'

import { getDeliveryToBeRated } from '@api/graphql/delivery/getDeliveryByCode'
import LoadingIcon from '@components/common/icons/loading.icon'
import { useIdToken } from '@hooks/idToken'
import { useCheckIfMounted } from '@hooks/mountingRef'
import { type RatingType } from '@types_def/components/rating'

import { ratings } from '../utils/constants'
import RatingError from './Error'
import styles from './index.module.scss'
import RatingLoading from './loading'
import DeliveryRatingStar from './RatingStar'

const divideRatingValues = (ratings: RatingType): RatingType => {
  const dividedRatings = { ...ratings }
  for (const key in ratings) {
    dividedRatings[key as keyof RatingType] = ratings[key as keyof RatingType] / 20
  }

  return dividedRatings
}

const rateDelivery = async (token: string, rating: RatingType) => {
  const url = import.meta.env.VITE_APP_API_BASE_URL + 'delivery/rate'

  const res = await axios.request({
    method: 'POST',
    url,
    headers: {
      token,
      'Content-Type': 'application/json',
    },
    data: { ...rating },
  })
  return res
}

const DeliveryRating = () => {
  const [loading, setLoading] = useState(true)
  const [rated, setRated] = useState(false)
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [ratingsValues, setRatingsValues] = useState<RatingType>({
    outfitRating: 0,
    takeOffRating: 0,
    qualityRating: 0,
    communicationRating: 0,
    correspondenceRating: 0,
    justiceRating: 0,
  })
  const isMountedRef = useCheckIfMounted()

  const { id, token } = useIdToken()

  const handleRatingChange = (val: number, key: keyof RatingType) => {
    setRatingsValues((prev) => ({
      ...prev,
      [key]: val,
    }))
  }

  const getDelivery = async () => {
    if (!id) {
      setError("Le code de livraison n'a pas été fourni correctement")
      return
    }

    setLoading(true)
    try {
      const delivery = await getDeliveryToBeRated(Number(id))
      if (delivery.rated) {
        setRatingsValues((prev) => ({
          ...prev,
          outfitRating: delivery.outfitRating! * 20,
          takeOffRating: delivery.takeOffRating! * 20,
          qualityRating: delivery.qualityRating! * 20,
          communicationRating: delivery.communicationRating! * 20,
          correspondenceRating: delivery.communicationRating! * 20,
          justiceRating: delivery.communicationRating! * 20,
        }))
        if (isMountedRef) {
          setRated(true)
        }
      }
    } catch (error: any) {
      if (error?.message && typeof error.message === 'string') {
        if (isMountedRef) {
          setError(error.message as string)
        }
      }
    } finally {
      if (isMountedRef) {
        setLoading(false)
      }
    }
  }

  const handleSubmit = async () => {
    if (!id || !token) {
      return
    }

    setSubmitting(true)
    try {
      const ratings = await rateDelivery(token, divideRatingValues(ratingsValues))
      if (ratings == undefined) {
        throw new Error("Une erreur est survenue lors de l'envoi de votre note")
      }

      if (isMountedRef) {
        setRated(true)
      }
    } catch (error: any) {
      if (error?.message && typeof error.message === 'string') {
        if (isMountedRef) {
          setError(error.message as string)
        }
      }
    } finally {
      if (isMountedRef) {
        setSubmitting(false)
      }
    }
  }

  const onSubmit = () => {
    handleSubmit()
  }

  useEffect(() => {
    getDelivery()
  }, [id])

  const Wrapper = ({ children }: PropsWithChildren<Record<never, never>>) => (
    <div className={styles.deliveryRatingContainer}>
      <div className={styles.deliveryRating}>
        <header className={styles.header}>
          <img src='/images/logo.svg' alt='logo' className={`${styles.logo}`} />
          Merci d'avoir utilisé notre service ! <br />
          Vous pouvez évaluer votre expérience avec nous.
        </header>
        <main>{children}</main>
      </div>
    </div>
  )

  if (loading) {
    return (
      <Wrapper>
        <RatingLoading />
      </Wrapper>
    )
  }

  if (error) {
    return (
      <Wrapper>
        <RatingError error={error} />
      </Wrapper>
    )
  }

  return (
    <div className={styles.deliveryRatingContainer}>
      <div className={styles.deliveryRating}>
        <header className={styles.header}>
          <img src='/images/logo.svg' alt='logo' className={`${styles.logo}`} />
          Merci d'avoir utilisé notre service ! <br />
          Vous pouvez évaluer votre expérience avec nous.
        </header>
        <main>
          <div>
            {ratings.map((rating) => (
              <DeliveryRatingStar
                key={rating.key}
                ratingKey={rating.key}
                value={ratingsValues[rating.key]}
                label={rating.label}
                viewOnly={rated}
                handleRatingChange={handleRatingChange}
              />
            ))}
            {!rated && (
              <button onClick={onSubmit} className={styles.submit} disabled={rated || submitting}>
                Poster Mon Avis {submitting && <LoadingIcon />}
              </button>
            )}
            {rated && <h3 className={styles.thankYou}>Merci pour votre avis!</h3>}
          </div>
        </main>
      </div>
    </div>
  )
}

export default DeliveryRating
