import React, { useEffect } from 'react'
import { Mission, PreviewType } from './type'
import { useMap } from '@vis.gl/react-google-maps'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import CustomMarker from './Marker'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import { addRoutes } from './utils'
import { useTranslation } from 'react-i18next'

type Props = {
  mission: Mission
  previewType: PreviewType
}

const PreviewMissionMarkers = ({ mission, previewType }: Props) => {
  const { t } = useTranslation()
  const map = useMap()
  useEffect(() => {
    const bounds = new google.maps.LatLngBounds()
    if (previewType === PreviewType.AB) {
      // we will check if the origin and destination are of valid types eg: LatLngLiteral or LatLng
      if (
        typeof mission.origin?.lat === 'undefined' ||
        typeof mission.destination?.lat === 'undefined'
      ) {
        console.warn('origin or destination is undefined')
        return
      }

      bounds.extend(mission.origin)
      bounds.extend(mission.destination)
      addRoutes(map, {
        origin: mission.origin,
        destination: mission.destination,
        index: 2,
      })
      map.fitBounds(bounds)
    } else if (previewType === PreviewType.BC) {
      // we will check if the destination and return are valid
      if (
        typeof mission.destination.lat === 'undefined' ||
        typeof mission.toReturn.lat === 'undefined'
      ) {
        console.warn('destination or return is undefined')
        return
      }
      bounds.extend(mission.destination)
      if (mission.toReturn) {
        bounds.extend(mission.toReturn)
      }
      addRoutes(map, {
        color: getDeliveryTypeColor(mission.type),
        origin: mission.destination,
        destination: mission.toReturn,
        weight: 2,
        index: 1,
      })
      map.fitBounds(bounds)
    } else if (previewType === PreviewType.ABC) {
      // we will check if the origin, destination and return are valid
      if (
        typeof mission.origin.lat === 'undefined' ||
        typeof mission.destination.lat === 'undefined' ||
        typeof mission.toReturn.lat === 'undefined'
      ) {
        console.warn('origin, destination or return is undefined')
        return
      }
      const datas = [
        {
          color: getDeliveryTypeColor(mission.type),
          origin: mission.destination,
          destination: mission.toReturn,
          weight: 2,
          index: 1,
        },
        {
          origin: mission.origin,
          destination: mission.destination,
          index: 2,
        },
      ]
      const bounds = new google.maps.LatLngBounds()
      datas.forEach((data) => {
        addRoutes(map, data)
        bounds.extend(data.origin)
        bounds.extend(data.destination)
      })
      map.fitBounds(bounds)
    }
  }, [previewType, map, mission])

  return (
    <>
      <CustomMarker
        key={mission.id + 'destination-marker'}
        mission={mission}
        title={
          <h5
            className='marker-title'
            style={{
              whiteSpace: 'normal',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                textDecoration: `underline ${getDeliveryTypeColor(mission.type)}`,
                textUnderlineOffset: '2px',
                textTransform: 'capitalize',
              }}
            >
              {t('common.shared.arrival-point')}
            </span>
            <span
              style={{
                width: 13,
                height: 13,
                background: getDeliveryTypeColor(mission.type),
                display: 'block',
                borderRadius: '50%',
              }}
            ></span>
          </h5>
        }
        description={`${mission.destinationCity}`}
        position={mission.destination}
        map={map}
        extendable={mission.status === DeliveryStatus.ONGOING}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            position: 'relative',
            zIndex: 99,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0.47,
          }}
        >
          <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
            {mission.arrive}
          </Typography>
        </motion.div>
      </CustomMarker>
      {/* origin marker */}

      {previewType !== PreviewType.BC && mission.origin?.lat ? (
        <CustomMarker
          extraStyle={{
            bgColor: '#3B3C70',
          }}
          key={mission.id + 'origin-marker'}
          mission={mission}
          title={
            <h5
              className='marker-title'
              style={{
                whiteSpace: 'normal',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ textTransform: 'capitalize' }}>
                {t('common.shared.departure-point')}
              </span>
            </h5>
          }
          description={`${mission.originCity}`}
          position={mission.origin}
          map={map}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
              {mission.depart}
            </Typography>
          </div>
        </CustomMarker>
      ) : null}
      {/* restitution */}
      {previewType !== PreviewType.AB && mission.toReturn?.lat ? (
        <CustomMarker
          extraStyle={{
            bgColor: '#28293d',
          }}
          key={mission.id + 'restitution-marker'}
          mission={mission}
          title={
            <h5
              className='marker-title'
              style={{
                whiteSpace: 'normal',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>{t('common.shared.restitution-point')}</span>
            </h5>
          }
          position={mission.toReturn}
          map={map}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
              {mission.depart}
            </Typography>
          </div>
        </CustomMarker>
      ) : null}
    </>
  )
}

export default PreviewMissionMarkers
