const StarIcon = () => (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6527 5.2847L17.6216 6.02992C18.0331 6.08968 18.3788 6.38495 18.5102 6.7892C18.6416 7.19696 18.5344 7.63987 18.2371 7.94218L14.634 11.5347L15.4846 16.688C15.5538 17.1098 15.3844 17.5386 15.0386 17.7882C14.6963 18.0378 14.2433 18.0694 13.8733 17.8691L9.43348 15.4612L4.99711 17.8691C4.62367 18.0694 4.17069 18.0378 3.82837 17.7882C3.48605 17.5386 3.31316 17.1098 3.38577 16.688L4.23639 11.5347L0.632662 7.94218C0.334945 7.63987 0.229136 7.19696 0.359841 6.7892C0.490201 6.38495 0.834944 6.08968 1.24954 6.02992L6.21426 5.2847L8.44109 0.633053C8.62435 0.24582 9.01163 0 9.43348 0C9.85879 0 10.2461 0.24582 10.4293 0.633053L12.6527 5.2847Z'
      fill='#000'
    />
  </svg>
)

export default StarIcon
