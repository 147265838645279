import { Grid } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getDashboardDelivery } from '@api/graphql/delivery/getDelivery'
import Skeleton from '@components/common/Skeleton'
import { useUserContext } from '@contexts/UserContext/index.context'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'
import { RoutePaths } from '@utils/constants/routes'
import { formatRoute } from '@utils/functions/formatRoute'

import styles from './index.module.scss'

type Props = {
  title: string
  type: DeliveryStatus
  link: string
  linkText: string
  icon: JSX.Element
}

type DeliveriesData = {
  records: Array<Partial<Delivery>>
  total: number
}

const DashboardDataGrid = ({ title, type, link, linkText, icon }: Props) => {
  const id = useUserContext().userState?.organizationId
  const [deliveries, setDeliveries] = useState<DeliveriesData>()

  const getData = () => {
    getDashboardDelivery(type, id!).then((res) => {
      setDeliveries(res)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className={`${styles.dashboardDataBoxContainer} item`}>
      {deliveries ? (
        <div className={`${styles.dashboardDataBox} item`}>
          <div className={`${styles.dashboardDataBoxTitle}`}>
            {icon}
            {title} {deliveries.total}
          </div>
          <Grid
            container
            spacing={2}
            paddingY={2.5}
            fontSize={'var(--small-font-size)'}
            overflow={'hidden'}
          >
            {deliveries.records?.slice(0, 10).map((delivery, index) => {
              const remainingTime = delivery?.duration ? (delivery.duration / 60).toFixed(2) : 0
              return (
                <Fragment key={`data-grid-${index}`}>
                  <Grid item xs={type === DeliveryStatus.WAITING_FOR_DRIVER ? 1 : 2}>
                    {delivery.publicId}
                  </Grid>
                  <Grid item xs={5}>
                    {`${delivery.fromAddress?.city} > ${delivery.toAddress?.city}`}
                  </Grid>
                  <Grid item xs={2}>
                    {`${delivery.distance}km`}
                  </Grid>
                  {type === DeliveryStatus.WAITING_FOR_DRIVER && (
                    <Grid item xs={1} justifyContent={'flex-start'}>
                      {`${remainingTime}h`}
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <Link
                      to={`${RoutePaths.clientPilot}/${formatRoute(delivery.type)}/${formatRoute(
                        delivery.status,
                      )}/${delivery.id}`}
                    >
                      Voir le détail
                    </Link>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid>
          <div>
            <Link to={link} className={`${styles.moreDetails}`}>
              {linkText}
            </Link>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </div>
  )
}

export default DashboardDataGrid
