import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { Doc } from '@types_def/models/delivery.types'
import { deleteDeliveryDoc } from '../Delivery/DeliveryFormV2/Querys'
import FilesGrid from './FilesGrid'

type TFileUpload = {
  name?: string
  remove?: boolean
  forceAllowEdit?: boolean
  id?: string | number
  initialValue?: Doc[]
}

const FileUpload: React.FC<TFileUpload> = ({
  name = 'docs',
  remove = true,
  forceAllowEdit = undefined,
  id = undefined,
  initialValue = [],
}) => {
  const { t } = useTranslation()
  const { field, fieldState } = useController({
    name,
    rules: { required: true },
  })

  const [uploadedFiles, setUploadedFiles] = useState<Doc[]>(() => {
    // Initialize with the defaultValue or field.value, whichever is non-empty
    // if initialValue is already there then it will not be added again
    const value = field.value as Doc[]
    if (!value || value.length === 0) return initialValue
    const oldDocIndex = value.findIndex((doc) => doc.name?.includes('Handover'))
    if (oldDocIndex >= 0) {
      value.splice(oldDocIndex, 1)
    }
    return [...initialValue, ...value]
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // Only update the field if uploadedFiles have changed
    if (JSON.stringify(field.value) !== JSON.stringify(uploadedFiles)) {
      field.onChange(uploadedFiles)
    }
  }, [uploadedFiles, field])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true)
      field.onBlur()

      try {
        const newDocs = await Promise.all(
          acceptedFiles.map(async (file) => {
            const formData = new FormData()
            formData.append('file', file)
            if (id && forceAllowEdit) {
              formData.append('key', `deliveryDocs/delivery-${id}/validationDocs/${file.name}`)
            }

            const { data } = await axios.post('/s3/upload-files', formData)
            return {
              name: id && forceAllowEdit ? data.key : data.key.split('-')[1],
              key: data.key,
              url: data.Location,
            }
          }),
        )

        const uniqueFiles = newDocs.filter(
          (newFile) => !uploadedFiles.some((existingFile) => existingFile.name === newFile.name),
        )

        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles])
        toast.success(t('network.delivery.documents.uploaded'), { position: 'bottom-right' })
      } catch (error) {
        console.error('Error uploading files:', error)
        toast.error(t('network.errors.unknown'))
      } finally {
        setLoading(false)
      }
    },
    [uploadedFiles, id, forceAllowEdit, t, field],
  )

  const changeFileState = useCallback(
    async (file: Doc, deleted: boolean) => {
      if (remove && file.key) {
        try {
          await deleteDeliveryDoc(file.key)
          setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.key !== file.key))
          toast.success(t('network.delivery.documents.deleted'))
        } catch (error) {
          console.error('Error deleting file:', error)
          toast.error(t('network.errors.unknown'))
        }
      } else {
        setUploadedFiles((prevFiles) =>
          prevFiles.map((f) => (f.key === file.key ? { ...f, deleted } : f)),
        )
      }
    },
    [remove, t],
  )

  const disabled = forceAllowEdit !== undefined ? !forceAllowEdit : loading
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    disabled,
  })

  // const handleClear = () => {
  //   setUploadedFiles([])
  // }

  return (
    <Grid container spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform={'capitalize'}>
          {t('delivery.form.documents')}{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          ref={field.ref}
          {...getRootProps()}
          sx={{
            minHeight: 180,
            border: '1px dashed',
            borderColor: disabled ? '#9e9e9e' : ' #fe5d8d',
            display: 'grid',
            placeItems: 'center',
            cursor: disabled ? 'not-allowed' : 'pointer',
            position: 'relative',
            bgcolor: disabled ? '#bdbdbd' : 'transparent',
          }}
        >
          {/* <IconButton
            sx={{
              position: 'absolute',
              top: -5,
              right: -14,
            }}
            onClick={handleClear}
            disabled={uploadedFiles.length === 0}
          >
            <ClearIcon />
          </IconButton> */}

          <input {...getInputProps()} />
          {loading ? (
            <CircularProgress />
          ) : isDragActive ? (
            <p>Drop only PDF files here...</p>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                alignItems: 'center',
              }}
            >
              <Typography>{t('delivery.form.docs-upload-note')}</Typography>
              {disabled ? <CloudOffIcon fontSize='large' /> : <CloudUploadIcon fontSize='large' />}
            </Box>
          )}
          {fieldState.error && <p style={{ color: 'red' }}>{JSON.stringify(fieldState.error)}</p>}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <FilesGrid changeFileState={changeFileState} disabled={disabled} docs={uploadedFiles} />
      </Grid>
    </Grid>
  )
}

export default FileUpload
