import { DeliveryStatus } from '@types_def/models/delivery.types'
import { getDeliveryAttributesByStatus } from '@utils/functions/Delivery/DeliveryStatus/deliveryStatusAttributes'

export const deliveryStatusRoutes = {
  requested: getDeliveryAttributesByStatus(DeliveryStatus.REQUESTED),
  cancelled: getDeliveryAttributesByStatus(DeliveryStatus.CANCELLED),
  ongoing: getDeliveryAttributesByStatus(DeliveryStatus.ONGOING),
  set: getDeliveryAttributesByStatus(DeliveryStatus.SET),
  alert: getDeliveryAttributesByStatus(DeliveryStatus.ALERT),
  completed: getDeliveryAttributesByStatus(DeliveryStatus.COMPLETED),
  'waiting-for-driver': getDeliveryAttributesByStatus(DeliveryStatus.WAITING_FOR_DRIVER),
  confirmed: getDeliveryAttributesByStatus(DeliveryStatus.CONFIRMED),
  'pending-customer-confirmation': getDeliveryAttributesByStatus(
    DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
  ),
  'confirmed-by-customer': getDeliveryAttributesByStatus(DeliveryStatus.CONFIRMED_BY_CUSTOMER),
  'cancelled-by-customer': getDeliveryAttributesByStatus(DeliveryStatus.CANCELLED_BY_CUSTOMER),
  'rescheduled-by-admin': getDeliveryAttributesByStatus(DeliveryStatus.RESCHEDULED_BY_ADMIN),
}
