import { type ClientDashboardMonthDeliveryData } from '@types_def/models/delivery.types'
import { getObjectKeys } from '@utils/functions/object.functions'

import styles from './index.module.scss'

type Props = {
  active: boolean
  payload: Array<{ payload: ClientDashboardMonthDeliveryData & { name: string } }>
  label: string
}

const chartKeys: Record<keyof ClientDashboardMonthDeliveryData, string> = {
  totalDeliveries: 'Nombre de livraisons',
  totalPrice: 'Montant total payé',
  averagePrice: 'Montant moyen payé',
  totalDistance: 'Distance totale parcourue',
  averageDistance: 'Distance moyenne parcourue',
}

const CustomChartTooltip = ({ active, payload, label: _label }: Props) => {
  if (!active || !payload || !payload.length) {
    return null
  }

  if (payload[0].payload.totalDeliveries === 0) {
    return <div className={styles.customChartTooltip}>Il n'y a pas eu de livraisons ce mois-ci</div>
  }

  return (
    <div className={styles.customChartTooltip}>
      {getObjectKeys(chartKeys).map((key, i) => (
        <p key={`custom-tooltip-chart-key-${i}`}>
          {chartKeys[key]} : {payload[0].payload[key]} {i !== 0 ? (i < 3 ? '€' : 'km') : null}
        </p>
      ))}
    </div>
  )
}

export default CustomChartTooltip
