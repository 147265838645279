import { useQuery } from '@tanstack/react-query'
import { sendRawRequest } from '@api/clients/axios'

const findMe = async () => {
  const response = await sendRawRequest<any, any>('post', '/organization/find-me')
  return response.data
}

const useFindMe = (sub: string) => {
  return useQuery({ queryKey: ['findMe', sub], queryFn: findMe })
}

export { useFindMe }
