import { type PropsWithChildren } from 'react'

import { type DeliveryStatus, type DeliveryType } from '@types_def/models/delivery.types'

import styles from './index.module.scss'

type Props = {
  id: number
  status: DeliveryStatus
  type: DeliveryType
}

const AdminActionCellContainer = ({ children }: PropsWithChildren<Props>) => <>{children}</>

export default AdminActionCellContainer
