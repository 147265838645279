import { Grid, Paper, Stack, Button } from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Delivery, DeliveryType } from '@types_def/models/delivery.types'
import Client from '../Client'
import Mandataire from '../Mandataire'
import MissionDetails from '../MissionDetails'
import Address from '../Address'
import DeliveryMap from '../DeliveryMap'
import MissionDate from '../MissionDate'
import InformationSupp from '../InformationSupp'
import MainButtons from '../MainButtons'
import { TFormActions } from '../Types'
import { buttonsConfig } from '../MainButtons/config'
import { zodResolver } from '@hookform/resolvers/zod'
import Vehicle from '../Vehicles/Vehicle'
import ComponentContainer from '../ComponentContainer'
import { useFormSessionsessionStorage } from '../hooks'
import ComponentContainerAccordion from '../ComponentContainer/ComponentContainerAccordin'
import Summary from '../summary/Summary'
import { PreviewType } from '@components/Map/type'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'
import { Schemas } from '@utils/functions/Delivery/schema'
import { useTranslation } from 'react-i18next'
import DocumentsComponent from './documents.component'
// import { DevTool } from '@hookform/devtools'

type TForm = {
  isRestitution?: boolean
  stepsInputs: Array<any>
  data: Delivery
  type: keyof typeof Schemas
  step: number
  isLastStep: boolean
  formId: string
  handleNext: () => void
  handleBack: () => void
}

const Form = ({
  data,
  step,
  isLastStep,
  type,
  stepsInputs,
  formId,
  handleNext,
  handleBack,
  isRestitution,
}: TForm) => {
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const isAdmin = userState?.['custom:role'] === UserRole.ADMIN
  const settings = !isAdmin
    ? userState?.settings
    : { one_date: false, no_docs: false, defaultValues: { documents: undefined, car: undefined } }
  const methods = useForm({
    resolver: zodResolver(Schemas[type]),
    defaultValues: data,
  })

  const mainButtons: TFormActions = buttonsConfig({
    id: data.id,
    reset: methods.reset,
  })

  const stepValidate = async () => {
    try {
      const isValid = await methods.trigger(stepsInputs[step], { shouldFocus: true })
      if (isValid) {
        saveToSessionsessionStorage(step)
        handleNext()
      }
    } catch (e) {}
  }

  const { resetSessionsessionStorage, saveToSessionsessionStorage } = useFormSessionsessionStorage(
    formId,
    methods,
  )
  const options = useWatch({ name: 'options', control: methods.control })
  const comment = useWatch({ name: 'comment', control: methods.control })
  const category = useWatch({ name: 'category', control: methods.control })

  return (
    <FormProvider {...methods}>
      <Grid
        container
        component={'form'}
        spacing={4}
        onSubmit={(e) => {
          e.preventDefault()
          resetSessionsessionStorage()
          mainButtons.submit(methods.getValues())
          //methods.handleSubmit(mainButtons.submit)
        }}
        id='deliveryFormV2'
      >
        {step === 0 ? (
          <>
            <Grid item xs={12} lg={isAdmin ? 6 : 12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <Client />
              </Paper>
            </Grid>
            {/* {isAdmin && ( */}
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: !isAdmin ? 'none' : undefined,
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <Mandataire />
              </Paper>
            </Grid>
            {/* )} */}

            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <MissionDate settings={settings} />
              </Paper>
            </Grid>
          </>
        ) : null}
        {step === 1 ? (
          <>
            <Grid item xs={12} lg={6}>
              <Stack gap={4}>
                <Paper
                  elevation={4}
                  sx={{
                    backgroundColor: '#E7E6F7',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Address identifier='fromAddress' title={t('delivery.form.departure-address')} />
                </Paper>
                <Paper
                  elevation={4}
                  sx={{
                    backgroundColor: '#E7E6F7',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Address identifier='toAddress' title={t('delivery.form.arrival-address')} />
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <DeliveryMap
                  type={isRestitution ? DeliveryType.TWO_WAY_DELIVERY : DeliveryType.STANDARD}
                  previewType={PreviewType.AB}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <ComponentContainer title={t('delivery.form.liveried-vehicle')}>
                  <Vehicle index={0} />
                </ComponentContainer>
              </Paper>
            </Grid>
          </>
        ) : null}
        {step === 2 && !isLastStep ? (
          <>
            <Grid item xs={12} lg={6}>
              <Stack gap={4}>
                <Paper
                  elevation={4}
                  sx={{
                    backgroundColor: '#E7E6F7',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Address
                    identifier='toReturnFromAddress'
                    title={t('delivery.form.departure-address')}
                    disabled={true}
                    values={{
                      ...methods.getValues('toAddress'),
                      placeId: methods.getValues('toAddress').place_id,
                    }}
                  />
                </Paper>
                <Paper
                  elevation={4}
                  sx={{
                    backgroundColor: '#E7E6F7',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Address
                    identifier='toReturnToAddress'
                    title={t('delivery.form.restitution-address')}
                  />
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <DeliveryMap
                  previewType={PreviewType.BC}
                  type={isRestitution ? DeliveryType.TWO_WAY_DELIVERY : DeliveryType.STANDARD}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <ComponentContainer title={t('delivery.form.vehicle-to-be-returned')}>
                  <Vehicle index={1} />
                </ComponentContainer>
              </Paper>
            </Grid>
          </>
        ) : null}
        {isLastStep ? (
          <>
            <DocumentsComponent canEdit={!!data.id} />
            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <MissionDetails type={type} isRestitution={isRestitution} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{
                  backgroundColor: '#E7E6F7',
                  borderRadius: 1,
                  padding: '20px',
                  height: '100%',
                }}
              >
                <InformationSupp />
              </Paper>
            </Grid>
            {/* {Boolean(status) && (
              <Grid item xs={12}>
                <Paper
                  elevation={4}
                  sx={{
                    backgroundColor: '#E7E6F7',
                    borderRadius: 1,
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <SMSForm />
                </Paper>
              </Grid>
            )} */}
            <Grid item xs={12}>
              <ComponentContainerAccordion title={t('delivery.form.summary')}>
                <Summary
                  key={options?.selected?.length + '' + comment.length + '' + category}
                  isRestitution={isRestitution}
                  data={methods.getValues()}
                  isCreation={!data?.id}
                />
              </ComponentContainerAccordion>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12}>
          <Grid container item xs={12} spacing={1}>
            {isLastStep ? (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    disabled={step === 0}
                    onClick={() => handleBack()}
                    variant='contained'
                    color='secondary'
                  >
                    {t('common.navigation.previous')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MainButtons />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    disabled={step === 0}
                    onClick={() => handleBack()}
                    variant='contained'
                    color='secondary'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('common.navigation.previous')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    disabled={false}
                    onClick={() => stepValidate()}
                    variant='contained'
                    color='primary'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('common.navigation.next')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* <DevTool control={methods.control} /> */}
    </FormProvider>
  )
}

export default Form
