import { type PropsWithChildren, useEffect, useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'

import { getDeliveryServiceStats } from '@api/Rest/getDeliveryServiceStats'
import { getDeliveryTypeStats } from '@api/Rest/getDeliveryTypeStats'
import { capitalizeFirstLetter } from '@utils/functions/capitalizeFirstLetter'

type Props = {
  deliveryType: boolean
}

type PieActiveShape = {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
  payload: { name: keyof typeof mappings }
  percent: number
  value: number
}

const mappings = {
  STANDARD: 'Courses C1',
  LESS_THAN_30: '-30 véhicules',
  MORE_THAN_30: '+30 véhicules',
  TWO_WAY_DELIVERY: 'Echange',
  QUALITY_CHECK: 'Quality check',
}

const DeliveryTypePieChart = ({ deliveryType }: PropsWithChildren<Props>) => {
  const [state, setState] = useState<{ activeIndex: number }>({
    activeIndex: 0,
  })
  const [tableData, setTableData] = useState<Array<{ name: string; value: number }>>([])

  const onPieEnter = (_: any, index: number) => {
    setState({
      activeIndex: index,
    })
  }

  const renderActiveShape = (props: PieActiveShape) => {
    const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor='middle' fill={'#00244f'}>
          {capitalizeFirstLetter(mappings[payload.name] ?? payload.name)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill='#ff5c8d'
        >{`Nbr : ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill='#ff5c8d'
        >
          {`(${(percent * 100).toFixed(2)} %)`}
        </text>
      </g>
    )
  }

  const getDeliveryTypeData = async () => {
    const res = await getDeliveryTypeStats()
    if (res.data) {
      setTableData(res.data)
    }
  }

  const getDeliveryServiceData = async () => {
    const res = await getDeliveryServiceStats()
    if (res.data) {
      setTableData(res.data)
    }
  }

  // useEffect(() => {
  // if (deliveryType) {
  //   getDeliveryTypeData()
  // } else {
  // getDeliveryServiceData()
  // }
  // }, [])

  return (
    <ResponsiveContainer width='100%' height='87.5%'>
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={state.activeIndex}
          activeShape={renderActiveShape}
          data={tableData}
          cx='50%'
          cy='50%'
          innerRadius={80}
          outerRadius={100}
          fill='#9BB2CE'
          dataKey='value'
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default DeliveryTypePieChart
