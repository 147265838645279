import dayjs from 'dayjs'
import { z } from 'zod'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import errorMessages from '../errorMessages'

dayjs.extend(isSameOrAfter)

function disablePast(date: string | Date | any) {
  return dayjs(date)?.isSameOrAfter(dayjs(), 'minute')
}

function areSameOrAfter(date1: dayjs.Dayjs | any, date2: dayjs.Dayjs | any) {
  return date1?.isSameOrAfter(date2, 'minute')
}

function isAfter(date1: dayjs.Dayjs | any, date2: dayjs.Dayjs | any) {
  return date1?.isAfter(date2, 'minute')
}
const publishDeliverySchema = z.object({
  id: z.number(),
  clientFinalPrice: z.coerce.number(),
  carAvailabilityDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carPickUpDate: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  carDeliveryDeadline: z.coerce
    .date()
    .refine((value) => disablePast(value), { message: errorMessages.disablePast }),
  selectedDriver: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .nullish(),
  wantedPrice: z.number(),
  maxPrice: z.number(),
  minPrice: z.number(),
})

export default publishDeliverySchema
