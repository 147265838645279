import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import { NavigationButton } from '@components/common/NavigationLink'
import CarIcon from '@components/common/icons/car.icon'
import ExchangeArrowsIcon from '@components/common/icons/exchange-arrows.icon'
import { RoutePaths } from '@utils/constants/routes'
import ParkedCarIcon from '@components/common/icons/parked-car.icon'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'

type TAddNewDeals = {}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}

const AddNewDeals = ({}: TAddNewDeals) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  //navigate('/admin/new-delivery/standard')
  return (
    <>
      <Button
        style={{ minWidth: '200px', height: '100%' }}
        variant='contained'
        onClick={handleOpen}
        color='primary'
        startIcon={<AddIcon />}
      >
        {t('layouts.header.new-delivery')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            marginBottom={2}
            sx={{
              '&::first-letter': {
                textTransform: 'capitalize',
              },
            }}
          >
            {t('layouts.sub-headers.deliveries.select-delivery-type')}
          </Typography>
          <Stack direction='row' gap={2} flexWrap={'wrap'}>
            <NavigationButton
              to={`${RoutePaths.adminNewDelivery}/standard`}
              includes='/standard'
              extraStyle={{
                width: 200,
                textTransform: 'uppercase',
              }}
            >
              <CarIcon />
              {t('layouts.sub-headers.deliveries.standard')}
            </NavigationButton>
            <NavigationButton
              to={`${RoutePaths.adminNewDelivery}/to-return`}
              includes='/to-return'
              extraStyle={{
                width: 200,
                textTransform: 'uppercase',
              }}
            >
              <ParkedCarIcon />
              {t('layouts.sub-headers.deliveries.to-return')}
            </NavigationButton>

            <NavigationButton
              to={`${RoutePaths.adminNewDelivery}/two-way-delivery`}
              includes='/two-way-delivery'
              extraStyle={{
                width: 200,
                textTransform: 'uppercase',
              }}
            >
              <ExchangeArrowsIcon />
              {t('layouts.sub-headers.deliveries.two-way-delivery')}
            </NavigationButton>

            <NavigationButton
              to={`${RoutePaths.adminNewDelivery}/okm`}
              includes='/two-way-delivery'
              extraStyle={{
                width: 200,
                textTransform: 'uppercase',
              }}
            >
              <LocalShippingIcon />
              {t('layouts.sub-headers.deliveries.okm')}
            </NavigationButton>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

export default AddNewDeals
