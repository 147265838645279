import { sendRequest } from '@api/clients/axios'

type CreatePushNotificationToken = {
  token: string
}
type DeletePushNotificationToken = {
  id: string
}

type DeletePushNotificationTokenResponse = any

type UpdatePushNotificationToken = {
  token: string
  id: number
}

type UpdatePushNotificationTokenResponse = {
  id: number
  token: string
}

type CreatePushNotificationTokenResponse = {
  id: number
  token: string
}

export async function createPushNotificationToken(
  createPushNotificationToken: CreatePushNotificationToken,
) {
  return sendRequest<CreatePushNotificationToken, CreatePushNotificationTokenResponse>(
    'post',
    'admins/create-push-notification',
    createPushNotificationToken,
  ).then((res) => {
    if (res.status === 201) {
      return res.data
    }
  })
}

export async function updatePushNotificationToken(
  updatePushNotificationToken: UpdatePushNotificationToken,
) {
  return sendRequest<UpdatePushNotificationToken, UpdatePushNotificationTokenResponse>(
    'post',
    'admins/update-push-notification',
    updatePushNotificationToken,
  ).then((res) => {
    if (res.status === 201) {
      return res.data
    }
  })
}

export async function deletePushNotificationToken(
  deletePushNotificationToken: DeletePushNotificationToken,
) {
  return sendRequest<DeletePushNotificationToken, DeletePushNotificationTokenResponse>(
    'post',
    'admins/delete-push-notification',
    deletePushNotificationToken,
  ).then((res) => {
    if (res.status === 201) {
      return res.data
    }
  })
}
