import { ReactNode, useState } from 'react'
import './styles.css'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { Mission } from './type'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'

const MakerInner = styled.div<{
  width: number | 'auto'
  height: number
  $bgcolor?: string
  $opacity?: number
}>`
  background-color: ${(props) => (props.$bgcolor ? props.$bgcolor : 'var(--primary-color)')};
  color: white;
  opacity: ${(props) => props.$opacity ?? 1};
  border-radius: 8px;
  padding: 5px 10px;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  transition-property: width, height;
  transition-duration: 0.6s;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => (props.$bgcolor ? props.$bgcolor : 'var(--primary-color)')};
  }
`
function MapMarker({
  title,
  description,
  mission,
  children,
  position,
  extraStyle,
  extendable = false,
}: {
  mission: Mission
  children?: React.ReactNode
  title: ReactNode
  description?: string
  position: google.maps.LatLngLiteral
  extraStyle?: {
    bgColor?: string
    opacity?: number
  }
  extendable?: Boolean
}) {
  const [showDetails, setShowDetails] = useState(false)
  return (
    <AdvancedMarker position={position} onClick={() => extendable && setShowDetails(!showDetails)}>
      <MakerInner
        $bgcolor={extraStyle?.bgColor}
        width={showDetails ? 300 : extendable ? 150 : 'auto'}
        height={showDetails ? 180 : extendable || description ? 50 : 30}
        style={{ opacity: extraStyle?.opacity ? extraStyle.opacity : 1 }}
      >
        <div className='marker-main'>
          <div style={{ width: 120, display: 'block' }}>
            {title}
            {description ? (
              <span
                className='marker-description'
                style={{ whiteSpace: 'normal', textTransform: 'capitalize' }}
              >
                {description}
              </span>
            ) : null}
          </div>
        </div>
        {showDetails ? children : null}
        {showDetails ? (
          <IconButton
            aria-label='close'
            size='small'
            sx={{ position: 'absolute', right: 0, top: 0, color: 'whitesmoke' }}
          >
            <Close />
          </IconButton>
        ) : null}
      </MakerInner>
    </AdvancedMarker>
  )
}
export default MapMarker
