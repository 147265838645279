import { gql } from '@apollo/client'

import { graphqlQuery } from '@api/clients/apollo'
import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type Delivery } from '@types_def/models/delivery.types'

export async function confirmDelivery(id: number): Promise<Delivery | undefined> {
  try {
    const res = await graphqlQuery<ApiGetResponse<Delivery>, { id: string }>(
      gql`
          mutation {
            data: confirmDelivery(
              ConfirmDeliveryInput: {
                deliveryId: ${id}
              }
            ) {
              id
            }
          }
        `,
    )
    if (res?.data) return res.data.data
    throw new Error((res.errors[0] as Error).message)
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
