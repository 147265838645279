import { Grid, Typography, Divider } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { Delivery, DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import CustomMap from '@components/Map'
import { Mission, PreviewType } from '@components/Map/type'
import { Params, useParams } from 'react-router-dom'
import MapInstance from '@components/Map/mapInstance'
import PreviewMissionMarkers from '@components/Map/previewMarkers'
import { useTranslation } from 'react-i18next'
type TDeliveryForm = {
  type: DeliveryType
  previewType?: PreviewType
}

const DeliveryMap = ({ previewType = PreviewType.AB, type }: TDeliveryForm) => {
  const { t } = useTranslation()
  const { getValues } = useFormContext<Delivery>()

  const fromAddress = useWatch({ name: 'fromAddress' })
  const toAddress = useWatch({ name: 'toAddress' })
  const toReturnToAddress = useWatch({ name: 'toReturnToAddress' })
  const status = getValues('status')
  const mission: Mission = {
    status: getValues('status'),
    type,
    origin: {
      lng: fromAddress?.longitude,
      lat: fromAddress?.latitude,
    },
    originCity: fromAddress?.city,
    destinationCity: toAddress?.city,
    destination: {
      lng: toAddress?.longitude,
      lat: toAddress?.latitude,
    },
    restitution:
      type === DeliveryType.TWO_WAY_DELIVERY && toReturnToAddress
        ? toReturnToAddress?.address
        : undefined,
    toReturn:
      type === DeliveryType.TWO_WAY_DELIVERY && toReturnToAddress
        ? {
            lng: toReturnToAddress?.longitude,
            lat: toReturnToAddress?.latitude,
          }
        : undefined,
    depart: fromAddress?.address,
    arrive: toAddress?.address,
    color: 'green',
    id: getValues('id')?.toString() ?? '0',
    driver:
      status === DeliveryStatus.ONGOING
        ? {
            id: getValues('selectedDriver').id.toString(),
            name:
              getValues('selectedDriver').firstName + ' ' + getValues('selectedDriver').lastName,
            avatar: getValues('selectedDriver').avatar,
          }
        : undefined,
  }
  const { contract } = useParams<Params>()
  const showMap =
    (previewType === PreviewType.AB && mission.arrive?.length && mission.depart?.length) ||
    (previewType === PreviewType.BC && mission.arrive?.length && mission.restitution?.length) ||
    (previewType === PreviewType.ABC &&
      mission.arrive?.length &&
      mission.depart?.length &&
      mission.restitution?.length)
  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2} sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '100%', minHeight: '40vh' }}>
        {showMap ? (
          // <CustomMap
          //   key={mission.depart + mission.arrive}
          //   data={[mission]}
          //   liveTracking={status === DeliveryStatus.ONGOING}
          //   newDelivery={!contract}
          // />
          <MapInstance>
            <PreviewMissionMarkers mission={mission} previewType={previewType} />
          </MapInstance>
        ) : (
          <Grid
            container
            width={'100%'}
            height='100%'
            justifyContent={'center'}
            alignContent={'center'}
            padding={5}
          >
            <Typography fontSize={'1.1rem'} margin={10}>
              {t('delivery.form.map-no-address')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default DeliveryMap
