import React from 'react'
import { ModalComponent } from '@components/common/modal'
import DamagedVehicleImages from '../DamagedVehicleImages'
import FilesGrid from '@components/fileUpload/FilesGrid'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/material'

type Props = {
  files: any
  isOpen: boolean
  handleModel: (open: boolean) => void
  isDocs?: boolean
  title: string
}

const FilesComponent = ({ files, isOpen, handleModel, isDocs = false, title }: Props) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => {
        handleModel(false)
      }}
      backgroundColor={'#FFFFFF'}
      noBorder={true}
    >
      {isDocs ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h5' textAlign={'center'} p={2}>
            {title}
            <IconButton
              onClick={() => {
                handleModel(false)
              }}
              sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}
            >
              <CloseIcon color='error' />
            </IconButton>
          </Typography>

          <FilesGrid docs={files} disabled />
        </Box>
      ) : (
        <DamagedVehicleImages
          images={files}
          handleSetCarImagesModalOpen={handleModel}
          title={title}
        />
      )}
    </ModalComponent>
  )
}

export default FilesComponent
