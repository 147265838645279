import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'

import { InputContainer } from '../InputContainer'
import './SelectListStyles/index.module.scss'

type Props<T extends Record<string, unknown>> = {
  placeholder: string
  identifier?: string
  value?: string
  onChange?: (changes: string, identifier?: string) => void
  required?: boolean
  label?: string
  alignLabel?: boolean
  disabled?: boolean
  options: T[]
  firstOptionDisabled?: boolean
  optionTextProperty: keyof T
  optionValueProperty: keyof T
  renderValueFn?: (options: T) => JSX.Element | JSX.Element[]
  renderItemsFn?: (options: T[]) => JSX.Element | JSX.Element[]
}

export const SelectInput = <T extends Record<string, unknown>>(props: Props<T>) => {
  const {
    placeholder,
    value,
    onChange,
    label,
    optionTextProperty,
    optionValueProperty,
    identifier = undefined,
    required = false,
    alignLabel = false,
    disabled = false,
    firstOptionDisabled = false,
    options,
    renderValueFn,
    renderItemsFn,
  } = props
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        width: 'fit-content',
      },
    },
  }

  const handleChange = (event: SelectChangeEvent) => {
    if (!onChange) {
      return
    }

    const eventValue = event.target.value
    onChange(eventValue, event.target.name ?? null)
  }

  return (
    <InputContainer required={required} label={label} alignLabel={alignLabel}>
      <Select
        displayEmpty
        size='small'
        sx={{
          ':before': {
            borderBottom: 0,
          },
          ':after': {
            borderBottom: 0,
          },
          '&:hover': {
            ':before': {
              borderBottom: 0,
            },
            ':after': {
              borderBottom: 0,
            },
          },
          width: '100%',
        }}
        disabled={disabled}
        value={value}
        variant='filled'
        className='select filled'
        name={identifier}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected && options && options.length) {
            const findOption = options.find((option) =>
              option[optionValueProperty] ? `${option[optionValueProperty]}` === selected : null,
            )
            if (findOption) {
              if (renderValueFn) {
                return renderValueFn(findOption)
              }

              return findOption[optionTextProperty]
            }

            return <>{placeholder}</>
          }

          return <>{placeholder}</>
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled={firstOptionDisabled} classes={{}} value={undefined}>
          <>{placeholder}</>
        </MenuItem>
        {renderItemsFn
          ? renderItemsFn(options)
          : options.map((option, index) => {
              const optionValue = `${option[optionValueProperty]}`
              const optionText = `${option[optionTextProperty]}`
              return (
                <MenuItem
                  key={`select-list-${optionValue}-${index}-${optionText}`}
                  value={optionValue}
                  className='selected'
                >
                  {optionText}
                </MenuItem>
              )
            })}
      </Select>
    </InputContainer>
  )
}
