import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "sans-serif"',
  },
  palette: {
    primary: {
      main: '#00244f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff5c8d',
      contrastText: '#fff',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "sans-serif"',
          fontWeight: 400,
        },
      },
    },
    MuiTextField: {
      // captilise the label
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
})
