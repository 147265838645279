const DeliveryCancelReasons = [
    "car_not_at_mcc",
    "car_not_ready_technical",
    "car_not_ready_damaged",
    "car_not_ready_dirty",
    "car_not_ready_no_license_plate",
    "customer_not_available",
    "lms_partner_not_available",
    "car_breakdown_during_transfer",
    "other"
  ]

  const cancellationReasonKeys = [
    "car_not_at_mcc",
    "car_not_ready_technical",
    "car_not_ready_damaged",
    "car_not_ready_dirty",
    "car_not_ready_no_license_plate",
    "customer_not_available",
    "lms_partner_not_available",
    "car_breakdown_during_transfer",
    "other"
  ] as const;
  

  export default DeliveryCancelReasons;

  export { cancellationReasonKeys}