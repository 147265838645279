import globals from '@styles/globals.module.scss'

import styles from '../inputs.module.scss'
type Props = {
  required?: boolean
  label?: string
  alignLabel?: boolean
  className?: string
}

export const InputContainer = ({
  required = false,
  label,
  alignLabel = false,
  children,
  className,
}: React.PropsWithChildren<Props>) => (
  <div
    style={{ width: '100%' }}
    className={`${styles['input-container']}  ${globals.flex} ${
      alignLabel ? styles['aligned-label'] : globals['flex-col']
    } ${className}`}
  >
    {label && (
      <>
        <label className='text-bold'>
          {required && <span className='required'> * </span>}
          <span>{label}</span>
          <span> : </span>
        </label>
      </>
    )}
    {children}
  </div>
)
