import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { createOrganization } from '@api/graphql/organization/createOrganization'
import { getOrganizationDetails } from '@api/graphql/organization/getOrganization'
import { updateOrganization } from '@api/graphql/organization/updateOrganization'
import { DeepInclude } from '@types_def/common/filter.types'
import { CreateOrganizationInput, Organization } from '@types_def/models/Organization.types'
import { t } from 'i18next'

const initialAddress = {
  city: '',
  postalCode: '',
  address: '',
  place_id: '',
  longitude: '',
  latitude: '',
}

const initialOrganization = {
  name: '',
  slug: '',
  settings: {},
  owner: {
    address: initialAddress,
    assigneeFirstName: '',
    assigneeLastName: '',
    assigneeFunction: '',
    assigneeService: '',
    assigneeEmail: '',
    assigneePhone: '',
  },
} as unknown as CreateOrganizationInput

const useOrganizationDetails = (data: { slug: string; include?: DeepInclude<Organization> }) => {
  return useQuery({
    queryKey: ['organizationDetails', data],
    queryFn: async () => {
      if (!data.slug) return initialOrganization
      const res = await getOrganizationDetails(data)
      return res
    },
  })
}

const useCreateOrganization = () => {
  return useMutation({
    mutationFn: createOrganization,
    onSuccess: () => {
      toast(t('network.organization.created'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      if (e.message === 'Error: An account with the given email already exists.') {
        toast("Ce client est déjà enregistré auprès d'une autre société", {
          position: 'bottom-right',
          type: 'error',
        })
      } else {
        toast(e?.message?.replace('ApolloError: ', '') ?? t('network.errors.unknown'), {
          position: 'bottom-right',
          type: 'error',
        })
      }
      console.error(e)
    },
  })
}

const useUpdateOrganization = () => {
  return useMutation({
    mutationFn: updateOrganization,
    onSuccess: () => {
      toast(t('network.organization.updated'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (e: any) => {
      toast(e?.message ?? t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
      console.error(e)
    },
  })
}

export { useUpdateOrganization, useCreateOrganization, useOrganizationDetails }
