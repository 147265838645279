import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  CancelledByCell,
  ContractCell,
  DateCell,
  LoginCell,
  LogoutCell,
  OrganizationCell,
  PilotActionCell,
  cancelledAdminActionCell,
  cancelledClientActionCell,
} from '../CustomCells'

const initialCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'fromAddress',
    label: 'Arrivée',
    customCellComponent: LoginCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'toAddress',
    label: 'Départ',
    customCellComponent: LogoutCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: true,
  },
  {
    id: 'cancelledAt',
    label: 'Annulé à',
    customCellComponent: DateCell,
    isNotSortable: true,
  },
]

export const adminCancelledHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  {
    id: 'status',
    label: 'Annuler par',
    customCellComponent: CancelledByCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: cancelledAdminActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]

export const clientCancelledHeadCells: Array<HeadCell<Delivery>> = [
  ...initialCells,
  {
    id: 'status',
    label: 'Annuler par',
    customCellComponent: CancelledByCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Action',
    customCellComponent: PilotActionCell,
    passWholeObject: true,
    isNotSortable: true,
  },
]
