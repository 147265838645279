import { DeliveryType } from '@types_def/models/delivery.types'

type DeliveryTypeAttributes = {
  key: DeliveryType
  title: string
  acronym: string
  color: string
}

export const deliveryTypeAttributes: DeliveryTypeAttributes[] = [
  {
    key: DeliveryType.STANDARD,
    title: 'Nouvelle Course Standard',
    acronym: 'C1',
    color: '#1C6DD0',
  },
  {
    key: DeliveryType.TO_RETURN,
    title: 'Retour client',
    acronym: 'RC',
    color: '#F4BB29',
  },
  // {
  //   key: DeliveryType.MORE_THAN_30,
  //   title: 'Nouvelle Course Spéciale (+ de 30)',
  //   acronym: 'CS',
  //   color: '#f0f',
  // },
  {
    key: DeliveryType.TWO_WAY_DELIVERY,
    title: 'Nouvelle Course avec Restitution',
    acronym: 'CR',
    color: '#8d10cb',
  },
  {
    key: DeliveryType.OKM,
    title: 'Nouvelle Course 0KM',
    acronym: 'ZKM',
    color: '#16c599',
  },
  // {
  //   key: DeliveryType.QUALITY_CHECK,
  //   title: 'Quality Check',
  //   acronym: 'QC',
  //   color: '#10cb74',
  // },
]
