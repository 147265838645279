const SearchIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.6344 13.8641L11.8938 10.1239C12.7444 8.86123 13.1641 7.28359 12.9394 5.60252C12.5563 2.74313 10.2125 0.416195 7.34999 0.0538272C3.09404 -0.484647 -0.484725 3.09372 0.0538391 7.3492C0.41634 10.2126 2.74385 12.558 5.60386 12.9392C7.28512 13.1639 8.86325 12.7444 10.1258 11.8937L13.8664 15.6339C14.3545 16.122 15.1461 16.122 15.6342 15.6339C16.1219 15.1452 16.1219 14.3516 15.6344 13.8641ZM2.47185 6.4993C2.47185 4.29391 4.26623 2.49972 6.47186 2.49972C8.6775 2.49972 10.4719 4.29391 10.4719 6.4993C10.4719 8.70469 8.6775 10.4989 6.47186 10.4989C4.26623 10.4989 2.47185 8.70531 2.47185 6.4993Z'
      fill='#fff'
    />
  </svg>
)

export default SearchIcon
