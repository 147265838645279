import * as jwt_decode from 'jwt-decode'

import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type UseIdTokenReturn = {
  id: number | null
  token: string | null
}

export function useIdToken(): UseIdTokenReturn {
  const [searchParams] = useSearchParams()

  const token = useMemo<string | null>(() => {
    const token = searchParams.get('token')
    return token
  }, [searchParams])

  useEffect(() => {
    if (token) {
      localStorage.setItem('AccessToken', token)
    }
  }, [token])

  const id = useMemo<number | null>(() => {
    try {
      if (!token) {
        return null
      }
      const decodedToken = jwt_decode.jwtDecode(token) as {
        deliveryId: number
        exp: number
        iat: number
        userId: string
      }
      return decodedToken.deliveryId ?? null
    } catch (e: any) {
      console.error(e)
      return null
    }
  }, [token])

  return { id, token }
}
