import { gql } from '@apollo/client'

import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type DeliveriesCount } from '@types_def/models/delivery.types'

import { graphqlQuery } from '../../clients/apollo'

export async function getDeliveriesCountByAdmin(): Promise<DeliveriesCount> {
  try {
    const res = await graphqlQuery<ApiGetResponse<DeliveriesCount>, never>(gql`
      query {
        data: getDeliveriesCountByAdmin {
          requestedCount
          confirmedCount
          onGoingCount
          waitingDriverCount
          setCount
          alertCount
          deliveredCount
          cancelledCount
        }
      }
    `)
    return res.data.data ?? ({} as DeliveriesCount)
  } catch (error) {
    console.error(error)
    throw new Error((error as Error).message)
  }
}

export default getDeliveriesCountByAdmin
