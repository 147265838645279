import CarIcon from '@components/common/icons/car.icon'
import ExchangeArrowsIcon from '@components/common/icons/exchange-arrows.icon'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'

import LinksSubHeader from '..'
import ParkedCarIcon from '@components/common/icons/parked-car.icon'
import { useTranslation } from 'react-i18next'

const FormSubHeader = () => {
  const { t } = useTranslation()
  return (
    <LinksSubHeader>
      <NavigationButton to={`${RoutePaths.clientNewDelivery}/standard`} includes='/standard'>
        <CarIcon />
        {t('layouts.sub-headers.deliveries.car-delivery')}
      </NavigationButton>
      <NavigationButton to={`${RoutePaths.clientNewDelivery}/to-return`} includes='/to-return'>
        <ParkedCarIcon />
        {t('layouts.sub-headers.deliveries.car-return')}
      </NavigationButton>
      {/* 
      <NavigationButton to={`${RoutePaths.clientNewDelivery}/less-than-30`} includes='/less-than-30'>
        <MultipleCarsIcon />
        Multi Course
      </NavigationButton>
  
      <NavigationButton to={`${RoutePaths.clientNewDelivery}/more-than-30`} includes='/more-than-30'>
        <SteeringWheelIcon />
        Course Spéciale
      </NavigationButton> */}

      <NavigationButton
        to={`${RoutePaths.clientNewDelivery}/two-way-delivery`}
        includes='/two-way-delivery'
      >
        <ExchangeArrowsIcon />
        {t('layouts.sub-headers.deliveries.delivery-with-swap')}{' '}
      </NavigationButton>
      <NavigationButton to={`${RoutePaths.clientNewDelivery}/okm`} includes='/okm'>
        <LocalShippingIcon />
        {t('layouts.sub-headers.deliveries.okm')}
      </NavigationButton>
      {/* <NavigationButton to={`${RoutePaths.clientNewDelivery}/quality-check`} includes='quality-check'>
        <RepairIcon />
        Quality Check
      </NavigationButton> */}
    </LinksSubHeader>
  )
}

export default FormSubHeader
