import { Grid, Typography, Divider, Autocomplete, TextField } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import useAddressAutoComplete from '@hooks/useAddressAutoComplete'
import { Delivery } from '@types_def/models/delivery.types'
import AlbInput from '@components/common/AlbInput'
import { Address as AddressType } from '@types_def/models/address.types'
import { useTranslation } from 'react-i18next'

type TAddress = {
  title: string
  identifier: 'toAddress' | 'fromAddress' | 'toReturnFromAddress' | 'toReturnToAddress'
  disabled?: boolean
  values?: AddressType
}

const Address = ({ title, identifier, disabled = false, values }: TAddress) => {
  const { t } = useTranslation()
  const { value, data, setValue: setAddress, handleSelect } = useAddressAutoComplete()
  const {
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useFormContext<Delivery>()

  const handleAutoFill = (data: any) => {
    const { longitude, latitude, address, placeId, postalCode, city, addressPrecision, country } =
      data
    setValue(`${identifier}.longitude`, Number(longitude))
    setValue(`${identifier}.latitude`, Number(latitude))
    setValue(`${identifier}.address`, address)
    setValue(`${identifier}.place_id`, placeId)
    setValue(`${identifier}.postalCode`, postalCode)
    setValue(`${identifier}.city`, city)
    setValue(`${identifier}.country`, country)
    if (addressPrecision)
      setValue(`${identifier}.addressPrecision`, addressPrecision, {
        shouldDirty: true,
      })
  }

  if (identifier === 'toReturnFromAddress' && values && values.address) {
    handleAutoFill(values)
  }

  const resetAddress = () => {
    setValue(`${identifier}.longitude`, '')
    setValue(`${identifier}.latitude`, '')
    setValue(`${identifier}.address`, '')
    setValue(`${identifier}.place_id`, '')
    setValue(`${identifier}.postalCode`, '')
    setValue(`${identifier}.city`, '')
    setValue(`${identifier}.addressPrecision`, '')
    setValue(`${identifier}.country`, '')
    setValue(`${identifier}`, '')
  }

  return (
    <Grid container direction={'row'} spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform='capitalize'>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d', marginBottom: 5 }} />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disabled={disabled}
          freeSolo
          disableClearable
          filterOptions={(x) => x}
          options={data.map((option) => option.description)}
          onChange={(event, searchedAddress) => {
            handleSelect(searchedAddress).then((data) => handleAutoFill(data))
          }}
          value={getValues(`${identifier}.address`)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={Boolean(errors?.[identifier]?.address)}
                helperText={
                  errors?.[identifier]?.address
                    ? t(`common.form.errors.${errors?.[identifier]?.address?.message}`)
                    : ''
                }
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='standard'
                label={t('common.form.input.search-an-address')}
                InputLabelProps={
                  getValues(`${identifier}.address`) || value ? { shrink: true } : {}
                }
                onChange={(e) => {
                  if (e.target.value.trim() === '') resetAddress()
                  else setAddress(e.target.value)
                }}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AlbInput
          name={`${identifier}.city`}
          label={t('common.form.input.city')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item xs={4}>
        <AlbInput
          name={`${identifier}.postalCode`}
          label={t('common.form.input.zip-code')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item xs={4}>
        <AlbInput
          name={`${identifier}.country`}
          label={t('common.form.input.country')}
          type='TextField'
          isEditAllowed={false}
        />
      </Grid>
      <Grid item xs={12}>
        <AlbInput
          name={`${identifier}.addressPrecision`}
          label={t('common.form.input.address-accuracy')}
          type='TextField'
          isEditAllowed={!disabled}
        />
      </Grid>
    </Grid>
  )
}
export default Address
