import { Button, Grid } from '@mui/material'
import DeliveryTableServiceFilter from './ServiceFilter'
import DeliveryTableTypeFilter from './TypeFilter'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import DeliveryFilterSchema, {
  DeliveryFilterDefaultValues,
  DeliveryFilterSchemaType,
} from '@schemas/DeliveryForm/DeliveryFilterSchema'
import DeliveryTableDeliveryDateFilter from './DateFilter'
import DeliveryTableCountryFilter from './CountryFilter'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import OrganizationFilter from './OrganizationFilter'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'

type TDeliveryTableFilters = {
  handleExpand: () => void
}

const DeliveryTableFilters = ({ handleExpand }: TDeliveryTableFilters) => {
  const { userState } = useUserContext()
  const userRole = userState['custom:role']
  const [searchParams, setSearchParams] = useSearchParams()
  const deliveryId = searchParams.get('deliveryId')
  const deliveryType = searchParams.get('deliveryType')
  const deliveryServices = searchParams.get('deliveryServices')
  const deliveryCountries = searchParams.get('deliveryCountries')
  const carDeliveryDeadlineFrom = searchParams.get('carDeliveryDeadlineFrom')
  const carDeliveryDeadlineTo = searchParams.get('carDeliveryDeadlineTo')
  const organizationSlug = searchParams.get('organizationSlug')

  const methods = useForm<DeliveryFilterSchemaType>({
    resolver: zodResolver(DeliveryFilterSchema),
    defaultValues: {
      deliveryType: deliveryType
        ? deliveryType.split(',').reduce((acc, type) => {
            acc[type] = true
            return acc
          }, {})
        : DeliveryFilterDefaultValues.deliveryType,
      deliveryCountries: deliveryCountries
        ? deliveryCountries.split(',').reduce((acc, country) => {
            acc[country] = true
            return acc
          }, {})
        : DeliveryFilterDefaultValues.deliveryCountries,
      deliveryServices: deliveryServices
        ? deliveryServices.split(',').reduce((acc, service) => {
            acc[service] = true
            return acc
          }, {})
        : DeliveryFilterDefaultValues.deliveryServices,
      carDeliveryDeadlineFrom: carDeliveryDeadlineFrom
        ? dayjs(new Date(parseInt(carDeliveryDeadlineFrom)))
        : DeliveryFilterDefaultValues.carDeliveryDeadlineFrom,
      carDeliveryDeadlineTo: carDeliveryDeadlineTo
        ? dayjs(new Date(parseInt(carDeliveryDeadlineTo)))
        : DeliveryFilterDefaultValues.carDeliveryDeadlineTo,
      organizationSlug: organizationSlug
        ? organizationSlug
        : DeliveryFilterDefaultValues.organizationSlug,
    },
  })
  const clearAllSearchParams = () => {
    setSearchParams({})
    methods.reset(DeliveryFilterDefaultValues)
    handleExpand()
  }
  const onSubmit = (data: DeliveryFilterSchemaType) => {
    const deliveryCountries = Object.keys(data.deliveryCountries).filter((country) =>
      Boolean(data.deliveryCountries[country]),
    )
    if (deliveryCountries.includes('FR')) deliveryCountries.push('MC')
    if (deliveryCountries.includes('BE')) deliveryCountries.push('DE')
    if (deliveryCountries.length)
      searchParams.set('deliveryCountries', deliveryCountries.toString())
    else searchParams.delete('deliveryCountries')

    const deliveryType = Object.keys(data.deliveryType).filter((type) =>
      Boolean(data.deliveryType[type]),
    )
    if (deliveryType.length) searchParams.set('deliveryType', deliveryType.toString())
    else searchParams.delete('deliveryType')

    const deliveryServices = Object.keys(data.deliveryServices).filter((service) =>
      Boolean(data.deliveryServices[service]),
    )
    if (deliveryServices.length) searchParams.set('deliveryServices', deliveryServices.toString())
    else searchParams.delete('deliveryServices')

    const carDeliveryDeadlineFrom = data.carDeliveryDeadlineFrom
    if (carDeliveryDeadlineFrom)
      searchParams.set('carDeliveryDeadlineFrom', carDeliveryDeadlineFrom.getTime().toString())
    else searchParams.delete('carDeliveryDeadlineFrom')

    const carDeliveryDeadlineTo = data.carDeliveryDeadlineTo
    if (carDeliveryDeadlineTo)
      searchParams.set('carDeliveryDeadlineTo', carDeliveryDeadlineTo.getTime().toString())
    else searchParams.delete('carDeliveryDeadlineTo')

    const organizationSlug = data.organizationSlug
    if (organizationSlug) searchParams.set('organizationSlug', organizationSlug)
    else searchParams.delete('organizationSlug')

    setSearchParams(searchParams)
    handleExpand()
  }
  return (
    <FormProvider {...methods}>
      <Grid container spacing={2} component={'form'} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <DeliveryTableCountryFilter />
        </Grid>
        <Grid item xs={12}>
          <DeliveryTableTypeFilter />
        </Grid>
        <Grid item xs={12}>
          <DeliveryTableServiceFilter />
        </Grid>
        <Grid item xs={12}>
          <DeliveryTableDeliveryDateFilter />
        </Grid>
        {userRole === UserRole.ADMIN ? (
          <Grid item xs={12}>
            <OrganizationFilter />
          </Grid>
        ) : null}
        <Grid item xs={4} md={3} lg={2}>
          <Button
            size='medium'
            fullWidth
            variant='outlined'
            color='warning'
            type='button'
            onClick={clearAllSearchParams}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={6} md={5} lg={4}>
          <Button size='medium' fullWidth variant='contained' color='secondary' type='submit'>
            Apply Filters
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default DeliveryTableFilters
