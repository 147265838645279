export type AdminRank = {
  logo: string
  rank: number
  text: 'SILVER' | 'BRONZE' | 'GOLD'
}

export const adminRanks: AdminRank[] = [
  {
    logo: '/images/medals/silver-medal.png',
    rank: 2,
    text: 'SILVER',
  },
  {
    logo: '/images/medals/gold-medal.png',
    rank: 3,
    text: 'GOLD',
  },
  {
    logo: '/images/medals/bronze-medal.png',
    rank: 1,
    text: 'BRONZE',
  },
]
