import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer'
import { Delivery } from '@types_def/models/delivery.types'
import { style } from '@mui/material'

// Font.register({
//   family: 'Oswald',
//   src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });

const formatDate = (dateOrString: Date | string) => {
  if (!dateOrString) return 'Error'
  const date = new Date(dateOrString)
  return new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date)
}
const styles = StyleSheet.create({
  page: {
    padding: 20,
    flex: 1,
    fontFamily: 'Times-Roman',
  },
  header: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
  BlockContainer: {
    border: '2px solid #3C5081',
    marginBottom: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    minHeight: 80,
    backgroundColor: '#E7EFFF',
  },
  BlockContainerTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    borderBottom: '1px solid #334B49',
    paddingBottom: 5,
    marginBottom: 10,
    color: '#3B3C70',
    textTransform: 'capitalize',
  },
  logo: {
    width: 140,
    objectFit: 'contain',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#A7AABD',
  },
  image: {
    width: 260,
    objectFit: 'contain',
  },
})

const PdfDoc = ({ delivery }: { delivery: Delivery }) => {
  const { imagesBefore, imagesAfter } = delivery.vehicles?.[0]
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Header id={delivery?.id} />

        <BlockContainer title={'Date et heure locale'}>
          <TextRow title={'Temps de dépard'} value={formatDate(delivery?.departedAt)} />
          <TextRow title={"Temps d'arrivée"} value={formatDate(delivery?.completedAt)} />
        </BlockContainer>
        <BlockContainer title={'Géo-localisation'}>
          <TextRow title={'Point de dépard'} value={delivery?.fromAddress?.address} />
          <TextRow title={"Point d'arrivée"} value={delivery?.toAddress?.address} />
        </BlockContainer>
        {delivery.selectedDriver?.firstName ? (
          <BlockContainer title={'Convoyeur'}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <TextRow title={'Prénom'} value={delivery?.selectedDriver.firstName} />
                <TextRow title={'Nom'} value={delivery?.selectedDriver.lastName} />
              </View>
              <View>
                <TextRow title={'Société'} value={delivery?.selectedDriver.company} />
                <TextRow title={'Adresse'} value={delivery?.selectedDriver?.address?.address} />
              </View>
            </View>
          </BlockContainer>
        ) : null}
        <BlockContainer title={'Client'}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>
              <TextRow title={'Prénom'} value={delivery?.clientFirstName} />
              <TextRow title={'Nom'} value={delivery?.clientLastName} />
            </View>
            <View>
              <TextRow title={'Société'} value={delivery?.clientCompany} />
              <TextRow title={'Coordonnées'} value={delivery?.clientPhone} />
            </View>
          </View>
        </BlockContainer>
        <BlockContainer title={'Validation'}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>
              <TextRow title={'Prénom'} value={delivery?.representativeFirstName} />
              <TextRow title={'Nom'} value={delivery?.representativeLastName} />
            </View>
            <View>
              <TextRow title={'Société'} value={'ALB'} />
              <TextRow title={'Position'} value={'Gestionnaire compte client'} />
            </View>
          </View>
        </BlockContainer>
        <BlockContainer title={'Véhicule'}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>
              <TextRow title={'Immatriculation'} value={delivery?.vehicles?.[0].vehicleCode} />
              <TextRow title={'Marque'} value={delivery?.vehicles?.[0].marque} />
              <TextRow title={'Modèle'} value={delivery?.vehicles?.[0].model} />
              <TextRow
                title={'Kilométrage départ'}
                value={delivery?.vehicles?.[0]?.mileageBefore?.toString() + ' Km'}
              />
              <TextRow
                title={'Kilométrage retour'}
                value={delivery?.vehicles?.[0]?.mileageAfter?.toString() + ' Km'}
              />
            </View>

            <View>
              <TextRow title={'Mise en Main'} value={''} />
              <TextRow title={'Antenne'} value={''} />
              <TextRow title={'Kit de sécurité'} value={''} />
              <TextRow title={'Manuel d’utilisation'} value={''} />
            </View>
          </View>
        </BlockContainer>

        {/* <BlockContainer title='Clôture de la livraison'>
          <Text style={{ fontSize: 12 }}>
            Livraison clôturé avec l’accord de Nicolas Ibarrart, directeur général de ALB suite à un
            problème technique de l’application.{' '}
          </Text>
        </BlockContainer> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      <Page size='A4' style={styles.page}>
        <Header id={delivery?.id} />
        <ImagesBlock images={imagesBefore} title={'Véhicule: état de départ'} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      <Page size='A4' style={styles.page}>
        <Header id={delivery?.id} />
        <ImagesBlock images={imagesAfter} title={'Véhicule: état de retour'} />
        <Text
          style={{
            fontSize: 12,
            textAlign: 'left',
            color: 'grey',
            marginTop: 10,
            textTransform: 'capitalize',
          }}
        >
          {formatDate(new Date())}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}
const Header = ({ id }: { id?: number }) => {
  return (
    <View
      fixed
      style={{
        marginBottom: 20,
      }}
    >
      <View style={styles.header}>
        <Image src='/images/logo.png' style={styles.logo} />
        <View>
          <Text
            style={{
              borderBottom: '1px solid black',
              paddingBottom: 5,
              marginBottom: 5,
              fontSize: 14,
              fontFamily: 'Times-Bold',
              color: '#00244F',
            }}
          >
            Rapport d’état – FINAL :
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: '#3C5081',
            }}
          >
            E-check Livraison by ALB
          </Text>
        </View>
      </View>
      <Text
        style={{
          fontSize: 12,
          color: '#00AF91',
          fontFamily: 'Times-BoldItalic',
          letterSpacing: 0.5,
        }}
      >
        Référence livraison : n°{id}
      </Text>
    </View>
  )
}
const ImagesBlock = ({ images, title }: { images: Record<string, string>; title: string }) => {
  return (
    <BlockContainer title={title}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 5,
          flexWrap: 'wrap',
        }}
      >
        {images &&
          Object?.entries(images)?.map(([key, value]) => {
            if (typeof value === 'string' && value.includes('.')) {
              return <Image key={key} src={value} style={styles.image} />
            } else {
              return null
            }
          })}
      </View>
    </BlockContainer>
  )
}

const TextRow = ({ title, value }: { title: string; value: string }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        gap: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: 250,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          letterSpacing: 0.4,
        }}
      >
        {title}:{' '}
      </Text>
      <Text
        style={{
          fontSize: 11,
          fontWeight: 'light',
        }}
      >
        {value}
      </Text>
    </View>
  )
}
const BlockContainer = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <View style={styles.BlockContainer}>
      <Text style={styles.BlockContainerTitle}>{title}</Text>
      {children}
    </View>
  )
}
export default PdfDoc
