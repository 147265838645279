const FlagIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.922 0.00073125C14.7299 0.00073125 14.5304 0.0427313 14.3395 0.132825C12.9612 0.7847 11.9394 1.00595 11.0866 1.00595C9.27771 1.00595 8.22655 0.010325 6.14327 0.0100125C5.0946 0.0100094 3.79158 0.260125 2.00109 1.01188V1C2.00109 0.445312 1.55582 0 1.00117 0C0.446528 0 0.00124993 0.445312 0.00124993 1L0 15.5C0 15.7734 0.226545 16 0.499961 16H1.50113C1.77455 16 2.00109 15.775 2.00109 15.5V12C3.61784 11.2544 4.89806 11.0056 6.01016 11.0056C8.23154 11.0056 9.77861 11.9987 12.0003 11.9987C12.963 11.9987 14.0523 11.8122 15.3782 11.2772C15.7594 11.1219 16 10.7625 16 10.3781V0.960625C16 0.375313 15.4938 0.00073125 14.922 0.00073125ZM14.0002 4.43125C13.0005 4.87 12.1937 5.07469 11.5004 5.13406V7.64062C12.2953 7.59447 13.1124 7.42334 14.0002 7.09875V9.65312C13.2868 9.88566 12.6275 9.99938 11.9994 9.99938C11.8292 9.99938 11.6647 9.98607 11.5004 9.97154V7.64062C11.3617 7.64868 11.2225 7.66101 11.0851 7.66101C10.2783 7.66101 9.6055 7.51892 9.00055 7.34601V9.43695C8.25654 9.24773 7.43286 9.08038 6.50074 9.03132V6.65938C6.382 6.67813 6.26639 6.66563 6.1414 6.66563C5.52582 6.66563 4.78525 6.80313 4.00094 7.03438V9.19375C3.35099 9.35625 2.68542 9.55625 2.00109 9.825V7.83437L2.7751 7.50969C3.2135 7.32812 3.61347 7.18438 4.00094 7.03438V4.48125C3.40411 4.64062 2.75416 4.85 2.00109 5.16563V3.18125L2.7751 2.85656C3.2135 2.6725 3.61347 2.53031 4.00094 2.40687V4.48125C4.82556 4.26397 5.53488 4.16438 6.14077 4.16469C6.26801 4.16469 6.38063 4.1786 6.50074 4.18556V2.03219C7.08195 2.08125 7.57566 2.23594 8.16311 2.43219C8.42872 2.51969 8.70994 2.60781 9.00055 2.69125V4.75594C9.65018 4.96969 10.2958 5.16094 11.0851 5.16094C11.2165 5.16094 11.3595 5.14602 11.5004 5.13406V2.98563C12.2953 2.93947 13.1124 2.76834 14.0002 2.44375V4.43125ZM6.50074 4.18437V6.68563C7.08226 6.73469 7.57472 6.88947 8.16405 7.08563C8.42872 7.175 8.70994 7.26163 9.00055 7.34601V4.75594C8.25373 4.50906 7.48817 4.24375 6.50074 4.18437Z'
      fill='#fff'
    />
  </svg>
)

export default FlagIcon
