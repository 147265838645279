import { useNavigate } from 'react-router-dom'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRank, UserRole } from '@types_def/models/user.type'
import { RoutePaths } from '@utils/constants/routes'
import HomeIcon from '../../icons/home.icon'
import NotebookIcon from '../../icons/notebook.icon'
import PersonIcon from '../../icons/person.icon'
import SteeringWheelIcon from '../../icons/steering-wheel.icon'
import { NavigationLink } from '../../NavigationLink'
import styles from './index.module.scss'
import NotificationContainer from '@services/Notification'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { useTranslation } from 'react-i18next'
import ChangeLangauge from '../../ChangeLanguage'
import AnySearch from '../../AnySearch'
import UserAvatar from '../UserAvatar'

const MainHeader = () => {
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const navigate = useNavigate()
  const role = userState?.['custom:role']?.toLowerCase()
  const isAdmin = role === UserRole.ADMIN.toLowerCase()
  const rank = Number(userState?.['custom:rank']) ?? undefined

  const goToDashboard = () => {
    navigate(`${role}/dashboard`)
  }

  return (
    <>
      {/* <NotificationContainer /> Remove firebase and notification cause they are unused */}
      <nav className={`${styles['main-header']} `}>
        <div>
          <img
            src='/images/logo.svg'
            alt='logo'
            className={`${styles.logo}`}
            onClick={goToDashboard}
          />
          <NavigationLink to={`/${role}/dashboard`} includes={RoutePaths.dashboard}>
            <HomeIcon /> {t('layouts.header.dashboard')}
          </NavigationLink>
          <NavigationLink
            to={`/${role}/pilot/delivery-table/${
              role === UserRole.ADMIN.toLowerCase() ? 'requested' : 'confirmed'
            }`}
            includes={RoutePaths.deliveryTable}
          >
            <SteeringWheelIcon />
            {t('layouts.header.deliveries')}
          </NavigationLink>
          {role === UserRole.CLIENT.toLowerCase() && rank <= UserRank.ORGANIZATION_MANAGER && (
            <NavigationLink
              to={RoutePaths.clientOrganizationAddMember}
              includes={RoutePaths.organization}
            >
              <CorporateFareIcon /> {t('layouts.header.organization')}
            </NavigationLink>
          )}
          {role === UserRole.ADMIN.toLowerCase() && (
            <>
              <NavigationLink
                to={RoutePaths.adminDriversTableacceptedRequests}
                includes={RoutePaths.drivers}
              >
                <PersonIcon /> {t('layouts.header.drivers')}
              </NavigationLink>
              <NavigationLink
                to={RoutePaths.adminNewOrganization}
                includes={RoutePaths.organization}
              >
                <CorporateFareIcon /> Organisations
              </NavigationLink>
              {isAdmin && rank === UserRank.SUPER_ADMIN ? (
                <NavigationLink
                  to={RoutePaths.adminManageAdmins}
                  includes={RoutePaths.manageAdmins}
                >
                  <AdminPanelSettingsIcon /> {t('layouts.header.managers')}
                </NavigationLink>
              ) : null}
              <NavigationLink
                to={`${RoutePaths.adminInvoicesDriver}/subscription`}
                includes={RoutePaths.invoices}
              >
                <NotebookIcon /> {t('layouts.header.invoices')}
              </NavigationLink>
            </>
          )}
        </div>

        {/* <div>{role === UserRole.CLIENT.toLowerCase() && <img src='/images/arval-logo.png' />}</div> */}
        <div>
          <AnySearch isAdmin={isAdmin} />
          <ChangeLangauge />
          {/* {role === UserRole.CLIENT.toLowerCase() && (
            <>
              <NavigationLink to={`/${role}/help`} includes={RoutePaths.help}>
                <FloatIcon /> {t('layouts.header.support-faq')}
              </NavigationLink>
            </>
          )}
          
          <div className={`${styles.exit}`}>
            <a onClick={handleLogout}>
              <ExitIcon />
            </a>
          </div> */}
          <UserAvatar userState={userState} />
        </div>
      </nav>
    </>
  )
}

export default MainHeader
