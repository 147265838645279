import type Driver from '@types_def/models/driver.types'

import update from '../generic/update'

export const confirmDriverByAlb = async (id: number) => {
  const driver = await update<Driver>({ id, confirmedByAlb: true }, 'Driver', {
    id: true,
    confirmedByAlb: false,
  })
  return driver
}

export const DisableDriver = async (id: number) => {
  alert('disable driver')
  const driver = await update<Driver>({ id, disabled: true }, 'Driver', {
    id: true,
    disabled: true,
  })
  return driver
}

export const EnableDriver = async (id: number) => {
  const driver = await update<Driver>({ id, disabled: true }, 'Driver', {
    id: true,
    disabled: false,
  })
  return driver
}

//TODO: add update driver
export const UpdateDriver = async (id: number, changes: Driver) => {
  return await update<Driver>({ id, ...changes }, 'Driver', { id: true })
}
