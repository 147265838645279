import { Box, Chip, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useRef } from 'react'
import styles from './index.module.scss'
import SearchIcon from '@mui/icons-material/Search'
import { Delivery as TDelivery } from '@types_def/models/delivery.types'
import DeliveryTable from './DeliveryTable'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'
import CircularProgress from '@mui/material/CircularProgress'
import SearchOptions from './SearchOptions'
import { TableStateType } from '../query'

const Delivery = () => {
  const [isLoading, setIsLoading] = useState(false)
  const user = useUserContext().userState
  const isAdmin = user!['custom:role'] === UserRole.ADMIN
  const organizationId = user!.organizationId
  const [tableState, setTableState] = React.useState<TableStateType<TDelivery>>({
    tableFilter: {
      page: 0,
      order: 'desc',
      orderBy: 'id',
    },
  })
  const searchOptions = SearchOptions({ isAdmin, organizationId })
  const [data, setData] = useState<{
    records: TDelivery[]
    total: number
  }>()
  const formRef = useRef<HTMLFormElement>(null)
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const keyword = event.currentTarget.querySelector('input')?.value
    if (!keyword || keyword === '') {
      setIsLoading(false)
      setData(undefined)
      return
    }
    setIsLoading(true)
    searchOptions[search]
      .fn(keyword, tableState)
      .then((data) => {
        setData(data)
      })
      .catch((err) => {
        console.error(err)
        setData(undefined)
      })
      .finally(() => setIsLoading(false))
  }
  // click on escape to clear the search :: reset form
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Escape') {
      formRef.current?.reset()
      setData(undefined)
    }
  }
  const [search, setSearch] = useState(0)

  const handlepageChange = (page: number) => {
    const value = formRef.current?.querySelector('input')?.value
    if (!value || value === '') {
      setIsLoading(false)
      setData(undefined)
      return
    }
    setIsLoading(true)
    setTableState({ ...tableState, tableFilter: { ...tableState.tableFilter, page } })
    searchOptions[search]
      .fn(value, {
        ...tableState,
        tableFilter: { ...tableState.tableFilter, page },
      })
      .then((data) => {
        setData(data)
      })
      .catch((err) => {
        console.error(err)
        setData(undefined)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {searchOptions.map((option) => (
          <Chip
            sx={{
              minWidth: '60px',
              textTransform: 'uppercase',
            }}
            key={option.id}
            label={option.name}
            color='secondary'
            onClick={() => setSearch(option.id)}
            variant={option.id == search ? 'filled' : 'outlined'}
          />
        ))}
      </div>
      <Divider sx={{ marginY: '10px' }} />
      <div>
        <form
          className={`${styles.search} item`}
          onSubmit={handleSubmit}
          ref={formRef}
          onKeyDown={handleKeyDown}
        >
          <input type='text' placeholder={'search'} />
          <SearchIcon />
        </form>
      </div>

      <Divider sx={{ marginY: '20px' }} />
      {isLoading ? (
        <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <DeliveryTable
          data={data}
          handleChangePage={(_, page) => {
            handlepageChange(page)
          }}
          page={tableState.tableFilter.page}
        />
      )}
    </>
  )
}

export default Delivery
