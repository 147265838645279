const StatsIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.4812 7.5H8.5V0.518125C8.5 0.236094 8.69063 0 9 0C12.8656 0 16 3.13438 16 7C16 7.28125 15.7625 7.5 15.4812 7.5ZM5.94375 1.16188C6.50938 1.0425 7 1.50625 7 2.08437V9H13.9156C14.4937 9 14.9562 9.49062 14.8375 10.0562C14.1219 13.4531 11.1094 16 7.5 16C3.35938 16 0 12.6438 0 8.5C0 4.89062 2.54781 1.87875 5.94375 1.16188Z'
      fill='#202020'
    />
  </svg>
)

export default StatsIcon
