import './index.scss'

import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  hasNumber,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
  passwordMatch,
  validLength,
} from '@utils/functions/inputValidators'
type Props = {
  password: string
  confirmPassword: string
}

export const PasswordValidator: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { password, confirmPassword } = props

  return (
    <div className='password-validator-container'>
      <div>{t('common.form.helper.password-must-contain')} : </div>
      <ul>
        <li className={`${validLength(password, 8) && 'secondary-color'}`}>
          8 {t('common.shared.characters')}
        </li>
        <li className={`${hasNumber(password) && 'secondary-color'}`}>
          1 {t('common.shared.number')}
        </li>
        {/* <li className={hasSpecialCharacter(password) ? 'secondary-color' : ''}>
          1 caractère spécial
        </li> */}
        <li className={hasUpperAndLowerCase(password) ? 'secondary-color' : ''}>
          1 {t('common.shared.uppercase')} et 1 {t('common.shared.lowercase')}
        </li>
        <li className={passwordMatch(password, confirmPassword) ? 'secondary-color' : ''}>
          {t('common.form.helper.passwords-match')}
        </li>
      </ul>
    </div>
  )
}
