import { Grid, Typography } from '@mui/material'
import React from 'react'
import styles from './index.module.scss'
type Props = {
  data: Record<string, string | string[]>
}

const renderQ = (q: string | string[]) => {
  if (typeof q === 'string') return <span>{q}.</span>
  return q.map((item: string) => <span key={item}>{item}. </span>)
}

const Questions = ({ data }: Props) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#E7E6F7',
        color: '#00244f',
        borderRadius: 1,
        padding: '20px',
        height: '100%',
      }}
    >
      <Grid item xs={12}>
        <Typography variant='h5' component='h4'>
          Réponses aux questions
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr className={styles.devider} />
        <ul
          style={{
            listStyle: 'none',
            textTransform: 'capitalize',
            fontSize: '1rem',
          }}
        >
          {data &&
            Object.entries(data).map(([key, value]) => (
              <li key={key} color='#00244f' style={{ marginBottom: '5px' }}>
                <strong style={{ display: 'inline-block', width: '120px' }}>
                  {`${key.slice(0, -1)} ${key.charAt(key.length - 1)}`}:{' '}
                </strong>{' '}
                {renderQ(value)}
              </li>
            ))}
        </ul>
        <hr className={styles.devider} />
      </Grid>
    </Grid>
  )
}

export default Questions
