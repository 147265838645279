import './index.scss'

import { DateTime } from 'luxon'
import { useState } from 'react'

import { InputContainer } from '../InputContainer'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
type Props = {
  value: DateTime | undefined
  label?: string
  alignLabel?: boolean
  onChange: (date: DateTime | undefined) => void
  type?: 'date' | 'dateTime'
  disabled?: boolean
  maxDate?: DateTime
  minDate?: DateTime
  inChart?: boolean
  validation?: boolean
}
const DatePickerControl = ({
  value,
  label,
  alignLabel,
  onChange,
  disabled,
  type = 'date',
  maxDate,
  minDate,
  inChart,
  validation = true,
}: Props) => {
  const inChartClass = inChart ? 'inChart' : ''
  const [dateValue, setDateValue] = useState<DateTime | undefined>(value)

  const handleDateChange = (date: Dayjs | undefined) => {
    // @ts-ignore
    setDateValue(date?.toISOString())
    // @ts-ignore
    onChange(date?.toISOString())
  }
  return (
    <InputContainer alignLabel={alignLabel} label={label} className={`${inChartClass} `}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
        {type === 'date' ? (
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            minDate={minDate ? dayjs(minDate?.toString()) : null}
            maxDate={maxDate ? dayjs(maxDate?.toString()) : null}
            disabled={disabled}
            value={dateValue ? dayjs(dateValue?.toString()) : null}
            onChange={(date) => handleDateChange(date)}
          />
        ) : (
          <DateTimePicker
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            ampm={false}
            minDate={minDate ? dayjs(minDate?.toString()) : null}
            maxDate={maxDate ? dayjs(maxDate?.toString()) : null}
            disabled={disabled}
            value={dateValue ? dayjs(dateValue?.toString()) : null}
            onChange={(date) => handleDateChange(date)}
          />
        )}
      </LocalizationProvider>
    </InputContainer>
  )
}

export default DatePickerControl
