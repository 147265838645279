import { gql, graphqlMutation } from '@api/clients/apollo'
import { Delivery, DeliveryType } from '@types_def/models/delivery.types'

type ApiUpdateResponse<Delivery> = {
  updateType: Delivery
}

export async function updateDeliveryType(input: { id: number; type: DeliveryType }) {
  const { id, type } = input
  try {
    const res = await graphqlMutation<ApiUpdateResponse<Delivery>, any>(
      gql`
        mutation updateDeliveryType($input: updateDeliveryTypeInput!) {
          updateDeliveryType(UpdateDeliveryTypeInput: $input) {
            id
            type
          }
        }
      `,
      { input },
    )
    return res.data?.updateType
  } catch (error) {
    throw new Error(error as string)
  }
}

export default updateDeliveryType
