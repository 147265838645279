import { Dialog, DialogContent, DialogContentText, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useCancelDelivery,
  useConfirmDelivery,
  useDeleteDelivery,
  useUpdateDeliveryStatus,
  useUpdateDeliveryType,
} from '@components/Delivery/DeliveryFormV2/Querys'
import CustomModal from '@components/common/CustomModal'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { UserRank } from '@types_def/models/user.type'
import { enumToStr } from '@utils/strEnum'
import CancelDelivery from '@forms/cancelDelivery'
import { TCancelDelivery } from '@schemas/DeliveryForm/CancelDeliverySchema'
import useDeliveriesCount from '@hooks/useDeliveriesCount'
import { getDeliveriesCountKeyByStatus } from '@utils/getDeliveriesCountKeyByStatus'

type Props = {
  id: number
  status: keyof typeof DeliveryStatus
  type: keyof typeof DeliveryType
  isAdmin?: boolean
  rank?: UserRank
}

type ButtonDetails = {
  title?: string
  description?: string
  action?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  confirmText?: string
  canOpen?: boolean
  requireAdmin?: boolean
  minRank?: UserRank
  children?: React.ReactNode
}

const ViewDeliveryButtons = ({ id, status, type, isAdmin = false, rank = undefined }: Props) => {
  const { updateDeliveriesCount } = useDeliveriesCount()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  if (
    !isAdmin &&
    ![
      DeliveryStatus.REQUESTED,
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_MANDATORY,
    ].includes(status as DeliveryStatus)
  )
    return null
  const navigation = useNavigate()
  const strType = enumToStr<DeliveryType>(type)
  const strStatus = enumToStr<DeliveryStatus>(status)
  const { mutate: confirmDelivery } = useConfirmDelivery()
  const { mutate: deleteDelivery } = useDeleteDelivery()
  const { mutate: updateDeliverStatus } = useUpdateDeliveryStatus()
  const { mutate: updateDeliverType } = useUpdateDeliveryType()
  const { mutate: cancelDelivery } = useCancelDelivery()

  const onRemoveRestitution = (id: number) => {
    updateDeliverType(
      { id, type: DeliveryType.STANDARD },
      {
        onSuccess: () => {
          navigation(-1)
        },
      },
    )
  }

  const onTerminate = (id: number) => {
    updateDeliverStatus(
      { id, status: DeliveryStatus.COMPLETED },
      {
        onSuccess: () => {
          updateDeliveriesCount('deliveredCount', '+')
          updateDeliveriesCount('onGoingCount', '-')
          navigation(`/admin/pilot/details/${strType}/completed/${id}`)
        },
      },
    )
  }

  const onEnable = (id: number) => {
    updateDeliverStatus(
      { id, status: DeliveryStatus.REQUESTED },
      {
        onSuccess: () => {
          updateDeliveriesCount('cancelledCount', '-')
          updateDeliveriesCount('requestedCount', '+')
          navigation(-1)
        },
      },
    )
  }

  const onCancel = (data: { id: number; reason: TCancelDelivery }) => {
    const countKey = getDeliveriesCountKeyByStatus(status as DeliveryStatus)
    cancelDelivery(data, {
      onSuccess: () => {
        updateDeliveriesCount(countKey, '-')
        updateDeliveriesCount('cancelledCount', '+')
        navigation(-1)
      },
    })
  }

  const onConfirm = (id: number) => {
    confirmDelivery(id, {
      onSuccess: () => {
        updateDeliveriesCount('confirmedCount', '+')
        updateDeliveriesCount('requestedCount', '-')
        navigation(`/admin/pilot/create-deal/confirmed/${strType}/${id}`)
      },
    })
  }

  const onDelete = (id: number) => {
    deleteDelivery(id, {
      onSuccess: () => {
        navigation(-1)
      },
    })
  }

  const onEdit = (id: number) => {
    isAdmin
      ? navigation(`/admin/pilot/edit-deal/${strType}/${strStatus}/${id}`)
      : navigation(`/client/edit-delivery/${strType}/${strStatus}/${id}`)
  }

  const annulerRestitution: ButtonDetails = {
    canOpen: true,
    confirmText: t('delivery.action.cancel-restitution.text'),
    variant: 'contained',
    color: 'error',
    title: `${t('delivery.action.cancel-restitution.title')} ${id}`,
    description: t('delivery.action.cancel-restitution.description'),
    action: () => onRemoveRestitution(id),
    requireAdmin: true,
    minRank: UserRank.MAINTAINER,
  }

  const changerDriver: ButtonDetails = {
    canOpen: true,
    confirmText: 'Changer chauffeur',
    variant: 'contained',
    color: 'info',
    title: 'Changer le chauffeur',
    description: 'Êtes-vous sûr de vouloir change le chauffeur du cette course',
    action: () => navigation(`/admin/pilot/edit-deal/waiting-for-driver/${strType}/${id}`),
    requireAdmin: true,
    minRank: UserRank.MAINTAINER,
  }

  const modifier: ButtonDetails = {
    confirmText: t('delivery.action.edit.text'),
    action: () => onEdit(id),
    variant: 'contained',
    color: 'primary',
    canOpen: false,
    requireAdmin: false,
    minRank: UserRank.MAINTAINER,
  }

  const annuler: ButtonDetails = {
    title: `${t('delivery.action.cancel.title')} ${id}`,
    description: t('delivery.action.cancel.description'),
    action: () => setOpen(true),
    variant: 'contained',
    color: 'warning',
    confirmText: t('delivery.action.cancel.text'),
    canOpen: false,
    requireAdmin: false,
    minRank: UserRank.MAINTAINER,
  }

  const supprimer: ButtonDetails = {
    title: `${t('delivery.action.delete.title')} ${id}`,
    description: t('delivery.action.delete.description'),
    action: () => onDelete(id),
    variant: 'contained',
    color: 'error',
    confirmText: t('delivery.action.delete.text'),
    canOpen: true,
    requireAdmin: true,
    minRank: UserRank.MAINTAINER,
  }

  const confirmer: ButtonDetails = {
    title: `${t('delivery.action.confirm.title')} ${id}`,
    description: t('delivery.action.confirm.description'),
    action: () => onConfirm(id),
    variant: 'contained',
    color: 'success',
    confirmText: t('delivery.action.confirm.text'),
    canOpen: true,
    requireAdmin: true,
    minRank: UserRank.MAINTAINER,
  }

  const terminer: ButtonDetails = {
    title: `${t('delivery.action.terminate.title')} ${id}`,
    description: t('delivery.action.terminate.description'),
    action: () => onTerminate(id),
    variant: 'contained',
    color: 'success',
    confirmText: t('delivery.action.terminate.text'),
    canOpen: true,
    requireAdmin: true,
    minRank: UserRank.MAINTAINER,
  }

  const activer: ButtonDetails = {
    title: `${t('delivery.action.activate.title')} ${id}`,
    description: t('delivery.action.activate.description'),
    action: () => onEnable(id),
    variant: 'contained',
    color: 'success',
    confirmText: t('delivery.action.activate.text'),
    canOpen: true,
    requireAdmin: false,
    minRank: UserRank.MAINTAINER,
  }

  const buttonsDetails = (): ButtonDetails[] => {
    if (
      [
        DeliveryStatus.REQUESTED,
        DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
        DeliveryStatus.SMS_SENT,
        DeliveryStatus.CONFIRMED_BY_CUSTOMER,
      ].includes(status as DeliveryStatus)
    ) {
      const buttons = [annuler, supprimer, modifier, confirmer]
      if (type === DeliveryType.TWO_WAY_DELIVERY) buttons.unshift(annulerRestitution)
      return buttons.filter((button) => {
        return rank && isAdmin ? button?.minRank >= rank : isAdmin ? true : !button.requireAdmin
      })
    } else if (DeliveryStatus.COMPLETED === (status as DeliveryStatus)) {
      return []
    } else if (
      [
        DeliveryStatus.CONFIRMED,
        DeliveryStatus.WAITING_FOR_DRIVER,
        DeliveryStatus.ALERT,
        DeliveryStatus.SET,
      ].includes(status as DeliveryStatus)
    ) {
      const buttons = [annuler, supprimer, modifier]
      if (
        type === DeliveryType.TWO_WAY_DELIVERY &&
        [DeliveryStatus.CONFIRMED, DeliveryStatus.WAITING_FOR_DRIVER].includes(
          status as DeliveryStatus,
        )
      )
        buttons.unshift(annulerRestitution)
      if ([DeliveryStatus.SET].includes(status as DeliveryStatus)) buttons.push(changerDriver)

      return buttons.filter((button) => {
        return rank && isAdmin ? button?.minRank >= rank : isAdmin ? true : !button.requireAdmin
      })
    } else if (
      [
        DeliveryStatus.CANCELLED_BY_ADMIN,
        DeliveryStatus.CANCELLED,
        DeliveryStatus.CANCELLED_BY_CUSTOMER,
        DeliveryStatus.CANCELLED_BY_MANDATORY,
      ].includes(status as DeliveryStatus)
    ) {
      const buttons = [supprimer, activer]
      return buttons.filter((button) => {
        return isAdmin ? true : !button.requireAdmin
      })
    } else if (
      [
        DeliveryStatus.PICKUP_CLIENT,
        DeliveryStatus.PICKUP_DRIVER,
        DeliveryStatus.ONGOING,
        DeliveryStatus.SWAP_DELIVERED,
        DeliveryStatus.DELIVERED,
        DeliveryStatus.SWAP_IN_TRANSIT,
        DeliveryStatus.SWAP_PICKUP_CLIENT,
        DeliveryStatus.SWAP_PICKUP_DRIVER,
      ].includes(status as DeliveryStatus)
    ) {
      const buttons = [annuler, terminer]
      //if (type === DeliveryType.TWO_WAY_DELIVERY) buttons.unshift(annulerRestitution)
      return buttons.filter((button) => {
        return isAdmin ? true : !button.requireAdmin
      })
    }
  }

  const buttons: ButtonDetails[] = buttonsDetails()

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-describedby='alert-dialog-description'>
        <DialogContent>
          <CancelDelivery handleClose={handleClose} id={id} handleCancelDelivery={onCancel} />
        </DialogContent>
      </Dialog>
      {buttons.map((button, index) => (
        <Grid
          key={`mainbuttons${index}`}
          item
          xs={index === buttons.length - 1 ? (Number(buttons.length % 2) === 0 ? 6 : 12) : 6}
          lg={12 / buttons.length}
        >
          <CustomModal
            key={index + button.title}
            canOpen={button.canOpen}
            fullWidth={true}
            button={button.confirmText}
            confirmText={button.confirmText}
            title={button?.title}
            confirmAction={button.action}
            disabled={false}
            variant={button.variant}
            color={button.color}
          >
            <DialogContentText id='alert-dialog-description'>
              {button?.description}
            </DialogContentText>
          </CustomModal>
        </Grid>
      ))}
    </>
  )
}

export default ViewDeliveryButtons
