const WarningIcon = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.799 13.75L10.2999 0.75C9.72581 -0.25 8.27697 -0.25 7.69898 0.75L0.203399 13.75C-0.373536 14.7464 0.345048 16 1.50141 16H16.4996C17.6514 16 18.3721 14.75 17.799 13.75ZM8.15532 4.85714C8.15532 4.38393 8.53327 4 8.99911 4C9.46494 4 9.84289 4.38571 9.84289 4.85714V9.42857C9.84289 9.90179 9.46494 10.2857 9.03075 10.2857C8.59655 10.2857 8.15532 9.90357 8.15532 9.42857V4.85714ZM8.99911 13.7143C8.38877 13.7143 7.89375 13.2114 7.89375 12.5914C7.89375 11.9714 8.38842 11.4686 8.99911 11.4686C9.60979 11.4686 10.1045 11.9714 10.1045 12.5914C10.1031 13.2107 9.61085 13.7143 8.99911 13.7143Z'
      fill='#FFF'
    />
  </svg>
)

export default WarningIcon
