import { sendRawRequest } from '@api/clients/axios'
import { type ApiLoginResponse } from '@types_def/common/auth.types'
import { type HttpError } from '@types_def/common/error.types'
import { storeAuthResult } from '@utils/tokenManagement.util'

export type SetNewPasswordInput = {
  username: string
  newPassword: string
  session: string
}

export type ResendCodeInput = {
  username: string
}

export default async function loginWithNewPassword(attempt: SetNewPasswordInput) {
  try {
    const { data } = await sendRawRequest<SetNewPasswordInput, ApiLoginResponse>(
      'post',
      '/auth/set-new-password',
      attempt,
    )

    storeAuthResult(data)
    return {
      success: true,
      action: 'LOGIN',
      result: data.AuthenticationResult,
    }
  } catch (error) {
    throw new Error((error as HttpError).message as string)
  }
}
