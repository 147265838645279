import { Box, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { toast } from 'react-toastify'
import { sendRawRequest, sendRequest } from '@api/clients/axios'
import { EnterpriseNameWithContext } from '@components/organization/OrganizationForm/EnterpriseName'
import { useUserContext } from '@contexts/UserContext/index.context'
import { useTranslation } from 'react-i18next'
import OrganizationMembersTable from '@components/organization/OrganizationMembersTable'
import { UserRank } from '@types_def/models/user.type'
import MemberForm from '@components/organization/Member/Form'
import {
  MemberFormSchema,
  MemberFormSchemaDefaultValues,
} from '@schemas/OrganizationForm/member.schema'

type Props = {}
export function fetchOrganization(slug: string) {
  return sendRequest<any, any>('get', `/organizations/${slug}`)
}
const ClientOrganization = (props: Props) => {
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const userRank = userState['custom:rank']
  const unUsedFields: Array<keyof typeof MemberFormSchema.shape> = ['id']
  const values = MemberFormSchemaDefaultValues
  if (userRank !== UserRank.ORGANIZATION_OWNER) {
    unUsedFields.push('assigneeRank')
    values.assigneeRank = UserRank.ORGANIZATION_MEMBER
  } else {
    values.assigneeRank = UserRank.ORGANIZATION_MANAGER
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ['organization'],
    queryFn: () => fetchOrganization(userState.organizationId),
  })

  if (isLoading) return <div>Loading...</div>

  if (isError) return <div>Error...</div>
  const onSubmit = async (data) => {
    const { member } = data.owner
    try {
      const response = await sendRawRequest<any, any>('post', '/organization/add-member', member)
      if (response.data.success) {
        toast.success(t('network.organization.member.created'), {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t('network.errors.unknown'), {
        position: 'bottom-right',
      })
    }
  }
  return (
    <Box p={4}>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={4} spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h4' sx={{ mb: 4 }}>
              Organization details
            </Typography>

            <EnterpriseNameWithContext defaultValues={data.data} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{ mb: 4 }}>
              Add member
            </Typography>
            <MemberForm
              unUsedFields={unUsedFields}
              values={values}
              onSubmit={onSubmit}
              title={''}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <h2>Liste des membres</h2>
          </Grid>
          <Grid item xs={12}>
            <OrganizationMembersTable memberRank={UserRank.ORGANIZATION_MEMBER} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientOrganization
