import { type FunctionComponent } from 'react'

import SimpleInputControl from '@components/common/inputs/SimpleInput'

import { PasswordValidator } from '../../PasswordValidator'
import { useTranslation } from 'react-i18next'

type Props = {
  email: string
  password: string
  confirmPassword: string
  handleInputChange: (value: string | boolean | number, identifier?: string) => void
}

export const NewPasswordFormSection: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { email, password, confirmPassword, handleInputChange } = props

  return (
    <>
      <SimpleInputControl
        value={email}
        type='text'
        label={t('common.form.input.email')}
        identifier='email'
        disabled={true}
      />
      <SimpleInputControl
        value={password}
        onChange={handleInputChange}
        type='password'
        identifier='password'
        label={t('common.form.input.new-password')}
      />

      <SimpleInputControl
        value={confirmPassword}
        onChange={handleInputChange}
        type='password'
        identifier='confirmPassword'
        label={t('common.form.input.confirm-password')}
      />
      <PasswordValidator confirmPassword={confirmPassword} password={password} />
    </>
  )
}
