type ValidateFormReturn = {
  valid: boolean
  message: string
}

export const isValidEmail = (email: string) =>
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    email,
  )

export const areValidEmails = (...emails: string[]) => {
  for (let i = 0; i < emails.length; i++) {
    if (!isValidEmail(emails[i])) {
      return false
    }
  }

  return true
}

export const hasInvalidEmail = (...emails: string[]) => {
  for (let i = 0; i < emails.length; i++) {
    if (!isValidEmail(emails[i])) {
      return true
    }
  }

  return false
}

export const isNotEmptyString = (str: string) => str && str !== ''

export const isEmptyString = (str: string) => !str || str === ''

export const areNotEmptyStrings = (...strs: string[]) => {
  for (let i = 0; i < strs.length; i++) {
    if (!isNotEmptyString(strs[i])) {
      return false
    }
  }

  return true
}

export const hasEmptyString = (...strs: string[]) => {
  for (let i = 0; i < strs.length; i++) {
    if (isEmptyString(strs[i])) {
      return true
    }
  }

  return false
}

export const validLength = (expression: string, length = 8) => expression.length >= length
export const hasNumber = (expression: string) => /\d/.test(expression)
export const hasSpecialCharacter = (s: string) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(s)
export const hasUpperAndLowerCase = (s: string) => /[a-z]/.test(s) && /[A-Z]/.test(s)
export const passwordMatch = (password: string, confirmPassowrd: string) =>
  Boolean(password) && password === confirmPassowrd

export const checkPasswordValidaty = (password: string, confirmPassowrd: string) =>
  validLength(password) &&
  hasNumber(password) &&
  // hasSpecialCharacter(password) &&
  hasUpperAndLowerCase(password) &&
  passwordMatch(password, confirmPassowrd)

export default ValidateFormReturn
