import { SvgIcon } from '@mui/material'

const DownloadIcon = () => (
  <SvgIcon
    sx={{
      fontSize: 18,
      margin: 0,
    }}
  >
    <svg
      fill='currentColor'
      viewBox='0 0 16 16'
      // fill='none'

      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 11H5.17188L6.58594 12.4141C6.9625 12.7906 7.46562 13 8 13C8.53438 13 9.03625 12.792 9.41406 12.4141L10.8281 11H15C15.5522 11 16 11.4478 16 12V15C16 15.5522 15.5522 16 15 16H1C0.447812 16 0 15.5522 0 15V12C0 11.4469 0.446875 11 1 11ZM2.5 14.25C2.9125 14.25 3.25 13.9125 3.25 13.5C3.25 13.0875 2.9125 12.75 2.5 12.75C2.0875 12.75 1.75 13.0875 1.75 13.5C1.75 13.9125 2.0875 14.25 2.5 14.25ZM8.70625 11.7063C8.5125 11.9031 8.25625 12 8 12C7.74375 12 7.48812 11.9023 7.29313 11.707L3.29313 7.70703C2.90281 7.31641 2.90281 6.68359 3.29313 6.29297C3.68375 5.90234 4.31688 5.90234 4.70719 6.29297L7 8.5875V1C7 0.447812 7.44781 0 8 0C8.55312 0 9 0.447812 9 1V8.5875L11.2937 6.29375C11.6841 5.90312 12.3172 5.90312 12.7078 6.29375C13.0981 6.68437 13.0981 7.31719 12.7078 7.70781L8.70625 11.7063Z'
        // fill='inherit'
      />
    </svg>
  </SvgIcon>
)

export default DownloadIcon
