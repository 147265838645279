import { Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { downloadDrivers } from '@api/Rest/download/driversDownloadXLSX'
import SearchBox from '@components/common/SearchBox'
import { GenericTable } from '@components/common/table'
import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type WhereFilter } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import type Driver from '@types_def/models/driver.types'
import { driverTableRoutes } from '@utils/constants/DriverTable/driverTableRoutes'
import { getDriverTableRouteHeadCells } from '@utils/functions/Delivery/DriverTable/driverTableAttributes'
import { t } from 'i18next'
type Params = {
  tableRoute: keyof typeof driverTableRoutes
}

type TableState = {
  filter?: WhereFilter<Driver>
  headCells: Array<HeadCell<Driver>>
}

const getFilterFromParams = (params: keyof typeof driverTableRoutes): WhereFilter<Driver> => {
  switch (params) {
    case 'accepted-requests':
      return {
        confirmedByAlb: true,
        disabled: false,
        rejectedByAlb: false,
      }
    case 'refused-requests':
      return {
        rejectedByAlb: true,
        confirmedByAlb: false,
        disabled: false,
      }
    case 'waiting-requests':
      return {
        confirmedByAlb: false,
        rejectedByAlb: false,
        disabled: false,
      }
    case 'disabled-drivers':
      return {
        disabled: true,
        confirmedByAlb: true,
      }
  }
}
const DriversTable = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get('email')

  const { tableRoute: registrationRoute } = useParams<Params>()
  const tableRoute = driverTableRoutes[registrationRoute ?? 'waiting-requests']

  const [tableState, setTableState] = useState<TableState>({
    filter: {},
    headCells: [],
  })
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...getFilterFromParams(registrationRoute),
      },
      headCells: getDriverTableRouteHeadCells(tableRoute.key),
    }))
  }, [registrationRoute])

  // Const filters = [dateLabel, fromDateFilter, toDateFilter];

  const DriverTableQueryFields: QueryType<Driver> = {
    id: true,
    publicId: true,
    fullName: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
    drivingPermitNumber: true,
    totalRating: true,
    topDeals: true,
    address: {
      city: true,
      postalCode: true,
    },
    createdAt: true,
    nbDeals: true,
    disabled: true,
  }

  useEffect(() => {
    if (email) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          email: { contains: email },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          email: undefined,
        },
      }))
    }
  }, [email])

  return (
    <>
      <Container
        sx={{
          p: 2,
        }}
      >
        {/* <InputSearch setTableState={setTableState} /> */}
        <SearchBox placeholder={t('driver.search-for-driver')} searchKey={'email'} />
      </Container>
      {tableState.headCells.length > 0 && (
        <>
          <GenericTable<Driver>
            resource={`Drivers${tableRoute?.title}`}
            graphqlQueryFieldsObject={DriverTableQueryFields}
            headCells={tableState.headCells}
            filter={tableState.filter}
            exportHandler={() => {
              downloadDrivers(tableState)
            }}
            isReadOnly={true}
          />
        </>
      )}
    </>
  )
}
export default DriversTable
