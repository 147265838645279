import {
  Grid,
  Typography,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  TableRow,
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Stack } from '@mui/material'
import { copyText } from '@utils/copyText'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

type TDeliveryCode = {
  startCode: string
  destinationCode: string
}

const DeliveryCode = ({ startCode, destinationCode }: TDeliveryCode) => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Typography variant='h5' textTransform={'capitalize'}>
          {t('common.shared.mission-codes')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item xs={12}>
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow>
              <TableCell align={'center'} sx={{ textTransform: 'capitalize' }}>
                {t('common.shared.starting-code')}
              </TableCell>
              <TableCell align={'center'} sx={{ textTransform: 'capitalize' }}>
                {t('common.shared.receiption-code')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align={'center'}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography variant='h5' fontWeight={'bold'}>
                    {startCode}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      copyText(startCode)
                      toast.success('Code copié', {
                        position: 'bottom-right',
                      })
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Stack>
              </TableCell>
              <TableCell align={'center'}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography variant='h5' fontWeight={'bold'}>
                    {destinationCode}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      copyText(destinationCode)
                      toast.success('Code copié', {
                        position: 'bottom-right',
                      })
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default DeliveryCode
