import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'

import { AdminDeliveryStats } from '@types_def/models/delivery.types'
import { RoutePaths } from '@utils/constants/routes'

type Props = {
  data: Array<{ name: string; amount: number }>
  type: AdminDeliveryStats
}

const DeliveryAdminChart = ({ data, type }: Props) => {
  const height = type === AdminDeliveryStats.ByType ? '78%' : '95%'
  // Const dataKey = type === AdminDeliveryStats.ByType ? "percentage" : "amount";

  let highest = 0
  data.forEach((d) => {
    highest = highest > d.amount ? highest : d.amount
  })

  const formatLabel = (amount: number) => {
    if (amount === data[data.length - 1].amount) {
      return `${amount}€`
    }

    return `${amount}%`
  }

  const handleCellClick = (data: { name: string; amount: number }) => {
    switch (data.name) {
      case 'Nouvelles':
        window.location.href = RoutePaths.adminPilotDeliveryTableRequested
        break
      case 'En attente':
        window.location.href = RoutePaths.adminPilotDeliveryTableWaitingForDriver
        break
      case 'Attribué':
        window.location.href = RoutePaths.adminPilotDeliveryTableSet
        break
      case 'Confirmer':
        window.location.href = RoutePaths.adminPilotDeliveryTableConfirmed
        break
      case 'En cours':
        window.location.href = RoutePaths.adminPilotDeliveryTableOngoing
        break
      case 'Livre':
        window.location.href = RoutePaths.adminPilotDeliveryTableDelivered
        break
      case 'Alert':
        window.location.href = `${RoutePaths.adminPilotDeliveryTable}/alert`
        break
      default:
        break
    }
  }

  return (
    <ResponsiveContainer width='100%' height={height}>
      <BarChart data={data} margin={{ top: 30, right: 20, bottom: 5, left: -15 }} barSize={18}>
        <CartesianGrid x={60} vertical={false} horizontal={false} />
        <Tooltip
          cursor={{ fill: 'none' }}
          contentStyle={{ background: 'var(--item-color)' }}
          formatter={type === AdminDeliveryStats.ByType ? formatLabel : undefined}
        />
        <Bar dataKey='amount' fill='#9BB2CE'>
          {data.map((_, index) => (
            <Cell
              key={data[index].name}
              fill={
                data[index].name === 'Marge moyenne'
                  ? '#77D970'
                  : data[index].amount === highest
                    ? '#1C6DD0'
                    : '#9BB2CE'
              }
              onClick={() => {
                handleCellClick(data[index])
              }}
            />
          ))}

          <LabelList
            fill='black'
            formatter={type === AdminDeliveryStats.ByType ? formatLabel : undefined}
            dataKey='amount'
            position='top'
            style={{
              fontSize: 'var(--small-font-size)',
              fontWeight: 'bold',
            }}
          ></LabelList>

          {''}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DeliveryAdminChart
