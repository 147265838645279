import ListIcon from '@components/common/icons/list.icon'
import PersonIcon from '@components/common/icons/person.icon'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'

import LinksSubHeader from '..'
import { useTranslation } from 'react-i18next'

const ClientSubHeader = () => {
  const { t } = useTranslation()

  return (
    <LinksSubHeader>
      <NavigationButton to={RoutePaths.adminNewOrganization} includes={RoutePaths.newOrganization}>
        <PersonIcon />
        {t('layouts.sub-headers.organization.create-organization')}
      </NavigationButton>
      <NavigationButton
        to={RoutePaths.adminOrganizationsTable}
        includes={RoutePaths.organizationsTable}
      >
        <ListIcon />
        {t('layouts.sub-headers.organization.organization-list')}
      </NavigationButton>
    </LinksSubHeader>
  )
}

export default ClientSubHeader
