import { Vehicle } from './vehicle.types'

// ? add company type and link it here
export type CognitoUser = {
  sub: string
  'custom:role': UserRole
  'custom:rank': UserRank
  'cognito:username': string
  email: string
  email_verified: boolean
  iss: string
  origin_jti: string
  aud: string
  event_id: string
  token_use: string
  auth_time: number
  exp: number
  iat: number
  jti: string
  firstname: string
  lastname: string
  phone: string
  is_organization_owner: boolean
  organizationId: string
  settings: {
    one_date: boolean
    no_docs: boolean
    defaultValues: {
      car: Vehicle
      documents: any[]
    }
  }
}
export type Client = {
  id: number
  role: UserRole
  rank: UserRank
  email: string
  confirmed: boolean
  firstname: string
  lastname: string
  siret?: string //* This represents company id (specific to france)
  phone?: string
  company?: string
  facebook?: string
  instagram?: string
  twitter?: string
  subscribedToNewsletter: boolean
} & Partial<CognitoUser>
// Export interface Driver extends CognitoUser {
//   demandedPrice: number;
//   outfitRating: number;
//   takeOffRating: number;
//   qualityRating: number;
//   communicationRating: number;
// }
export enum UserRole {
  CLIENT = 'CLIENT',
  DRIVER = 'DRIVER',
  ADMIN = 'ADMIN',
}

export enum UserRank {
  SUPER_ADMIN = 1,
  MAINTAINER = 2,
  SUPPORT = 3,
  ORGANIZATION_OWNER = 4,
  ORGANIZATION_MANAGER = 5,
  ORGANIZATION_MEMBER = 6,
}
export type UserContextData = {
  connected: boolean
} & Partial<CognitoUser>
