import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, IconButton, Button } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import getUserInfo from '@api/auth/getUserInfo'
import { confirmAccount, login } from '@api/auth/login'
import loginWithNewPassword from '@api/auth/setNewPassword'
import FlagIcon from '@components/common/icons/flag.icon'
import HomeIcon from '@components/common/icons/home.icon'
import { useUserContext } from '@contexts/UserContext/index.context'
import useLocation from '@hooks/location.hook'
import { UserRole } from '@types_def/models/user.type'
import * as Sentry from '@sentry/react'
import { ForgotPassword } from '../ForgotPassword'
import { SetNewPassword } from '../SetNewPassword'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '@components/common/modal'
import { LoginSchema, TLoginSchema } from '@schemas/Auth'
import { HttpError } from '@types_def/common/error.types'

const initialState = {
  needConfirm: false,
  showPassword: false,
  errorMessage: '',
  modalOpen: {
    forgotPassword: false,
    newPassword: false,
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NEED_CONFIRM':
      return { ...state, needConfirm: action.payload }
    case 'TOGGLE_SHOW_PASSWORD':
      return { ...state, showPassword: !state.showPassword }
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        modalOpen: {
          ...state.modalOpen,
          [action.payload]: !state.modalOpen[action.payload],
        },
      }
    default:
      return state
  }
}

const Login = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { handleSetUser, userState } = useUserContext()
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
      code: '',
      session: '',
    },
  })

  const username = useWatch({
    control,
    name: 'username',
  })

  const session = useWatch({
    control,
    name: 'session',
  })

  useEffect(() => {
    if (userState && userState['custom:role']) {
      handleRedirect(userState['custom:role'])
    }
  }, [userState])

  const handleRedirect = (userRole: UserRole) => {
    const pathName = location?.from?.pathname || `/${userRole.toLowerCase()}`
    navigate(pathName)
  }

  const setUserInfo = async () => {
    const { success, data } = await getUserInfo()
    if (success && data) {
      handleSetUser({ ...data, connected: true })
      Sentry.setUser({
        id: data.sub,
        role: data['custom:role'],
      })
    }
  }

  const onSubmit = async (data: TLoginSchema) => {
    try {
      const { username, password, session, code } = data
      const res: any = state.modalOpen.newPassword
        ? await loginWithNewPassword({
            username: username.replaceAll(' ', ''),
            newPassword: password,
            session,
          })
        : state.needConfirm
          ? await confirmAccount({ username, password, code })
          : await login({ username, password })

      if (res.success) {
        if (res.result) {
          await setUserInfo()
          return
        }

        if (res.session) {
          setValue('session', res.session)
          dispatch({ type: 'TOGGLE_MODAL', payload: 'newPassword' })
        }

        if (res.action === 'need-confirm') {
          dispatch({ type: 'SET_NEED_CONFIRM', payload: true })
          dispatch({ type: 'SET_ERROR_MESSAGE', payload: "Votre compte n'est pas encore confirmé" })
        } else {
          dispatch({ type: 'SET_ERROR_MESSAGE', payload: '' })
        }
      } else {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Email ou mot de passe incorrect' })
      }
    } catch (error) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: (error as HttpError).message as string })
    }
  }

  const toggleShowPassword = () => dispatch({ type: 'TOGGLE_SHOW_PASSWORD' })

  const handleModal = (modal: 'forgotPassword' | 'newPassword') => {
    dispatch({ type: 'TOGGLE_MODAL', payload: modal })
  }

  return (
    <>
      <div className={styles.loginContainer}>
        <div className={styles.login}>
          <img src='/images/logo.svg' alt='logo' className={styles.logo} />
          <span className={styles.connection}>{t('connection')}</span>
          <p className={styles.credentials_error}>{state.errorMessage}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant='filled'
              sx={{
                color: '#000',
                borderRadius: 2,
                background: '#fff',
              }}
              placeholder={t('login.identifier')}
              {...register('username')}
              error={!!errors.username}
              helperText={errors.username?.message}
              fullWidth
              margin='normal'
            />
            <TextField
              variant='filled'
              sx={{
                background: '#fff',
                color: '#000',
                borderRadius: 2,
              }}
              placeholder={t('common.form.input.password')}
              type={state.showPassword ? 'text' : 'password'}
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              fullWidth
              margin='normal'
              InputProps={{
                endAdornment: (
                  <IconButton onClick={toggleShowPassword} edge='end'>
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            {state.needConfirm && (
              <TextField
                variant='filled'
                sx={{
                  background: '#fff',
                  color: '#000',
                  borderRadius: 2,
                }}
                placeholder={t('common.form.input.confirmation-code')}
                {...register('code')}
                error={!!errors.code}
                helperText={errors.code?.message}
                fullWidth
                margin='normal'
              />
            )}
            <div className={styles.loginButton}>
              <Button color='secondary' variant='contained' type='submit' fullWidth>
                <HomeIcon />
                {t('login.connect')}
                <FlagIcon />
              </Button>
            </div>
          </form>
          <a
            style={{ cursor: 'pointer', textTransform: 'capitalize' }}
            onClick={() => handleModal('forgotPassword')}
          >
            {t('login.forget-password')} ?
          </a>
        </div>
      </div>
      <ModalComponent
        handleClose={() => handleModal('forgotPassword')}
        isOpen={state.modalOpen.forgotPassword}
        showButton={false}
      >
        <ForgotPassword closeModal={() => handleModal('forgotPassword')} />
      </ModalComponent>
      <ModalComponent
        handleClose={() => handleModal('forgotPassword')}
        isOpen={state.modalOpen.newPassword}
        showButton={false}
      >
        <SetNewPassword email={username} session={session} />
      </ModalComponent>
    </>
  )
}

export default Login
