import React from 'react'

export const GenericTableContext = React.createContext({
  key: 0,
  setKey: (key: number) => {},
})

export const DriverContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [key, setKey] = React.useState(0)

  return (
    <GenericTableContext.Provider value={{ key, setKey }}>{children}</GenericTableContext.Provider>
  )
}

export const useGenericTableContext = () => React.useContext(GenericTableContext)
