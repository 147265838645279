import { useQuery } from '@tanstack/react-query'
import { getOrganizationMembers } from '../Querys'

const useGetOrganizationMembers = (data: {slug?: string, page: number, take: number}, role: 'members' | 'managers') => {
  return useQuery({
    queryKey: ['organization-members',data, role],
    queryFn: async () => await getOrganizationMembers(data, role),
  })
}

export { useGetOrganizationMembers }
