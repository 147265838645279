import React, { createContext, useContext, } from 'react';

type TReactQueryKeyContext = {
    queryKey: any
}
const ReactQueryKeyContext = createContext<TReactQueryKeyContext>({
    queryKey: undefined,
});

export const ReactQueryKeyContextProvider = ({ children, queryKey } : { children: React.ReactNode, queryKey: any }) => {
  return (
    <ReactQueryKeyContext.Provider value={{ queryKey }}>
      {children}
    </ReactQueryKeyContext.Provider>
  );
};

export const useReactQueryKey = () =>  useContext(ReactQueryKeyContext);