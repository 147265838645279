import ChatIcon from '@components/common/icons/chat.icon'
import MailInboxIcon from '@components/common/icons/mail-inbox.icon'
import PhoneIcon from '@components/common/icons/phone.icon'
import { type HelpMenuItemProps, HelpMenuItemType } from '@types_def/components/helpMenuItem'

import styles from './index.module.scss'

const zendDeskUrl = import.meta.env.VITE_APP_ZENDESK_URL

const HelpMenuItem = ({ method, type, contactInfo, responseTime }: HelpMenuItemProps) => {
  const RenderIcon = () => {
    switch (type) {
      case HelpMenuItemType.CHAT:
        return <ChatIcon />
      case HelpMenuItemType.EMAIL:
        return <MailInboxIcon />
      case HelpMenuItemType.PHONE:
        return <PhoneIcon />
      default:
        return null
    }
  }

  const contactUrl = () => {
    switch (type) {
      case HelpMenuItemType.CHAT:
        return zendDeskUrl
      case HelpMenuItemType.EMAIL:
        return `mailto:${contactInfo}`
      case HelpMenuItemType.PHONE:
        return `tel:${contactInfo}`
      default:
        return ''
    }
  }

  return (
    <a
      href={contactUrl()}
      target='_blank'
      className={`${styles['contact-card']} item`}
      rel='noreferrer'
    >
      <div className={`${styles['contact-method']}`}>{method}</div>
      <div className={`${styles['contact-info']}`}>
        <RenderIcon />
        {contactInfo}
      </div>
      {responseTime}
    </a>
  )
}

export default HelpMenuItem
