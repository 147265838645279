// AdminForm.js
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import Button from '@mui/material/Button'
import Admin from '@types_def/models/admin.types'
import { Grid } from '@mui/material'
import AlbInput from '@components/common/AlbInput'
import { getDirtyFields } from '@utils/ReactFormHook'

const schema = z.object({
  firstName: z.string().min(2).max(50),
  lastName: z.string().min(2).max(50),
  email: z.string().email(),
  rank: z.number().min(1).max(3),
})

type TAdminForm = {
  admin: Admin
  onSubmit: (data: Admin) => void
  handleClose: () => void
  disaled?: boolean
}

const rankOptions = [
  { value: 1, name: 'Super Administrateur' },
  { value: 2, name: 'Mainteneur' },
  { value: 3, name: 'Support' },
]

const AdminForm = ({ admin, onSubmit, handleClose, disaled = false }) => {
  const methods = useForm({
    resolver: zodResolver(schema),
    values: admin,
  })

  const handleFormSubmit = (data: Admin) => {
    const values: Admin = getDirtyFields(methods.formState.dirtyFields, methods.getValues())
    onSubmit({ ...values, id: admin.id })
  }

  return (
    <FormProvider {...methods}>
      <Grid
        container
        component={'form'}
        spacing={3}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
      >
        <Grid item xs={6}>
          <AlbInput variant='outlined' type='TextField' label='First Name' name={'firstName'} />
        </Grid>
        <Grid item xs={6}>
          <AlbInput variant='outlined' type='TextField' label='Last Name' name={'lastName'} />
        </Grid>
        <Grid item xs={6}>
          <AlbInput variant='outlined' type='TextField' label='Email' name={'email'} />
        </Grid>
        <Grid item xs={6}>
          <AlbInput
            variant='outlined'
            type='SelectField'
            label='Rank'
            name={'rank'}
            options={rankOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => {
              methods.reset()
              handleClose()
            }}
            fullWidth
            variant='outlined'
            color='warning'
            disabled={disaled}
          >
            Annuler
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button disabled={disaled} fullWidth variant='contained' color='success' type='submit'>
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default AdminForm
