import { gql, graphqlMutation } from '@api/clients/apollo'
import { TCancelDelivery } from '@schemas/DeliveryForm/CancelDeliverySchema'
import { Delivery } from '@types_def/models/delivery.types'

type ApiUpdateResponse<Delivery> = {
  cancelDelivery: Delivery
}

export async function cancelDelivery(input: { id: number; reason: TCancelDelivery }) {
  try {
    const res = await graphqlMutation<ApiUpdateResponse<Delivery>, any>(
      gql`
        mutation cancelDelivery($input: CancelDeliveryInput!) {
          cancelDelivery(CancelDeliveryInput: $input) {
            id
            status
          }
        }
      `,
      { input },
    )
    return res.data?.cancelDelivery
  } catch (error) {
    throw new Error(error as string)
  }
}

export default cancelDelivery
