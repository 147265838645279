import { z } from 'zod'
import { UserRank } from '@types_def/models/user.type'

const errorMessages = {
  required: 'Ce champ est requis',
  invalidEmail: "L'adresse email est invalide",
  invalidPhoneNumber: 'Le numéro de téléphone est invalide',
}

const Address = z.object({
  latitude: z.number(),
  longitude: z.number(),
  city: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  address: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  postalCode: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  place_id: z.nullable(z.string()),
})

const OrganizationFormSchema = z.object({
  name: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  slug: z.string().refine((value) => value.length > 0, {
    message: errorMessages.required,
  }),
  settings: z.object({}).nullish().default({}),
  owner: z.object({
    id: z.number().optional().nullish(),
    /*     contactFirstName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    contactLastName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    contactPhone: z.string(),
    contactEmail: z.string().refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: errorMessages.invalidEmail,
    }),
    contactFunction: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    contactService: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
 */
    assigneeFirstName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    assigneeLastName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    assigneePhone: z.string(),
    assigneeEmail: z.string().refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: errorMessages.invalidEmail,
    }),
    assigneeFunction: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    assigneeService: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    assigneeRank: z
      .number({
        required_error: errorMessages.required,
      })
      .default(UserRank.ORGANIZATION_OWNER),

    address: Address,

    /*     billingClerkFirstName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    billingClerkLastName: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    billingClerkPhone: z.string(),
    billingClerkEmail: z.string().refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: errorMessages.invalidEmail,
    }),
    billingClerkFunction: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    billingClerkService: z.string().refine((value) => value.length > 0, {
      message: errorMessages.required,
    }),
    billingClerkAddress: Address,
    address: Address, */
  }),
})

export { OrganizationFormSchema }
