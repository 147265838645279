import {
  ButtonGroup,
  Container,
  DialogContentText,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomModal from '@components/common/CustomModal'
import { deleteMember, updateMember } from '@components/organization/Querys'
import frenchDate from '@utils/functions/customDates'
import { useGetOrganizationMembers } from '../hooks/useGetMembers'
import { useUserContext } from '@contexts/UserContext/index.context'
import { UserRank, UserRole } from '@types_def/models/user.type'
import {
  MemberFormSchema,
  MemberFormSchemaDefaultValues,
  TMemberFormSchema,
} from '@schemas/OrganizationForm/member.schema'
import MemberForm from '../Member/Form'
import { NavigationButton } from '@components/common/NavigationLink'
import { RoutePaths } from '@utils/constants/routes'

const rankToStr = (rank: UserRank) => {
  switch (rank) {
    case UserRank.ORGANIZATION_MANAGER:
      return 'managers'
    case UserRank.ORGANIZATION_MEMBER:
      return 'members'
    default:
      return undefined
  }
}

type TOrganizationMembersTable = {
  memberRank: UserRank
}

const OrganizationMembersTable = ({ memberRank }: TOrganizationMembersTable) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { userState } = useUserContext()
  const userRank = userState['custom:rank']
  const unUsedFields: Array<keyof typeof MemberFormSchema.shape> = []
  const values = MemberFormSchemaDefaultValues
  if (userRank == UserRank.ORGANIZATION_MANAGER) {
    unUsedFields.push('assigneeRank')
    values.assigneeRank = UserRank.ORGANIZATION_MEMBER
  } else if (userRank === UserRank.ORGANIZATION_OWNER) {
    values.assigneeRank = UserRank.ORGANIZATION_MANAGER
  }
  const isAdmin = userState['custom:role'] === UserRole.ADMIN
  const { slug, organizationName } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || 0
  const take = Number(searchParams.get('take')) || 10
  const [paginations, setPaginations] = useState({
    page,
    take,
  })
  useEffect(() => {
    setPaginations({ page, take })
  }, [take, page])
  const membersRanks = rankToStr(memberRank)
  const { data, isLoading } = isAdmin
    ? useGetOrganizationMembers({ page, take, slug }, membersRanks)
    : useGetOrganizationMembers({ page, take }, membersRanks)

  const handlePageChange = (event: unknown, page: number) => {
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
  }

  const onUpdate = async (data: TMemberFormSchema) => {
    updateMember(data)
      .then(() => {
        toast.success(t('network.organization.member.updated'), {
          position: 'bottom-right',
        })
        if (isAdmin)
          queryClient.invalidateQueries({
            queryKey: ['organization-members', { page, take, slug }, membersRanks],
          })
        else
          queryClient.invalidateQueries({
            queryKey: ['organization-members', { page, take }, membersRanks],
          })
      })
      .catch((error) => {
        if (error?.response?.status === 403)
          toast.error(t('network.errors.403'), {
            position: 'bottom-right',
          })
        else
          toast.error(error?.response?.data?.message ?? t('network.errors.unknown'), {
            position: 'bottom-right',
          })
      })
  }

  if (isLoading) return <>'loading...'</>

  return (
    <>
      <Container maxWidth='xl'>
        {isAdmin ? (
          <>
            <Stack direction={'row'} gap={2} p={2} alignItems={'center'}>
              <Typography paddingRight={20} variant='h5' textAlign={'center'}>
                {organizationName}{' '}
              </Typography>
              <NavigationButton
                extraStyle={{
                  width: '200px',
                }}
                to={`${RoutePaths.adminOrganizationsTable}/view-members/${organizationName}/${slug}`}
                includes={'view-members'}
              >
                Members
              </NavigationButton>
              <NavigationButton
                extraStyle={{
                  width: '200px',
                }}
                to={`${RoutePaths.adminOrganizationsTable}/view-managers/${organizationName}/${slug}`}
                includes={'view-managers'}
              >
                Managers
              </NavigationButton>
            </Stack>
          </>
        ) : null}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.form.input.name')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.form.input.email')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.form.input.phone')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.form.input.function')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.form.input.service')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.table.headers.Créé à')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.table.headers.Modifié à')}
                </TableCell>
                <TableCell align='left' sx={{ textTransform: 'uppercase' }}>
                  {t('common.table.headers.Action')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.records?.map((member) => (
                <TableRow
                  key={member.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='left'>
                    {member.assigneeFirstName + ' ' + member.assigneeLastName}
                  </TableCell>
                  <TableCell align='left'>{member.assigneeEmail}</TableCell>
                  <TableCell align='left'>{member.assigneePhone}</TableCell>
                  <TableCell align='left'>{member.assigneeFunction}</TableCell>
                  <TableCell align='left'>{member.assigneeService}</TableCell>
                  <TableCell align='left'>{frenchDate(member.createdAt)}</TableCell>
                  <TableCell align='left'>{frenchDate(member.updatedAt)}</TableCell>
                  <TableCell align='left'>
                    <ButtonGroup orientation='vertical' sx={{ gap: 1 }}>
                      <CustomModal
                        fullWidth={true}
                        button={t('common.actions.update')}
                        noAction={true}
                        title={t('organization.actions.edit-member.title')}
                        color='info'
                      >
                        <DialogContentText id='alert-dialog-description' component={'div'}>
                          <Paper
                            component={'div'}
                            elevation={4}
                            sx={{
                              backgroundColor: '#E7E6F7',
                              borderRadius: 1,
                              padding: '20px',
                              height: '100%',
                            }}
                          >
                            <MemberForm
                              label={t('common.actions.update')}
                              values={member}
                              unUsedFields={unUsedFields}
                              disabledFields={['assigneeEmail']}
                              title='user-info'
                              onSubmit={onUpdate}
                            />
                          </Paper>
                        </DialogContentText>
                      </CustomModal>
                      <CustomModal
                        fullWidth={true}
                        button={t('organization.actions.delete.text')}
                        color='error'
                        cancelText={t('common.actions.cancel')}
                        confirmText={t('organization.actions.delete.text')}
                        confirmAction={async () => {
                          await deleteMember({ id: member.id })
                            .then((res) =>
                              toast.success(t('network.organization.member.deleted'), {
                                position: 'bottom-right',
                              }),
                            )
                            .catch((err) =>
                              err.response.status === 403
                                ? toast.error(t('network.errors.403'), { position: 'bottom-right' })
                                : toast.error(t('network.errors.unknown'), {
                                    position: 'bottom-right',
                                  }),
                            )
                          queryClient.invalidateQueries({ queryKey: ['all-organization-members'] })
                        }}
                        title={t('organization.actions.delete.title')}
                      >
                        <DialogContentText id='alert-dialog-description'>
                          {t('organization.actions.delete.description')}{' '}
                          <strong>
                            {member.assigneeFirstName + ' ' + member.assigneeLastName} ?
                          </strong>
                        </DialogContentText>
                      </CustomModal>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={data.total}
            rowsPerPage={10}
            page={paginations.page}
            onPageChange={handlePageChange}
          />
        </TableContainer>
      </Container>
    </>
  )
}

export default OrganizationMembersTable
