import { type DeliveryStatus } from '@types_def/models/delivery.types'
import {
  type DeliveryStatusAttributes,
  deliveryStatusAttributes,
} from '@utils/constants/DeliveryStatus/deliveryStatusAttributes'

export const getDeliveryAttributesByStatus = (deliveryStatus: DeliveryStatus) =>
  deliveryStatusAttributes.find((c) => c.key === deliveryStatus) ?? deliveryStatusAttributes[0]

export const getDeliveryAttributeByStatus = (
  deliveryStatus: DeliveryStatus,
  deliveryStatusAttribute: keyof DeliveryStatusAttributes,
) => getDeliveryAttributesByStatus(deliveryStatus)[deliveryStatusAttribute]
