import { object, z } from 'zod'
import { DeliveryType } from '@types_def/models/delivery.types'
import Countries from '@utils/constants/county'
import { extraOptions } from '@utils/constants/deliveryOptions'

const DeliveryFilterSchema = z.object({
  deliveryCountries: z
    .object(
      Object.values(Countries).reduce((acc, country) => {
        acc[country.code] = z.boolean().default(false)
        return acc
      }, {}),
    )
    .nullish(),
  deliveryType: z
    .object(
      Object.values(DeliveryType).reduce((acc, type) => {
        acc[type] = z.boolean().default(false)
        return acc
      }, {}),
    )
    .nullish(),
  deliveryServices: z
    .object(
      Object.values(extraOptions).reduce((acc, option) => {
        acc[option.id] = z.boolean().default(false)
        return acc
      }, {}),
    )
    .nullish(),
  organizationSlug: z.string().nullish().default(null),
  carDeliveryDeadlineFrom: z.coerce.date().nullish().default(null),
  carDeliveryDeadlineTo: z.coerce.date().nullish().default(null),
})

export default DeliveryFilterSchema
export type DeliveryFilterSchemaType = z.infer<typeof DeliveryFilterSchema>
export const DeliveryFilterDefaultValues = {
  deliveryCountries: Object.values(Countries).reduce((acc, country) => {
    acc[country.code] = false
    return acc
  }, {}),
  deliveryType: Object.values(DeliveryType).reduce((acc, type) => {
    acc[type] = false
    return acc
  }, {}),
  deliveryServices: extraOptions.reduce((acc, option) => {
    acc[option.id] = false
    return acc
  }, {}),
  organizationSlug: null,
  carDeliveryDeadlineFrom: null,
  carDeliveryDeadlineTo: null,
}
