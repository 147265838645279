import { type GetAutoCompleteListInput } from '@types_def/common/filter.types'

import { gql, graphqlQuery } from '../../clients/apollo'

type ApiAutoCompleteListResponse<T> = {
  findAutoCompleteList: T[]
}

export async function getAutoCompleteList<T>(
  getAutoCompleteListInput: GetAutoCompleteListInput<T, keyof T>,
  resource: string,
  fields: Array<keyof T>,
  customEndpoint?: string,
) {
  const graphqlField = fields.join(' ')
  const res = await graphqlQuery<
    ApiAutoCompleteListResponse<T>,
    { getAutoCompleteListInput: GetAutoCompleteListInput<T, keyof T> }
  >(
    gql`
      query ${
        customEndpoint ?? `findAutoCompleteList${resource}`
      }($getAutoCompleteListInput: GetAutoCompleteListInput!) {
      findAutoCompleteList:  ${
        customEndpoint ?? `findAutoCompleteList${resource}`
      }(GetAutoCompleteListInput: $getAutoCompleteListInput) {
          ${graphqlField}
        }
      }
    `,
    {
      getAutoCompleteListInput,
    },
  )
  if (res?.data) {
    return {
      success: true,
      message: 'Ok',
      data: res.data.findAutoCompleteList,
    }
  }

  return { success: false, message: 'Request failed' }
}
