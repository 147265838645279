import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import { type Delivery } from '@types_def/models/delivery.types'

import {
  ContractCell,
  AlertAdminActionCell,
  DateCell,
  DistanceCell,
  OrganizationCell,
  PilotActionCell,
  PriceCell,
  RouteCell,
  StatusCell,
} from '../CustomCells'

const alertHeadCells: Array<HeadCell<Delivery>> = [
  {
    id: 'publicId',
    label: 'Contrat',
    customCellComponent: ContractCell,
    passWholeObject: true,
    isNotSortable: false,
  },
  {
    id: 'id',
    label: 'Trajet',
    customCellComponent: RouteCell,
    passWholeObject: true,
    isNotSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Créé à',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
]

const clientPilotCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  customCellComponent: PilotActionCell,
  passWholeObject: true,
  isNotSortable: true,
}

const adminPilotCell: HeadCell<Delivery> = {
  id: 'id',
  label: 'Action',
  customCellComponent: AlertAdminActionCell,
  isNotSortable: true,
  passWholeObject: true,
}

const extraCells: Array<HeadCell<Delivery>> = [
  {
    id: 'distance',
    label: 'Distance totale',
    customCellComponent: DistanceCell,
    isNotSortable: false,
  },
  {
    id: 'clientFinalPrice',
    label: 'Montant Client',
    customCellComponent: PriceCell,
    isNotSortable: false,
  },
]

export const clientAlertHeadCells: Array<HeadCell<Delivery>> = [
  ...alertHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'status',
    label: 'Statut',
    customCellComponent: StatusCell,
    isNotSortable: false,
    // PassWholeObject: true,
  },
  ...extraCells,
  clientPilotCell,
]
export const adminAlertHeadCells: Array<HeadCell<Delivery>> = [
  ...alertHeadCells,
  // {
  //   id: "clientEstimatedDeliveryDate",
  //   label: "Date de livraison estimée du client",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  // {
  //   id: "adminEstimatedDeliveryDate",
  //   label: "Votre date de livraison estimée",
  //   customCellComponent: DateCell,
  //   isNotSortable: true,
  // },
  {
    id: 'carDeliveryDeadline',
    label: 'Date de livraison',
    customCellComponent: DateCell,
    isNotSortable: false,
  },
  {
    id: 'organization',
    label: 'Organisation',
    isNotSortable: false,
    customCellComponent: OrganizationCell,
  },
  ...extraCells,
  adminPilotCell,
]
