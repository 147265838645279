import { TDeliveriesCount } from '@hooks/useDeliveriesCount'
import { DeliveryStatus } from '@types_def/models/delivery.types'

const requested = [
  DeliveryStatus.REQUESTED,
  DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
  DeliveryStatus.CONFIRMED_BY_CUSTOMER,
  DeliveryStatus.CANCELLED_BY_CUSTOMER,
  DeliveryStatus.RESCHEDULED_BY_ADMIN,
]

const confirmed = [DeliveryStatus.CONFIRMED]

const set = [DeliveryStatus.SET]

const waiting_for_driver = [DeliveryStatus.WAITING_FOR_DRIVER]
export const getDeliveriesCountKeyByStatus = (status: DeliveryStatus): keyof TDeliveriesCount => {
  if (requested.includes(status)) return 'requestedCount'
  else if (confirmed.includes(status)) return 'confirmedCount'
  else if (set.includes(status)) return 'setCount'
  else if (waiting_for_driver.includes(status)) return 'waitingDriverCount'
}
