import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip } from 'recharts'

import { type ClientDashboardMonthDeliveryData } from '@types_def/models/delivery.types'

import CustomChartTooltip from './Tooltip'

type Props = {
  data: Array<ClientDashboardMonthDeliveryData & { name: string }>
}

const DeliveredVehiclesChart = ({ data }: Props) => {
  return (
    <ResponsiveContainer width='100%' height='87.75%'>
      <BarChart
        data={data.map((c) => ({
          name: c.name,
          totalPrice: c.totalPrice == 0 ? 0 : c.totalPrice.toFixed(2),
          totalDistance: c.totalDistance.toFixed(2),
          totalDeliveries: c.totalDeliveries,
          averagePrice: c.averagePrice.toFixed(2),
          averageDistance: c.averageDistance.toFixed(2),
        }))}
        margin={{ top: 30, right: 20, bottom: 5, left: -15 }}
        barSize={14}
      >
        <CartesianGrid x={45} vertical={false} />
        <Tooltip
          // @ts-expect-error
          content={<CustomChartTooltip />}
        />
        <Bar dataKey='totalPrice' fill='#9BB2CE'>
          <LabelList
            dataKey='totalPrice'
            position='top'
            formatter={(amount: number) => `${Math.round(amount)}€`}
            style={{
              fontSize: 'var(--small-font-size)',
              fontWeight: 'bold',
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DeliveredVehiclesChart
