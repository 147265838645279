const ExchangeArrowsIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15 5.5H3.4125L5.20562 7.29313C5.59625 7.68375 5.59625 8.31656 5.20562 8.70719C5.0125 8.90312 4.75625 9 4.5 9C4.24375 9 3.98812 8.90234 3.79313 8.70703L0.293125 5.20703C-0.0974998 4.81641 -0.0974998 4.18359 0.293125 3.79297L3.79313 0.292969C4.18375 -0.0976562 4.81656 -0.0976562 5.20719 0.292969C5.59781 0.683594 5.59781 1.31641 5.20719 1.70703L3.4125 3.5H15C15.5528 3.5 16 3.94719 16 4.5C16 5.05281 15.5528 5.5 15 5.5ZM1 10.5H12.5844L10.7913 8.70687C10.4006 8.31625 10.4006 7.68344 10.7913 7.29281C11.1819 6.90219 11.8147 6.90219 12.2053 7.29281L15.7053 10.7928C16.0959 11.1834 16.0959 11.8162 15.7053 12.2069L12.2053 15.7069C12.0125 15.9031 11.7562 16 11.5 16C11.2438 16 10.9881 15.9023 10.7931 15.707C10.4025 15.3164 10.4025 14.6836 10.7931 14.293L12.5844 12.5H1C0.447187 12.5 0 12.0528 0 11.5C0 10.9472 0.446875 10.5 1 10.5Z'
      fill='#fff'
    />
  </svg>
)

export default ExchangeArrowsIcon
