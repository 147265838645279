import { useEffect, useState } from 'react'

import { getDeliveryStats } from '@api/graphql/delivery/getDeliveryState'
import CarIcon from '@components/common/icons/car.icon'
import GarageIcon from '@components/common/icons/garage.icon'
import HourglassIcon from '@components/common/icons/hourglass.icon'
import RoadIcon from '@components/common/icons/road.icon'
import StarIcon from '@components/common/icons/star.icon'
import { useUserContext } from '@contexts/UserContext/index.context'
import { useCheckIfMounted } from '@hooks/mountingRef'
import {
  type ClientDashboardMonthDeliveryData,
  DeliveryProgress,
  DeliveryStatus,
} from '@types_def/models/delivery.types'
import { RoutePaths } from '@utils/constants/routes'

import DashboardDataBox from '../DashboardDataBox'
import DashboardDataGrid from '../DashboardDataGrid'
import DeliveredVehiclesChart from '../DeliveredVehiclesChart'
import DeliveryTypePieChart from '../DeliveryTypePieChart'
import DriverNotationRadarChart from '../DriverNotationRadarChart'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Chart = ClientDashboardMonthDeliveryData & {
  name: string
  totalPrice: number
  totalDistance: number
  totalDeliveries: number
  averageDistance: number
  averagePrice: number
}

const emptyChartData: ClientDashboardMonthDeliveryData = {
  averageDistance: 0,
  averagePrice: 0,
  totalDeliveries: 0,
  totalPrice: 0,
  totalDistance: 0,
}

export const ClientDashboard = () => {
  const { t } = useTranslation()
  const chart: Chart[] = [
    { name: t('common.months.Jan'), ...emptyChartData },
    { name: t('common.months.Fév'), ...emptyChartData },
    { name: t('common.months.Mar'), ...emptyChartData },
    { name: t('common.months.Avr'), ...emptyChartData },
    { name: t('common.months.Mai'), ...emptyChartData },
    { name: t('common.months.Jun'), ...emptyChartData },
    { name: t('common.months.Jul'), ...emptyChartData },
    { name: t('common.months.Aoû'), ...emptyChartData },
    { name: t('common.months.Sep'), ...emptyChartData },
    { name: t('common.months.Oct'), ...emptyChartData },
    { name: t('common.months.Nov'), ...emptyChartData },
    { name: t('common.months.Déc'), ...emptyChartData },
  ]

  const { userState } = useUserContext()
  const [chartData, setChartData] = useState<Chart[]>()
  const [totalDeliveries, setTotalDeliveries] = useState(0)
  const isMountedRef = useCheckIfMounted()

  const handleGetDeliveryState = async (id: string) => {
    const data = await getDeliveryStats(id)
    if (data && isMountedRef) {
      data.result.forEach((record) => {
        const index = record.month
        chart[index].totalPrice = record.totalPrice
        chart[index].totalDistance = record.totalDistance
        chart[index].totalDeliveries = record.totalDeliveries
        chart[index].averageDistance = record.totalDistance / record.totalDeliveries
        chart[index].averagePrice = record.totalPrice / record.totalDeliveries
      })
      setChartData(chart)
      setTotalDeliveries(data.total)
    }
  }

  useEffect(() => {
    const id = userState?.organizationId
    handleGetDeliveryState(id!)
  }, [])
  return (
    <>
      <div className={`${styles.dashboard}`}>
        <>
          <DashboardDataBox
            title={`${t('dashboard.deliveries-completed')}: ${totalDeliveries}`}
            type={DeliveryProgress.DELIVERED}
            link={`${RoutePaths.clientPilotDeliveryTable}${RoutePaths.delivered}`}
            linkText={t('dashboard.see-details')}
          >
            {chartData && <DeliveredVehiclesChart data={chartData} />}
          </DashboardDataBox>
          <>
            <DashboardDataGrid
              title={t('dashboard.deliveries-in-progress')}
              type={DeliveryStatus.ONGOING}
              link={`${RoutePaths.clientPilotDeliveryTable}${RoutePaths.ongoing}`}
              linkText={t('dashboard.see-details')}
              icon={<RoadIcon />}
            />
            <DashboardDataGrid
              title={t('dashboard.deliveries-awaiting-for-driver')}
              type={DeliveryStatus.WAITING_FOR_DRIVER}
              link={`${RoutePaths.clientPilotDeliveryTableWaitingForDriver}`}
              linkText={t('dashboard.see-details')}
              icon={<HourglassIcon />}
            />
          </>
        </>
      </div>
      <div className={`${styles.dashboard}`}>
        <DashboardDataBox title={t('dashboard.average-driver-ratings')} icon={<StarIcon />}>
          <DriverNotationRadarChart />
        </DashboardDataBox>
        <DashboardDataBox title={t('dashboard.deliveries-types')} icon={<CarIcon />}>
          <DeliveryTypePieChart deliveryType={true} />
        </DashboardDataBox>
        <DashboardDataBox title={t('dashboard.services-types')} icon={<GarageIcon />}>
          <DeliveryTypePieChart deliveryType={false} />
        </DashboardDataBox>
      </div>
    </>
  )
}
