const HomeIcon = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9945 7.98438C17.9945 8.54688 17.5257 8.9875 16.9945 8.9875H15.9944L16.0163 13.9906C16.0163 14.0781 16.01 14.1594 16.0007 14.2437V14.75C16.0007 15.4406 15.4413 16 14.7506 16H14.2506C14.2162 16 14.1818 15.9719 14.1475 15.9969C14.1037 15.9719 14.06 16 14.0162 16H12.2505C11.5599 16 11.0005 15.4406 11.0005 14.75V12C11.0005 11.4469 10.5536 11 10.0004 11H8.00033C7.44718 11 7.00029 11.4469 7.00029 12V14.75C7.00029 15.4406 6.44089 16 5.75024 16H4.00329C3.95641 16 3.90954 15.9969 3.86266 15.9937C3.82516 15.9969 3.78766 16 3.75016 16H3.25013C2.55979 16 2.00008 15.4406 2.00008 14.75V11.25C2.00008 11.2219 2.00102 11.1906 2.0029 11.1625V8.9875H1.0016C0.438143 8.9875 0 8.54688 0 7.98438C0 7.70312 0.0938789 7.45312 0.312825 7.23438L8.32535 0.2505C8.5441 0.0313125 8.79412 0 9.01287 0C9.23163 0 9.48164 0.062625 9.67228 0.219187L17.6507 7.23438C17.9007 7.45312 18.0289 7.70312 17.9945 7.98438Z'
      fill='#fff'
    />
  </svg>
)

export default HomeIcon
