/* eslint-disable */
import {
  ApolloClient,
  createHttpLink,
  type DefaultOptions,
  type DocumentNode,
  from,
  fromPromise,
  gql,
  InMemoryCache,
  type TypedDocumentNode,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { refreshToken } from './axios'

type PreviousContext = {
  headers: Record<string, string>
}

export const GraphqlHttpLink = createHttpLink({
  uri: import.meta.env.VITE_APP_API_BASE_URL + 'graphql',
})

export const GraphqlDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.message) {
        case 'TOKEN_EXPIRED':
          const tokenRefreshPromise = refreshToken().then(() => {
            // Modify the operation context with a new token
            const accessToken = localStorage.getItem('AccessToken')
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              headers: { ...oldHeaders, authorization: accessToken },
            })
          })
          // Retry the request, returning the new observable
          return fromPromise(tokenRefreshPromise).flatMap(() => forward(operation))
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    }
  }
})

function getAuthLink() {
  return setContext((_, { headers }: PreviousContext) => {
    // Get the authentication token from local storage if it exists
    const accessToken: string = localStorage.getItem('AccessToken') ?? 'no-token'
    // Return the headers to the context so httpLink can read them
    if (!accessToken) {
      return { headers }
    }

    return { headers: { ...headers, authorization: accessToken } }
  })
}

export function initApolloClient() {
  const client = new ApolloClient({
    link: from([errorLink, getAuthLink(), GraphqlHttpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    connectToDevTools: true,
    defaultOptions: GraphqlDefaultOptions,
  })
  return client
}

const apolloClient = initApolloClient()

/* GRAPHQL QUERY */
async function rawGraphqlQuery<T, TVariables>(
  query: DocumentNode | TypedDocumentNode<T, TVariables>,
  variables?: TVariables,
) {
  return apolloClient.query<T, TVariables>({
    query,
    variables,
  })
}

// Query with refreshToken :
async function graphqlQuery<T, TVariables>(
  query: DocumentNode | TypedDocumentNode<T, TVariables>,
  variables?: TVariables,
) {
  try {
    return await rawGraphqlQuery<T, TVariables>(query, variables)
  } catch (error) {
    throw error
  }
}

/* GRAPHQL MUTATION */
async function rawGraphqlMutation<TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
) {
  return apolloClient.mutate({ mutation, variables })
}

// Query with refreshToken :
async function graphqlMutation<TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
) {
  try {
    return await rawGraphqlMutation<TData, TVariables>(mutation, variables)
  } catch (error) {
    throw error
  }
}

export { graphqlQuery, graphqlMutation, gql }
