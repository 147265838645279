import { useEffect, useRef } from 'react'

export const useCheckIfMounted = () => {
  const isMounted = useRef(true)
  useEffect(
    () => () => {
      isMounted.current = false
    },
    [],
  )
  return isMounted.current
}
