import { type Delivery } from './delivery.types'

export enum VehicleNumberEnum {
  LESS_THAN_10 = 'LESS_THAN_10',
  BETWEEN_10_20 = 'BETWEEN_10_20',
  BETWEEN_20_50 = 'BETWEEN_20_50',
  MORE_THAN_50 = 'MORE_THAN_50',
  LESS_THAN_3 = 'LESS_THAN_3',
  BETWEEN_3_5 = 'BETWEEN_3_5',
  BETWEEN_5_10 = 'BETWEEN_5_10',
  MORE_THAN_10 = 'MORE_THAN_10',
}

export enum VehicleType {
  GAS = 'GAS',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
}

export type Vehicle = {
  id?: number
  vin?: string
  vehicleCode: string
  marque: string
  model: string
  type: VehicleType
  carType?: string
  deliveryId?: string
  delivery?: Delivery
  isDelivered?: boolean
  toRetrieve?: boolean
  imagesBefore?: DamagedCarImages
  imagesAfter?: DamagedCarImages
  garageImages?: CarImages
  essenceBefore?: number
  essenceAfter?: number
  mileageBefore?: number
  mileageAfter?: number
}

export type CarImages = {
  photo1: string
  photo2: string
  photo3: string
  photo4: string
  photo5: string
  photo6: string
}

export type DamagedCarImages = {
  topImage: string
  topLeftImage: string
  topRightImage: string
  bottomImage: string
  bottomLeftImage: string
  bottomRightImage: string
  // ExtraImages: string[];
}
