import TranslateIcon from '@mui/icons-material/Translate'
import { Button, Fade, IconButton, Paper, Popper, PopperPlacementType, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {}
const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Français',
    value: 'fr',
  },
  // {
  //   label: 'German',
  //   value: 'de',
  // },
  // {
  //   label: 'Dutch',
  //   value: 'nl',
  // },
]

const ChangeLangauge = (props: Props) => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState<PopperPlacementType>()
  const [currentLang, setCurrentLang] = React.useState(i18n.language)

  const handleClick =
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
      setOpen((prev) => placement !== newPlacement || !prev)
      setPlacement(newPlacement)
    }
  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setCurrentLang(value)
    setOpen(false)
  }

  // listen to escape to hide the popper
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  return (
    <>
      <Popper sx={{ zIndex: 1200 }} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                minWidth: '200px',
                maxWidth: '300px',
                display: 'flex',
                justifyContent: 'center',
                padding: 1,
              }}
            >
              <Stack direction={'column'} gap={1} width={'80%'}>
                {languages.map((lang) => (
                  <Button
                    variant={currentLang === lang.value ? 'contained' : 'outlined'}
                    key={lang.value}
                    onClick={() => changeLanguage(lang.value)}
                  >
                    {lang.label}
                  </Button>
                ))}
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton
        sx={{
          marginInline: 2,
        }}
        onClick={handleClick('bottom')}
      >
        <TranslateIcon
          sx={{
            fill: 'white',
          }}
        />
      </IconButton>
    </>
  )
}

export default ChangeLangauge
