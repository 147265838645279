import * as React from 'react'
import Box from '@mui/material/Box'

import TextField from '@mui/material/TextField'
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { createAdmin } from '@api/graphql/admin'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

type Props = {}

const AddAdminForm = (props: Props) => {
  const { t } = useTranslation()
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget) as any
    const data = Object.fromEntries(formData.entries())

    const { confirmPassword, ...rest } = data

    if (data.password !== data.confirmPassword) {
      alert('Les mots de passe ne correspondent pas')
      return
    }
    rest.rank = Number(rest.rank)
    await createAdmin(rest as any)
      .then((res) => {
        toast(t('network.managers.created'), {
          position: 'bottom-right',
          type: 'success',
        })
      })
      .catch((err) => {
        err?.message.split(':')[1]
          ? toast(err?.message.split(':')[1], {
              position: 'bottom-right',
              type: 'error',
            })
          : toast(t('network.errors.unknown'), {
              position: 'bottom-right',
              type: 'error',
            })
      })
  }
  return (
    <Box component='form' onSubmit={handleSubmit} id='addAdmin'>
      <Typography
        id='transition-modal-title'
        variant='h5'
        component='h2'
        textTransform={'capitalize'}
        sx={{
          color: 'var(--secondary-color)',
          marginBottom: 4,
        }}
      >
        {t('common.shared.new-manager-account')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete='given-name'
            name='firstName'
            required
            fullWidth
            id='firstName'
            label={t('common.form.input.first-name')}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id='lastName'
            label={t('common.form.input.last-name')}
            name='lastName'
            autoComplete='family-name'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='email'
            label={t('common.form.input.email')}
            name='email'
            autoComplete='email'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name='password'
            label={t('common.form.input.password')}
            type='password'
            id='password'
            autoComplete='new-password'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name='confirmPassword'
            label={t('common.form.input.confirm-password')}
            type='password'
            id='confirmPassword'
            autoComplete='new-password'
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControl fullWidth required>
            <InputLabel id='demo-simple-select-label'>Admin Rank</InputLabel>
            <Select label={t('common.form.input.manager-rank')} name='rank' id='rank'>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
          {/*           <TextField required fullWidth name='rank' label='Rank' type='number' id='rank' />
           */}{' '}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddAdminForm
