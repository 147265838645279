import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Grid, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AlbInput from '@components/common/AlbInput'
import CancelDeliverySchema, { TCancelDelivery } from '@schemas/DeliveryForm/CancelDeliverySchema'
import { cancellationReasonKeys } from '@utils/constants/deliveryCancelReasons'

type props = {
  id: number
  handleClose: () => void
  handleCancelDelivery: (data: { id: number; reason: TCancelDelivery }) => void
}

const CancelDelivery = ({ handleClose, id, handleCancelDelivery }: props) => {
  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: {
      reason: null,
      additionalInfo: '',
    },
    resolver: zodResolver(CancelDeliverySchema),
  })

  const onSubmit = (data: TCancelDelivery) => {
    handleCancelDelivery({ id, reason: data })
  }

  const selectOptions = cancellationReasonKeys.map((key) => ({
    name: t(`delivery.cancelReason.${key}`),
    value: key,
  }))

  return (
    <FormProvider {...methods}>
      <Grid container component='form' onSubmit={methods.handleSubmit(onSubmit)} spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            {`${t('delivery.action.cancel.title')} ${id}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AlbInput name={'reason'} label={'Reason'} type='SelectField' options={selectOptions} />
        </Grid>
        <Grid item xs={12}>
          <AlbInput
            name={'additionalInfo'}
            type='TextAreaField'
            label={'Additional Info'}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='outlined'
            color='error'
            onClick={() => {
              methods.reset()
              handleClose()
            }}
          >
            {t('delivery.action.cancel.text')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant='contained' color='primary' type='submit'>
            {t('delivery.action.confirm.text')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default CancelDelivery
