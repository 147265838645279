import { SMSDiv } from '../StyledComponents'

const Cancel = () => (
  <>
    <SMSDiv>Vous avez signaler une anomalie</SMSDiv>
    <SMSDiv>
      Un conseiller ALB prendra contact avec vous dans les meilleurs délais afin de résoudre
      l'anomalie
    </SMSDiv>
  </>
)

export default Cancel
