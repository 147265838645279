import { useEffect, useState } from 'react'
import { useCalculateDeliveryPrice } from '../CalculateDeliveryPrice'

const useFormSessionsessionStorage = (formKey: string, formMethods) => {
  const { setValue, getValues } = formMethods

  // Load data from local storage when the component mounts
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem(formKey))
    if (storedData) {
      Object.keys(storedData).forEach((key) => {
        setValue(key, storedData[key])
      })
    }
  }, [])

  // Save data to local storage whenever form data changes
  const saveToSessionsessionStorage = (step: number) => {
    const dataToStore = { ...getValues(), step: ++step }
    sessionStorage.setItem(formKey, JSON.stringify(dataToStore))
  }

  // Reset local storage data when the form is reset
  const resetSessionsessionStorage = () => {
    sessionStorage.removeItem(formKey)
  }

  return { resetSessionsessionStorage, saveToSessionsessionStorage }
}

const useCalculateTotalPrice = (deliveryDetails) => {
  const [totalPrice, setTotalPrice] = useState(0)
  const calculateTotalPrice = async () => {
    return await Promise.all(
      (deliveryDetails || []).map(async (delivery) => {
        if (!Boolean(delivery.toAddress?.place_id)) return 0
        const { fetchPrice } = useCalculateDeliveryPrice({
          origin: delivery.fromAddress?.place_id,
          destination: delivery.toAddress?.place_id,
          category: delivery.category,
          numberOfCars: delivery.numberOfVehicles,
          options: delivery.options?.selected,
          carType: delivery.vehicle?.carType,
        })
        const currentPrice = await fetchPrice()
        return currentPrice?.price
      }),
    )
  }
  useEffect(() => {
    calculateTotalPrice().then((totalPrice) => {
      const sum = totalPrice.reduce((acc, price) => {
        return Number(acc) + Number(price)
      }, 0)
      setTotalPrice(sum)
    })
  }, [deliveryDetails])

  return { totalPrice }
}

export { useFormSessionsessionStorage, useCalculateTotalPrice }
