import { sendRequest } from '@api/clients/axios'
import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type CognitoUser } from '@types_def/models/user.type'

export default async function getUserInfo(): Promise<ApiGetResponse<CognitoUser>> {
  try {
    const res = await sendRequest<never, CognitoUser>('get', '/auth/me')
    return { success: true, data: res.data }
  } catch (error: unknown) {
    return { success: false }
  }
}
