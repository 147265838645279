import { type DriverRatingStats } from '@types_def/models/driver.types'

import { sendRequest } from '../clients/axios'

type Output = {
  data: DriverRatingStats
}

export async function getDriversRatingStats() {
  return sendRequest<undefined, Output>('get', 'drivers/getDriverStats')
}
