import { type Vehicle } from '@types_def/models/vehicle.types'

import InformationRow from '../StyledComponents/InformationRow'

const Vehicles = ({ vehicles }: { vehicles: Vehicle[] }) => {
  const toRetrieveVehicle = vehicles.find((c) => c.toRetrieve)
  const mainVehicle = vehicles[0]
  return (
    <>
      <InformationRow
        title='Vehicule livré'
        value={
          mainVehicle.vehicleCode +
          ' ' +
          (mainVehicle.marque ?? '') +
          ' ' +
          (mainVehicle.model ?? '')
        }
      />
      {toRetrieveVehicle && (
        <InformationRow
          title='Vehicule restitué'
          value={
            toRetrieveVehicle.vehicleCode +
            ' ' +
            (toRetrieveVehicle.marque ?? '') +
            ' ' +
            (toRetrieveVehicle.model ?? '')
          }
        />
      )}
    </>
  )
}

export default Vehicles
