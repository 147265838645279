import { Grid } from '@mui/material'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { setDelivery } from '@api/graphql/delivery/updateDelivery'
import { type Delivery, DeliveryType } from '@types_def/models/delivery.types'
import { type Driver } from '@types_def/models/driver.types'
import CreateDeliveryDealDetails from './Details'
import styles from './index.module.scss'
import CreateDeliveryDealSkeleton from './Skeleton'
import CreateDeliveryDealTable from './Table'
import { toast } from 'react-toastify'
import { Mission } from '@components/Map/type'
import CustomMap from '@components/Map/index'
import { strToEnum } from '@utils/strEnum'
import { useDeliveryDetails } from '../DeliveryFormV2/Querys'
import PublishDeliveryFields from './fields'
import { QueryType } from '@types_def/common/query.types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAutoCompleteList } from '@api/graphql/generic/getAutoCompleteList'
import CreateDeliveryDealForm from './Form'

const CreateDeliveryDeal = () => {
  const { type } = useParams<Params>()
  const deliveryType = strToEnum<DeliveryType>(type)
  const fields: QueryType<Delivery> = {
    id: true,
    ...PublishDeliveryFields,
    ...(deliveryType === DeliveryType.TWO_WAY_DELIVERY
      ? {
          toReturnFromAddress: {
            postalCode: true,
            city: true,
            longitude: true,
            latitude: true,
            address: true,
          },
          toReturnToAddress: {
            postalCode: true,
            city: true,
            longitude: true,
            latitude: true,
            address: true,
          },
        }
      : {}),
  }

  const { data, isLoading, isError, error } = useDeliveryDetails(fields, [
    'CreateDeliveryDeal',
    deliveryType,
  ])
  const { data: drivers, isLoading: driversLoading } = useQuery({
    queryKey: ['select_drivers'],
    queryFn: async () => {
      const res = await getAutoCompleteList<Driver>(
        {
          filter: {
            confirmedByAlb: true,
            disabled: false,
          },
        },
        'Driver',
        ['id', 'firstName', 'lastName', 'email'],
      )
      return res.data.map((driver) => ({
        value: driver.id,
        label: `${driver.id} - ${driver.firstName} ${driver.lastName} (${driver.email.split('@')[0]})`,
      }))
    },
  })
  if (isError) {
    return <p>Error: {JSON.stringify(error)}</p>
  }
  return (
    <div className={`${styles.createDeliveryDeal}`}>
      {!isLoading && !driversLoading && drivers && data ? (
        <Content data={data} deliveryType={deliveryType} drivers={drivers} />
      ) : (
        <CreateDeliveryDealSkeleton />
      )}
    </div>
  )
}

const Content = ({
  data,
  deliveryType,
  drivers,
}: {
  data: Delivery
  deliveryType: DeliveryType
  drivers: { value: number; label: string }[]
}) => {
  const queryClient = useQueryClient()
  const navigation = useNavigate()

  const missions: Mission[] = [
    {
      status: data?.status,
      origin: {
        lng: data?.fromAddress.longitude,
        lat: data?.fromAddress.latitude,
      },
      destination: {
        lng: data?.toAddress.longitude,
        lat: data?.toAddress.latitude,
      },
      depart: data?.fromAddress.address,
      arrive: data?.toAddress.address,
      color: 'green',
      id: data?.id.toString(),
      restitution: data?.toReturnToAddress?.address,
      toReturn: {
        lng: data?.toReturnToAddress?.longitude,
        lat: data?.toReturnToAddress?.latitude,
      },
      type: data?.type,
      destinationCity: data?.toAddress.city,
      originCity: data?.fromAddress.city,
    },
  ]

  const onSubmit = (formData: Delivery) => {
    setDelivery(
      data.id,
      formData.clientFinalPrice,
      formData.wantedPrice,
      formData.carAvailabilityDate,
      formData.carPickUpDate,
      formData.carDeliveryDeadline,
      (formData.selectedDriver as unknown as { value: number } | undefined)?.value,
    )
      .then((_) => {
        toast('Contrat confirmé avec succès!', {
          position: 'bottom-right',
          type: 'success',
        })
        if ((formData.selectedDriver as unknown as { value: number } | undefined)?.value > 0) {
          navigation('/admin/pilot/delivery-table/set')
        } else {
          navigation('/admin/pilot/delivery-table/waiting-for-driver')
        }
        queryClient.invalidateQueries({
          queryKey: ['delivery', data.id.toString(), ['CreateDeliveryDeal', deliveryType]],
        })
      })
      .catch((e) => {
        toast(e ?? "Une erreur s'est produite, veuillez réessayer", {
          position: 'bottom-right',
          type: 'error',
        })
        console.error(e)
      })
  }
  return (
    <>
      <CreateDeliveryDealTable
        id={data?.publicId}
        date={data.carPickUpDate!}
        representative={data.representativeCompany ?? data.client.name ?? ''}
        login={data.fromAddress!.city!}
        logout={data.toAddress!.city!}
        numberOfVehicles={data.numberOfVehicles}
      />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <CustomMap data={missions} />
        </Grid>
        <Grid item xs={7}>
          <div className={`${styles.createDeliveryDealDetailsContainer}`}>
            <CreateDeliveryDealDetails
              data={data}
              login={data.fromAddress!.city!}
              logout={data.toAddress!.city!}
              distance={data.distance}
              duration={data.duration}
              isInIdf={data.toAddress?.isInIdf && data.toAddress?.isInIdf}
              isPeriodic={data.type === DeliveryType.TWO_WAY_DELIVERY}
            />
            <CreateDeliveryDealForm data={data} drivers={drivers} onSubmit={onSubmit} />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CreateDeliveryDeal
