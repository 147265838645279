import * as z from 'zod'
import errorMessages from '../errorMessages'

const MemberFormSchema = z.object({
  id: z.number(),
  assigneeFirstName: z
    .string({
      required_error: errorMessages.required,
    })
    .min(1, {
      message: errorMessages.required,
    }),
  assigneeLastName: z
    .string({
      required_error: errorMessages.required,
    })
    .min(1, {
      message: errorMessages.required,
    }),
  assigneePhone: z
    .string({
      required_error: errorMessages.invalidPhoneNumber,
    })
    .min(1, {
      message: errorMessages.required,
    }),
  assigneeEmail: z
    .string({
      required_error: errorMessages.required,
    })
    .min(1, {
      message: errorMessages.required,
    })
    .refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: errorMessages.invalidEmail,
    }),
  assigneeFunction: z.string().nullish().optional(),
  assigneeService: z.string().nullish().optional(),
  assigneeRank: z.number(), // Assuming this is expected to always be a number
})

type TMemberFormSchema = z.infer<typeof MemberFormSchema>

// Default values for a form reset or initial state
const MemberFormSchemaDefaultValues = {
  id: -1,
  assigneeFirstName: '',
  assigneeLastName: '',
  assigneePhone: '',
  assigneeEmail: '',
  assigneeFunction: '',
  assigneeService: '',
  assigneeRank: 6,
}

export {
  MemberFormSchema,
  MemberFormSchemaDefaultValues,
}
export type { TMemberFormSchema }
