import { StandardDeliverySchema } from '@schemas/DeliveryForm/DeliveryStandard'
import { TwoWayDeliverySchema } from '@schemas/DeliveryForm/DeliveryTwoWay'

export const Schemas = {
  'two-way-delivery': TwoWayDeliverySchema,
  standard: StandardDeliverySchema,
  'to-return': StandardDeliverySchema,
  okm: StandardDeliverySchema,
}

export type SchemaKeys = keyof typeof Schemas
export const parseSchema = (schemaKey: SchemaKeys, data: any) => {
  const res = Schemas[schemaKey].safeParse(data)
  return res.success ? res.data : {}
}
export const generateValidationFieldsObject = (schemaKey: SchemaKeys, settings?: any) => {
  return {
    'two-way-delivery': {
      0: [
        'clientFirstName',
        'clientLastName',
        'clientPhone',
        'clientEmail',
        'clientCompany',
        'clientSiret',
        'representativeFirstName',
        'representativeLastName',
        'representativePhone',
        'representativeEmail',
        'representativeCompany',
        'representativeSiret',
        settings?.one_date ? '' : 'carAvailabilityDate',
        settings?.one_date ? '' : 'carPickUpDate',
        'carDeliveryDeadline',
        //settings.no_docs ? '' : 'docs',
      ],
      1: ['fromAddress', 'toAddress', 'vehicles.0'],
      2: ['toReturnFromAddress', 'toReturnToAddress', 'vehicles.1'],
      3: ['category', 'options', 'garageOptions', 'comment'],
    },
    'to-return': {
      0: [
        'clientFirstName',
        'clientLastName',
        'clientPhone',
        'clientEmail',
        'clientCompany',
        'clientSiret',
        'representativeFirstName',
        'representativeLastName',
        'representativePhone',
        'representativeEmail',
        'representativeCompany',
        'representativeSiret',
        settings?.one_date ? '' : 'carAvailabilityDate',
        settings?.one_date ? '' : 'carPickUpDate',
        'carDeliveryDeadline',
        //settings?.no_docs ? '' : 'docs',
      ],
      1: ['fromAddress', 'toAddress', 'vehicles'],
      2: ['category', 'options', 'garageOptions', 'comment'],
    },
    standard: {
      0: [
        'clientFirstName',
        'clientLastName',
        'clientPhone',
        'clientEmail',
        'clientCompany',
        'clientSiret',
        'representativeFirstName',
        'representativeLastName',
        'representativePhone',
        'representativeEmail',
        'representativeCompany',
        'representativeSiret',
        settings?.one_date ? '' : 'carAvailabilityDate',
        settings?.one_date ? '' : 'carPickUpDate',
        'carDeliveryDeadline',
        //settings?.no_docs ? '' : 'docs',
      ],
      1: ['fromAddress', 'toAddress', 'vehicles'],
      2: ['category', 'options', 'garageOptions', 'comment'],
    },
    okm: {
      0: [
        'clientFirstName',
        'clientLastName',
        'clientPhone',
        'clientEmail',
        'clientCompany',
        'clientSiret',
        'representativeFirstName',
        'representativeLastName',
        'representativePhone',
        'representativeEmail',
        'representativeCompany',
        'representativeSiret',
        settings?.one_date ? '' : 'carAvailabilityDate',
        settings?.one_date ? '' : 'carPickUpDate',
        'carDeliveryDeadline',
        //settings?.no_docs ? '' : 'docs',
      ],
      1: ['fromAddress', 'toAddress', 'vehicles'],
      2: ['category', 'options', 'garageOptions', 'comment'],
    },
  }[schemaKey]
}
