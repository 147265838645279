import { QueryType } from '@types_def/common/query.types'
import { Delivery } from '@types_def/models/delivery.types'

const PublishDeliveryFields: QueryType<Delivery> = {
  id: true,
  publicId: true,
  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,
  representativeCompany: true,
  numberOfVehicles: true,
  distance: true,
  duration: true,
  options: true,
  garageOptions: true,
  clientInitialPrice: true,
  clientFinalPrice: true,
  minPrice: true,
  wantedPrice: true,
  maxPrice: true,
  type: true,
  fromAddress: {
    postalCode: true,
    city: true,
    longitude: true,
    latitude: true,
    address: true,
  },
  toAddress: {
    city: true,
    postalCode: true,
    latitude: true,
    longitude: true,
    address: true,
  },
  client: {
    name: true,
  },
  selectedDriverId: true,
}

export default PublishDeliveryFields
