import { WhereFilter, type GetAllInput, DeepInclude } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import {
  DeliveryStatus,
  type Delivery,
  CreateDeliveryInput,
} from '@types_def/models/delivery.types'

import get from '../generic/get'
import getAllFiltered from '../generic/getAllFiltered'

export const getDelivery = async (id: string, fields: QueryType<Delivery>) => {
  const getDeliveryResponse = await get<Delivery>(id, 'Delivery', fields)
  return getDeliveryResponse
}

export const getDashboardDelivery = async (status: DeliveryStatus, slug: string) => {
  const getDDashboardsDeliveryInput: GetAllInput<Delivery, 'createdAt'> = {
    pagination: { page: 0, perPage: 10 },
    filter: {
      status,
      organization: {
        slug,
      },

      // ClientId: id,
    },
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
    include: {
      fromAddress: true,
      toAddress: true,
    },
  }
  const deliveryQuery: QueryType<Delivery> = {
    id: true,
    publicId: true,
    distance: true,
    status: true,
    type: true,
    totalHoursToEstimatedDate: true,
    remainingHoursToEstimatedDate: true,
    duration: true,
    fromAddress: {
      city: true,
    },
    toAddress: {
      city: true,
    },
  }
  const getDeliveryResponse = (
    await getAllFiltered<Delivery, 'createdAt'>(
      getDDashboardsDeliveryInput,
      'Delivery',
      deliveryQuery,
    )
  ).data
  return getDeliveryResponse
}

export const getAdminRequestedDelivery = async (id: string, fields: QueryType<Delivery>) => {
  const getDeliveryResponse = await get<Delivery>(id, 'Delivery', fields)
  return getDeliveryResponse
}

export const getAdminCreateDealDelivery = async (id: string) => {
  const deliveryQuery: QueryType<Delivery> = {
    id: true,
    carAvailabilityDate: true,
    carPickUpDate: true,
    carDeliveryDeadline: true,
    numberOfVehicles: true,
    distance: true,
    duration: true,
    options: undefined,
    garageOptions: undefined,
    twoWayDeliverType: true,
    minPrice: true,
    maxPrice: true,
    fromAddress: {
      postalCode: true,
      city: true,
      longitude: true,
      latitude: true,
      address: true,
    },
    toAddress: {
      city: true,
      postalCode: true,
      latitude: true,
      longitude: true,
      address: true,
    },
    client: { name: true },
    subscriptions: {
      id: true,
      name: true,
    },
  }
  const getDeliveryResponse = await get<Delivery>(id, 'Delivery', deliveryQuery)
  return getDeliveryResponse
}
const adminDeliveryQuery: QueryType<Delivery> = {
  id: true,
  status: true,
  type: true,
  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,
  options: undefined,
  fromAddress: {
    longitude: true,
    latitude: true,
    address: true,
  },
  toAddress: {
    latitude: true,
    longitude: true,
    address: true,
  },
  selectedDriver: {
    id: true,
    fullName: true,
    avatar: true,
  },
  client: { name: true, assigneeFirstName: true, assigneeLastName: true },
  toReturnToAddress: {
    address: true,
    city: true,
    latitude: true,
    longitude: true,
  },
}
const adminOnGoingFilter: WhereFilter<Delivery> = {
  status: DeliveryStatus.ONGOING,
}
const defaultInclude: DeepInclude<Delivery> = {
  toReturnFromAddress: true,
  toReturnToAddress: true,
  fromAddress: true,
  toAddress: true,
  selectedDriver: true,
}
export const getDeliveries = async (
  filter: WhereFilter<Delivery> = adminOnGoingFilter,
  query: QueryType<Delivery> = adminDeliveryQuery,
  include: DeepInclude<Delivery> = defaultInclude,
  pagination: { page: number; perPage: number } = { page: 0, perPage: 10 },
) => {
  const getDDashboardsDeliveryInput: GetAllInput<Delivery, 'createdAt'> = {
    pagination,
    filter,
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
    include,
  }
  const getDeliveryResponse = await getAllFiltered(getDDashboardsDeliveryInput, 'Delivery', query)
  return getDeliveryResponse
}
