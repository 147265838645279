import InformationRow from '../StyledComponents/InformationRow'

const ContactInformation = ({ phone, email }: { phone: string; email: string }) => (
  <>
    <InformationRow title='Numéro de téléphone sur lequel vous êtes joignable' value={phone} />{' '}
    <InformationRow title='Email' value={email} />
  </>
)

export default ContactInformation
