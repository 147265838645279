import styled from 'styled-components'

import ArrowIcon from '@components/common/icons/arrow.icon'

const EndPointsSeperator = styled.div`
  position: relative;
  width: fit-content;
`
const EndPointsSeperatorLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #000;
`
const SeperatorArrowWrapper = styled.div`
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%);
`
const SeperatorArrow = styled.div`
  min-width: calc(22px + 0.5rem);
  width: fit-content;
  padding: 0.5rem 0;
  background: #c9ccd5;
  svg * {
    fill: #000;
    margin-right: 0;
  }
`

const MiddleArrow = () => (
  <EndPointsSeperator>
    <EndPointsSeperatorLine />
    <SeperatorArrowWrapper>
      <SeperatorArrow>
        <ArrowIcon />
      </SeperatorArrow>
    </SeperatorArrowWrapper>
  </EndPointsSeperator>
)

export default MiddleArrow
