import { gql } from '@apollo/client'
import { graphqlMutation } from '@api/clients/apollo'

type ApiSendResponse = {
  createOffer: CreateOfferInput
}
export type CreateOfferInput = {
  demandedPrice: number
  driverId: number
  deliveryId: number
  accepted: boolean
}

export async function sendOfferGraphql(CreateOfferInput: CreateOfferInput) {
  try {
    const res = await graphqlMutation<ApiSendResponse, { CreateOfferInput: CreateOfferInput }>(
      gql`
        mutation createOffer($CreateOfferInput: CreateOfferInput!) {
          createOffer(CreateOfferInput: $CreateOfferInput) {
            demandedPrice
            driverId
            deliveryId
            accepted
          }
        }
      `,
      { CreateOfferInput },
    )
    return res.data.createOffer
  } catch (error) {
    throw error
  }
}
