import { useEffect, useState } from 'react'

import getDeliveryStatistics from '@api/graphql/delivery/getStatsByStatus'
import { useCheckIfMounted } from '@hooks/mountingRef'
import { AdminDeliveryStats } from '@types_def/models/delivery.types'

import AdminDashboardDataBox from '../AdminDashboardDataBox/idex'
import AdminDeliveryTypesChart from '../AdminDashboardTypesChart'
import DeliveryAdminChart from '../DeliveryAdminChart'
import styles from './index.module.scss'

type Chart = { name: string; amount: number }

export const AdminDashboard = () => {
  const [statusChartData, setStatusChartData] = useState<Chart[]>([{ name: '', amount: 0 }])
  const isMountedRef = useCheckIfMounted()

  const getStatusStats = () => {
    getDeliveryStatistics().then((res) => {
      if (isMountedRef) {
        setStatusChartData(res)
      }
    })
  }

  useEffect(() => {
    getStatusStats()
  }, [])

  return (
    <div className={`${styles.adminDashboard}`}>
      <AdminDashboardDataBox title='Deals en cours' type={AdminDeliveryStats.ByStatus}>
        {statusChartData.length > 0 && (
          <DeliveryAdminChart type={AdminDeliveryStats.ByStatus} data={statusChartData} />
        )}
      </AdminDashboardDataBox>

      <AdminDashboardDataBox
        title='Performance historique  (nombre de livraisons)'
        type={AdminDeliveryStats.ByType}
      >
        <AdminDeliveryTypesChart />
      </AdminDashboardDataBox>
    </div>
  )
}
