import { type FC } from 'react'
import { useLocation } from 'react-router-dom'
import SearchBox from '@components/common/SearchBox'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
const LinksSubHeader: FC = ({ children }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navs = [
    {
      component: (
        <SearchBox
          searchKey={'deliveryId'}
          placeholder={t('common.form.input.filter-id')}
          key={'search-id'}
        />
      ),
    },
    {
      component: (
        <SearchBox
          searchKey={'vin'}
          placeholder={t('common.form.input.filter-vin')}
          key={'search-vin'}
        />
      ),
    },
  ]
  return (
    <div className={`${styles['sub-header']}`}>
      <div className={`${styles['button-container']}`}>{children}</div>
      {/pilot\/delivery-table/.exec(location.pathname) && (
        <div className={`${styles['ajouter-deal-search']}`}>{navs.map((nav) => nav.component)}</div>
      )}
    </div>
  )
}

export default LinksSubHeader
