import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { sendRawRequest } from '@api/clients/axios'

import { device } from '@styles/mediaQuery'
import { primaryColor } from '@styles/variables'
import { SelectedOption } from '@types_def/models/delivery.types'
import { extraOptions } from '@utils/constants/deliveryOptions'
type Props = {
  origin: string
  destination: string
  category: string
  numberOfCars?: number
  options: SelectedOption[]
  carType: 'A' | 'B' | 'C'
}

const Wrapper = styled.div`
  background-color: ${primaryColor};
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  @media ${device.mobileL} {
    text-align: left;
  }
`

type TuseCalculateDeliveryPrice = {
  origin: string
  destination: string
  category: string
  numberOfCars?: number
  options: SelectedOption[]
  carType: 'A' | 'B' | 'C'
  status?: string
}

export const useCalculateDeliveryPrice = ({
  origin,
  destination,
  category,
  numberOfCars,
  carType,
  options,
  status = 'PROFESSIONAL',
}: TuseCalculateDeliveryPrice) => {
  const fetchPrice = async () => {
    try {
      const data = (await sendRawRequest('post', 'delivery/estimateMissionPrice', {
        origin,
        destination,
        numberOfCars,
        status: 'PROFESSIONAL',
        carType,
      })) as any
      if (data.status === 201) {
        return {
          price: (
            Number(data.data.prices[category]) +
            Number(
              options.reduce((acc, curr: SelectedOption) => {
                const extraOption = extraOptions.find((option) => option.id === curr?.id)
                return acc + Number(extraOption?.price || 0)
              }, 0),
            )
          ).toFixed(2),
          prices: data.data.prices,
          distance: data.data.distance,
          duration: data.data.duration,
        }
      }
    } catch (error) {
      return error
    }
  }
  return { fetchPrice }
}

const CalculateDeliveryPrice = ({
  origin,
  destination,
  category,
  numberOfCars,
  options,
  carType,
}: Props) => {
  const { fetchPrice } = useCalculateDeliveryPrice({
    origin,
    destination,
    category,
    numberOfCars,
    carType,
    options,
  })

  const [data, setData] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    fetchPrice()
      .then((res) => {
        setData(res)
      })
      .catch((err) => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [data?.price, isLoading, options.length, origin, destination, category, carType, numberOfCars])

  if (!origin || !destination || !category || !carType || isLoading) {
    return (
      <Wrapper>
        Pour calculer le prix de la course, veuillez choisir un lieu de départ, un lieu d'arrivée et
        une date
      </Wrapper>
    )
  }

  if (isError) {
    return <Wrapper>Une erreur est survenue, veuillez réessayer</Wrapper>
  }

  const { price, distance, duration } = data

  return (
    <Wrapper>
      Prix: {price} &euro;, Distance: {distance?.text}, Durée: {duration?.text}
    </Wrapper>
  )
}

export default React.memo(CalculateDeliveryPrice)
