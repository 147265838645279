import { CircularProgress } from '@mui/material'

import styles from './index.module.scss'

export default function FullPageLoader() {
  return (
    <div className={styles['loading-container']}>
      <CircularProgress size={100} color='primary' value={50} />
    </div>
  )
}
