import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DeepInclude, type WhereFilter } from '@types_def/common/filter.types'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'
import { getDeliveryAttributesByStatus } from '@utils/functions/Delivery/DeliveryStatus/deliveryStatusAttributes'
import { HeadCell } from '@/components/common/table/EnhancedTableHead'
import { QueryType } from '@/types/common/query.types'
import { UserRole } from '@/types/models/user.type'
import { useUserContext } from '@/contexts/UserContext/index.context'

type TableState = {
  filter?: WhereFilter<Delivery>
  headCells: Array<HeadCell<Delivery>>
  include?: DeepInclude<Delivery>
  deliveriesTableQueryFields: QueryType<Delivery> | undefined
}

export const useTableState = (statusKey: DeliveryStatus, urlRole: 'admin' | 'client') => {
  const { userState } = useUserContext()
  const userRole = userState!['custom:role'] as UserRole
  const organizationId = userState!.organizationId
  const [searchParams] = useSearchParams()
  const idSearchParam = searchParams.get('deliveryId')
  const vinSearchParam = searchParams.get('vin')
  const deliveryCountries = searchParams.get('deliveryCountries')
  const deliveryType = searchParams.get('deliveryType')
  const deliveryServices = searchParams.get('deliveryServices')
  const carDeliveryDeadlineFrom = searchParams.get('carDeliveryDeadlineFrom')
  const carDeliveryDeadlineTo = searchParams.get('carDeliveryDeadlineTo')

  const [tableState, setTableState] = useState<TableState>({
    filter: {},
    headCells: [],
    deliveriesTableQueryFields: undefined,
  })

  useEffect(() => {
    const alertFilter: WhereFilter<Delivery> =
      statusKey === DeliveryStatus.ALERT
        ? {
            carDeliveryDeadline: {
              lte: DateTime.now().plus({ days: 2 }),
            },
            offers: {},
          }
        : {}

    setTableState({
      filter: {
        ...alertFilter,
        status: {
          in: getDeliveryAttributesByStatus(statusKey)[`${urlRole}TableStatusFilter`],
        },
      },
      headCells: getDeliveryAttributesByStatus(statusKey)[`${urlRole}HeadCells`],
      include: getDeliveryAttributesByStatus(statusKey)[`${urlRole}DeliveryInclude`],
      deliveriesTableQueryFields:
        getDeliveryAttributesByStatus(statusKey)[`${urlRole}DeliveriesTableQueryFields`],
    })
  }, [statusKey, urlRole])

  const organizationSlug = useMemo(
    () => (userRole === UserRole.ADMIN ? searchParams.get('organizationSlug') : organizationId),
    [searchParams, userRole, organizationId],
  )

  useEffect(() => {
    let filter = {}
    if (organizationSlug) {
      filter = {
        organization: {
          slug: {
            in: organizationSlug.split(','),
          },
        },
      }
    } else if (!organizationSlug) {
      if (userRole === UserRole.CLIENT) throw new Error('No Organization found for client account')
      filter = {}
    }
    setTableState((prevState) => {
      if (prevState.filter?.organization) delete prevState.filter.organization
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...filter,
        },
      }
    })
  }, [organizationSlug, setTableState, userRole])

  useEffect(() => {
    let filter = {}
    if (idSearchParam) {
      filter = {
        id: parseInt(idSearchParam),
      }
    } else {
      filter = {}
    }
    setTableState((prev) => {
      if (prev.filter?.id) {
        delete prev.filter.id
      }
      return {
        ...prev,
        filter: {
          ...prev.filter,
          ...filter,
        },
      }
    })
  }, [idSearchParam, setTableState])

  useEffect(() => {
    let filter = {}
    if (vinSearchParam) {
      filter = {
        vehicles: {
          some: {
            vin: {
              contains: vinSearchParam,
            },
          },
        },
      }
    } else {
      filter = {}
    }
    setTableState((prev) => {
      if (prev.filter?.vehicles) {
        delete prev.filter.vehicles
      }
      return {
        ...prev,
        filter: {
          ...prev.filter,
          ...filter,
        },
      }
    })
  }, [vinSearchParam, setTableState])

  // search by type
  useEffect(() => {
    if (deliveryType) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: {
            in: deliveryType.split(','),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          type: undefined,
        },
      }))
    }
  }, [deliveryType])

  // search by gte
  useEffect(() => {
    if (carDeliveryDeadlineFrom) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            gte: new Date(parseInt(carDeliveryDeadlineFrom)).toISOString(),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            gte: undefined,
          },
        },
      }))
    }
  }, [carDeliveryDeadlineFrom])

  // search by lte
  useEffect(() => {
    if (carDeliveryDeadlineTo) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            lte: new Date(parseInt(carDeliveryDeadlineTo)).toISOString(),
          },
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          carDeliveryDeadline: {
            ...prev.filter?.carDeliveryDeadline,
            lte: undefined,
          },
        },
      }))
    }
  }, [carDeliveryDeadlineTo])

  useEffect(() => {
    if (deliveryServices) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionIdFilter: deliveryServices,
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          optionIdFilter: undefined,
        },
      }))
    }
  }, [deliveryServices])
  // search by country
  // search by country
  useEffect(() => {
    if (deliveryCountries) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          OR: [
            {
              fromAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toReturnFromAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
            {
              toReturnToAddress: {
                country: {
                  in: deliveryCountries.split(','),
                },
              },
            },
          ],
        },
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          OR: undefined,
        },
      }))
    }
  }, [deliveryCountries])

  return { tableState, setTableState }
}
