import { type PropsWithChildren, useState } from 'react'

import { useUserContext } from '@contexts/UserContext/index.context'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'
import { UserRole } from '@types_def/models/user.type'

import styles from './index.module.scss'

type Props = {
  id: number
}

const CancelDeliveryContainer = ({ id, children }: PropsWithChildren<Props>) => {
  const role = useUserContext().userState?.['custom:role']?.toLowerCase()
  const status =
    role === UserRole.CLIENT.toLowerCase()
      ? DeliveryStatus.CANCELLED_BY_MANDATORY
      : DeliveryStatus.CANCELLED_BY_ADMIN
  if (!role) {
    return <i>Error</i>
  }
  return <div className={styles.container}>{children}</div>
}

export default CancelDeliveryContainer
