import { useQueryClient } from '@tanstack/react-query'
import { useUserContext } from '@contexts/UserContext/index.context'

export type TDeliveriesCount = {
  requestedCount: number
  confirmedCount: number
  onGoingCount: number
  waitingDriverCount: number
  setCount: number
  alertCount: number
  deliveredCount: number
  cancelledCount: number
}

const useDeliveriesCount = () => {
  const { userState } = useUserContext()
  const queryClient = useQueryClient()
  const role = userState?.['custom:role']?.toString()?.toLowerCase() || ''
  const updateDeliveriesCount = (key: keyof TDeliveriesCount, sign: '+' | '-') => {
    queryClient.setQueryData(['getDeliveriesCounts', role], (previous: TDeliveriesCount) => {
      return {
        ...previous,
        [key]: sign === '+' ? previous[key] + 1 : previous[key] - 1,
      }
    })
  }

  return {
    updateDeliveriesCount,
  }
}

export default useDeliveriesCount
