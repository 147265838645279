import { toast } from 'react-toastify'
import { sendRawRequest, sendRequest } from '@api/clients/axios'
import { Delivery } from '@types_def/models/delivery.types'

const downloadInvoice = (invoice: string) => {
  window.open(`${import.meta.env.VITE_APP_S3_MEDIA_BUCKET_URL}/${invoice}`)
}
const generateInvoice = async (id: number) => {
  try {
    const response = await sendRequest<null, Delivery>('get', `/invoice/downloadPDFMobile/${id}`)
    downloadInvoice(response.data.invoice)
  } catch (error) {
    toast.error('Une erreur se produit', {
      position: 'bottom-right',
    })
  }
}

export { downloadInvoice, generateInvoice }
