import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'

import { type LoginResponse } from '@types_def/common/auth.types'
import { type AxiosHttpError } from '@types_def/common/error.types'
import { setToken, storeAuthResult } from '@utils/tokenManagement.util'

axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL

setToken()

export default axios

export async function refreshToken() {
  const refreshToken: string | undefined =
    typeof window !== 'undefined' ? localStorage.getItem('RefreshToken') : null
  if (!refreshToken) {
    return false
  }

  try {
    const res = await axios.post<{ refreshToken: string }, AxiosResponse<LoginResponse>>(
      '/auth/refresh-token',
      { RefreshToken: refreshToken },
    )
    if (res.data.ChallengeName === undefined) {
      storeAuthResult(res.data)
    }

    return true
  } catch (e) {
    console.log(e)
  }

  return false
}

// Request without retry if 401
export async function sendRawRequest<T, R>(
  method: 'get' | 'post' | 'put' | 'patch',
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>,
) {
  if (method === 'get') {
    return axios.get<T, AxiosResponse<R>>(url, config)
  }

  return axios[method]<T, AxiosResponse<R>>(url, data, config)
}

// Request with retry if 401
export async function sendRequest<T, R>(
  method: 'get' | 'post' | 'put' | 'patch',
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>,
) {
  try {
    return await sendRawRequest<T, R>(method, url, data, config)
  } catch (error) {
    if (error.response?.status === 403) throw error.response.data.message
    const axiosError = error as Error | AxiosHttpError
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
      const success = await refreshToken()
      if (success) {
        return sendRawRequest<T, R>(method, url, data, config)
      }
    }
    throw error
  }
}
