import { Button, Divider, Grid, TextField, Typography } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { MuiTelInput } from 'mui-tel-input'
import { useTranslation } from 'react-i18next'
import ControlledInput from '@components/common/ControlledInput'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { ranksToArrayObject } from '@utils/rankToRole'
import { UserRank } from '@types_def/models/user.type'
import {
  MemberFormSchema,
  MemberFormSchemaDefaultValues,
  TMemberFormSchema,
} from '@schemas/OrganizationForm/member.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'

type TMemberForm = {
  title: string
  unUsedFields?: Array<keyof typeof MemberFormSchema.shape>
  disabledFields?: Array<keyof typeof MemberFormSchema.shape>
  onSubmit: (data: TMemberFormSchema) => Promise<void>
  values?: TMemberFormSchema
  label?: string
}
const MemberForm = ({
  title = 'Informations de Responsable',
  unUsedFields = [],
  disabledFields = [],
  onSubmit,
  values = MemberFormSchemaDefaultValues,
  label = 'Ajouter',
}: TMemberForm) => {
  const { t } = useTranslation()
  const methods = useForm<TMemberFormSchema>({
    resolver: zodResolver(MemberFormSchema),
    values: values,
    shouldUnregister: true,
  })
  const {
    formState: { errors, isSubmitting },
    getValues,
    trigger,
    register,
  } = methods
  const isFieldDisabled = (name: keyof typeof MemberFormSchema.shape) =>
    disabledFields.includes(name)
  const isFieldUnUsed = (name: keyof typeof MemberFormSchema.shape) => unUsedFields.includes(name)

  const memberFormFields: [keyof typeof MemberFormSchema.shape] = Object.keys(
    MemberFormSchema.shape,
  ) as [keyof typeof MemberFormSchema.shape]

  const submit = async (data: TMemberFormSchema) => {
    const validationKeys: Array<keyof typeof MemberFormSchema.shape> = memberFormFields.filter(
      (key) => !isFieldUnUsed(key) && !isFieldDisabled(key),
    )
    const isFormValid = await trigger(validationKeys)
    if (isFormValid) {
      const validData: TMemberFormSchema = validationKeys.reduce(
        (acc: TMemberFormSchema, key: keyof typeof MemberFormSchema.shape) => {
          //@ts-ignore
          acc[key] = data[key]
          return acc
        },
        {} as TMemberFormSchema,
      )
      onSubmit(validData).then(() => methods.reset())
    }
  }
  return (
    <FormProvider {...methods}>
      <Grid
        container
        direction={'row'}
        spacing={2}
        paddingBottom={2}
        component={'form'}
        onSubmit={(e) => {
          e.preventDefault()
          submit(methods.getValues())
        }}
      >
        {!isFieldUnUsed('id') ? (
          <input type='hidden' name='id' value={values.id} {...register('id')} />
        ) : null}
        <Grid item xs={12}>
          <Typography
            variant='h5'
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {t(`common.shared.${title}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ bgcolor: '#fe5d8d' }} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledInput name='assigneeFirstName' label={t('common.form.input.first-name')}>
            <TextField
              error={Boolean(errors?.assigneeFirstName)}
              helperText={
                Boolean(errors?.assigneeFirstName)
                  ? t(`common.form.errors.${errors?.assigneeFirstName?.message}`)
                  : ''
              }
              fullWidth
              variant='standard'
              InputLabelProps={Boolean(getValues('assigneeFirstName')) ? { shrink: true } : {}}
            />
          </ControlledInput>
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledInput name='assigneeLastName' label={t('common.form.input.last-name')}>
            <TextField
              error={Boolean(errors?.assigneeLastName)}
              helperText={
                Boolean(errors?.assigneeLastName)
                  ? t(`common.form.errors.${errors?.assigneeLastName?.message}`)
                  : ''
              }
              fullWidth
              variant='standard'
              InputLabelProps={Boolean(getValues('assigneeLastName')) ? { shrink: true } : {}}
            />
          </ControlledInput>
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledInput
            name='assigneePhone'
            disabled={isFieldDisabled('assigneePhone')}
            label={t('common.form.input.phone')}
          >
            <MuiTelInput
              error={Boolean(errors?.assigneePhone)}
              value={getValues('assigneePhone') ?? ''}
              variant='standard'
              InputLabelProps={Boolean(getValues('assigneePhone')) ? { shrink: true } : {}}
              helperText={
                Boolean(errors?.assigneePhone)
                  ? t(`common.form.errors.${errors?.assigneePhone?.message}`)
                  : ''
              }
              fullWidth
            />
          </ControlledInput>
        </Grid>

        <Grid item md={6} xs={12}>
          <ControlledInput
            name='assigneeEmail'
            disabled={isFieldDisabled('assigneeEmail')}
            label={t('common.form.input.email')}
          >
            <TextField
              error={Boolean(errors?.assigneeEmail)}
              helperText={
                Boolean(errors?.assigneeEmail)
                  ? t(`common.form.errors.${errors?.assigneeEmail?.message}`)
                  : ''
              }
              fullWidth
              variant='standard'
              InputLabelProps={Boolean(getValues('assigneeEmail')) ? { shrink: true } : {}}
            />
          </ControlledInput>
        </Grid>
        {!isFieldUnUsed('assigneeFunction') ? (
          <Grid item md={6} xs={12}>
            <ControlledInput
              name='assigneeFunction'
              disabled={isFieldDisabled('assigneeFunction')}
              label={t('common.form.input.function')}
            >
              <TextField
                error={Boolean(errors?.assigneeFunction)}
                helperText={
                  Boolean(errors?.assigneeFunction)
                    ? t(`common.form.errors.${errors?.assigneeFunction?.message}`)
                    : ''
                }
                fullWidth
                variant='standard'
                InputLabelProps={Boolean(getValues('assigneeFunction')) ? { shrink: true } : {}}
              />
            </ControlledInput>
          </Grid>
        ) : null}

        {!isFieldUnUsed('assigneeService') ? (
          <Grid item md={6} xs={12}>
            <ControlledInput
              name='assigneeService'
              disabled={isFieldDisabled('assigneeService')}
              label={t('common.form.input.service')}
            >
              <TextField
                error={Boolean(errors?.assigneeService)}
                helperText={
                  Boolean(errors?.assigneeService)
                    ? t(`common.form.errors.${errors?.assigneeService?.message}`)
                    : ''
                }
                fullWidth
                variant='standard'
                InputLabelProps={Boolean(getValues('assigneeService')) ? { shrink: true } : {}}
              />
            </ControlledInput>
          </Grid>
        ) : null}
        {!isFieldUnUsed('assigneeRank') ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant='standard'>
              <InputLabel id='level-label'>Rank</InputLabel>
              <ControlledInput
                name='assigneeRank'
                label={'rank'}
                disabled={isFieldDisabled('assigneeRank')}
              >
                <Select labelId='level-label'>
                  {ranksToArrayObject([
                    UserRank.ORGANIZATION_MANAGER,
                    UserRank.ORGANIZATION_MEMBER,
                  ]).map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </ControlledInput>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            type='submit'
            size='large'
            variant='contained'
            fullWidth
            disabled={isSubmitting}
            sx={{ mt: 4 }}
          >
            {label}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
export default MemberForm
