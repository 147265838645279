import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { type DateTime } from 'luxon'

import { frenchTimeDate } from '@utils/functions/customDates'

import styles from './index.module.scss'

type Props = {
  id: string
  date: DateTime
  representative: string
  login: string
  logout: string
  numberOfVehicles: number
}

const CreateDeliveryDealTable = ({
  id,
  date,
  representative,
  login,
  logout,
  numberOfVehicles,
}: Props) => (
  <Table aria-label='simple table' className={`${styles.createDeliveryDealTable}`}>
    <TableHead>
      <TableRow>
        <TableCell>Numéro de contrat :</TableCell>
        <TableCell>Date :</TableCell>
        <TableCell>Mandataire :</TableCell>
        <TableCell>Pick up :</TableCell>
        <TableCell>Livraison :</TableCell>
        <TableCell>Nombre de véhicules :</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{date ? frenchTimeDate(date) : <i>Error</i>}</TableCell>
        <TableCell>{representative ?? 'Arval'}</TableCell>
        <TableCell>{login}</TableCell>
        <TableCell>{logout}</TableCell>
        <TableCell>{numberOfVehicles}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
)

export default CreateDeliveryDealTable
