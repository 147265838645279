import { sendRawRequest } from '@api/clients/axios'

const updateMember = async (data: any) => {
  return await sendRawRequest<any, any>('post', '/organization/update-member', data)
}

const updateProfile = async (data: any) => {
  return await sendRawRequest<any, any>('post', '/organization/update-profile', data)
}

const deleteMember = async (data: any) => {
  return await sendRawRequest<any, any>('post', '/organization/delete-member', data)
}

const getOrganizationMembers = async (
  data: { slug?: string; take: number; page: number },
  role: 'members' | 'managers',
) => {
  const response = await sendRawRequest<any, any>('post', `/organization/get-all-${role}`, data)
  return response.data
}

const deleteOrganization = async (slug: string) => {
  return await sendRawRequest<any, any>('post', '/organization/delete', { slug })
}

export { getOrganizationMembers, updateMember, updateProfile, deleteMember, deleteOrganization }
