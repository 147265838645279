import React, { useEffect, useState } from 'react'
import { SelectedOption } from '@types_def/models/delivery.types'
import { TCarType } from '@types_def/models/delivery.types'

import {
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from '@mui/material'
import { Address } from '@types_def/models/address.types'
import { useWatch } from 'react-hook-form'
import { Vehicle } from '@types_def/models/vehicle.types'
import { useTranslation } from 'react-i18next'
import { useCalculateDeliveryPrice } from '../CalculateDeliveryPrice'

type PriceResponse = {
  duration: {
    text: string
    value: number
  }
  distance: {
    text: string
    value: number
  }
  price: number | string
  prices: {
    STANDARD: number | string
    EXPRESS: number | string
    OKM: number | string
  }
}

type TDeliveryPrice = {
  title: string
  fromAddress: Address
  toAddress: Address
  category: string
  numberOfVehicles: number
  options: { selected: SelectedOption[] }
  vehicle: Vehicle
}

const DeliveryPrice = ({
  title,
  fromAddress,
  toAddress,
  category,
  numberOfVehicles,
  options,
  vehicle,
}: TDeliveryPrice) => {
  const { t } = useTranslation()
  const [data, setData] = useState<PriceResponse | null>(null)
  useEffect(() => {
    if (
      toAddress?.place_id &&
      fromAddress?.place_id &&
      category &&
      numberOfVehicles &&
      options &&
      Boolean(vehicle?.carType)
    ) {
      const { fetchPrice } = useCalculateDeliveryPrice({
        origin: fromAddress.place_id,
        destination: toAddress.place_id,
        category: category,
        numberOfCars: numberOfVehicles,
        options: options.selected,
        carType: vehicle?.carType as TCarType,
      })
      fetchPrice().then((data) => {
        setData({ ...data })
      })
    }
  }, [
    fromAddress?.place_id,
    toAddress?.place_id,
    category,
    numberOfVehicles,
    options,
    vehicle?.carType,
  ])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <Typography variant='h6' textTransform={'capitalize'}>
                {title}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' sx={{ textTransform: 'capitalize' }}>
              {t('delivery.form.departure-city')}
            </TableCell>
            <TableCell align='right'>
              {fromAddress?.city ? fromAddress?.city : '--------'}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' sx={{ textTransform: 'capitalize' }}>
              {t('delivery.form.arrival-city')}
            </TableCell>
            <TableCell align='right'>{toAddress?.city ? toAddress?.city : '--------'}</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' sx={{ textTransform: 'capitalize' }}>
              {t('delivery.form.distance')}
            </TableCell>
            <TableCell align='right'>{data ? data.distance?.text : '--------'}</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' sx={{ textTransform: 'capitalize' }}>
              {t('delivery.form.duration')}
            </TableCell>
            <TableCell align='right'>{data ? data.duration?.text : '--------'}</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' sx={{ textTransform: 'capitalize' }}>
              <strong>{t('delivery.form.delivery-price')}</strong>
            </TableCell>
            <TableCell align='right'>
              <strong>{data ? data.price + ' €' : '--------'}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DeliveryPrice
