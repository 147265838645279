const MultipleCarsIcon = (styles) => (
  <svg
    width='20'
    height='16'
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={styles}
  >
    <path
      d='M1.12969 4.2L1.95938 1.70938C2.29969 0.688438 3.25625 0 4.33125 0H8.66875C9.74375 0 10.7 0.688438 11.0406 1.70938L11.8031 4H11.3313C9.825 4 8.4875 4.9625 8.00937 6.39375L7.3 8.52812C7.10313 8.66562 6.92188 8.82187 6.76562 9H2.5V10C2.5 10.5531 2.05219 11 1.5 11H1C0.447812 11 9.375e-06 10.5531 9.375e-06 10L0 6C0 5.20625 0.460938 4.52187 1.12969 4.2ZM3.30312 4H9.69687L9.14375 2.34188C9.075 2.13781 8.88437 2 8.66875 2H4.33125C4.11563 2 3.925 2.13781 3.85625 2.34188L3.30312 4ZM2.5 5.75C2.08594 5.75 1.75 6.08437 1.75 6.5C1.75 6.91563 2.08594 7.25 2.5 7.25C2.91406 7.25 3.25 6.91563 3.25 6.5C3.25 6.08437 2.91406 5.75 2.5 5.75ZM11.3313 5H15.6687C16.7437 5 17.7 5.6875 18.0406 6.70937L18.8719 9.2C19.5375 9.52187 20 10.2063 20 11V15C20 15.5531 19.5531 16 19 16H18.5C17.9469 16 17.5 15.5531 17.5 15V14H9.5V15C9.5 15.5531 9.05313 16 8.5 16H8C7.44688 16 7 15.5531 7 15V11C7 10.2063 7.4625 9.52187 8.12813 9.2L8.95938 6.70937C9.3 5.6875 10.2563 5 11.3313 5ZM11.3313 7C11.1156 7 10.925 7.1375 10.8562 7.34062L10.3031 9H16.6969L16.1437 7.34062C16.075 7.1375 15.8844 7 15.6687 7H11.3313ZM9.5 12.25C9.91562 12.25 10.25 11.9156 10.25 11.5C10.25 11.0844 9.91562 10.75 9.5 10.75C9.08438 10.75 8.75 11.0844 8.75 11.5C8.75 11.9156 9.08438 12.25 9.5 12.25ZM17.5 10.75C17.0844 10.75 16.75 11.0844 16.75 11.5C16.75 11.9156 17.0844 12.25 17.5 12.25C17.9156 12.25 18.25 11.9156 18.25 11.5C18.25 11.0844 17.9156 10.75 17.5 10.75Z'
      fill='#fff'
    />
  </svg>
)

export default MultipleCarsIcon
