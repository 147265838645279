import { type DateTime } from 'luxon'

import { type ApiGetResponse } from '@types_def/common/auth.types'
import { type Delivery, type DeliveryStatus } from '@types_def/models/delivery.types'

import { gql, graphqlQuery } from '../../clients/apollo'

type AssignDeliveryInput = {
  id: number
  driverId: number
  carAvailabilityDate: DateTime
  carPickUpDate: DateTime
  carDeliveryDeadline: DateTime
  status: DeliveryStatus | undefined
  wantedPrice?: number | undefined
  clientFinalPrice?: number | undefined
  isEditable?: boolean | undefined
}

export async function assignDelivery(
  assignDeliveryInput: AssignDeliveryInput,
): Promise<Delivery | undefined> {
  try {
    const res = await graphqlQuery<ApiGetResponse<Delivery>, { id: string }>(
      gql`
        mutation {
          data: assignDriverToDelivery(
            UpdateDeliveryDriverInput: {
              id: ${assignDeliveryInput.id}
              driverId: ${assignDeliveryInput.driverId}
              isEditable: ${assignDeliveryInput.isEditable}
              clientFinalPrice: ${assignDeliveryInput.clientFinalPrice}
              wantedPrice: ${assignDeliveryInput.wantedPrice}
              status: ${assignDeliveryInput.status}
              carAvailabilityDate: "${assignDeliveryInput.carAvailabilityDate}"
              carPickUpDate: "${assignDeliveryInput.carPickUpDate}"
              carDeliveryDeadline: "${assignDeliveryInput.carDeliveryDeadline}"
            }
          ) {
            id
          }
        }
      `,
    )
    return res.data.data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
